import React from "react";
import Bootstrap from "src/Bootstrap";
import Loading from "src/components/Loading/Loading";
import {getStyles} from "src/variables/DropVariables";
import {iDig_Wallet_Transactions} from "src/variables/C6";
import Skeleton from "react-loading-skeleton";
import MediaQuery from "react-responsive";
import {iAmProfileDescendant} from "src/views/Profile/Profile";
import classNames from "classnames";
import getWalletTransaction from "src/api/rest/getWalletTransaction";
import moment from "moment";

// core components

export const HISTORY_PATH = 'history'

export default class History extends React.Component<iAmProfileDescendant, {}> {
    constructor(props) {
        super(props);
    }

    componentDidMount() {

        const {users} = Bootstrap.bootstrap.state;

        const currentSelectedUser = this.props.params.userId.toString();

        const currentEditUser = users?.find(user => user.ID === currentSelectedUser)

        if (undefined === currentEditUser?.history) {

            getWalletTransaction()

        }

    }


    render() {

        const {users} = Bootstrap.bootstrap.state;

        const currentSelectedUser: number = parseInt(this.props.params.userId.toString());

        const currentEditUser = users?.find(user => user.ID === currentSelectedUser)

        const history : iDig_Wallet_Transactions[]|undefined = currentEditUser?.history || undefined;

        if (undefined === history) {

            return <Loading message={"Loading Transaction History!"} />

        }

        let currentDate : string = '';

        const dig = getStyles()


        return (
            <div className={classNames(dig.container, dig.my5)}>
                {
                    history.length === 0 ?
                        <h2 className={classNames(dig.textDark, dig.textCenter)}>No transactions.</h2>
                        :
                        history.map((entry, key) => {

                            if (undefined === entry?.created) {
                                return null
                            }

                            entry.created = moment(entry.created).format('MM/DD/YYYY').toString();

                            const newDate = !(entry.created === currentDate);

                            currentDate = entry.created;

                            return <div key={key}>
                                {newDate &&
                                    <div className={classNames(dig.mt5, dig.mb3)}>
                                        <h4 className={classNames(dig.ggTextPrimary, dig.textStart, dig.ggHistoryDate)}>
                                            {entry.created}
                                        </h4>
                                    </div>}

                                <div
                                    className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.bgWhite, dig.py4, dig.mb4, dig.border1, dig.borderRounded5)}>
                                    <div className={classNames(dig.col12, dig.colSm4)}>
                                        <h3 className={classNames(dig.ggTextColor2, dig.textStart, dig.ms4)}>{entry.comment}</h3>
                                    </div>
                                    {/* Only display on screen 425 (phone) */}
                                    <MediaQuery maxWidth={425}>
                                        <hr className={classNames(dig.borderTop1, dig.borderColorGg, dig.w100, dig.my3)}/>
                                    </MediaQuery>
                                    <div className={classNames(dig.col12, dig.colSm6)}>
                                        <h3 className={classNames(dig.ggTextColor2, dig.textStart, dig.ms4, dig.fwNormal)}>
                                            Transaction: {entry.id} - {entry.action}
                                        </h3>
                                    </div>
                                    <div className={classNames(dig.col12, dig.colSm2)}>
                                        <MediaQuery maxWidth={425}>
                                            <h3 className={classNames(dig.ggTextColor2, dig.textStart, dig.ms4, dig.mt3)}>{entry.amount}</h3>
                                        </MediaQuery>
                                        <MediaQuery minWidth={768}>
                                            <h3 className={classNames(dig.ggTextColor2, dig.textEnd, dig.me3)}>{entry.amount}</h3>
                                        </MediaQuery>
                                    </div>
                                </div>
                            </div>
                        })
                        || <div style={{'lineHeight': 4}}>
                            <Skeleton count={5} height={40} inline={true}/>
                        </div>
                }
            </div>
        );
    }
}

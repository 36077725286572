import {useEffect} from "react";
import getPosts, {ePost} from "src/api/rest/getPosts";
import Bootstrap from "src/Bootstrap";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import isAppLocal from "src/variables/isAppLocal";
import Sidebar from "src/views/Intel/Sidebar";
import Comments from "src/views/Intel/Comments";
import classNames from "classnames";

export const ARTICLE_PATH = "article";

// core components
export default function Article(props: WithRouter) {

    const dig = getStyles()

    const articleID = parseInt(props.params.articleId)

    useEffect(() => {
        getPosts({
            postId: articleID,
            queryPostType: ePost.INTEL
        })
    })

    const post = Bootstrap.bootstrap.state.posts?.find(post => post.ID === articleID)

    if (DropVariables.objectLoading(post)) {

        return <Loading message={"Loading article!"}/>

    }
    return <div className={classNames(dig.digMainBlogContent, dig.container)}>
        <div className={dig.row}>

            <Sidebar {...props} />

            <div className={dig.colSm9}>
                <main role="main">
                    <section>

                        <ExpandableBox header={post?.post_title ?? 'Loading article'}>
                            <div dangerouslySetInnerHTML={{__html: post?.post_content ?? 'Loading post content'}}/>
                        </ExpandableBox>

                    </section>
                </main>
                {isAppLocal && <Comments/>}
            </div>

        </div>
    </div>

}

import restApi, {GET} from "src/api/API";
import {C6, iDig_Comp_Evidence} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iCompetitions} from "src/variables/sharedInterfaces";


export default restApi<{compIds: number[]}, iDig_Comp_Evidence>({
    axios: DropVariables.axios,
    tableName: C6.dig_comp_evidence.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => ({
        [C6.SELECT]: [
            C6.dig_competitions.COMP_ID,
            C6.dig_comp_evidence.USER_ID,
            C6.dig_comp_evidence.TEAM_ID,
            C6.dig_comp_evidence.EVIDENCE_DESC,
            C6.dig_comp_evidence.EVIDENCE_EXTERNAL_URL,
        ],
        [C6.JOIN]: {
            [C6.INNER]: {
                [C6.dig_competitions.TABLE_NAME]: [
                    C6.dig_competitions.COMP_ID,
                    C6.dig_comp_evidence.COMP_ID
                ]
            },
        },
        [C6.WHERE]: {
            [C6.dig_comp_evidence.COMP_ID]: [C6.IN, request.compIds]
        }
    }),
    responseCallback: (response, request) => {

        let restData: iDig_Comp_Evidence[] = response?.data?.rest ?? [];

        updateRestfulObjectArray<iCompetitions>(request.compIds.map(compId => ({
            comp_id: compId,
            match_dispute_evidence: restData.find(evidence => evidence.comp_id === compId)
        })), 'comp_id', 'competitions');

    }
})

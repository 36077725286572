import classNames from "classnames";
import {toast} from "react-toastify";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import uploadImage from "src/api/hoc/uploadImage";
import Bootstrap from "src/Bootstrap";
import ProfileInvitationBanner from "src/components/Invitation/ProfileInvitationBanner";
import Loading from "src/components/Loading/Loading";
import Menu from "src/components/Menu/Menu";
import ProfilePicture from "src/components/ProfilePicture/ProfilePicture";
import {getStyles} from "src/variables/DropVariables";
import DropVariables from "src/variables/DropVariables";
import isGG from "src/variables/isGG";
import {iAmProfileDescendant, PROFILE_PATH} from "src/views/Profile/Profile";
import {HISTORY_PATH} from "src/views/Profile/History/History";
import styles from "./style.module.scss";
import {iCompetitions, iUser} from "src/variables/sharedInterfaces";
import {Link} from "react-router-dom";
import Tooltip from "../../../components/Tooltip/Tooltip";

export default function ProfileHeader(props: iAmProfileDescendant) {

    const {invitations, id, competitions} = Bootstrap.bootstrap.state

    const {currentEditUser} = props;

    if (DropVariables.undefinedOrNull(currentEditUser)) {

        return <Loading message={'Loading User Information'}/>

    }

    const dig = getStyles(styles)

    const myAccount: iUser | undefined = getCurrentLoggedInUser()

    //profile stats
    const getMyUpcomingCompetitions = (): iCompetitions[] => {
        const filteredCompetitions = competitions?.filter(
            competition => competition.registered_users?.includes(myAccount?.ID ?? 0)
        );
        return filteredCompetitions || [];
    };


    const myCompetitions: iCompetitions[] = getMyUpcomingCompetitions();

    const getMostPlayedCompetition = (): string | undefined => {
        const competitionCounts: { [key: string]: number } = {};

        myCompetitions.forEach((competition) => {
            const count = competitionCounts[competition?.code ?? 0] || 0;
            competitionCounts[competition?.code ?? 0] = count + 1;
        });

        let highestCount = 0;
        let mostPlayedCompetition: string | undefined = undefined;

        Object.keys(competitionCounts).forEach((competitionName) => {
                const count = competitionCounts[competitionName];
                if (count > highestCount) {
                    highestCount = count;
                    mostPlayedCompetition = competitionName;
                }
            }
        );

        return mostPlayedCompetition;
    };

    //earnings
    const totalEarnings = (account: iUser): number | undefined   => {

        if(undefined === myAccount || null === myAccount){
            return 0;
        }

        const history = account.history || [];

        const earnings = history
            .filter((transaction) => transaction.comment === 'PRIZE DISBURSEMENT')
            .reduce((total, transaction) => total + Number(transaction.amount), 0);
        return earnings ? parseInt(earnings.toFixed(2)) : 0.00;
    };



    return <div className={classNames(dig.container)}
                style={{marginTop: "20px"}}>
        <div className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentCenter)}>
            <div className={classNames(dig.col12, dig.colLg4, dig.dFlex, dig.alignItemsCenter, dig.mb3, dig.content)}>

                <button
                    className={classNames(dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.border0, dig.bgTransparent, dig.cursorPointer)}
                    onClick={uploadImage('profile_photo', undefined, (imageId: number, dataUriBase64: string)=> {

                        updateRestfulObjectArray<iUser>([{
                            ID: currentEditUser.ID,
                            profile_photo: dataUriBase64 // this will turn into a standard image uri later, but for now it's a base64 string (which will render fine)
                        }], "ID", "users")

                        toast.success('Profile Photo Updated (' + imageId + ')', DropVariables.toastOptions)
                    })}>

                    <Tooltip content={"Change profile photo?"}
                             direction="top">
                        <ProfilePicture user={currentEditUser}
                                        width={50}
                                        height={50} />                    </Tooltip>


                </button>
                <h1 className={classNames(dig.ms4, dig.dFlex, dig.flexColumn)}>
                    {currentEditUser.display_name}
                </h1>
            </div>

            <div className={classNames(dig.col12, dig.colLg5, dig.mb3)}>
                <div
                    className={classNames(
                        dig.row, dig.mx0, dig.p4, dig.digBgNeutral9, dig.dFlex, dig.alignItemsCenter, dig.w100, dig.rowCols2, dig.g0
                    )}>
                    <div className={dig.col}>
                        <div className={classNames(dig.digLightBlue, dig.fwBold)}>
                            MATCHES PLAYED
                        </div>
                        <h3 className={dig.textStart}>
                            {
                                myCompetitions?.filter(competition => competition.comp_type_id === 1).length
                            }
                        </h3>
                    </div>
                    <div className={dig.col}>
                        <div className={classNames(dig.fwBold, dig.digLightRed)}>
                            TOURNAMENTS PLAYED
                        </div>
                        <h3 className={dig.textStart}>
                            {
                                myCompetitions?.filter(competition => competition.comp_type_id === 2).length
                            }
                        </h3>
                    </div>
                    <div className={classNames(dig.col, dig.mt3)}>
                        <div className={classNames(dig.digLightBlue, dig.fwBold)}>
                            TOTAL EARNINGS
                        </div>
                        <h3 className={classNames(dig.textStart, "dig-positive-balance")}>
                            ${myAccount ? totalEarnings(myAccount) : 'Loading'}
                        </h3>
                    </div>
                    <div className={classNames(dig.col, dig.mt3)}>
                        <div className={classNames(dig.digLightRed, dig.fwBold)}>
                            FAVORITE GAME
                        </div>
                        <h3 className={dig.textStart}>
                            {getMostPlayedCompetition() || 'None'}
                        </h3>
                    </div>
                </div>
            </div>


            {isGG() && currentEditUser.isChild &&
                <div className={classNames(dig.col12, dig.colLg3)}>
                    <div className={classNames("profile_balance ", dig.border0)}>

                        <div className={dig.profileBalanceMain}>
                            <a href="/user/?profiletab=dig_deposit_tab"
                               title="Deposit Funds">
                                <i className={classNames("fa-plus-circle", dig.fas)}></i>
                            </a>
                            <div className={classNames(dig.actionTrayBalance, dig.inProfile)}>
                                <Link title="Account History"
                                      to={'/' + PROFILE_PATH + '/' + id + '/' + HISTORY_PATH + '/'}>
                                    <span className={"dig-positive-balance"}>{(myAccount?.funds) === undefined ? "0.00" : myAccount.funds}</span>
                                </Link>
                            </div>
                            <a href="/user/?profiletab=dig_withdraw_tab"
                               title="Withdraw Funds">
                                <i className={classNames("fa-minus-circle", dig.fas)}></i>
                            </a>
                        </div>
                        <div className={"text"}>
                            FUNDS
                            |{(myAccount?.funds) === undefined ? "0.00" : myAccount.funds}
                            <Link title="Account History" to={'/' + PROFILE_PATH + '/' + id + '/' + HISTORY_PATH + '/'}>
                                HISTORY
                            </Link>
                        </div>
                    </div>
                </div>}
        </div>

        {
            invitations ?
                (invitations.filter(invite => invite.user_id === id).length > 0 ?
                        <ProfileInvitationBanner invitations={invitations.filter(invite => invite.user_id === id)}/>
                        : null
                )
                : <Loading count={1} message={"Loading Invitation(s)"}/>
        }

        <Menu {...props}/>

    </div>


}

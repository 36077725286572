import classNames from "classnames";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import isGameBlocked from "src/api/hoc/isGameBlocked";
import Bootstrap from "src/Bootstrap";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {iUser} from "src/variables/sharedInterfaces";
import Loading from "src/components/Loading/Loading";
import getGameList from "src/api/rest/getGameList";
import isCOM from "src/variables/isCOM";
import {toast} from "react-toastify";


export const GAMES_PATH = 'games';

// core components
export default function Games(props: {
    handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
} & WithRouter) {

    useEffect(() => {

        getGameList();

    }, [])

    const bootstrap = Bootstrap.bootstrap;

    const dig = getStyles()

    const searchUser = props.params.userId ? parseInt(props.params.userId) : bootstrap.state.id;

    const whoami = getCurrentLoggedInUser()

    const currentEditUser: undefined | iUser = 0 === searchUser ? undefined : bootstrap.state.users?.find(user => user.ID === searchUser);

    const isMyChild = whoami?.isParent === true && whoami.children?.includes(currentEditUser?.ID ?? 0);

    const allowedGames = currentEditUser?.allowedGames || [];

    const isChild = currentEditUser?.isChild === true

    const gameGrid: any = bootstrap.state.games?.map((value, key) => {

        if (undefined === value.game_id) {
            return undefined
        }

        const isBlocked = false === isCOM() && isChild && false === allowedGames?.includes(value.game_id)

        console.log('isBlocked!!!! ', currentEditUser, isBlocked, isChild,
            allowedGames, value.game_id, isCOM(), 'isGameBlocked', isGameBlocked(value.game_id))

        const blockedStyle = isBlocked ? dig.blocked_games : ''

        const styleWrap = classNames(dig.cursorPointer, dig.game_row_item,
            dig.digBgNeutral2, dig.positionRelative, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter,
            dig.borderRounded5, blockedStyle);

        const wrap = (children: JSX.Element) => (undefined !== props.handleClick) ?
            <div
                onClick={isBlocked && !isMyChild ? undefined : props.handleClick}
                data-gameid={value.game_id}
                key={key}
                className={styleWrap}>{children}</div>
            : <Link
                to={isBlocked ? '#' : "/game/" + value.game_id}
                onClick={() => {
                    isBlocked && toast.error("This game is currently blocked, in order to play it please have your parent/guardian unblock the game in their parental controls", DropVariables.toastOptions)
                }}
                data-gameid={value.game_id}
                key={key}
                className={styleWrap}>{children}</Link>

        return wrap(("" === value.featured_image)
            ? <div
                className={classNames(dig.gameOverlay, dig.h100, dig.positionAbsolute, dig.dFlex,
                    dig.alignItemsCenter, dig.justifyContentCenter, dig.borderRounded5
                )}>
                <img src={value.game_logo_image}
                     className={classNames(dig.styleSvg, dig.digSecondaryFillColor, dig.borderRounded5)}
                     alt={value.name}/>
            </div>
            : <div
                data-gameid={value.game_id}
                className={classNames(dig.gameFeaturedImage, dig.positionRelative, dig.borderRounded5)}
                style={{background: "url(" + value.featured_image + ")"}}
                data-bg={value.featured_image}
                data-ll-status="loaded">
            </div>);

    });

    return <div className={classNames(dig.container, dig.p4, dig.pt5)}>

        <h1>All Games</h1>

        {undefined === gameGrid &&
            <Loading message={"Loading games!"}/> ||
            <div className={classNames(dig.gameRow, dig.my5, dig["gg__overview-game-list"])}>{gameGrid}</div>}

    </div>;

}



import restApi, {iPostC6RestResponse, POST, removeInvalidKeys} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, dig_comp_invitation, iDig_Comp_Invitation} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iInvitation} from "src/variables/sharedInterfaces";


export function postInvitationCallback(request) {
    updateRestfulObjectArray<iInvitation>([{
        ...removeInvalidKeys(request, [dig_comp_invitation])
    }], "invitation_id", "invitations")
}

export default restApi<{},iDig_Comp_Invitation, {}, iPostC6RestResponse<iDig_Comp_Invitation>>({
        axios: DropVariables.axios,
        tableName: C6.dig_comp_invitation.TABLE_NAME,
        requestMethod: POST,
        queryCallback: (request) => {
            request.success = 'Invitation(s) Sent!'
            request.error = 'An unknown issue occurred while sending your invitation. We will be looking into this shortly.'
            return request
        },
        responseCallback: (_response, request, id) => {

            if ('number' !== typeof id) {

                return;

            }

            // here, we have id returned, but the socket listener will have id listed in request. Since it's different, we'll only have the listener call the callback function
            updateRestfulObjectArray<iInvitation>([{
                ...removeInvalidKeys(request, [dig_comp_invitation]),
                invitation_id: id,
            }], "invitation_id", "invitations")

        }
    });


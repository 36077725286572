import classes from "../../style.module.scss";


export default function DisplayHttpResponseCode(props: { httpCode: number}) {

    let {httpCode} = props;

    const statusMessages = [
        {httpCode: 200, codeMessage: 'OK'},
        {httpCode: 201, codeMessage: 'Created'},
        {httpCode: 200, codeMessage: 'OK'},
        {httpCode: 201, codeMessage: 'Created'},
        {httpCode: 202, codeMessage: 'Accepted'},
        {httpCode: 203, codeMessage: 'Non-Authoritative Information'},
        {httpCode: 204, codeMessage: 'No Content'},
        {httpCode: 205, codeMessage: 'Reset Content'},
        {httpCode: 206, codeMessage: 'Partial Content'},
        {httpCode: 207, codeMessage: 'Multi-Status (WebDAV)'},
        {httpCode: 208, codeMessage: 'Already Reported (WebDAV)'},
        {httpCode: 226, codeMessage: 'IM Used'},
        {httpCode: 300, codeMessage: 'Multiple Choices'},
        {httpCode: 301, codeMessage: 'Moved Permanently'},
        {httpCode: 302, codeMessage: 'Found'},
        {httpCode: 303, codeMessage: 'See Other'},
        {httpCode: 304, codeMessage: 'Not Modified'},
        {httpCode: 305, codeMessage: 'Use Proxy'},
        {httpCode: 306, codeMessage: '(Unused)'},
        {httpCode: 307, codeMessage: 'Temporary Redirect'},
        {httpCode: 308, codeMessage: 'Permanent Redirect (experimental)'},
        {httpCode: 400, codeMessage: 'Bad Request'},
        {httpCode: 401, codeMessage: 'Unauthorized'},
        {httpCode: 402, codeMessage: 'Payment Required'},
        {httpCode: 403, codeMessage: 'Forbidden'},
        {httpCode: 404, codeMessage: 'Not Found'},
        {httpCode: 405, codeMessage: 'Method Not Allowed'},
        {httpCode: 406, codeMessage: 'Not Acceptable'},
        {httpCode: 407, codeMessage: 'Proxy Authentication Required'},
        {httpCode: 408, codeMessage: 'Request Timeout'},
        {httpCode: 409, codeMessage: 'Conflict'},
        {httpCode: 410, codeMessage: 'Gone'},
        {httpCode: 411, codeMessage: 'Length Required'},
        {httpCode: 412, codeMessage: 'Precondition Failed'},
        {httpCode: 413, codeMessage: 'Request Entity Too Large'},
        {httpCode: 414, codeMessage: 'Request-URI Too Long'},
        {httpCode: 415, codeMessage: 'Unsupported Media Type'},
        {httpCode: 416, codeMessage: 'Requested Range Not Satisfiable'},
        {httpCode: 417, codeMessage: 'Expectation Failed'},
        {httpCode: 418, codeMessage: 'I\'m a teapot (RFC 2324)'},
        {httpCode: 420, codeMessage: 'Enhance Your Calm (Twitter)'},
        {httpCode: 422, codeMessage: 'Unprocessable Entity (WebDAV)'},
        {httpCode: 423, codeMessage: 'Locked (WebDAV)'},
        {httpCode: 424, codeMessage: 'Failed Dependency (WebDAV)'},
        {httpCode: 425, codeMessage: 'Reserved for WebDAV'},
        {httpCode: 426, codeMessage: 'Upgrade Required'},
        {httpCode: 428, codeMessage: 'Precondition Required'},
        {httpCode: 429, codeMessage: 'Too Many Requests'},
        {httpCode: 431, codeMessage: 'Request Header Fields Too Large'},
        {httpCode: 444, codeMessage: 'No Response (Nginx)'},
        {httpCode: 449, codeMessage: 'Retry With (Microsoft)'},
        {httpCode: 450, codeMessage: 'Blocked by Windows Parental Controls (Microsoft)'},
        {httpCode: 451, codeMessage: 'Unavailable For Legal Reasons'},
        {httpCode: 499, codeMessage: 'Client Closed Request (Nginx)'},
        {httpCode: 500, codeMessage: 'Internal Server Error'},
        {httpCode: 501, codeMessage: 'Not Implemented'},
        {httpCode: 502, codeMessage: 'Bad Gateway'},
        {httpCode: 503, codeMessage: 'Service Unavailable'},
        {httpCode: 504, codeMessage: 'Gateway Timeout'},
        {httpCode: 505, codeMessage: 'HTTP Version Not Supported'},
        {httpCode: 506, codeMessage: 'Variant Also Negotiates (Experimental)'},
        {httpCode: 507, codeMessage: 'Insufficient Storage (WebDAV)'},
        {httpCode: 508, codeMessage: 'Loop Detected (WebDAV)'},
        {httpCode: 509, codeMessage: 'Bandwidth Limit Exceeded (Apache)'},
        {httpCode: 510, codeMessage: 'Not Extended'},
        {httpCode: 511, codeMessage: 'Network Authentication Required'},
        {httpCode: 598, codeMessage: 'Network read timeout error'},
        {httpCode: 599, codeMessage: 'Network connect timeout error'}
    ];

    const statusMessage = statusMessages.find((statusMessage) => {
        return statusMessage.httpCode === httpCode;
    });


    return (
        <div key={httpCode}>
            <div className={classes.notfound}>
                <div className={classes.notfound_404}>
                    <h3>Oops! We ran into an error.</h3>
                    You have received a {statusMessage?.httpCode ?? '520'} error
                </div>
                <h1 style={{fontSize: '10em'}}>{statusMessage?.httpCode ?? '520'}</h1>
                <h2>{statusMessage?.codeMessage ?? 'unknown error message'}</h2>
            </div>
        </div>
    )
}
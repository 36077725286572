import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import getPosts, {ePost} from "src/api/rest/getPosts";
import getRegistrations from "src/api/rest/getRegistrations";
import getTeams from "src/api/rest/getTeams";
import Bootstrap from "src/Bootstrap";
import {eCOMPETITION, eCOMPETITION_CATEGORY, eCOMPETITION_STATUS, eCOMPETITION_TIMING, iCompetitions} from "src/variables/sharedInterfaces";
import {C6} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import getUsers from "src/api/rest/getUsers";
import {toast} from "react-toastify";
import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import getCompetitionMeta from "./getCompetitionMeta";
import getResults from "./getResults";
import getCompScores from "./getCompScores";
import getCompEvidence from "./getCompEvidence";
import getMatchConfirmations from "./getMatchConfirmations";
import moment from "moment";
import getSubMode from "./getSubMode";

export interface iGetCompetitions {
    competitionIds?: number[],
    competition?: eCOMPETITION,
    page?: number,
    userID?: number,
    category?: eCOMPETITION_CATEGORY,
    timing?: eCOMPETITION_TIMING,
    gameID?: number,
    fetchDependencies?: boolean,
    competitionTypeIds?: number[]
}

let competitionCache: number[] = [];

export function clearCompetitionCache() {
    competitionCache = []
}

export function removeCompetitionFromCache(comp_id: number) {
    competitionCache = competitionCache.filter(c => c !== comp_id);
}

export function getCompetitionMetaSubQuery(value: string, as: string): Array<any> {
    return [
        C6.SELECT,
        C6.dig_competitions_meta.TABLE_NAME,
        null,
        {
            [C6.SELECT]: [
                C6.dig_competitions_meta.META_VALUE
            ],
            [C6.WHERE]: {
                [C6.dig_competitions_meta.COMP_ID]: C6.dig_competitions.COMP_ID,
                [C6.dig_competitions_meta.META_KEY]: value
            }
        },
        as
    ];
}

interface iIntermediateCompetitionReturnValues {
    comp_id?: number,
    registered_users: string | string[] | number[],
    incomplete_teams: string | string[] | number[],
    complete_teams: string | string[] | number[],
    match_dispute_uploads: string | string[] | number[] | undefined, // used with left join
    vendors: string | string[],
    regions: string | string[],
    max_players: number,
}

export default restApi<iGetCompetitions, iCompetitions, {
    comp_id?: number
}, iGetC6RestResponse<iCompetitions, iIntermediateCompetitionReturnValues>>({
    axios: DropVariables.axios,
    tableName: C6.dig_competitions.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {

        const currentCompetitionState = Bootstrap.bootstrap.state.competitions;

        if (null === currentCompetitionState) {

            return undefined;

        }

        if (undefined === Bootstrap.bootstrap.state.competitions) {

            Bootstrap.bootstrap.setState({
                competitions: null
            })

        }


        getSubMode({});

        getPosts({
            queryPostType: ePost.GAME_RULES,
        })

        let competitionsQueue: number[] = [];


        /*if (1 < (request?.competitionIds?.length ?? 0)) {

            console.warn('to re-fetch cached data set request.cacheResults = false')

        } else */

        if (undefined !== request.competitionIds) {

            let competitionsToFetch: iCompetitions[] = [];

            request.competitionIds?.map(id => {

                if (false === competitionCache.includes(id)) {

                    competitionCache.push(id)

                    competitionsQueue.push(id)

                    let fetchComp: iCompetitions = {
                        comp_id: id
                    };

                    competitionsToFetch.push(fetchComp);

                    if (DropVariables.isLocal) {
                        toast.info("DEVS: Competition request was not found in cache. (" + id + ")", DropVariables.toastOptionsDevs);
                    }

                } else {

                    if (DropVariables.isLocal) {
                        toast.warning("DEVS: Competition found in cache. (" + id + ")", DropVariables.toastOptionsDevs);
                    }

                }

            })

            competitionsToFetch.length > 0 && updateRestfulObjectArray<iCompetitions>(competitionsToFetch, "comp_id", "competitions");

            if (0 === competitionsQueue.length) {

                return null;

            }

        }

        let {competition, page, userID, category, timing, gameID, competitionTypeIds} = request;

        const userIdSet = undefined !== userID;

        const query = {
            [C6.SELECT]: [

                C6.dig_competitions.COMP_ID,
                C6.dig_competitions.COMP_TYPE_ID,
                C6.dig_competitions.MODE_GAME_ID,
                C6.dig_competitions.BEST_OF,
                C6.dig_competitions.NOW_FLAG,
                C6.dig_competitions.DATETIME,
                C6.dig_competitions.PUBLIC,
                C6.dig_competitions.PIN,
                C6.dig_competitions.ORGANIZER_ID,
                C6.dig_competitions.STATUS,
                C6.dig_competitions.DELETED,
                C6.dig_competitions.CREATED,
                C6.dig_competitions.MODIFIED,

                C6.dig_competitions.BUY_IN,
                [C6.dig_custom_buy_in.BUY_IN, C6.AS, 'custom_buy_in'],

                C6.dig_competitions.PARENT_COMP,

                C6.dig_custom_prize_pool.PRIZE,

                // todo - these generate sub-select queries which are generally slow
                getCompetitionMetaSubQuery('_sponsor_photo', 'sponsor_photo'),

                getCompetitionMetaSubQuery('_dig_comp_title', 'competition_title'),

                getCompetitionMetaSubQuery('_guaranteed', 'guaranteed'),
                getCompetitionMetaSubQuery('_dig_comp_clan_tag', 'clan_tag'),
                getCompetitionMetaSubQuery('_custom_total_prize', 'custom_total_prize'),
                getCompetitionMetaSubQuery('_dig_comp_sub_mode', 'sub_mode'),
                getCompetitionMetaSubQuery('_custom_prize_pool_percentage', 'comp_percentage_custom'),

                C6.dig_game.NAME,
                C6.dig_game.GAME_ID,
                C6.dig_game.GAME_LOGO_IMAGE,
                C6.dig_game.FEATURED_IMAGE,
                C6.dig_game.CODE,

                C6.dig_comp_type.COMP_PERCENTAGE,

                [
                    C6.SELECT,
                    C6.dig_comp_registration.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            // [GROUP_CONCAT, table column, name (empty will give the default validated name), order by column, order by type (ASC or DESC)]
                            [C6.GROUP_CONCAT, C6.dig_comp_registration.TEAM_ID, "", C6.dig_comp_registration.TEAM_FULL_TIMESTAMP, C6.ASC]
                        ],
                        [C6.WHERE]: {
                            [C6.dig_comp_registration.COMPETITIONS_ID]: C6.dig_competitions.COMP_ID,
                            [C6.dig_comp_registration.TEAM_FULL_TIMESTAMP]: [C6.IS_NOT, C6.NULL]
                        }
                    },
                    'complete_teams'
                ],
                [
                    C6.SELECT,
                    C6.dig_comp_registration.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.dig_comp_registration.TEAM_ID]
                        ],
                        [C6.WHERE]: {
                            [C6.dig_comp_registration.TEAM_FULL_TIMESTAMP]: [C6.IS, C6.NULL],
                            [C6.dig_comp_registration.COMPETITIONS_ID]: C6.dig_competitions.COMP_ID
                        }
                    },
                    'incomplete_teams'
                ],
                [
                    C6.SELECT,
                    C6.dig_comp_registration.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.dig_comp_registration.USER_ID]
                        ],
                        [C6.WHERE]: {
                            [C6.dig_comp_registration.COMPETITIONS_ID]: C6.dig_competitions.COMP_ID,
                        }
                    },
                    'registered_users'
                ],
                [C6.GROUP_CONCAT, C6.dig_comp_platform.PLATFORM_ID, 'dig_comp_platforms'],
                [C6.GROUP_CONCAT, C6.dig_categories.CATEGORY_NAME, 'categories'],
                [C6.GROUP_CONCAT, C6.dig_vendor.VENDOR_NAME, 'vendors'],
                [C6.GROUP_CONCAT, C6.dig_dispute_posts.POST_ID, 'match_dispute_uploads'],
                [C6.GROUP_CONCAT, C6.dig_comp_region.REGION_ID, 'regions'],
                C6.dig_mode.MAX_PLAYERS,
                C6.dig_mode.MODE_ID,
                C6.dig_mode.NAME,
                C6.dig_mode.TEAM_SIZE,
                C6.dig_mode_game.RULES_POST_ID,
                C6.dig_mode_game.MODE_GAME_ID,
                C6.dig_comp_tourneytype.BRACKET
            ],
            [C6.JOIN]: {
                [C6.LEFT]: {
                    [C6.dig_comp_registration.TABLE_NAME]: {
                        [C6.dig_comp_registration.COMPETITIONS_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_custom_prize_pool.TABLE_NAME]: {
                        [C6.dig_custom_prize_pool.COMP_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_custom_buy_in.TABLE_NAME]: {
                        [C6.dig_custom_buy_in.COMP_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_comp_category.TABLE_NAME]: {
                        [C6.dig_comp_category.COMP_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_comp_platform.TABLE_NAME]: {
                        [C6.dig_comp_platform.COMP_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_comp_region.TABLE_NAME]: {
                        [C6.dig_comp_region.COMP_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_mode_game.TABLE_NAME]: {
                        [C6.dig_mode_game.MODE_GAME_ID]: C6.dig_competitions.MODE_GAME_ID,
                    },
                    [C6.dig_vendor_game.TABLE_NAME]: {
                        [C6.dig_vendor_game.PLATFORM_ID]: C6.dig_comp_platform.PLATFORM_ID,
                        [C6.dig_vendor_game.GAME_ID]: C6.dig_mode_game.GAME_ID
                    },
                    [C6.dig_vendor.TABLE_NAME]: {
                        [C6.dig_vendor.VENDOR_ID]: C6.dig_vendor_game.VENDOR_ID
                    },
                    [C6.dig_comp_type.TABLE_NAME]: {
                        [C6.dig_competitions.COMP_TYPE_ID]: C6.dig_comp_type.COMP_TYPE_ID,
                    },
                    [C6.dig_game.TABLE_NAME]: {
                        [C6.dig_game.GAME_ID]: C6.dig_mode_game.GAME_ID,
                    },
                    [C6.dig_mode.TABLE_NAME]: {
                        [C6.dig_mode.MODE_ID]: C6.dig_mode_game.MODE_ID,
                    },
                    [C6.dig_categories.TABLE_NAME]: {
                        [C6.dig_categories.ID]: C6.dig_comp_category.CAT_ID
                    },
                    [C6.dig_comp_registration.TABLE_NAME]: {
                        [C6.dig_comp_registration.COMPETITIONS_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_comp_tourneytype.TABLE_NAME]: {
                        [C6.dig_comp_tourneytype.COMP_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_custom_prize_pool.TABLE_NAME]: {
                        [C6.dig_custom_prize_pool.COMP_ID]: C6.dig_competitions.COMP_ID
                    },
                    [C6.dig_dispute_posts.TABLE_NAME]: {
                        [C6.dig_dispute_posts.COMP_ID]: C6.dig_competitions.COMP_ID
                    }
                },
            },
            [C6.WHERE]: {},
            [C6.GROUP_BY]: [
                C6.dig_game.NAME,
                C6.dig_competitions.DATETIME,
                C6.dig_custom_prize_pool.PRIZE,
                C6.dig_competitions.PIN,
                C6.dig_comp_type.COMP_PERCENTAGE,
                C6.dig_competitions.COMP_ID,
                C6.dig_comp_tourneytype.BRACKET,
                C6.dig_custom_buy_in.BUY_IN,
            ],
            [C6.PAGINATION]: {
                [C6.ORDER]: {
                    [C6.dig_competitions.DATETIME]: C6.ASC,
                },
                [C6.LIMIT]: 200,
                // page is handled by the api wrapper
            }
        };

        if (0 !== competitionsQueue.length) {

            query[C6.WHERE][C6.dig_competitions.COMP_ID] = [C6.IN, competitionsQueue];

        }

        if (competition !== eCOMPETITION.ALL &&
            competition !== undefined &&
            competition !== null) {

            query[C6.WHERE][C6.dig_competitions.COMP_TYPE_ID] = competition;

        }

        // Get comp by multiple comp_type_ids
        if (competitionTypeIds !== undefined && competitionTypeIds?.length > 0) {
            query[C6.WHERE][C6.dig_competitions.COMP_TYPE_ID] = [C6.IN, competitionTypeIds];

        }

        query[C6.PAGINATION][C6.PAGE] = page;

        if (userIdSet) {

            query[C6.WHERE][C6.dig_comp_registration.USER_ID] = userID;

        }

        if (gameID) {

            query[C6.WHERE][C6.dig_mode_game.GAME_ID] = gameID;

        }

        switch (timing) {

            case undefined:
            case eCOMPETITION_TIMING.ALL:
                query[C6.WHERE][C6.dig_competitions.STATUS] = [
                    C6.NOT_IN,
                    [
                        eCOMPETITION_STATUS.CANCELED,
                        eCOMPETITION_STATUS.PAID,
                        eCOMPETITION_STATUS.COMPLETED,
                    ]
                ];
                break;
            case eCOMPETITION_TIMING.PAST:
                query[C6.WHERE][0] = {
                    [C6.dig_competitions.DATETIME] : [C6.LESS_THAN, C6.NOW]
                };

                query[C6.WHERE][C6.dig_competitions.STATUS] = [
                    C6.NOT_IN,
                    [
                        eCOMPETITION_STATUS.CANCELED,
                        eCOMPETITION_STATUS.PAID,
                        eCOMPETITION_STATUS.COMPLETED,
                    ]
                ];
                break;
            case eCOMPETITION_TIMING.FUTURE:

                query[C6.WHERE][0] = {
                    [C6.dig_competitions.DATETIME]: [C6.GREATER_THAN_OR_EQUAL_TO, C6.NOW],
                    [C6.dig_competitions.NOW_FLAG]: 1,
                };

                query[C6.WHERE][C6.dig_competitions.PUBLIC] = 1;

                query[C6.WHERE][C6.dig_competitions.DELETED] = 0;

                query[C6.WHERE][C6.dig_competitions.PARENT_COMP] = 0;

                query[C6.WHERE][C6.dig_competitions.STATUS] = [
                    C6.NOT_IN,
                    [
                        eCOMPETITION_STATUS.STARTED,
                        eCOMPETITION_STATUS.CANCELED,
                        eCOMPETITION_STATUS.PAID,
                        eCOMPETITION_STATUS.COMPLETED,
                    ]
                ];

                break;
            case eCOMPETITION_TIMING.NOW: // Really comps from today and beyond
                query[C6.WHERE][0] = {
                    [C6.dig_competitions.DATETIME]: [C6.GREATER_THAN_OR_EQUAL_TO, C6.CURRENT_DATE], //C6.CURRENT_DATE
                    [C6.dig_competitions.NOW_FLAG]: 1,
                };

                query[C6.WHERE][C6.dig_competitions.PUBLIC] = 1;

                query[C6.WHERE][C6.dig_competitions.DELETED] = 0;

                query[C6.WHERE][C6.dig_competitions.PARENT_COMP] = 0;

                query[C6.WHERE][C6.dig_competitions.STATUS] = [C6.NOT_EQUAL, eCOMPETITION_STATUS.CANCELED];

                break;

        }

        if (undefined !== category && category !== null && category !== eCOMPETITION_CATEGORY.ALL) {

            query[C6.WHERE][C6.dig_categories.ID] = category

        }


        return query;

    },
    responseCallback: (response, request) => {

        let restData = response?.data?.rest || [];

        let uniqueUsers: number[] = [];

        let uniqueTeams: number[] = [];

        restData = restData.map((value) => {

            value.registered_users = 'string' === typeof value.registered_users
                && value.registered_users?.split(',') || [];

            value.registered_users = value.registered_users?.map((stringId: string) => {

                const id = parseInt(stringId);

                if (false === uniqueUsers.includes(id)) {

                    uniqueUsers.push(id);

                }

                return id;
            })

            value.incomplete_teams = 'string' === typeof value.incomplete_teams
                && value.incomplete_teams?.split(',') || [];

            value.incomplete_teams = value.incomplete_teams.map(stringId => {

                const id = parseInt(stringId);

                if (false === uniqueTeams.includes(id)) {
                    uniqueTeams.push(id)
                }

                return id;
            })

            value.complete_teams = 'string' === typeof value.complete_teams
                && value.complete_teams?.split(',') || [];

            value.complete_teams = value.complete_teams?.map(stringId => {

                const id = parseInt(stringId);

                if (false === uniqueTeams.includes(id)) {
                    uniqueTeams.push(id)
                }

                return id;

            }) ?? [];

            value.match_dispute_uploads = 'string' === typeof value.match_dispute_uploads
                && value.match_dispute_uploads?.split(',') || [];

            value.match_dispute_uploads = value.match_dispute_uploads.map(postID => {
                return parseInt(postID)
            })


            // if there are more complete teams than max_players (max teams), move the excess over to incomplete_teams (queue)
            while (value.complete_teams.length > (value.max_players ?? 0)) {
                // pop teams from the back of the complete_teams array and into the front of incomplete_teams (queue)
                let queueTeamId = value?.complete_teams?.pop();

                if (queueTeamId !== undefined) {
                    value.incomplete_teams.unshift(queueTeamId);
                }
            }

            value.vendors = 'string' === typeof value.vendors && value.vendors?.split(',') || [];
            value.regions = 'string' === typeof value.regions && value.regions?.split(',') || [];

            // Add end_datetime to the competition
            const timelimit = Bootstrap.bootstrap.state.subModes?.find((subMode) => subMode.sub_mode_id == value.sub_mode)?.time_limit;
            value.end_datetime = moment(value.datetime).add(timelimit, 'minutes').format("YYYY-MM-DD HH:mm:ss").toString()

            return value

        })

        updateRestfulObjectArray<iCompetitions>(restData as iCompetitions[], "comp_id", "competitions")

        if (true === request?.fetchDependencies) {

            if (undefined !== request.competitionIds && request.competitionIds.length > 0) {



                getCompetitionMeta({
                    competitionIds: request.competitionIds
                });

                getCompScores({
                    compIds: request.competitionIds
                });

                let bracketsToGatherResultsFor: number[] = []

                let matchesToGatherResultsFor: number[] = []

                restData.map(competition => {

                    if (undefined === competition.comp_id) return;

                    if (competition?.comp_type_id === eCOMPETITION.MATCHES && (competition?.status === eCOMPETITION_STATUS.COMPLETED || competition?.status === eCOMPETITION_STATUS.PAID)) {

                        if ((competition?.parent_comp ?? 0) !== 0) {

                            // bracket
                            bracketsToGatherResultsFor.push(competition.comp_id)

                        } else {

                            matchesToGatherResultsFor.push(competition.comp_id)

                        }

                    }


                })

                // result_upload = tournament score pictures
                // comp_evidence = match dispute info
                getCompEvidence({compIds: request.competitionIds})

                if (bracketsToGatherResultsFor.length > 0) {

                    getResults({
                        compIds: bracketsToGatherResultsFor,
                        comp_type_id: eCOMPETITION.BRACKETS,
                    });

                    getMatchConfirmations({compIds: bracketsToGatherResultsFor});

                }

                if (matchesToGatherResultsFor.length > 0) {

                    getResults({
                        compIds: matchesToGatherResultsFor,
                        comp_type_id: eCOMPETITION.MATCHES,
                    });

                    getMatchConfirmations({compIds: matchesToGatherResultsFor});

                }


                // noinspection JSIgnoredPromiseFromCall
                getRegistrations({
                    competitionIds: request.competitionIds
                });

            }

            if (0 !== uniqueUsers.length) {

                console.log('Getting User dependencies from getCompetitions; uniqueUsers:', uniqueUsers)

                // noinspection JSIgnoredPromiseFromCall
                getUsers({
                    userIds: uniqueUsers
                })

            }

            if (0 !== uniqueTeams.length) {

                console.log('Getting Team dependencies from getCompetitions; uniqueTeams:', uniqueTeams)

                // noinspection JSIgnoredPromiseFromCall
                getTeams({
                    teamIds: uniqueTeams
                })

            }

        }

    }
});

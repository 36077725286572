import classNames from "classnames";
import {useEffect} from "react";
import {getStyles} from "src/variables/DropVariables";

import TeamLogo from "src/assets/img/site-icon-new.png";
import getTeams from "../../api/rest/getTeams";
import Bootstrap from "src/Bootstrap";
import {TEAM_PATH} from "src/views/Team/Team";
import {Link} from "react-router-dom";
import deleteTeamRoster from "../../api/rest/deleteTeamRoster";
import getUsers from "../../api/rest/getUsers";
import putTeamRoster from "../../api/rest/putTeamRoster";
import {iInvitation} from "src/variables/sharedInterfaces";

interface iDisplayInvitation {
    invitation: iInvitation,
    darkMode: boolean
}

export default function ProfileInvitationTeamCard(props: iDisplayInvitation) {

    const {invitation, darkMode} = props;
    const {teams, users} = Bootstrap.bootstrap.state;

    useEffect(() => {
        invitation.team_id && getTeams({
            teamIds: [invitation.team_id]
        });

        invitation.sender_id && getUsers({
            userIds: [invitation.sender_id]
        });
    }, [])

    const teamInfo = teams?.find(team => team.user_team_id === invitation.team_id);

    const sender = users?.find(user => user.ID === invitation.sender_id);

    const dig = getStyles()

    return <div className={classNames(dig.colLg3, dig.colMd4, dig.colSm6, dig.mb4)}>
        <div className={classNames("card-border-0", dig.bgSuccess)}>
            <div className={classNames(dig.cardHeader, dig.dFlex, dig.justifyContentBetween, dig.digBgCardHeader, dig.p3)}>
                <div className={dig.matchTitle}>
                    <h4 className={classNames(dig.digColorNeutral1, dig.textUppercase)}>
                        Team
                    </h4>
                </div>
                <div className={classNames("match-id", dig.digColorNeutral1, dig.fwBold)}>
                    <span>ID: {invitation.team_id}</span>
                </div>
            </div>
            <div className={classNames(dig.overflowHidden, dig.dFlex, dig.w100, dig.justifyContentCenter,  dig.minHeight100Px, dig.alignItemsCenter)}>
                <img height="60" className={classNames(dig.rounded, dig.my4)} src={TeamLogo} alt="Team logo"/>
            </div>
            <div className={classNames(dig.cardBody, dig.px4, dig.py2 + (darkMode), dig.textLight, dig.textDark, dig.pb4)}>
                <a className={classNames(dig.dBlock, "font size xs", dig.pb1)} href="#">
                    <strong>{sender?.user_nicename}</strong> <br/> invited you to be a member of team:
                </a>
                <h4 className={classNames("font size sm", dig.textStart)}>
                    <Link to={"/" + TEAM_PATH + "/" + invitation.team_id}>
                        {teamInfo?.team_name}
                    </Link>
                </h4>

            </div>
            <div className={classNames(dig.cardBody, dig.px4, "card-body-hidden", dig.dFlex, dig.mt0, dig.py0)}>
                <button
                    className={classNames(dig.btn, dig.w100, "accept-btn", dig.btnLg, dig.digTextWhite, dig.digBtnGreen, dig.rounded0, dig.mb2, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter)}
                    type="button"
                    onClick={() => {

                        // need the roster id
                        if (Array.isArray(teamInfo?.rosterDetails)) {

                            let inviteeRoster = teamInfo?.rosterDetails?.find(roster => roster.uid === invitation.user_id);

                            inviteeRoster?.id && invitation.invitation_id && putTeamRoster({
                                id: inviteeRoster.id,
                                invitation_id: invitation.invitation_id,
                                userTeamId: invitation.team_id
                            });

                        }
                    }}>
                    Accept
                </button>
                <button
                    className={classNames(dig.btn, dig.w100, dig.btnLg, dig.digTextWhite, dig.digBtnRed, dig.rounded0, dig.mb2, "deny-btn", dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter)}
                    type="button"
                    onClick={() => {
                        invitation.user_id && invitation.team_id && deleteTeamRoster({
                            uid: invitation.user_id,
                            invitation_id: invitation.invitation_id,
                            user_team_id: invitation.team_id
                        });
                    }}>
                    Deny
                </button>
            </div>
            <div className={classNames(dig.cardBody, "card-body-hidden", dig.dFlex, dig.mt0, dig.pt0, dig.px4, dig.pb4)}>
                <Link
                    className={classNames(dig.btn, dig.btnLg, dig.rounded0, dig.dFlex, dig.w100, dig.digTextWhite, dig.digBtnBlue, dig.justifyContentCenter, dig.alignItemsCenter)}
                    to={"/" + TEAM_PATH + "/" + invitation.team_id}>
                    View
                </Link>
            </div>
        </div>
    </div>


}

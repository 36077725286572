import {useEffect} from "react";
import {toast} from "react-toastify";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import {globalNavigate} from "src/api/hoc/GlobalHistory";
import logout from "src/api/hoc/logout";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import Bootstrap from "src/Bootstrap";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {BOOTSTRAP_CUSTOM_PATH} from "src/DropInGaming";
import {iUsers} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";


export const ACTIVATION_PATH = 'activation';


let attemptedRequests: string[] = [];

export default function Activation(props: WithRouter) {

    const bootstrap = Bootstrap.bootstrap;
    // we need to handle the parent already logged in

    console.log('activation props', props);

    const myId = parseInt(props.params.id);

    const key = props.params.key;

    const url = '/' + BOOTSTRAP_CUSTOM_PATH + '/' + ACTIVATION_PATH + '/' + myId + '/' + key + '/'

    // todo - is already parent of child
    const whoami = getCurrentLoggedInUser();

    useEffect(() => {

        if (undefined !== whoami && whoami?.ID !== myId) {

            logout().then(() => {
              toast.warn('You were logged into another account, other thant the one you are trying to activate. We have logged you out.', DropVariables.toastOptions);
            });

            return;
        }

        if (attemptedRequests.includes(url)) {
            return;
        }

        attemptedRequests.push(url);

        bootstrap.state.axios.post(url, {})
            .then(_res => {

                updateRestfulObjectArray<iUsers>(previousState => {

                    const whoami = previousState.users?.find(user => user.ID === previousState.id);

                    if (!whoami) {
                        return [];
                    }

                    whoami.children ??= [];

                    whoami.children.push(myId);

                    return [
                        whoami
                    ];

                }, "ID", "users")

                toast.success('You accounts were linked successfully!', DropVariables.toastOptions);

                globalNavigate('/');

            }).catch(_error => {

            toast.error('Oh no! We have failed to activate your account. Please contact support if problems persist.', DropVariables.toastOptions);

        });
    }, []);


    if (whoami?.user_status !== 0) {

        globalNavigate('/');

        return <>You are already authenticated.</>

    }

    if (attemptedRequests.includes(url)) {

        return <ExpandableBox>We have already attempted to authenticated your accounts. Please refresh and try again or contact
            support if problems persist.</ExpandableBox>

    }

    return <Loading message={'Working to activate your account...'}/>

}

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import HomeStyles from "../../views/Home/style.module.scss";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import Bootstrap from "../../Bootstrap";
import getSliders from "../../api/rest/getSliders";

function NextArrow(props) {
    const {className, onClick} = props;
    const dig = getStyles(HomeStyles)
    return (
        <div
            className={classNames(className, dig.rightArrow)}
            onClick={onClick}
        />
    );
}

function PrevArrow(props) {
    const {className, onClick} = props;
    const dig = getStyles(HomeStyles)
    return (
        <div
            className={classNames(className, dig.leftArrow)}
            onClick={onClick}
        />
    );
}


export default function SimpleSlider() {

    const {sliders} = Bootstrap.bootstrap.state;

    useEffect(() => {
        getSliders()

    }, [sliders])

    const dig = getStyles(HomeStyles);

    const settings = {
        className: dig.digSlider,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        nextArrow: <NextArrow/>,
        prevArrow: <PrevArrow/>
    };


    return (<Slider {...settings}>

            {
                sliders?.map((slider, key) => {

                    const mobileImage = slider?.postmeta?.find(meta => meta.meta_key == 'mobile_slider_image_value')?.meta_value
                    const desktopImage = slider?.postmeta?.find(meta => meta.meta_key == 'desktop_slider_image_value')?.meta_value
                    const slider_heading = slider?.postmeta?.find(meta => meta.meta_key == 'slider_heading_value')?.meta_value
                    const slider_desc = slider?.postmeta?.find(meta => meta.meta_key == 'slider_desc_value')?.meta_value
                    const feature_link = slider?.postmeta?.find(meta => meta.meta_key == 'slider_link_value')?.meta_value
                    const feature_button_text = slider?.postmeta?.find(meta => meta.meta_key == 'slider_button_text_value')?.meta_value


                    return (<div key={key} className={classNames(dig.dFlex, dig.justifyContentCenter)}>
                        <section className={classNames(dig.hero)}>

                            <picture>
                                <source media="(max-width: 768px)" srcSet={mobileImage ? mobileImage : desktopImage}/>
                                <source media="(min-width: 769px)" srcSet={desktopImage}/>
                                <img src={desktopImage} alt="Responsive Image"/>
                            </picture>
                            <div className={classNames(dig.heroBg, dig.dFlex, dig.alignItemsEnd)}>
                                <div className={classNames(dig.intro, dig.pyLg3, dig.psLg3, dig.p2, dig.col12, dig.colLg3)}>
                                    {slider_heading && <h1 className={classNames(dig.textLight, dig.pbSm3, dig.textUppercase)}
                                                           style={{
                                                               fontSize: "1.0rem",
                                                           }}>
                                        {slider_heading}
                                    </h1>}

                                    {slider_desc && <p style={{
                                        fontSize: "1.0rem",
                                    }}>{slider_desc}</p>}

                                    <div className={classNames(dig.py4, dig.ptSm5)}>
                                        <Link className={classNames(
                                            dig.btn, dig.btnPrimary, dig.btnLg, dig.border0, dig.rounded0, dig.textUppercase, dig.fwBold
                                        )} to={feature_link || ''} data-scroll="">
                                            {feature_button_text}
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>)

                })

            }


        </Slider>
    );

}

import classNames from "classnames";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import styles from "src/style.module.scss";
import {ChangeEvent, useState} from "react";
import Bootstrap from "src/Bootstrap";
import postCreateTeam from 'src/api/rest/postCreateTeam';
import TeamNameValidation from "src/api/hoc/TeamNameValidation";
import {toast} from "react-toastify";
// import {Navigate} from "react-router-dom";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";

// import {TEAM_PATH} from "src/views/Team/Team";

interface iTournamentProperties extends WithRouter {
    onlyTeamSize?: number,
    handleClose: () => void
}

const teamSizes = [
    'Solos',
    'Duos',
    'Trios',
    'Squads',
    'Fives'
];

export default function CreateTeam(props: iTournamentProperties) {

    const dig = getStyles(styles)

    const {handleClose} = props;
    const {id, teams} = Bootstrap.bootstrap.state;

    const [teamName, setTeamName] = useState('');
    const [teamSize, setTeamSize] = useState(props.onlyTeamSize || 1);
    // const [redirect, setRedirect] = useState<string | undefined>(undefined);



    const create = async () => {
        // Validation
        const teamNameExists = teams?.find(team => team.team_name === teamName),
            isValidTeamName = TeamNameValidation(teamName);

        if (undefined !== teamNameExists) {
            toast.error('Team name has existed. Please choose a different name!', DropVariables.toastOptions);
            return;
        }

        if (teamSize === 0) {
            toast.error('Team size should be greater than 0', DropVariables.toastOptions);
            return;
        }

        if (isValidTeamName.length > 0) {
            toast.error(isValidTeamName[0], DropVariables.toastOptions);
            return;
        }

        postCreateTeam({
            team_name: teamName,
            uid: id,
            team_desc: '',
            team_picture: '',
            hide: 0,
            team_type: 0, // if the team is creating from Profile page
            team_size_id: teamSize,
            handleSuccess: handleClose
        })

    }

    // if (undefined !== redirect) {
    //     return <Navigate to={"/" + redirect} replace={true}/>
    // }


    return <div className={classNames("model-content", dig.rounded0, dig.border0)}>
        <div className={classNames(dig.modalHeader, dig.rounded0, dig.border0, dig.digBgCardHeader)}>
            <h3 className={classNames(dig.modalTitle, dig.textDark)} id="staticBackdropLabel">Add A Team</h3>
        </div>
        <div className={classNames(dig.modalBody, dig.border0, dig.digBgNeutral8, dig.digTextWhite)}>
            <form className={classNames(dig.mb0, dig.w100)}>
                <div className={classNames(dig.row, dig.mx0)}>
                    <div className={classNames(dig.col12, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter)}>
                        <div className={classNames(dig.w100, dig.m0)}>
                            <input className={classNames(dig.formControl, dig.rounded0, dig.border2, dig.textDark)}
                                   name="team_name" type="text" value={teamName}
                                   onChange={(e: ChangeEvent<HTMLInputElement>) => setTeamName(e.target.value)}
                                   placeholder="Enter team name"/>
                        </div>

                    </div>
                    <div className={classNames(dig.col12, dig.mb3)}>
                        <small className="text-mute">50 characters max., A-Z, 0-9, spaces, - and _ allowed.</small>
                    </div>
                    <div
                        className={classNames(dig.col12, dig.dFlex, dig.justifyContentLgBetween, dig.alignItemsCenter, dig.mb3)}>
                        <div className={classNames(dig.w100, dig.m0)}>
                            <div
                                className="dropdown bootstrap-select form-control form-control-lg rounded-0 border-2 border-color-neutral3 font-size-1p2em">
                                <select name="team_size_id"
                                        className={classNames("selectpicker", dig.formControl, dig.rounded0, dig.border2)}
                                        title="Select Sizes"
                                        disabled={props.onlyTeamSize !== undefined}
                                        defaultValue={teamSize}
                                        onChange={(e) => setTeamSize(parseInt(e.target.value))}
                                >
                                    {
                                        teamSizes.map((value, key) =>
                                            <option
                                                key={key}
                                                value={key + 1}
                                                //selected={teamSizes[teamSize] === value}
                                            >
                                                {value}
                                            </option>
                                        )
                                    }

                                </select>

                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div className={classNames(dig.modalFooter, dig.digBgNeutral7, dig.border0, dig.rounded0)}>
            <div
                className={classNames(dig.btn, dig.btnLg, dig.digBgNeutral3, "btn-no", dig.rounded0, dig.px4, dig.py2, dig.fontSize1Em, dig.textWhite, dig.fontWeightBold)}
                onClick={handleClose}>CLOSE
            </div>
            <div
                className={classNames(dig.btn, dig.btnLg, dig.digBtnBlue, "btn-Yes", dig.rounded0, dig.px4, dig.py2, dig.fontSize1Em, dig.textWhite, dig.fontWeightBold)}
                onClick={create}>SAVE
            </div>
        </div>
    </div>

}



import { useEffect } from "react";
import getOptions from "src/api/rest/getOptions";
import Bootstrap from "src/Bootstrap";
import Input from "src/components/Input/Input";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";

export const ADMIN_OPTIONS_PATH = 'admin-options';

export default function AdminOptions(_props: WithRouter) {


    useEffect(() => {

        getOptions({
            option_name: 'maintenanceMode'
        })

    })

    const maintenanceMode = Bootstrap.bootstrap.state.options?.find(option => option.option_name === 'maintenanceMode')?.option_value;

    return <>

        {undefined === maintenanceMode ? <Loading message={'Loading Maintenance Status'}/> : <Input typeof={'checkbox'} name={'maintenanceMode'} checked={'true' === maintenanceMode} label={'Maintenance Mode'}/>}

    </>


}
import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
//import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Feature_Group_References} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iGroups} from "src/variables/sharedInterfaces";

export default restApi<{
    feature_entity_id: number;
    group_entity_id: number;
}, iDig_Feature_Group_References, {}, iPostC6RestResponse<iDig_Feature_Group_References>>({
    axios: DropVariables.axios,
    tableName: C6.dig_feature_group_references.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success = 'Yay! That group is now associated with the feature!'
        request.error = 'There was an issue adding the feature to the group. Please try again.'
        return request;
    },
    responseCallback: (_response, request, success) => {

        if (!request.feature_entity_id || !request.group_entity_id || typeof success !== "boolean") {
            return;
        }

        updateRestfulObjectArray<iGroups>(previousState => {

            const fullGroup: iGroups | undefined = previousState.groups?.find((group: iGroups) => group.entity_id === request.group_entity_id);

            return fullGroup ? [fullGroup] : [];

        }, "entity_id", "groups")
    }
});


import {faEllipsisH} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Button from "src/components/Button/Button";
import {getStyles} from "src/variables/DropVariables";

import styleOverrides from "./style.module.scss"

interface iPaginate {
    currentPage: number,
    knownPages?: number,
    totalPages?: number,
    onClick: (page: number) => void,
}

export default function Pagination({currentPage, totalPages, onClick, knownPages}: iPaginate) {

    if (typeof knownPages === "number") {
        knownPages = Math.ceil(knownPages)
    }

    const dig = getStyles(styleOverrides)

    const menuItem = (page: number) => <Button isSelectable={true}
                                               isSelected={page === currentPage}
                                               className={classNames(dig.btn,dig.px3,dig.dInline, dig.rounded0,dig.ms2, dig.me2)}
                                               onClick={() => onClick(page)}>{page}</Button>

    const dots = <div className={classNames(dig.dInline, dig.m3)}>
        <FontAwesomeIcon color={'white'} icon={faEllipsisH} size={'lg'}/>
    </div>

    const largestKnownPage = totalPages ?? knownPages ?? 0;

    const pagesLeft =  largestKnownPage - currentPage;

    return <div className={dig.pagination}>

        {currentPage > 1 && <Button onClick={() => onClick(currentPage - 1)}>« Back</Button>}
        {currentPage > 5 && <>{menuItem(1)}{dots}</>}


        {currentPage > 4 && menuItem(currentPage - 4)}
        {currentPage > 3 && menuItem(currentPage - 3)}
        {currentPage > 2 && menuItem(currentPage - 2)}
        {currentPage > 1 && menuItem(currentPage - 1)}

        {menuItem(currentPage)}


        {pagesLeft > 0 && menuItem(currentPage + 1)}
        {pagesLeft > 1 && menuItem(currentPage + 2)}
        {pagesLeft > 2 && menuItem(currentPage + 3)}
        {pagesLeft > 3 && menuItem(currentPage + 4)}


        {pagesLeft > 4 && <>{dots}{menuItem(largestKnownPage)}</>}
        {largestKnownPage !== currentPage && <Button onClick={() => onClick(currentPage + 1)}>Next »</Button>}
    </div>
}

import restApi, {DELETE, iDeleteC6RestResponse} from "src/api/API";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";
import {C6, iDig_Comp_Invitation, iDig_Comp_Registration, iDig_Team_Roster, iDig_Vendor} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iCompetitions, iInvitation} from "src/variables/sharedInterfaces";
import Bootstrap from "src/Bootstrap";
import getRegistrations from "src/api/rest/getRegistrations";
import updateRestfulObjectArray from "../hoc/updateRestfulObjectArrays";
import getUsers from "./getUsers";
import isTest from "../../variables/isTest";

// the only time the frontend should call this is when accepting an invitation in which the invited user is already on the roster and accepted
// team_roster.id = roster_id && team_roster.position = 1
export interface iDeleteInvitation extends iDig_Comp_Invitation {
    invitation_id: number,
    id?: number,
    vendor_id?: number,
    vendor_name?: string,
    vendor_gamertag?: string,
    streaming_url?: string,
    streaming_platform?: string
}

export function deleteInvitationCallback(request) {
    let invitation = Bootstrap.bootstrap.state.invitations?.find(invitation => invitation.invitation_id === request.invitation_id),
        comp_id = request.vendor_id !== undefined ?
        Bootstrap.bootstrap.state.competitions?.find(
            comp => comp.comp_id === invitation?.comp_id
        )?.comp_id : undefined,
        user_id = invitation?.user_id ?? 0;

    deleteRestfulObjectArrays<iInvitation>((previousState): iInvitation[] => {
        // get the objects to delete only
        return previousState.invitations?.filter(invitation => invitation.invitation_id === request.invitation_id) ?? []
    }, "invitation_id", "invitations");

    if (false === isTest && user_id !== 0 && user_id === Bootstrap.bootstrap.state.id) {
        // re-fetch user for updated wallet
        getUsers({
            userIds: [
                user_id
            ],
            cacheResults: false,
        })
    }

    // only set if we have vendor id, which means the user accepted the invitation
    if (comp_id !== undefined) {

        invitation?.team_id !== undefined && updateRestfulObjectArray<iCompetitions>((prev) => {
            // we'll update this entry and return it
            let prevComp = prev.competitions?.find(competition => competition.comp_id === comp_id);

            if (prevComp === undefined) {
                return [];
            }

            let team = prev.teams?.find(team => team.user_team_id === invitation?.team_id);

            if (team === undefined) {
                return [];
            }

            // if anyone is in rosterPending, make no change. Otherwise, check if everyone has registration data
            if ((team.rosterPending?.length ?? 0) > 0) {
                return [];
            }

            let thisTeamRegistrations = prev.registrations?.filter(registration => registration.team_id === invitation?.team_id && registration.competitions_id === comp_id)?.length ?? 0;


            // we do plus one because our current registration isn't in state just yet
            if (thisTeamRegistrations + 1 === (prevComp.team_size ?? -1)) {
                prevComp.complete_teams ??= [];

                !prevComp.complete_teams.includes(invitation?.team_id ?? 0) && prevComp.complete_teams.push(invitation?.team_id ?? 0);

                prevComp.incomplete_teams = prevComp?.incomplete_teams?.filter(team => team !== invitation?.team_id) ?? [];
            }

            prevComp.registered_users ??= [];

            !prevComp.registered_users.includes(user_id) && prevComp.registered_users.push(user_id);

            return [prevComp];

        }, "comp_id", "competitions");

        // grab the registration that was just inserted
        getRegistrations({competitionIds: [comp_id], cacheResults: false});

    }
}

export default restApi<iDeleteInvitation, iDig_Comp_Invitation & iDig_Vendor & iDig_Comp_Registration & iDig_Team_Roster, {}, iDeleteC6RestResponse<iDig_Comp_Invitation & iDig_Vendor & iDig_Comp_Registration & iDig_Team_Roster>>(
        {
            axios: DropVariables.axios,
            tableName: [C6.dig_comp_invitation.TABLE_NAME, C6.dig_vendor.TABLE_NAME, C6.dig_comp_registration.TABLE_NAME, C6.dig_team_roster.TABLE_NAME],
            requestMethod: DELETE,
            queryCallback: (request) => {
                request.success = 'Successfully removed the invitation!'
                request.error = 'An unknown issue occurred removing the invitation. We will be looking into this shortly.'
                request.streaming_url ??= '';
                return request
            },
            responseCallback: (_response, request) => {

                deleteInvitationCallback(request);

            }
        });

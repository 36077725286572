import {faArrowUp} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import ScrollIntoViewDirective from "src/api/hoc/scrollIntoView";
import getChat from "src/api/rest/getChat";
import getNotifications from "src/api/rest/getNotifications";
import postChatGlobalMessages from "src/api/rest/postChatGlobalMessages";
import {ReactComponent as BracketSVG} from "src/assets/img/svg-icons/bracket.svg"
import {ReactComponent as MatchSVG} from "src/assets/img/svg-icons/match.svg"

// @link https://dev.to/abachi/how-to-change-svg-s-color-in-react-42g2
import {ReactComponent as TournamentSVG} from "src/assets/img/svg-icons/tournament.svg"
import {ReactComponent as EmojiSVG} from "src/assets/img/svg/emoji.svg"
import Bootstrap from "src/Bootstrap";
import Button from "src/components/Button/Button";
import ChatHeader from "src/components/Chat/ChatHeader/ChatHeader";
import Messages from "src/components/Chat/Messages/Messages";
// import Tournaments from "src/components/Chat/Tournaments/Tournaments";
import Loading from "src/components/Loading/Loading";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import Emojis from "src/variables/Emojis";
import ChatStyle from "./style.module.scss";
import {
    iUser,
    iCompetitions,
} from "src/variables/sharedInterfaces";
// import ProfilePicture from "../ProfilePicture/ProfilePicture";
// import {Link} from "react-router-dom";
// import {PROFILE_PATH} from "../../views/Profile/Profile";
import getCompetitions from "../../api/rest/getCompetitions";
import {Link} from "react-router-dom";
import {MATCHES_PATH, TOURNAMENTS_PATH} from "src/views/Profile/Profile";
import {COMPETITION_PATH} from "src/views/Competition/Competition";
import moment from "moment";
import getCurrentLoggedInUser from "../../api/hoc/getCurrentLoggedInUser";
import isGG from "src/variables/isGG";

export enum eGLOBAL_CHAT_TABS {
    NONE,
    NOTIFICATIONS,
    CHAT,
    MATCHES,
    TOURNAMENTS,
    BRACKETS,
    EMOJIS
}


export let globalChatInput: undefined | [chatInput: string, setChatInput: Dispatch<SetStateAction<string>>] = undefined;


export const CHAT_INPUT_PLACEHOLDER = "Enter a Message..."

export default function Chat() {


    console.log('CHAT Render')

    const bootstrap: Bootstrap = Bootstrap.bootstrap;

    const [chatInput, setChatInput] = useState('')
    const [inviteOthers, setInviteOthers] = useState<iCompetitions | undefined>(undefined)


    globalChatInput = [chatInput, setChatInput]

    const {id, rightSideBarTabOpened, globalMessages, competitions, darkMode} = bootstrap.state;
    let notificationsTab: JSX.Element[] | JSX.Element | null = null;


    const submitChat = () => {

        if (imAdmin) {

            if (inviteOthers) {
                alert('You are an admin inviting others to a competition. Name ' + inviteOthers.name + ' Code ' + inviteOthers.code + ' ID ' + inviteOthers.comp_id + ' Message ' + chatInput)
                postChatGlobalMessages({
                    global_message: `${chatInput}[::${inviteOthers.name} ${inviteOthers.code}](${inviteOthers.comp_id})`
                });
            } else {

                let matches;

                const aCommandWithALink = /^!(\S+)\s+(.+)\s+(https?:\/\/\S+)$/i;
                const aCommandWithAMessage = /^!(\S+)\s+(.+)$/i;
                const justACommand = /^!(\S+)$/i;


                if (chatInput.match(aCommandWithALink)) {
                    matches = chatInput.match(aCommandWithALink)
                } else if (chatInput.match(aCommandWithAMessage)) {
                    matches = chatInput.match(aCommandWithAMessage)
                } else if (chatInput.match(justACommand)) {
                    matches = chatInput.match(justACommand)
                } else {
                    postChatGlobalMessages({
                        global_message: chatInput
                    });
                }


                if (matches) {
                    const command = matches[1];
                    let additionalMessage = matches[2] || '';
                    let additionalLink = matches[3] || '';

                    if (command[0] === '1' || command[0] === '2' || command[0] === '3' || command[0] === '4' || command[0] === '5' || command[0] === '6' || command[0] === '7' || command[0] === '8' || command[0] === '9' || command[0] === '0') {
                        if (additionalMessage == '') {
                            alert("Please provide both a valid ID and message for the command.");
                            return;
                        }
                        postChatGlobalMessages({
                            global_message: `[::${additionalMessage}](/competition/${matches[1]})`,
                        });
                    } else {
                        switch (command) {
                            case 'Commands':
                            case 'commands':

                                alert(`Commands: 
                            !Twitch - posts the link to Twitch,
                            Message not needed, link not needed,
                            Example: !twitch // !Twitch Hello,
                            
                            !YouTube - posts the link to YouTube,
                            Message not needed, link not needed,
                            Example: !youtube // !YouTube Hello,
                            
                            !Twitter - posts the link to Twitter,
                            Message not needed, link not needed,
                            Example: !twitter // !Twitter Hello,
                            
                            !Discord - posts the link to Discord,
                            Message not needed, link not needed,
                            Example: !discord // !Discord Hello,
                            
                            !Link - posts a link,
                            Message needed, link needed,
                            Example: !link Hello https://example.com,
                            
                            !CompetitionID - posts a link to a competition,
                            Message needed, link not needed,
                            Example: !1234 Hello`);


                                Bootstrap.bootstrap.setState(
                                    prevState => {
                                        return {

                                            globalMessages: [

                                                {
                                                    global_message: 'Commands: !Twitch, !YouTube, !Twitter, !Discord, !Link, !CompetitionID',
                                                },
                                                ...(prevState.globalMessages ?? []),

                                            ]

                                        }

                                    }
                                )

                                break;

                            case 'Link':
                            case  'link':
                                if (additionalMessage === '' || !command) {
                                    alert("Please provide both a message and a valid URL for the !Link command.");
                                    break;
                                }
                                if (!matches[3]) {
                                    alert("A valid URL looks like this: https://example.com/something");
                                    break;
                                }
                                postChatGlobalMessages({
                                    global_message: `[::${additionalMessage}](${additionalLink})`,
                                });
                                break;

                            case 'Twitch':
                            case 'twitch':
                                additionalMessage == '' ? additionalMessage = 'Follow us on Twitch!' : additionalMessage;
                                postChatGlobalMessages({
                                    global_message: `[::${additionalMessage}](${'https://twitch.tv/dropingaming'})`
                                });
                                break;

                            case 'YouTube':
                            case 'youtube':
                                additionalMessage == '' ? additionalMessage = 'Subscribe to us on YouTube!' : additionalMessage;
                                postChatGlobalMessages({
                                    global_message: `[::${additionalMessage}](${'https://youtube.com/@drop-ingaming3489'})`
                                });
                                break;

                            case 'Twitter':
                            case 'twitter':
                                additionalMessage == '' ? additionalMessage = 'Follow us on Twitter!' : additionalMessage;
                                postChatGlobalMessages({
                                    global_message: `[::${additionalMessage}](${'https://twitter.com/dropingamingllc'})`
                                });
                                break;

                            case 'Discord':
                            case 'discord':
                                additionalMessage == '' ? additionalMessage = 'Follow us on Discord!' : additionalMessage;
                                postChatGlobalMessages({
                                    global_message: `[::${additionalMessage}](${'https://discord.com/invite/rhCQgHkNg3'})`
                                });
                                break;

                            default:
                                alert('Possible typo? For a list of all commands, type !Commands');
                        }
                    }
                }
            }


            //postChat for non admin
        } else if (inviteOthers) {
            postChatGlobalMessages({
                global_message: `${chatInput}[::${inviteOthers.name} ${inviteOthers.code}](${inviteOthers.comp_id})`
            });
        } else {
            postChatGlobalMessages({
                global_message: chatInput
            });
        }


        setChatInput('');
        setInviteOthers(undefined);
        bootstrap.setState({rightSideBarTabOpened: eGLOBAL_CHAT_TABS.CHAT});
    };


    const myAccount: iUser | undefined = getCurrentLoggedInUser();
    const imAdmin = myAccount?.user_is_admin || false


    const [today, setToday] = useState(() => moment().format('YYYY-MM-DD HH:mm:ss'));

    const getMyUpcomingCompetitions = (): iCompetitions[] | undefined => {
        if (undefined === myAccount) {
            return []
        }
        const filteredCompetitions = competitions?.filter(
            competition => myAccount.ID && competition.registered_users?.includes(myAccount.ID)
            // this line is commented out to show past competitions for testing.
            // && (competition.datetime ? Date.parse(competition.datetime) : 0) > Date.parse(today)
        );
        return filteredCompetitions || [];
    };

    const myCompetitions: undefined | iCompetitions[] = getMyUpcomingCompetitions();

    const chatAvailable = (!isGG() || myAccount?.isParent); // todo - add websocket check?

    const dig = getStyles(ChatStyle)


    useEffect(() => {

        if (rightSideBarTabOpened === eGLOBAL_CHAT_TABS.MATCHES || rightSideBarTabOpened === eGLOBAL_CHAT_TABS.BRACKETS || rightSideBarTabOpened === eGLOBAL_CHAT_TABS.TOURNAMENTS) {
            setToday(today)
            getCompetitions({
                userID: myAccount?.ID,
            });

        } else if (rightSideBarTabOpened === eGLOBAL_CHAT_TABS.CHAT
            && undefined === globalMessages) {
            getChat({
                error: 'Failed fetching global messages!',
            });

        } else if (rightSideBarTabOpened === eGLOBAL_CHAT_TABS.NOTIFICATIONS
            && myAccount?.notifications === undefined) {
            getNotifications();
        }


    })

    if (false === chatAvailable) {
        return null;
    }

    if (eGLOBAL_CHAT_TABS.NOTIFICATIONS === rightSideBarTabOpened) {
        notificationsTab = myAccount?.notifications?.map((value, index) => {

            let photoUrlPath = value.photo?.replace(/^[a-zA-Z]{3,5}:\/{2}[a-zA-Z0-9_.:-]+\//, '');

            if ('wp-content/themes/dropingaming/img/notifications/wallet-notifications.png' === photoUrlPath) {
                photoUrlPath = 'wp-content/themes/dropingaming/assets/img/notifications/wallet-notifications.png';
            }

            const compId = competitions?.map((comp) => {
                myAccount?.ID && comp.registered_users?.includes(myAccount.ID)
                return comp.comp_id
            })

            return <li
                className={classNames("pl-3 pr-3 pb-2 pt-2", dig.chatBoxListItem, dig.dFlex)}
                style={{height: 'fit-content', width: '100%'}}
                key={index}>
                <Link to={'/' + COMPETITION_PATH + '/' + compId} className={classNames("dig-chat-item-header ml-2")}>
                    <div className={classNames("font-size-1p2rem d-flex justify-content-between", dig.dFlex)}>
                        <div className={classNames("d-flex align-items-center dig_chat_profile_header")}
                             style={{width: 'inherit'}}>
                            {value.user}
                            <img
                                src={'/' + photoUrlPath}
                                className={classNames("gravatar avatar avatar-190 um-avatar um-avatar-default border-1 border-color-neutral8 rounded-0")}
                                width="20" height="20" alt=""/>
                        </div>
                        <p className={classNames("global_message_datetime dig-chat-item-header m-0")}>{DropVariables.convertPstToLocal(value.time)}</p>
                    </div>
                    <p className={classNames("userChatMessage mt-2 dig-color-neutral4")}
                       data-chat="Duo KR tonight?"
                       dangerouslySetInnerHTML={{__html: value.content ?? ''}}/>
                </Link>
            </li>

        }) || <Loading message={'Loading Notifications'}/>

        if (Array.isArray(notificationsTab) && notificationsTab.length === 0) {
            notificationsTab = <div className="alert alert-warning mt-5">
                <strong><i className="fas fa-radiation-alt"/></strong>
                <div>
                    There are no notifications to display.
                </div>
            </div>
        }
    }

    const chatClosed = eGLOBAL_CHAT_TABS.NONE === rightSideBarTabOpened;

    const color = darkMode ? 'white' : 'orange';

    const isLoggedIn = 0 !== id


    const inviteToCompetition = (comp: iCompetitions, index: number) => <div key={index} onClick={() => {
        setInviteOthers(comp)
    }}>

        <Link to={'/' + COMPETITION_PATH + '/' + comp.comp_id}
              className={classNames(dig.dFlex, dig.justifyContentBetween)}>
            <img
                style={{height: '80px', width: '80px', marginRight: '30px'}}
                src={comp.featured_image} alt={comp.code + ' ' + comp.name}/>
            <div style={{display: 'grid'}}>
                <h5 className={classNames("digChatCompName")}>{comp.code + ' ' + comp.name} </h5>
                <h5 className={classNames("digChatCompDetails")}>{comp.prize ?? 'Free'}</h5>
                <h5 className={classNames("digChatCompDetails")}>{(comp.now_flag ?? 0) === 0 && DropVariables.convertPstToLocal(comp.datetime)}</h5>
            </div>
            <i style={{display: 'flex', alignItems: 'center'}} className="fas fa-plus cursor-pointer"/>
        </Link>
    </div>;


    return <div className={classNames({
        [dig.colMd3]: true,
        [dig.digChatBox]: true,
        [dig.p0]: true,
        [dig.loggedIn]: isLoggedIn,
        [dig.dNone]: true === chatClosed
    })}>
        <div className={classNames(
            dig.w100,
            dig.stickyTop,
        )} style={{zIndex: "0"}}>

            <div>
                <ChatHeader/>
            </div>
            <div className={classNames(
                dig.listGroup, dig.listGroupFlush, dig.positionSticky, dig.bgTransparent
            )} style={{
                height: "calc( 100dvh - " + (isLoggedIn ? "20" : "13.5") + "rem )",
                overflowX: "hidden"
            }}>

                {notificationsTab}

                {eGLOBAL_CHAT_TABS.CHAT === rightSideBarTabOpened
                    && <Messages/>}

                {
                    eGLOBAL_CHAT_TABS.MATCHES === rightSideBarTabOpened &&
                    <div id="globalChatBodyBrackets"
                         style={{fontSize: 'x-large', height: 'inherit'}}
                         className={classNames("digChatBoxBodyBox", dig.digChatBoxBodyBox)}>
                        <div id="globalMessages">
                            <div
                                className={classNames("chatBoxListItem pl-3 pr-3 pb-2 pt-2 position-relative", dig.chatBoxListItem)}>

                                {
                                    (myCompetitions?.filter(competition => competition.comp_type_id === 1).length ?? 0) > 0
                                        ? myCompetitions?.filter(competition => competition.comp_type_id === 1).map(inviteToCompetition)
                                        : <>You have no upcoming matches. Why not <Link to={'/' + MATCHES_PATH}
                                                                                        style={{color: 'Orange'}}>Join
                                            One?</Link></>
                                }
                            </div>
                        </div>
                    </div>
                }

                {
                    eGLOBAL_CHAT_TABS.TOURNAMENTS === rightSideBarTabOpened &&
                    <div id="globalChatBodyBrackets"
                         style={{fontSize: 'x-large', height: 'inherit'}}
                         className={classNames("digChatBoxBodyBox", dig.digChatBoxBodyBox)}>
                        <div id="globalMessages">
                            <div
                                className={classNames("chatBoxListItem pl-3 pr-3 pb-2 pt-2 position-relative", dig.chatBoxListItem)}>
                                {
                                    (myCompetitions?.filter(competition => competition.comp_type_id === 2).length ?? 0) > 0 ?
                                        myCompetitions?.filter(competition => competition.comp_type_id === 2).map(inviteToCompetition)
                                        : <>You have no upcoming tournaments. Why not <Link
                                            to={'/' + TOURNAMENTS_PATH} style={{color: 'Orange'}}>Join One?</Link></>
                                }

                            </div>
                        </div>
                    </div>
                }

                {
                    eGLOBAL_CHAT_TABS.BRACKETS === rightSideBarTabOpened &&
                    <div id="globalChatBodyBrackets"
                         style={{fontSize: 'x-large', height: 'inherit'}}
                         className={classNames("digChatBoxBodyBox", dig.digChatBoxBodyBox)}>
                        <div id="globalMessages">
                            <div
                                className={classNames("chatBoxListItem pl-3 pr-3 pb-2 pt-2 position-relative", dig.chatBoxListItem)}>

                                {
                                    (myCompetitions?.filter(competition => competition.comp_type_id === 3).length ?? 0) > 0 ?
                                        myCompetitions?.filter(competition => competition.comp_type_id === 3).map(inviteToCompetition)
                                        : <>You are in no brackets. Why not <Link
                                            to={'/' + TOURNAMENTS_PATH + '/Brackets/'} style={{color: 'Orange'}}>Join
                                            One?</Link></>
                                }
                            </div>

                        </div>
                    </div>
                }


                {eGLOBAL_CHAT_TABS.EMOJIS === rightSideBarTabOpened &&
                    <div id="globalChatBodyEmojiPage"
                         className={classNames(dig.dFlex, dig.flexWrap, dig.flexRow, dig.btnLg, dig.justifyContentLgAround)}>
                        {Emojis.map((emoji, index) =>
                            <p key={index}
                               style={{height: 50, width: 50, fontSize: 'xx-large'}}
                               className={classNames(dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, 'emoji')}
                               onClick={() => {
                                   //const inputRef = chatInputRef.current?.value as HTMLInputElement
                                   //  inputRef.value = inputRef.value + emoji
                                   setChatInput(last => last.concat(emoji))
                               }}>{emoji}</p>)}
                    </div>}


                <div ref={(el: HTMLDivElement) => {
                    ScrollIntoViewDirective(el as Element)
                }}/>


            </div>

            {0 === id
                ? <div
                    className={classNames(dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.chatInputBox)}>
                    <Button onClick={() => {
                        Bootstrap.bootstrap.setState({loginPopup: true})
                    }}>JOIN THE CONVERSATION</Button></div>
                : <>
                    {undefined !== inviteOthers && <div className={dig.col12}>

                        <p style={{
                            fontSize: 'large',
                            border: '1px solid white',
                            color: 'white',
                            bottom: '1px',
                            width: '100%',
                            alignItems: 'center',
                            paddingLeft: '5px',
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}>
                            {inviteOthers && <>{'Invite others to: ' + inviteOthers.code + ' ' + inviteOthers.name}</>}
                            <h2 onClick={() => setInviteOthers(undefined)}> ❌</h2>
                        </p>

                    </div>}

                    <div className={classNames(dig.row, dig.textCenter, dig.m4)}>
                        <div className={classNames(dig.col3)}>
                            <MatchSVG
                                fill={color}
                                style={{height: "25px"}}
                                onClick={() => bootstrap.setState({
                                    rightSideBarTabOpened: rightSideBarTabOpened === eGLOBAL_CHAT_TABS.MATCHES ? eGLOBAL_CHAT_TABS.CHAT : eGLOBAL_CHAT_TABS.MATCHES,
                                })}/>
                        </div>
                        <div className={classNames(dig.col3)}>
                            <TournamentSVG
                                fill={color}
                                style={{height: "25px"}}
                                onClick={() => bootstrap.setState({
                                    rightSideBarTabOpened: rightSideBarTabOpened === eGLOBAL_CHAT_TABS.TOURNAMENTS ? eGLOBAL_CHAT_TABS.CHAT : eGLOBAL_CHAT_TABS.TOURNAMENTS,
                                })}/>
                        </div>
                        <div className={classNames(dig.col3)}>
                            <BracketSVG
                                fill={color}
                                style={{height: "25px"}}
                                onClick={() => bootstrap.setState({
                                    rightSideBarTabOpened: rightSideBarTabOpened === eGLOBAL_CHAT_TABS.BRACKETS ? eGLOBAL_CHAT_TABS.CHAT : eGLOBAL_CHAT_TABS.BRACKETS,
                                })}/>
                        </div>
                        <div className={classNames(dig.col3)}>
                            <EmojiSVG
                                fill={color}
                                style={{height: '25px'}}
                                onClick={() => bootstrap.setState({
                                    rightSideBarTabOpened: rightSideBarTabOpened === eGLOBAL_CHAT_TABS.EMOJIS ? eGLOBAL_CHAT_TABS.CHAT : eGLOBAL_CHAT_TABS.EMOJIS,
                                })}/>
                        </div>


                        <div className={classNames(dig.col12, dig.mb3, dig.mx0, dig.p0)}>
                            <div className={classNames(dig.row, dig.mt3, dig.alignItemsCenter)}>
                                <div className={classNames(dig.colMd10, dig.col10)}>
                                    <input className={classNames(dig.w100, dig.h100, dig.py2, dig.px1)}
                                           style={{color: "black", fontSize: "1.2rem"}}
                                           placeholder={CHAT_INPUT_PLACEHOLDER}
                                           onKeyPress={(e) => {
                                               if (e.key === 'Enter') {
                                                   submitChat()
                                               }
                                           }}
                                           value={chatInput}
                                           maxLength={255}
                                           onChange={(e) => {
                                               setChatInput(e.target.value)
                                           }}
                                    />
                                </div>
                                <div className={classNames(dig.col2)}>
                                    <Button id="chatSubmitButton" className={classNames(dig.submitChat, dig.border0)}
                                            onClick={submitChat}>
                                        <FontAwesomeIcon icon={faArrowUp} size={'lg'}/>
                                    </Button>
                                </div>
                            </div>

                        </div>
                    </div>
                </>}
        </div>
    </div>

}

Chat.whyDidYouRender = true;


// should re--render?
// @link https://stackoverflow.com/questions/40909902/shouldcomponentupdate-in-function-components


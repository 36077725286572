import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import styles from "src/components/Wallet/style.module.scss";
import Bootstrap from "src/Bootstrap";
import deleteTeamRoster from "../../../api/rest/deleteTeamRoster";

interface iTournamentProperties {
    handleClose: () => void,
    teamId: number,
    userId: number
}

export default function RemoveRoster(props: iTournamentProperties) {

    const dig = getStyles(styles)

    const {teamId, userId} = props;

    const {teams, users} = Bootstrap.bootstrap.state;

    const team = teams?.find(team => team.user_team_id === teamId);
    const user = users?.find(user => user.ID === userId);

    const handleUpdate = () => {

        deleteTeamRoster({
            uid: userId,
            user_team_id: teamId
        });

        (() => {
            setTimeout(function() {
                props.handleClose();
            }, 1000);
        })();
    }

    return <div className={classNames("model-content", dig.rounded0, dig.border0)}>
        <div className={classNames(dig.modalHeader, dig.rounded0, dig.border0, dig.digBgCardHeader)}>
            <h3 className={classNames(dig.modalTitle, dig.textDark)} id="staticBackdropLabel">Remove Roster</h3>
        </div>
        <div className={classNames(dig.modalBody, dig.border0, dig.digBgNeutral8, dig.digTextWhite)}>
            <div className={dig.textCenter}>Are you sure you want to remove <strong>{user?.display_name}</strong> out of the <strong>{team?.team_name}</strong> team permanently?
                This action cannot be reverted.
            </div>
        </div>
        <div className={classNames(dig.modalFooter, dig.digBgNeutral7, dig.border0, dig.rounded0)}>
            <a className={classNames(dig.btn, dig.btnLg, dig.digBtnGreen, "btn-Yes", dig.rounded0)}
               onClick={handleUpdate}>Yes</a>
            <a className={classNames(dig.btn, dig.btnLg, dig.digBtnRed, "btn-no", dig.rounded0)} onClick={props.handleClose}>No</a>
        </div>
    </div>

}



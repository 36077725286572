import classNames from "classnames";
import {useState} from "react";
import {getStyles} from "src/variables/DropVariables";

import style from "./style.module.scss";

// core components
export default function DropDown(props: {
    defaultValue: string,
    onChange: (value: string) => void,
    options: string[],
    className?: string
}) {

    const {
        defaultValue,
        onChange,
        options,
        className = ''
    } = props

    const [dropDownOpen, setDropDownOpen] = useState<boolean>(false)
    const [currentSelectedOption, setCurrentSelectedOption] = useState<string>(defaultValue)

    const dig = getStyles(style)

    return <div className={classNames(dig.w100, dig.m0)}>
        <div className={classNames(
            dig.dropdown, dig.rounded0, dig.border2,dig.p0, dig.positionRelative,
            dig.borderColorNeutral3, className
        )}>
            <button type="button"
                    className={classNames(dig.dFlex, dig.alignItemsCenter, dig.justifyContentBetween,
                        dig.btn, dig.dropdownToggle, dig.digBgNeutral6, dig.textWhite,dig.w100, dig.border0 )}
                    data-toggle="dropdown" role="combobox" aria-owns="bs-select-1"
                    aria-haspopup="listbox" aria-expanded="true" title="All Sizes"
                    onClick={() => setDropDownOpen(!dropDownOpen)}>
                <div className={"filter-option"}>
                    <div className={"filter-option-inner"}>
                        <div className={"filter-option-inner-inner"}>{currentSelectedOption || 'Drop Down'}</div>
                    </div>
                </div>
            </button>

            {
                dropDownOpen ?
                    <div className={classNames(dig.dropdownMenu)}>
                        <div className={classNames("inner show")} role="listbox" id="bs-select-1"
                             style={{maxHeight: "333.047px", overflowY: "auto", minHeight: "61px"}}>
                            <ul className={classNames("dropdown-menu inner show")} role="presentation"
                                style={{marginTop: "0px", marginBottom: "0px"}}>
                                {options.map((value, key) => {
                                    return <li role="option"
                                               key={key}
                                               onClick={() => {
                                                   console.log('key', key, 'value', value)
                                                   setCurrentSelectedOption(value)
                                                   onChange(value)
                                                   setDropDownOpen(!dropDownOpen)
                                               }}
                                               className={classNames("dropdown-item font-size-1p5em")}>
                                        {value}
                                    </li>
                                })}
                            </ul>
                        </div>
                    </div>
                    :
                    ''
            }

        </div>
    </div>
}

import classNames from "classnames";
import deleteAllowedGames from "src/api/rest/deleteAllowedGames";
import postAllowedGames from "src/api/rest/postAllowedGames";
import {ppr} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import isGG from "src/variables/isGG";
import {iUser, iSubscription} from "src/variables/sharedInterfaces";
import {getStyles} from "src/variables/DropVariables";
import moment from "moment";
import {Link} from "react-router-dom";
import {ReactComponent as DigCoin} from "src/assets/img/svg-icons/dig_coin.svg";
import MediaQuery from "react-responsive";
import Games from "src/views/Games/Games";
import {iAmProfileDescendant} from "src/views/Profile/Profile";

export const ALLOWED_GAMES_PATH = 'Games'

export default function AllowBlockUserGames(props: iAmProfileDescendant) {

    const handleGameClick = (e) => {

        e.preventDefault();

        // dataset is used by adding `data-` before the attribute name in any html element
        let gameID : number = parseInt(e.target.dataset.gameid),
            currentEditUser = props.currentEditUser;

        const gameAllowed =
            props.currentEditUser?.allowedGames?.includes(gameID) || false;

        // this is the delete function
        if (gameAllowed) {

            deleteAllowedGames({
                user_id: currentEditUser.ID,
                game_id: gameID
            })

        } else {

            postAllowedGames({
                user_id: currentEditUser.ID,
                game_id: gameID
            })

        }

    }

    let currentEditUser: iUser = props.currentEditUser;

    const userSubscription: iSubscription|undefined = currentEditUser?.subscriptions?.find(e => !!e);

    const games = ppr(Games, {...props, handleClick: handleGameClick})

    if (false === isGG()){

        return games;

    }

    const dig = getStyles()

    return (
        <div className={classNames(dig.containerFluid, dig.p0)}>
            <div className={classNames(dig.row, dig.g0, dig.mb5)}>

                <div className={classNames(dig.col12, dig.colSm6, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.flexColumn)}>
                    <h3 className={classNames(dig.ggTextPrimary, dig.fontSize2Em, dig.fwBold, dig.textUppercase)}>Subscription</h3>
                    {userSubscription
                        ? <>
                            <h1 className={classNames(dig.ggTextColor2, dig.fontSize3Em, dig.mt3, dig.fwNormal)}>
                                {userSubscription?.receive_amount && parseInt(userSubscription?.receive_amount)} Coins <span
                                className={dig.fontSize0P5Em}>/mo.</span>
                            </h1>
                            <h3 className={classNames(dig.ggTextPrimary, dig.mt2)}>Renews {moment(userSubscription.modified).format('MM-DD-YYYY')}</h3>

                        </>
                        : <>
                            <h4 className={classNames(dig.ggTextColor2, dig.fontSize1Em, dig.mt3)}>Not choosing</h4>
                            <span className={classNames(dig.ggTextColor2, dig.mt2)}></span>
                        </>

                    }
                    <Link
                        to={`/parent-portal/profile/${currentEditUser?.ID}/subscription`}
                        className={classNames(dig.btn, dig.ggPrimaryBtn, dig.textWhite, dig.px4, dig.py2, dig.fontSize1P2Em, dig.border0, dig.mt3, dig.fwBold)}>
                        Change
                    </Link>
                </div>
                {/* Only display on screen 425 (phone) */}
                <MediaQuery maxWidth={425}>
                    <hr className={classNames(dig.borderTop1, dig.borderColorGg, dig.w100)}/>
                </MediaQuery>
                <div className={classNames(dig.col12, dig.colSm6, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.flexColumn)}>
                    <h3 className={classNames(dig.ggTextPrimary, dig.fontSize2Em, dig.fwBold)}>Wallet</h3>
                    <h1 className={classNames(dig.ggTextColor2, dig.fontSize3Em, dig.mt3, dig.fwNormal)}>
                        <span className={dig.me2}>{currentEditUser?.funds}</span>
                        <DigCoin style={{width: '35px'}}/>
                    </h1>
                    <Link
                        to={`/parent-portal/profile/${currentEditUser?.ID}/coin/`}
                        className={classNames(dig.btn, dig.ggPrimaryBtn, dig.textWhite, dig.px4, dig.py2, dig.fontSize1P2Em, dig.border0, dig.mt3, dig.fwBold)}>
                        Add Coins
                    </Link>
                </div>
            </div>
            {/* Only display on screen 425 (phone) */}
            <MediaQuery maxWidth={425}>
                <hr className={classNames(dig.borderTop1, dig.borderColorGg, dig.w100)}/>
                <div className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter)}>
                    <h1 className={classNames(dig.ml3, dig.ggTextPrimary, dig.fontSize2Em, dig.mb4)}>
                        Game Access
                    </h1>
                    <button type="button"
                            className={classNames(dig.btn, dig.ggSecondaryBtn, dig.ggTextPrimary, dig.px4, dig.py3, dig.fontSize1P2Em, dig.border0, dig.fwBold, dig.w100)}>
                        Block by ESRB Recommendations
                    </button>
                </div>
            </MediaQuery>
            {/* Only display on screen 1224 (desktop or laptop) */}
            <MediaQuery minWidth={768}>
                <div className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter)}>
                    <h1 className={classNames(dig.ml3, dig.ggTextPrimary, dig.fontSize2Em)}>
                        Game Access
                    </h1>
                    <button type="button"
                            className={classNames(dig.btn, dig.ggSecondaryBtn, dig.ggTextPrimary, dig.px4, dig.py2, dig.fontSize1P2Em, dig.border0, dig.fwBold)}>
                        Block by ESRB Recommendations
                    </button>
                </div>
            </MediaQuery>
            {games}
        </div>
    );

}


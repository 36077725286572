import {toast} from "react-toastify";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import {globalNavigate} from "src/api/hoc/GlobalHistory";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import Bootstrap from "src/Bootstrap";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import SignUpAndIn, {eSignUpAndIn} from "src/components/Header/Modal/SignUpAndIn";
import Loading from "src/components/Loading/Loading";
import {ppr, WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {BOOTSTRAP_CUSTOM_PATH} from "src/DropInGaming";
import {iUsers} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {PARENTAL_PORTAL_PATH} from "src/views/ParentalPortal/ParentalPortal";


export const CHILD_ACTIVATION_PATH = 'child-activation';


let attemptedRequests: string[] = [];

export default function ChildActivation(props: WithRouter) {

    const bootstrap = Bootstrap.bootstrap;
    // we need to handle the parent already logged in

    console.log('child activation props', props);

    const childId = parseInt(props.params.child);
    const key = props.params.key;

    if (0 !== bootstrap.state.id) {

        // todo - is already parent of child
        const whoami = getCurrentLoggedInUser();

        if (whoami?.children?.includes(childId)) {

            globalNavigate('/' + PARENTAL_PORTAL_PATH + '/');

            return <ExpandableBox>Your accounts have successfully been linked. The user ({childId}) has been added to
                your family.</ExpandableBox>

        }

        const url = '/' + BOOTSTRAP_CUSTOM_PATH + '/' + CHILD_ACTIVATION_PATH + '/' + childId + '/' + key + '/'

        if (attemptedRequests.includes(url)) {

            return <>We have already attempted to link your accounts. Please contact support if problems persist.</>

        }

        attemptedRequests.push(url);

        // post child
        bootstrap.state.axios.post(url, {})
            .then(_res => {

                updateRestfulObjectArray<iUsers>(previousState => {

                    const whoami = previousState.users?.find(user => user.ID === previousState.id);

                    if (!whoami) {
                        return [];
                    }

                    whoami.children ??= [];

                    whoami.children.push(childId);

                    return [
                        whoami
                    ];

                }, "ID", "users")

                toast.success('You accounts were linked successfully!', DropVariables.toastOptions);

                globalNavigate('/' + PARENTAL_PORTAL_PATH + '/');

            }).catch(_error => {

            toast.error('Oh no! We have failed to link your accounts. Please contact support if problems persist.', DropVariables.toastOptions);

            globalNavigate('/' + PARENTAL_PORTAL_PATH + '/');

        });

        return <Loading message={'Linking your account now! Please wait...'}/>
    }

    // login
    return <>

        {ppr(SignUpAndIn, {
            ...props,
            display: eSignUpAndIn.JOIN_POPUP,
        })}

    </>

}
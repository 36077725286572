import React from 'react';
import {Helmet} from "react-helmet";
import walletInputsValid from "src/api/hoc/walletInputsValid";
import TermsAndConditions from 'src/components/TermsAndConditions/TermsAndConditions';

interface iPayDevCrypto extends React.HTMLAttributes<HTMLElement> {
    paydev?: string;
    amount?: number;
    currency?: string;
    ref_id?: string;
    paydev_id?: string;
    className: string;
    disabled?: boolean,

    [key: string]: any;
}


export default function PayDevCrypto({
                                         paydev,
                                         amount,
                                         currency,
                                         ref_id,
                                         paydev_id,
                                         className
                                     }: iPayDevCrypto) {

    const [forumValid] = walletInputsValid()

    return <>
        <Helmet>
            <script async type="text/javascript"
                    src="https://digitalpaydev.com/widget/paydev.js?v4"/>
        </Helmet>
        <div>
            <TermsAndConditions/>
            <div style={{
                opacity: (forumValid ? "1" : '0.5'),
                pointerEvents: forumValid ? 'auto' : 'none',
                margin: "2em"
            }}>
                {React.createElement(
                    'div',
                    {
                        paydev: paydev,
                        paydev_id: paydev_id,
                        amount: amount,
                        currency: currency,
                        ref_id: ref_id,
                        className: className
                    }
                )}
            </div>
        </div>
    </>

}

import classNames from "classnames";
import {useState} from "react";
import postAuthenticate from "src/api/rest/postAuthenticate";
import {ppr, WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import Popup from "src/components/Popup/Popup";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {iUsers} from "src/variables/C6";

import style from "./style.module.scss";
import postUser from "src/api/rest/postUser";
import Bootstrap from "src/Bootstrap";
import Input from "src/components/Input/Input";
import Select from "src/components/Select/Select";
import TermsAndConditions from "src/components/TermsAndConditions/TermsAndConditions";
import {Link, Navigate} from "react-router-dom";
import ForgotPasswordReset, {PASSWORD_RESET_PATH} from "src/components/ForgotPasswordReset/ForgotPasswordReset";
import moment from "moment/moment";
// import Datetime from 'react-datetime';
import {HOME_PATH} from "src/views/Home/Home";
import isPRO from "../../../variables/isPRO";
import {toast} from "react-toastify";
// import BirtdayInput from "../../BirthdayInput/BirthdayInput";

export enum eSignUpAndIn {
    LOGIN,
    LOGIN_POPUP,
    JOIN,
    JOIN_POPUP,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_POPUP,
    NONE

}

export interface iSignUpAndIn {
    handleClose?: () => any,
    display: eSignUpAndIn,
}

export default function SignUpAndIn(props: iSignUpAndIn & WithRouter): JSX.Element | null {

    let [userSignUpAndInInformation, setUserSignUpAndInInformation] = useState<iUsers>({})
    let [emailWarningOpened, setEmailWarningOpened] = useState<boolean>(false)
    const [birthdate, setBirthdate] = useState(() => moment().format('MM/DD/YYYY'));
    const [isValid, setIsValid] = useState(true);

    if (0 !== Bootstrap.bootstrap.state.id) {
        return <Navigate to={`/${HOME_PATH}`}/>
    }
    const changeEmailWarningOpened = () => setEmailWarningOpened(!emailWarningOpened)

    const dig = getStyles(style)

    const today = moment();

    const unregisteredInvite = new URLSearchParams(window.location.search).get('referenceId');

    // check if parent activation data is present
    const childId = props.params?.child !== undefined ? parseInt(props.params.child) : undefined;

    // TODO: change this id to something a little more professional
    const childTimeCode = new URLSearchParams(window.location.search).get('dig4life');

    const childKey = childTimeCode !== undefined && childTimeCode !== null ? props.params.key + '_' + childTimeCode : undefined;

    const MINIMUM_AGE = isPRO() && childKey === undefined ? 8 : 18;

    const ageDenyMessage = childKey === undefined ? `You must be at least ${MINIMUM_AGE} years old to register.` : `You must be at least ${MINIMUM_AGE} years old to register as a parent user.`;


    const returnPopup = (element: any) => <Popup
        handleClose={props.handleClose ?? (() => console.log('no close handler for popup'))}
        open={props.display === eSignUpAndIn.LOGIN_POPUP || props.display === eSignUpAndIn.JOIN_POPUP}
    >
        <div className={classNames(
            dig.rounded2, dig.border0, dig.digBgNeutral6, dig.loginModal
        )} style={{
            width: "100%",
            scrollBehavior: "auto"
        }}>
            <div className={classNames(
                dig.modalHeader, dig.rounded0, dig.digBgNeutral6, dig.py3, dig.dFlex, dig.alignItemsCenter
            )}>
                <h3>Drop-In Gaming</h3>
                <div
                    onClick={props.handleClose}
                    style={{cursor: "pointer", float: "right"}}
                    className={classNames("close")}
                    data-dismiss="modal"
                    aria-label="Close">
                    <i className={classNames("fas fa-times m-0 text-white")}/>
                </div>
            </div>
            <div className={classNames(
                dig.modalBody, dig.digBgNeutral6, dig.tabContent
            )}>
                {element}
            </div>
        </div>
    </Popup>

    const login = <>
        {/*Toggle Popup*/}
        <div className={classNames(
            dig.row, dig.justifyContentBetween, dig.alignItemsCenter, dig.g0,
            dig.newLoginSwitchers, 5, dig.pb4
        )}>
            <h3 className={classNames(
                dig.modalTitle, dig.textWhite, dig.fwNormal, dig.alignItemsStart
            )}>
                Not a member yet?
            </h3>
            <a className={classNames(
                dig.btn, dig.btnSuccess, dig.minWidth100Px, dig.digBtnBlue, dig.btnLg,
                dig.rounded0, dig.fontSize1Em, dig.fwBold, dig.textUppercase, dig.border0
            )} id="register-tab" data-toggle="tab" role="tab"
               aria-controls="register-form" aria-selected="false"
               onClick={() => Bootstrap.bootstrap.setState({
                   joinPopup: true,
                   loginPopup: false
               })}>
                Join
            </a>
        </div>

        {/*Form*/}
        <div className={classNames(
            dig.tabPane, dig.show, dig.active
        )} id="login-form" role="tabpanel"
             aria-labelledby="login-tab">
            <div className={classNames(
                dig.bgTransparent, dig.rounded0, dig.border0,
            )}
                 role="form">
                <div className={classNames(
                    dig.card, dig.rounded0, dig.border0, dig.bgTransparent
                )}>

                    <div className={classNames(
                        dig.cardBody, dig.digBgNeutral2
                    )}>
                        {DropVariables.isLocal && <>
                            <div
                                className={classNames(
                                    3, dig.mt3, dig.border0, dig.rounded0, dig.cardDiscordBody,
                                    dig.fontSize1P2Em, dig.textCenter
                                )}>
                                <label className={dig.cardDiscordContent}>
                                    <a href="https://discord.com/api/v9/oauth2/authorize?client_id=851942914413559849&amp;redirect_uri=http%3A%2F%2Flocal.dropingaming.gg%3A8080%2F&amp;response_type=code&amp;scope=identify+email&amp;state=058224c6d97fef0fb94f9331056ed2cf">
                                        <i className={classNames("fab fa-discord", dig.me1)}/>
                                        Log In With Discord
                                    </a>
                                </label>
                            </div>
                            <div
                                className={classNames(3, dig.mt3, dig.rounded0, dig.card, dig.fontSize1P2Em,
                                    dig.textCenter, dig.cardTwitchBody
                                )}>
                                <label className={classNames(dig.cardTwitchContent)}>
                                    <a
                                        href="https://id.twitch.tv/oauth2/authorize?client_id=pck8egu7n8p7w18wffbnlf7ecejf1f&amp;redirect_uri=http%3A%2F%2Flocal.dropingaming.gg%3A8080%2F&amp;response_type=code&amp;scope=user%3Aread%3Aemail&amp;state=14b65c2e4f8f20a14c44d5c9e4a3fa42">
                                        <i className={classNames("fab fa-twitch", dig.me1)}/>
                                        Log In With Twitch
                                    </a>
                                </label>
                            </div>
                            <div
                                className={classNames(
                                    3, dig.mt3, dig.rounded0, dig.card, dig.fontSize1P2Em, dig.textCenter, dig.cardFacebookBody
                                )}>
                                <label className={dig.cardFacebookContent}>
                                    <a href="https://www.facebook.com/v11.0/dialog/oauth?client_id=538697897299160&amp;redirect_uri=http%3A%2F%2Flocal.dropingaming.gg%3A8080%2F&amp;response_type=code&amp;scope=public_profile+email&amp;state=708e69562a137fb967e2f1d122f3998e">
                                        <i className={classNames("fab fa-facebook", dig.me1)}/>
                                        Log In With Facebook
                                    </a>
                                </label>
                            </div>
                        </>}

                        <div className={classNames(
                            dig.mt3,
                        )}>
                            <Input
                                label={'Username'}
                                required
                                className={classNames(
                                    dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3
                                )} name="user_login" type="text"
                                placeholder="Enter Username"
                                value={userSignUpAndInInformation.user_login} onChange={(e) =>
                                setUserSignUpAndInInformation({
                                    ...userSignUpAndInInformation,
                                    user_login: e.target.value
                                })}/>


                            <Input
                                label={'Password'}
                                required className={classNames(
                                dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3,
                                dig.fontSize1P2Em
                            )}
                                id="password" name="user_password" type="password"
                                placeholder="Enter Password"
                                onChange={(e) => setUserSignUpAndInInformation({
                                    ...userSignUpAndInInformation,
                                    user_pass: e.target.value
                                })}/>
                        </div>
                        <div className={classNames(
                            0, dig.dFlex, dig.justifyContentBetween, dig.mt3
                        )}>
                            <div className={dig.formCheck}>
                                <input type="checkbox" className={dig.formCheckInput}
                                       style={{height: "auto !important"}}
                                       id="remember" name="remember" value="1"/>
                                <label className={classNames(dig.formCheckLabel, dig.ms2)} htmlFor="remember">
                                    Keep me signed in
                                </label>
                            </div>
                            <Link to={"/" + PASSWORD_RESET_PATH + "/"}
                                  className={classNames(
                                      dig.modalTitle, dig.digTextWhite, dig.fwNormal, dig.textCenter, dig.textUnderline
                                  )}
                                  onClick={() => {
                                      props.handleClose?.()
                                  }}>
                                Forgot your password?
                            </Link>
                        </div>
                    </div>
                </div>
                <div className={classNames(
                    dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.border0, dig.mt3
                )}>
                    <button
                        className={classNames(
                            dig.btn, dig.btnSuccess, dig.digBtnGreen, dig.btnLg, dig.alignItemsEnd,
                            dig.rounded0, dig.fontSize1Em, dig.w100, dig.fwBold, dig.textUppercase
                        )}
                        onClick={() => postAuthenticate({
                            user: {
                                user_login: userSignUpAndInInformation.user_login,
                                user_pass: userSignUpAndInInformation.user_pass,
                            }
                        })}>
                        Log In
                    </button>
                </div>
            </div>
        </div>
    </>

    const join = <>
        {/*Toggle Popup*/}
        <div>
            Regions currently supported: North America, Europe, Australasia
            <div className={classNames(
                dig.row, dig.justifyContentBetween, dig.alignItemsCenter, dig.g0, dig.loginSwitchers,
                5, dig.pb4
            )}>
                <h3 className={classNames(
                    dig.modalTitle, dig.digTextWhite, dig.fwNormal, dig.alignItemsStart
                )}>
                    Already a Member?
                </h3>
                <a className={classNames(
                    dig.btn, dig.btnSuccess, dig.minWidth100Px, dig.digBtnBlue, dig.btnLg, dig.rounded0,
                    dig.fontSize1Em, dig.fwBold, dig.textUppercase, dig.border0, dig.active
                )}
                   id="login-tab" data-toggle="tab" role="tab" aria-controls="login-form"
                   aria-selected="true"
                   onClick={() => Bootstrap.bootstrap.setState({
                       joinPopup: false,
                       loginPopup: true
                   })}>
                    Log In
                </a>
            </div>

            {/*Form*/}
            <div className={classNames(
                dig.tabPane, dig.show, dig.active
            )} id="login-form" role="tabpanel"
                 aria-labelledby="login-tab">
                <div className={classNames(
                    dig.bgTransparent, dig.rounded0, dig.border0,
                )}
                     role="form">
                    <div className={classNames(
                        dig.card, dig.rounded0, dig.border0, dig.bgTransparent
                    )}>
                        <div className={classNames(
                            dig.cardBody, dig.digBgNeutral2
                        )}>
                            <div className={classNames(dig.tabPane)} id="register-form" role="tabpanel"
                                 aria-labelledby="register-tab">
                                <div className={classNames(dig.bgTransparent, dig.rounded0, dig.border0)}
                                     role="form">
                                    {DropVariables.isLocal && <>
                                        <div
                                            className={classNames(3, dig.mt3, dig.rounded0, dig.border0, dig.fontSize1P2Em, dig.textCenter, dig.cardDiscordBody)}>
                                            <label className={classNames("card-discord-content")}><a
                                                href="https://discord.com/api/v9/oauth2/authorize?client_id=851942914413559849&amp;redirect_uri=http%3A%2F%2Flocal.dropingaming.gg%3A8080%2F&amp;response_type=code&amp;scope=identify+email&amp;state=058224c6d97fef0fb94f9331056ed2cf"><i
                                                className={classNames("fab fa-discord")}/>
                                                Sign Up With Discord
                                            </a>
                                            </label>
                                        </div>
                                        <div
                                            className={classNames(3, dig.mt3, dig.rounded0, dig.border0, dig.fontSize1P2Em, dig.textCenter, dig.cardTwitchBody)}>
                                            <label className={classNames("card-twitch-content")}><a
                                                href="https://id.twitch.tv/oauth2/authorize?client_id=pck8egu7n8p7w18wffbnlf7ecejf1f&amp;redirect_uri=http%3A%2F%2Flocal.dropingaming.gg%3A8080%2F&amp;response_type=code&amp;scope=user%3Aread%3Aemail&amp;state=14b65c2e4f8f20a14c44d5c9e4a3fa42"><i
                                                className={classNames("fab fa-twitch")}/> Sign Up With
                                                Twitch</a></label>
                                        </div>
                                        <div
                                            className={classNames(3, dig.mt3, dig.rounded0, dig.border0, dig.fontSize1P2Em, dig.textCenter, dig.cardFacebookBody)}>
                                            <label className={classNames("card-facebook-content")}><a
                                                href="https://www.facebook.com/v11.0/dialog/oauth?client_id=538697897299160&amp;redirect_uri=http%3A%2F%2Flocal.dropingaming.gg%3A8080%2F&amp;response_type=code&amp;scope=public_profile+email&amp;state=708e69562a137fb967e2f1d122f3998e"><i
                                                className={classNames("fab fa-facebook")}/>
                                                Sign Up With Facebook
                                            </a></label>
                                        </div>
                                    </>}

                                    <div
                                        className={classNames(dig.card, dig.rounded0, dig.border0, dig.bgTransparent)}>
                                        <div className={classNames(dig.cardBody, dig.borderColorNeutral2)}>

                                            <Input
                                                label={'Username'}
                                                required
                                                className={classNames(dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3,
                                                    dig.fontSize1P2Em)}
                                                id="dig-register-username" name="user_login" type="text"
                                                placeholder="Enter Username" defaultValue=""
                                                onChange={(e) => {
                                                    setUserSignUpAndInInformation({
                                                        ...userSignUpAndInInformation,
                                                        user_login: e.target.value
                                                    })
                                                }}/>


                                            <Input
                                                label={
                                                    <>E-mail Address
                                                        <i className={classNames("fas fa-question-circle")}
                                                           id="dig_question_mark_popup"
                                                           onMouseEnter={changeEmailWarningOpened}
                                                           onMouseLeave={changeEmailWarningOpened}/>
                                                        {emailWarningOpened && <>
                                                            <div
                                                                className={classNames("dig_question_mark_popup_text")}>
                                                                We will not share your email address with anyone.
                                                            </div>
                                                        </>}
                                                    </>}
                                                className={classNames(dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3,
                                                    dig.fontSize1P2Em)}
                                                id="dig-register-user-email" name="user_email" type="email"
                                                placeholder="Enter your E-mail" defaultValue=""
                                                onChange={(e) => {
                                                    setUserSignUpAndInInformation({
                                                        ...userSignUpAndInInformation,
                                                        user_email: e.target.value
                                                    })
                                                }}/>


                                            <Input
                                                label={'Password'}
                                                className={classNames(dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3,
                                                    dig.fontSize1P2Em)}
                                                id="new_password" name="user_password" type="password"
                                                defaultValue=""
                                                placeholder="Enter Password"
                                                onChange={(e) => {
                                                    setUserSignUpAndInInformation({
                                                        ...userSignUpAndInInformation,
                                                        user_pass: e.target.value
                                                    })
                                                }}/>


                                            Birthday (MM/DD/YYYY)
                                            <div className={classNames(
                                                     dig.border0,
                                                     dig.rounded0,
                                                     dig.borderColorNeutral3,
                                                     dig.datetimeInput,
                                                     dig.positionRelative,
                                                     dig.fontSize1P2Em)}
                                                 style={{color: 'black'}}>
                                                <input
                                                    type="text"
                                                    id="dateInput"
                                                    value={birthdate}
                                                    className={classNames(dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3,
                                                        dig.fontSize1P2Em)}
                                                    onChange={(event) => {

                                                        const value = event.target.value;
                                                        setBirthdate(value);

                                                        const isValidDate = moment(value, ['MM/DD/YYYY'], true).isValid();
                                                        setIsValid(isValidDate);
                                                    }}
                                                />
                                                {!isValid && (
                                                    <p style={{ color: 'red' }}>Please enter a valid date in MM/DD/YYYY format.</p>
                                                )}


                                                {/*<Datetime*/}
                                                {/*    onChange={(e) => {*/}

                                                {/*        if ('string' === typeof e) {*/}

                                                {/*            e = moment(e, DropVariables.momentGeneralDateFormat)*/}

                                                {/*        }*/}

                                                {/*        setBirthdate(e.format('YYYY-MM-DD'))*/}
                                                {/*    }}*/}
                                                {/*    inputProps={{className: classNames(dig.formControl, dig.rounded0)}}*/}
                                                {/*    dateFormat={DropVariables.momentGeneralDateFormat}*/}
                                                {/*    initialValue={today}*/}
                                                {/*    timeConstraints={{*/}
                                                {/*        hours: {min: 0, max: 72, step: 1}*/}
                                                {/*    }}*/}
                                                {/*/>*/}
                                            </div>


                                            <Select label={'How Did You Hear About Us?'} name="referral-source"
                                                    id="referral-source"
                                                    options={[
                                                        {value: 'None'},
                                                        {value: 'Twitch'},
                                                        {value: 'Twitter'},
                                                        {value: 'Instagram'},
                                                        {value: 'FaceBook'},
                                                        {value: 'YouTube'},
                                                        {value: 'Referral'},
                                                        {value: 'Other'},
                                                    ]}
                                                    className={classNames("custom-select")}
                                                    aria-hidden="true">
                                            </Select>

                                            <Input label={'Referral Code'}
                                                   defaultValue={''}
                                                   type="text"/>

                                            <Input label={'Source'}
                                                   type="text"
                                                   defaultValue={""}/>

                                            <div
                                                className={classNames(dig.dFlex, dig.alignItemsCenter)}>
                                                <TermsAndConditions/>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className={classNames(dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.border, dig.mt3)}>
                                        <button
                                            className={classNames(dig.btn, dig.btnSuccess, dig.btnLg, dig.rounded0, dig.fwBold, dig.w100, dig.textUppercase, "dig-btn-green pull-right dig-register font-size-1em")}
                                            type="submit"
                                            onClick={() => {

                                                const birthdateMoment = moment(birthdate);

                                                const age = today.diff(birthdateMoment, "years");

                                                age < MINIMUM_AGE
                                                    ? toast.error(ageDenyMessage, DropVariables.toastOptions)
                                                    : postUser({
                                                        user_pass: userSignUpAndInInformation.user_pass,
                                                        user_login: userSignUpAndInInformation.user_login,
                                                        user_email: userSignUpAndInInformation.user_email,
                                                        user_birthday: moment(birthdate).format(DropVariables.momentGeneralDateFormat),
                                                        temp_invite_id: unregisteredInvite ? parseInt(unregisteredInvite) : undefined,
                                                        ID: childId, // dig_parent_user fields are permitted, but the parent check already looks for self::ID, so this keeps that simple (even if potentially confusing here)
                                                        user_activation_key: childKey, // this is the child's activation key, but we have to set it like this or it will not make it to the backend
                                                        success: () => {
                                                            return 'Account created!'
                                                        }
                                                    })

                                            }}>
                                            Join
                                        </button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>

    const checkPath = () => {
        if (props.location.pathname.includes(PASSWORD_RESET_PATH))
            return <Navigate to={`/${HOME_PATH}`}/>
    }

    switch (props.display) {
        default:
        case eSignUpAndIn.NONE:
            return null;
        case eSignUpAndIn.JOIN:
            return checkPath() ?? join;
        case eSignUpAndIn.LOGIN:
            return checkPath() ?? login;
        case eSignUpAndIn.LOGIN_POPUP:
            return checkPath() ?? returnPopup(login);
        case eSignUpAndIn.JOIN_POPUP:
            return checkPath() ?? returnPopup(join);
        case eSignUpAndIn.FORGOT_PASSWORD:
            return ppr(ForgotPasswordReset, {});
        case eSignUpAndIn.FORGOT_PASSWORD_POPUP:
            return returnPopup(ppr(ForgotPasswordReset, {}));

    }

}



import restApi, {iPutC6RestResponse, PUT, removeInvalidKeys} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {
    C6, dig_comp_registration,
    iDig_Comp_Registration,
} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iUser} from "../../variables/sharedInterfaces";
import Bootstrap from "../../Bootstrap";
import getUsers from "./getUsers";
import isTest from "../../variables/isTest";

export interface iPutRegistration extends iDig_Comp_Registration {
    comp_registration_id: number,
    vendor_gamertag?: string,
    streaming_platform?: string,
    streaming_url?: string,
}

export default restApi<iPutRegistration, iDig_Comp_Registration, {}, iPutC6RestResponse<iDig_Comp_Registration>>({
    axios: DropVariables.axios,
    tableName: [C6.dig_comp_registration.TABLE_NAME],
    requestMethod: PUT,
    queryCallback: (request) => {
        request.success ??= 'Successfully updated streaming link!'
        request.error ??= 'An unknown issue occurred during your streaming link update.'
        return request
    },
    responseCallback: (_response, request, id) => {

        if ('number' !== typeof id) {
            return;
        }

        request.comp_registration_id = id

        // request has all the infos
        updateRestfulObjectArray<iDig_Comp_Registration>([
            removeInvalidKeys(request, [dig_comp_registration])
        ], "comp_registration_id", "registrations")

        if (0 !== Bootstrap.bootstrap.state.id) {
            // if this ends up being a newly entered meta value, we will need to trigger a getUser call
            let needsFreshUserData = false;

            updateRestfulObjectArray<iUser>((prev) => {
                let prevUser = prev.users?.find(user => user.ID === Bootstrap.bootstrap.state.id);

                if (prevUser === undefined) {
                    return [];
                }

                // get the index of the meta field we are trying to edit
                let metaKey = ('gamertag-' + request.streaming_platform).toLowerCase(),
                    metaIndex = (prevUser.userMeta?.findIndex(meta => meta.meta_key === metaKey)) ?? -1;

                if (metaIndex === -1) {
                    needsFreshUserData = true;
                    return [];
                }

                // strict typing killing me
                if (undefined === prevUser.userMeta || null === prevUser.userMeta) {
                    needsFreshUserData = true;
                    return [];
                }

                prevUser.userMeta[metaIndex].meta_value = request.streaming_url;

                return [prevUser];
            }, "ID", "users");

            if (needsFreshUserData && false === isTest) {
                getUsers({
                    userIds: [
                        Bootstrap.bootstrap.state.id
                    ],
                    cacheResults: false,
                })
            }
        }

    },
})
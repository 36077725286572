import classNames from "classnames";
import DropVariables, {eMONEY_OUTPUT_CONTEXT, getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, iCompetitions} from "src/variables/sharedInterfaces";
import compTypeToSingular from "src/api/hoc/compTypeToSingular";
import putCompetition from "src/api/rest/putCompetition";
import Bootstrap from "src/Bootstrap";
import SvgInline from "../../../components/SvgInline/SvgInline";

// import {COMPETITION_PATH} from "src/views/Competition/Competition";


interface iCompetitionHeader {
    competition: iCompetitions
}

export default function CompetitionHeader(props: iCompetitionHeader) {

    const {competition} = props;
    const {registrations, id, regions} = Bootstrap.bootstrap.state;

    const myRegistration = registrations?.find(registration => registration.user_id === id && registration.competitions_id === competition.comp_id) || undefined;

    const iAmRegistered = competition.registered_users?.includes(id) &&
        undefined !== myRegistration;

    const dig = getStyles()

    const arrayOfRegionIds = competition?.regions?.map(id => parseInt(id, 10)) || [];

    // @ts-ignore
    const filteredRegions = regions?.filter(item => arrayOfRegionIds?.includes(item.region_id));

    const headerPhoto = competition.competitionsMeta?.find(meta => meta.meta_key === '_header_photo')

    return <>
        <div
            style={{backgroundImage: "url("+ headerPhoto?.meta_value +")"}}
            className={classNames(
            dig.row,
            dig.g0,
            dig.mt4,
            dig.digBgNeutral9,
            dig.p3,
            dig.positionRelative,
            dig.gameHeroHeaderContainer
        )}>
            <div
                className={classNames(
                    dig.col12, dig.dFlex, dig.fwBold, dig.justifyContentBetween, dig.flexRow, dig.alignItemsStart
                )}>
                <div className={classNames(dig.textUppercase)}>{compTypeToSingular(competition.comp_type_id).charAt(0).toUpperCase() + compTypeToSingular(competition.comp_type_id).slice(1)} </div>
                <div className={classNames(dig.ms2)}>
                    ID:
                    <a className={classNames(dig.ms2, dig.digManageCompLink)}
                       href={"/wp-admin/admin.php?page=dig_add_tournament&action=edit&id=" + competition.comp_id}>
                        {competition.comp_id}
                    </a>
                </div>
            </div>
            <div className={classNames(dig.gameHeroHeader, dig.col12)}>
                <div
                    className={classNames(
                        dig.row, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.justifyContentLgStart
                    )}>
                    <div className={classNames(dig.col8, dig.colLg3, dig.my4, dig.gameHeroLogo)}>
                        {
                            competition.game_logo_image ?
                                <SvgInline
                                    className={classNames(dig.styleSvg, dig.digSecondaryFillColor, dig.w100, dig.h100)}
                                    url={competition.game_logo_image}
                                />
                                :
                                <h1 className={classNames(dig.fontSize3Em, dig.textCenter, dig.textUppercase, dig.digOrange)}>{competition.code}</h1>
                        }

                    </div>
                    <div id="dig-comp-desc-content" className={classNames(dig.col8, dig.colLg5)}>
                        <h1 className={classNames(
                            dig.fwBolder, dig.cardTitle, dig.textWhite, dig.textStart, dig.mb2
                        )}>
                            {competition.competition_title} </h1>

                        <h4 className={classNames(dig.fwNormal, dig.textWhite, dig.textStart, dig.mb1)}>
                            {competition.now_flag === 1 ? 'Available Now' : DropVariables.convertPstToLocal(competition.datetime)} • {competition.comp_type_id === eCOMPETITION.MATCHES ? DropVariables.formatMoney(2 * (competition?.team_size ?? 0) * parseFloat(competition?.buy_in ?? '0') * (1 - parseFloat(competition?.comp_percentage ?? '0')), eMONEY_OUTPUT_CONTEXT.PAY_OUT) + ' • ' : ''}{DropVariables.formatMoney(competition.buy_in, eMONEY_OUTPUT_CONTEXT.BUY_IN)}

                        </h4>
                        <h4 className={classNames(
                            dig.fwNormal, dig.textWhite, dig.pb3, dig.textStart
                        )}>
                            Available Region(s):
                            <span className={dig.ms2}>

                                {
                                    filteredRegions?.map(region => region.region_name).join(' ')
                                }
                            </span>
                        </h4>
                    </div>
                    {
                        competition.organizer_id === id && iAmRegistered
                        && competition.comp_type_id === eCOMPETITION.MATCHES
                        && competition.status === eCOMPETITION_STATUS.WAITING
                        && competition.parent_comp === 0
                            ?
                            <div className={classNames(dig.col8, dig.colLg3, dig.textCenter)}>
                                <ul className={classNames(dig.digEditPrivacySelectorBlock)} id="dig-edit-privacy-selector-block">
                                    <li
                                        onClick={() => {
                                            putCompetition({
                                                comp_id: competition.comp_id,
                                                public: 0
                                            });
                                        }}
                                        className={classNames(dig.digEditPrivacySelector, competition.public === 0 ? dig.uiSelected : '')}>
                                        Private
                                    </li>
                                    <li
                                        className={classNames(dig.digEditPrivacySelector, competition.public === 1 ? dig.uiSelected : '')}
                                        onClick={() => {
                                            putCompetition({
                                                comp_id: competition.comp_id,
                                                public: 1
                                            });
                                        }}
                                    >Public
                                    </li>
                                </ul>
                            </div>
                            :
                            ''
                    }

                </div>
            </div>
        </div>


    </>

}


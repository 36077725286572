export type tCountryInformation = {
    abbreviation: string,
    name: string,
    postal: string,
    state?: ({ name: string, abbreviation: string})[] | true
}

export default [
    {abbreviation: "AF", name: "Afghanistan", postal: "PostCode_Zip"},
    {abbreviation: "AX", name: "Åland Islands", postal: "PostCode_Zip"},
    {abbreviation: "AL", name: "Albania", postal: "PostCode_Zip"},
    {abbreviation: "DZ", name: "Algeria", postal: "PostCode_Zip"},
    {abbreviation: "AS", name: "American Samoa", postal: "PostCode_Zip"},
    {abbreviation: "AD", name: "Andorra", postal: "PostCode_Zip"},
    {abbreviation: "AO", name: "Angola", postal: ""},
    {abbreviation: "AI", name: "Anguilla", postal: "PostalCode_Zip"},
    {abbreviation: "AQ", name: "Antarctica", postal: "PostalCode_Zip"},
    {abbreviation: "AR", name: "Argentina", postal: "PostalCode_Zip"},
    {abbreviation: "AG", name: "Antigua and Barbuda", postal: "PostalCode_Zip"},
    {abbreviation: "AM", name: "Armenia", postal: "PostalCode_Zip"},
    {abbreviation: "AW", name: "Aruba", postal: "PostalCode_Zip"},
    {abbreviation: "AU", name: "Australia", postal: "PostalCode_Zip"},
    {abbreviation: "AT", name: "Austria", postal: "PostalCode_Zip"},
    {abbreviation: "AZ", name: "Azerbaijan", postal: "PostalCode_Zip"},
    {abbreviation: "BS", name: "Bahamas", postal: ""},
    {abbreviation: "BH", name: "Bahrain", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "BD", name: "Bangladesh", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "BB", name: "Barbados", postal: "PostCode_Zip"},
    {abbreviation: "BY", name: "Belarus", postal: "PostCode_Zip"},
    {abbreviation: "PW", name: "Belau", postal: "PostCode_Zip"},
    {abbreviation: "BE", name: "Belgium", postal: "PostCode_Zip"},
    {abbreviation: "BZ", name: "Belize", postal: "PostCode_Zip"},
    {abbreviation: "BJ", name: "Benin", postal: "PostCode_Zip"},
    {abbreviation: "BM", name: "Bermuda", postal: "PostCode_Zip"},
    {abbreviation: "BT", name: "Bhutan", postal: "PostCode_Zip"},
    {abbreviation: "BO", name: "Bolivia", postal: ""},
    {abbreviation: "BQ", name: "Bonaire, Saint Eustatius and Saba", postal: "PostCode_Zip"},
    {abbreviation: "BA", name: "Bosnia and Herzegovina", postal: "PostCode_Zip"},
    {abbreviation: "BW", name: "Botswana", postal: "PostCode_Zip"},
    {abbreviation: "BV", name: "Bouvet Island", postal: "PostCode_Zip"},
    {abbreviation: "BR", name: "Brazil", postal: "PostCode_Zip"},
    {abbreviation: "IO", name: "British Indian Ocean Territory", postal: "PostCode_Zip"},
    {abbreviation: "BN", name: "Brunei", postal: "PostCode_Zip"},
    {abbreviation: "BG", name: "Bulgaria", postal: "PostCode_Zip"},
    {abbreviation: "BF", name: "Burkina Faso", postal: "PostCode_Zip"},
    {abbreviation: "BI", name: "Burundi", postal: "PostCode_Zip"},
    {abbreviation: "KH", name: "Cambodia", postal: "PostCode_Zip"},
    {abbreviation: "CM", name: "Cameroon", postal: "PostCode_Zip"},
    {abbreviation: "CA", name: "Canada", postal: "Postal Code"},
    {abbreviation: "CV", name: "Cape Verde", postal: "PostCode_Zip"},
    {abbreviation: "KY", name: "Cayman Islands", postal: "PostCode_Zip"},
    {abbreviation: "CF", name: "Central African Republic", postal: "PostCode_Zip"},
    {abbreviation: "TD", name: "Chad", postal: "PostCode_Zip"},
    {abbreviation: "CL", name: "Chile", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "CN", name: "China", postal: "PostCode_Zip"},
    {abbreviation: "CX", name: "Christmas Island", postal: "PostCode_Zip"},
    {abbreviation: "CC", name: "Cocos (Keeling) Islands", postal: "PostCode_Zip"},
    {abbreviation: "CO", name: "Colombia", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "KM", name: "Comoros", postal: "PostCode_Zip"},
    {abbreviation: "CG", name: "Congo (Brazzaville)", postal: "PostCode_Zip"},
    {abbreviation: "CD", name: "Congo (Kinshasa)", postal: "PostCode_Zip"},
    {abbreviation: "CK", name: "Cook Islands", postal: "PostCode_Zip"},
    {abbreviation: "CR", name: "Costa Rica", postal: "PostCode_Zip"},
    {abbreviation: "HR", name: "Croatia", postal: "PostCode_Zip"},
    {abbreviation: "CU", name: "Cuba", postal: "PostCode_Zip"},
    {abbreviation: "CW", name: "Curaçao", postal: ""},
    {abbreviation: "CY", name: "Cyprus", postal: "PostCode_Zip"},
    {abbreviation: "CZ", name: "Czech Republic", postal: "PostCode_Zip"},
    {abbreviation: "DK", name: "Denmark", postal: "PostCode_Zip"},
    {abbreviation: "DJ", name: "Djibouti", postal: "PostCode_Zip"},
    {abbreviation: "DM", name: "Dominica", postal: "PostCode_Zip"},
    {abbreviation: "DO", name: "Dominican Republic", postal: "PostCode_Zip"},
    {abbreviation: "EC", name: "Ecuador", postal: "PostCode_Zip"},
    {abbreviation: "EG", name: "Egypt", postal: "PostCode_Zip"},
    {abbreviation: "SV", name: "El Salvador", postal: "PostCode_Zip"},
    {abbreviation: "GQ", name: "Equatorial Guinea", postal: "PostCode_Zip"},
    {abbreviation: "ER", name: "Eritrea", postal: "PostCode_Zip"},
    {abbreviation: "EE", name: "Estonia", postal: "PostCode_Zip"},
    {abbreviation: "ET", name: "Ethiopia", postal: "PostCode_Zip"},
    {abbreviation: "FK", name: "Falkland Islands", postal: "PostCode_Zip"},
    {abbreviation: "FO", name: "Faroe Islands", postal: "PostCode_Zip"},
    {abbreviation: "FJ", name: "Fiji", postal: "PostCode_Zip"},
    {abbreviation: "FI", name: "Finland", postal: "PostCode_Zip"},
    {abbreviation: "FR", name: "France", postal: "PostCode_Zip"},
    {abbreviation: "GF", name: "French Guiana", postal: "PostCode_Zip"},
    {abbreviation: "PF", name: "French Polynesia", postal: "PostCode_Zip"},
    {abbreviation: "TF", name: "French Southern Territories", postal: "PostCode_Zip"},
    {abbreviation: "GA", name: "Gabon", postal: "PostCode_Zip"},
    {abbreviation: "GM", name: "Gambia", postal: "PostCode_Zip"},
    {abbreviation: "GE", name: "Georgia", postal: "PostCode_Zip"},
    {abbreviation: "DE", name: "Germany", postal: "PostCode_Zip"},
    {abbreviation: "GH", name: "Ghana", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "GI", name: "Gibraltar", postal: "PostCode_Zip"},
    {abbreviation: "GR", name: "Greece", postal: "PostCode_Zip"},
    {abbreviation: "GL", name: "Greenland", postal: "PostCode_Zip"},
    {abbreviation: "GD", name: "Grenada", postal: "PostCode_Zip"},
    {abbreviation: "GP", name: "Guadeloupe", postal: "PostCode_Zip"},
    {abbreviation: "GU", name: "Guam", postal: "PostCode_Zip"},
    {abbreviation: "GT", name: "Guatemala", postal: ""},
    {abbreviation: "GG", name: "Guernsey", postal: "PostCode_Zip"},
    {abbreviation: "GN", name: "Guinea", postal: "PostCode_Zip"},
    {abbreviation: "GW", name: "Guinea - Bissau", postal: "PostCode_Zip"},
    {abbreviation: "GY", name: "Guyana", postal: "PostCode_Zip"},
    {abbreviation: "HT", name: "Haiti", postal: "PostCode_Zip"},
    {abbreviation: "HM", name: "Heard Island and McDonald Islands", postal: "PostCode_Zip"},
    {abbreviation: "HN", name: "Honduras", postal: "PostCode_Zip"},
    {abbreviation: "HK", name: "Hong Kong", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "HU", name: "Hungary", postal: "PostCode_Zip"},
    {abbreviation: "IS", name: "Iceland", postal: "PostCode_Zip"},
    {abbreviation: "IN", name: "India", postal: "Pin"},
    {abbreviation: "ID", name: "Indonesia", postal: "PostCode_Zip"},
    {abbreviation: "IR", name: "Iran", postal: "PostCode_Zip"},
    {abbreviation: "IQ", name: "Iraq", postal: "PostCode_Zip"},
    {abbreviation: "IE", name: "Ireland", postal: "Eircode-optional"},
    {abbreviation: "IM", name: "Isle of Man", postal: "PostCode_Zip"},
    {abbreviation: "IL", name: "Israel", postal: "PostCode_Zip"},
    {abbreviation: "IT", name: "Italy", postal: "PostCode_Zip"},
    {abbreviation: "CI", name: "Ivory Coast", postal: "PostCode_Zip"},
    {abbreviation: "JM", name: "Jamaica", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "JP", name: "Japan", postal: "PostCode_Zip"},
    {abbreviation: "JE", name: "Jersey", postal: "PostCode_Zip"},
    {abbreviation: "JO", name: "Jordan", postal: "PostCode_Zip"},
    {abbreviation: "KZ", name: "Kazakhstan", postal: "PostCode_Zip"},
    {abbreviation: "KE", name: "Kenya", postal: "PostCode_Zip"},
    {abbreviation: "KI", name: "Kiribati", postal: "PostCode_Zip"},
    {abbreviation: "KW", name: "Kuwait", postal: "PostCode_Zip"},
    {abbreviation: "KG", name: "Kyrgyzstan", postal: "PostCode_Zip"},
    {abbreviation: "LA", name: "Laos", postal: "PostCode_Zip"},
    {abbreviation: "LV", name: "Latvia", postal: "PostCode_Zip"},
    {abbreviation: "LB", name: "Lebanon", postal: "PostCode_Zip"},
    {abbreviation: "LS", name: "Lesotho", postal: "PostCode_Zip"},
    {abbreviation: "LR", name: "Liberia", postal: "PostCode_Zip"},
    {abbreviation: "LY", name: "Libya", postal: "PostCode_Zip"},
    {abbreviation: "LI", name: "Liechtenstein", postal: "PostCode_Zip"},
    {abbreviation: "LT", name: "Lithuania", postal: "PostCode_Zip"},
    {abbreviation: "LU", name: "Luxembourg", postal: "PostCode_Zip"},
    {abbreviation: "MO", name: "Macao", postal: "PostCode_Zip"},
    {abbreviation: "MG", name: "Madagascar", postal: "PostCode_Zip"},
    {abbreviation: "MW", name: "Malawi", postal: "PostCode_Zip"},
    {abbreviation: "MY", name: "Malaysia", postal: "PostCode_Zip"},
    {abbreviation: "MV", name: "Maldives", postal: "PostCode_Zip"},
    {abbreviation: "ML", name: "Mali", postal: "PostCode_Zip"},
    {abbreviation: "MT", name: "Malta", postal: "PostCode_Zip"},
    {abbreviation: "MH", name: "Marshall Islands", postal: "PostCode_Zip"},
    {abbreviation: "MQ", name: "Martinique", postal: "PostCode_Zip"},
    {abbreviation: "MR", name: "Mauritania", postal: "PostCode_Zip"},
    {abbreviation: "MU", name: "Mauritius", postal: "PostCode_Zip"},
    {abbreviation: "YT", name: "Mayotte", postal: "PostCode_Zip"},
    {abbreviation: "MX", name: "Mexico", postal: "PostCode_Zip"},
    {abbreviation: "FM", name: "Micronesia", postal: "PostCode_Zip"},
    {abbreviation: "MD", name: "Moldova", postal: "PostCode_Zip"},
    {abbreviation: "MC", name: "Monaco", postal: "PostCode_Zip"},
    {abbreviation: "MN", name: "Mongolia", postal: "PostCode_Zip"},
    {abbreviation: "ME", name: "Montenegro", postal: "PostCode_Zip"},
    {abbreviation: "MS", name: "Montserrat", postal: "PostCode_Zip"},
    {abbreviation: "MA", name: "Morocco", postal: "PostCode_Zip"},
    {abbreviation: "MZ", name: "Mozambique", postal: ""},
    {abbreviation: "MM", name: "Myanmar", postal: "PostCode_Zip"},
    {abbreviation: "NA", name: "Namibia", postal: "PostCode_Zip"},
    {abbreviation: "NR", name: "Nauru", postal: "PostCode_Zip"},
    {abbreviation: "NP", name: "Nepal", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "NL", name: "Netherlands", postal: "PostCode_Zip"},
    {abbreviation: "NC", name: "New Caledonia", postal: "PostCode_Zip"},
    {abbreviation: "NZ", name: "New Zealand", postal: "PostCode_Zip"},
    {abbreviation: "NI", name: "Nicaragua", postal: "PostCode_Zip"},
    {abbreviation: "NE", name: "Niger", postal: "PostCode_Zip"},
    {abbreviation: "NG", name: "Nigeria", postal: ""},
    {abbreviation: "NU", name: "Niue", postal: "PostCode_Zip"},
    {abbreviation: "NF", name: "Norfolk Island", postal: "PostCode_Zip"},
    {abbreviation: "KP", name: "North Korea", postal: "PostCode_Zip"},
    {abbreviation: "MK", name: "North Macedonia", postal: "PostCode_Zip"},
    {abbreviation: "MP", name: "Northern Mariana Islands", postal: "PostCode_Zip"},
    {abbreviation: "NO", name: "Norway", postal: "PostCode_Zip"},
    {abbreviation: "OM", name: "Oman", postal: "PostCode_Zip"},
    {abbreviation: "PK", name: "Pakistan", postal: "PostCode_Zip"},
    {abbreviation: "PS", name: "Palestinian Territory", postal: "PostCode_Zip"},
    {abbreviation: "PA", name: "Panama", postal: "PostCode_Zip"},
    {abbreviation: "PG", name: "Papua New Guinea", postal: "PostCode_Zip"},
    {abbreviation: "PY", name: "Paraguay", postal: "PostCode_Zip"},
    {abbreviation: "PE", name: "Peru", postal: "PostCode_Zip"},
    {abbreviation: "PH", name: "Philippines", postal: "PostCode_Zip"},
    {abbreviation: "PN", name: "Pitcairn", postal: "PostCode_Zip"},
    {abbreviation: "PL", name: "Poland", postal: "PostCode_Zip"},
    {abbreviation: "PT", name: "Portugal", postal: "PostCode_Zip"},
    {abbreviation: "PR", name: "Puerto Rico", postal: "PostCode_Zip"},
    {abbreviation: "QA", name: "Qatar", postal: "PostCode_Zip"},
    {abbreviation: "RE", name: "Reunion", postal: "PostCode_Zip"},
    {abbreviation: "RO", name: "Romania", postal: "PostCode_Zip"},
    {abbreviation: "RU", name: "Russia", postal: "PostCode_Zip"},
    {abbreviation: "RW", name: "Rwanda", postal: "PostCode_Zip"},
    {abbreviation: "ST", name: "São Tomé and Príncipe", postal: "PostCode_Zip"},
    {abbreviation: "BL", name: "Saint Barthélemy", postal: "PostCode_Zip"},
    {abbreviation: "SH", name: "Saint Helena", postal: "PostCode_Zip"},
    {abbreviation: "KN", name: "Saint Kitts and Nevis", postal: "PostCode_Zip"},
    {abbreviation: "LC", name: "Saint Lucia", postal: "PostCode_Zip"},
    {abbreviation: "SX", name: "Saint Martin (Dutch part)", postal: "PostCode_Zip"},
    {abbreviation: "MF", name: "Saint Martin (French part)", postal: "PostCode_Zip"},
    {abbreviation: "PM", name: "Saint Pierre and Miquelon", postal: "PostCode_Zip"},
    {abbreviation: "VC", name: "Saint Vincent and the Grenadines", postal: "PostCode_Zip"},
    {abbreviation: "WS", name: "Samoa", postal: "PostCode_Zip"},
    {abbreviation: "SM", name: "San Marino", postal: "PostCode_Zip"},
    {abbreviation: "SA", name: "Saudi Arabia", postal: "PostCode_Zip"},
    {abbreviation: "SN", name: "Senegal", postal: "PostCode_Zip"},
    {abbreviation: "RS", name: "Serbia", postal: "PostCode_Zip"},
    {abbreviation: "SC", name: "Seychelles", postal: "PostCode_Zip"},
    {abbreviation: "SL", name: "Sierra Leone", postal: "PostCode_Zip"},
    {abbreviation: "SG", name: "Singapore", postal: "PostCode_Zip"},
    {abbreviation: "SK", name: "Slovakia", postal: "PostCode_Zip"},
    {abbreviation: "SI", name: "Slovenia", postal: "PostCode_Zip"},
    {abbreviation: "SB", name: "Solomon Islands", postal: "PostCode_Zip"},
    {abbreviation: "SO", name: "Somalia", postal: "PostCode_Zip"},
    {abbreviation: "ZA", name: "South Africa", postal: "PostCode_Zip"},
    {abbreviation: "GS", name: "South Georgia/Sandwich Islands", postal: "PostCode_Zip"},
    {abbreviation: "KR", name: "South Korea", postal: "PostCode_Zip"},
    {abbreviation: "SS", name: "South Sudan", postal: "PostCode_Zip"},
    {abbreviation: "ES", name: "Spain", postal: "PostCode_Zip"},
    {abbreviation: "LK", name: "Sri Lanka", postal: "PostCode_Zip"},
    {abbreviation: "SD", name: "Sudan", postal: "PostCode_Zip"},
    {abbreviation: "SR", name: "Suriname", postal: "PostCode_Zip"},
    {abbreviation: "SJ", name: "Svalbard and Jan Mayen", postal: "PostCode_Zip"},
    {abbreviation: "SZ", name: "Swaziland", postal: "PostCode_Zip"},
    {abbreviation: "SE", name: "Sweden", postal: "PostCode_Zip"},
    {abbreviation: "CH", name: "Switzerland", postal: "PostCode_Zip"},
    {abbreviation: "SY", name: "Syria", postal: "PostCode_Zip"},
    {abbreviation: "TW", name: "Taiwan", postal: "PostCode_Zip"},
    {abbreviation: "TJ", name: "Tajikistan", postal: "PostCode_Zip"},
    {abbreviation: "TZ", name: "Tanzania", postal: "PostCode_Zip"},
    {abbreviation: "TH", name: "Thailand", postal: "PostCode_Zip"},
    {abbreviation: "TL", name: "Timor-Leste", postal: "PostCode_Zip"},
    {abbreviation: "TG", name: "Togo", postal: "PostCode_Zip"},
    {abbreviation: "TK", name: "Tokelau", postal: "PostCode_Zip"},
    {abbreviation: "TO", name: "Tonga", postal: "PostCode_Zip"},
    {abbreviation: "TT", name: "Trinidad and Tobago", postal: "PostCode_Zip"},
    {abbreviation: "TN", name: "Tunisia", postal: "PostCode_Zip"},
    {abbreviation: "TR", name: "Turkey", postal: "PostCode_Zip"},
    {abbreviation: "TM", name: "Turkmenistan", postal: "PostCode_Zip"},
    {abbreviation: "TC", name: "Turks and Caicos Islands", postal: "PostCode_Zip"},
    {abbreviation: "TV", name: "Tuvalu", postal: "PostCode_Zip"},
    {abbreviation: "UG", name: "Uganda", postal: "PostCode_Zip"},
    {abbreviation: "UA", name: "Ukraine", postal: "PostCode_Zip"},
    {abbreviation: "AE", name: "United Arab Emirates", postal: ""},
    {abbreviation: "GB", name: "United Kingdom (UK)", postal: "Post Code"},
    {
        abbreviation: "US",
        name: "United States (US)",
        postal: "Zip Code",
        state: [
            { name: 'ALABAMA', abbreviation: 'AL'},
            { name: 'ALASKA', abbreviation: 'AK'},
            { name: 'AMERICAN SAMOA', abbreviation: 'AS'},
            { name: 'ARIZONA', abbreviation: 'AZ'},
            { name: 'ARKANSAS', abbreviation: 'AR'},
            { name: 'CALIFORNIA', abbreviation: 'CA'},
            { name: 'COLORADO', abbreviation: 'CO'},
            { name: 'CONNECTICUT', abbreviation: 'CT'},
            { name: 'DELAWARE', abbreviation: 'DE'},
            { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC'},
            { name: 'FEDERATED STATES OF MICRONESIA', abbreviation: 'FM'},
            { name: 'FLORIDA', abbreviation: 'FL'},
            { name: 'GEORGIA', abbreviation: 'GA'},
            { name: 'GUAM', abbreviation: 'GU'},
            { name: 'HAWAII', abbreviation: 'HI'},
            { name: 'IDAHO', abbreviation: 'ID'},
            { name: 'ILLINOIS', abbreviation: 'IL'},
            { name: 'INDIANA', abbreviation: 'IN'},
            { name: 'IOWA', abbreviation: 'IA'},
            { name: 'KANSAS', abbreviation: 'KS'},
            { name: 'KENTUCKY', abbreviation: 'KY'},
            { name: 'LOUISIANA', abbreviation: 'LA'},
            { name: 'MAINE', abbreviation: 'ME'},
            { name: 'MARSHALL ISLANDS', abbreviation: 'MH'},
            { name: 'MARYLAND', abbreviation: 'MD'},
            { name: 'MASSACHUSETTS', abbreviation: 'MA'},
            { name: 'MICHIGAN', abbreviation: 'MI'},
            { name: 'MINNESOTA', abbreviation: 'MN'},
            { name: 'MISSISSIPPI', abbreviation: 'MS'},
            { name: 'MISSOURI', abbreviation: 'MO'},
            { name: 'MONTANA', abbreviation: 'MT'},
            { name: 'NEBRASKA', abbreviation: 'NE'},
            { name: 'NEVADA', abbreviation: 'NV'},
            { name: 'NEW HAMPSHIRE', abbreviation: 'NH'},
            { name: 'NEW JERSEY', abbreviation: 'NJ'},
            { name: 'NEW MEXICO', abbreviation: 'NM'},
            { name: 'NEW YORK', abbreviation: 'NY'},
            { name: 'NORTH CAROLINA', abbreviation: 'NC'},
            { name: 'NORTH DAKOTA', abbreviation: 'ND'},
            { name: 'NORTHERN MARIANA ISLANDS', abbreviation: 'MP'},
            { name: 'OHIO', abbreviation: 'OH'},
            { name: 'OKLAHOMA', abbreviation: 'OK'},
            { name: 'OREGON', abbreviation: 'OR'},
            { name: 'PALAU', abbreviation: 'PW'},
            { name: 'PENNSYLVANIA', abbreviation: 'PA'},
            { name: 'PUERTO RICO', abbreviation: 'PR'},
            { name: 'RHODE ISLAND', abbreviation: 'RI'},
            { name: 'SOUTH CAROLINA', abbreviation: 'SC'},
            { name: 'SOUTH DAKOTA', abbreviation: 'SD'},
            { name: 'TENNESSEE', abbreviation: 'TN'},
            { name: 'TEXAS', abbreviation: 'TX'},
            { name: 'UTAH', abbreviation: 'UT'},
            { name: 'VERMONT', abbreviation: 'VT'},
            { name: 'VIRGIN ISLANDS', abbreviation: 'VI'},
            { name: 'VIRGINIA', abbreviation: 'VA'},
            { name: 'WASHINGTON', abbreviation: 'WA'},
            { name: 'WEST VIRGINIA', abbreviation: 'WV'},
            { name: 'WISCONSIN', abbreviation: 'WI'},
            { name: 'WYOMING', abbreviation: 'WY' }
        ]
    },
    {abbreviation: "UM", name: "United States (US) Minor Outlying Islands", postal: "PostCode_Zip"},
    {abbreviation: "UY", name: "Uruguay", postal: "PostCode_Zip"},
    {abbreviation: "UZ", name: "Uzbekistan", postal: "PostCode_Zip"},
    {abbreviation: "VU", name: "Vanuatu", postal: "PostCode_Zip"},
    {abbreviation: "VA", name: "Vatican", postal: "PostCode_Zip"},
    {abbreviation: "VE", name: "Venezuela", postal: "PostCode_Zip"},
    {abbreviation: "VN", name: "Vietnam", postal: "Postcode_ZIP-Optional"},
    {abbreviation: "VG", name: "Virgin Islands (British)", postal: "PostCode_Zip"},
    {abbreviation: "VI", name: "Virgin Islands (US)", postal: "PostCode_Zip"},
    {abbreviation: "WF", name: "Wallis and Futuna", postal: "PostCode_Zip"},
    {abbreviation: "EH", name: "Western Sahara", postal: "PostCode_Zip"},
    {abbreviation: "YE", name: "Yemen", postal: "PostCode_Zip"},
    {abbreviation: "ZM", name: "Zambia", postal: "PostCode_Zip"},
    {abbreviation: "ZW", name: "Zimbabwe", postal: "PostCode_Zip"}
] as tCountryInformation[]


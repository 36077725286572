import classNames from "classnames";
import {useEffect, useState} from "react";
import PostWalletTransaction from "src/api/rest/postWalletTransaction";
import Bootstrap from "src/Bootstrap";
import {getStyles} from "src/variables/DropVariables";
import Amount from "src/components/Wallet/Amount/Amount";
import Billing from "src/components/Wallet/Billing/Billing";
import styles from "src/components/Wallet/style.module.scss";
import Cookies from 'js-cookie';
import Loading from "src/components/Loading/Loading";
import isProduction from "src/variables/isProduction";
import SubmitButtonNMI from "./SubmitButtonNMI";

export const CCV_EXPIRATION = "dig-cc-exp"
export const CC_NUMBER = "dig-cc-number"
export const CCV = "digCvv"
export const NMI_SUBMIT = "dig-add-funds-button"

declare const window: Window &
    typeof globalThis & {
    CollectJS: {
        // @link https://secure.nmi.com/merchants/resources/integration/integration_portal.php#cjs_example_inline2_js
        configure: (config: {
            paymentSelector?: string,
            variant?: string,
            styleSniffer?: string,
            googleFont?: string,
            customCss?: {
                color?: string,
                "background-color"?: string
            },
            invalidCss?: {
                color?: string,
                "background-color"?: string
            },
            validCss?: {
                color?: string,
                "background-color"?: string
            },
            placeholderCss?: {
                "color"?: string,
                "background-color"?: string
            },
            focusCss?: {
                "color"?: string,
                "background-color"?: string
            },
            fields?: {
                // noinspection SpellCheckingInspection
                ccnumber?: {
                    "selector"?: string,
                    "title"?: string,
                    "placeholder"?: string
                },
                // noinspection SpellCheckingInspection
                ccexp?: {
                    "selector": string,
                    "title": string,
                    "placeholder": string
                },
                cvv?: {
                    "display": string,
                    "selector": string,
                    "title": string,
                    "placeholder": string
                },
                // noinspection SpellCheckingInspection
                checkaccount?: {
                    "selector": string,
                    "title": string,
                    "placeholder": string
                },
                // noinspection SpellCheckingInspection
                checkaba?: {
                    "selector": string,
                    "title": string,
                    "placeholder": string
                },
                checkname?: {
                    "selector": string,
                    "title": string,
                    "placeholder": string
                },
                // noinspection SpellCheckingInspection
                googlePay?: {
                    "selector": string,
                    "shippingAddressRequired": true,
                    "shippingAddressParameters": {
                        "phoneNumberRequired": true,
                        "allowedCountryCodes": ('US' | 'CA')[]
                    },
                    "billingAddressRequired": true,
                    "billingAddressParameters": {
                        "phoneNumberRequired": true,
                        "format": string,
                    },
                    'emailRequired': true,
                    "buttonType": string,
                    "buttonColor": string,
                    "buttonLocale": string
                },
                'applePay'?: {
                    'selector': string,
                    'shippingMethods': ({
                        'label': string,
                        'amount': string,
                        'detail': string,
                        'identifier': string
                    })[],
                    'shippingType': string,
                    'requiredBillingContactFields': string[],
                    'requiredShippingContactFields': string[],
                    'contactFields': string[],
                    'contactFieldsMappedTo': string,
                    'lineItems': ({
                        'label': string,
                        'amount': string
                    })[],
                    'totalLabel': string,
                    'type': string,
                    'style': any
                }
            },
            price?: string,
            currency?: string,
            country?: string,
            validationCallback?: (field: string, status: boolean, message: string) => void,
            timeoutDuration?: number,
            timeoutCallback?: () => void,
            fieldsAvailableCallback?: () => void,
            callback?: (response: any) => void,
        }) => void;
    }
}

export default function NMI({children}: { children: React.ReactNode }) {

    const [transactionInProgress, setTransactionInProgress] = useState<boolean>(false);


    const dig = getStyles(styles)

    // @link https://github.com/gateway-services/collectjs-react-demo/tree/master/src
    // @link https://secure.nmi.com/merchants/resources/integration/integration_portal.php#cjs_example_2_js
    // @link https://secure.nmi.com/merchants/resources/integration/integration_portal.php#cjs_example_inline2_js
    // noinspection JSUnusedGlobalSymbols
    useEffect(() => {
        // @link https://stackoverflow.com/questions/7307983/while-variable-is-not-defined-wait
        (async () => {

            while (undefined === window.CollectJS) {// define the condition as you like
                console.log("waiting for NMI CollectJS");
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
            console.log("NMI CollectJS is defined");

            window.CollectJS.configure({
                paymentSelector: `#${NMI_SUBMIT}`,
                variant: 'inline',
                invalidCss: {
                    color: "red"
                },
                validCss: {
                    color: "green"
                },
                placeholderCss: {
                    color: "#BBB"
                },
                focusCss: {
                    color: "black"
                },
                fields: {
                    // noinspection SpellCheckingInspection
                    ccnumber: {
                        "title": "Card Number",
                        "selector": `#${CC_NUMBER}`,
                        "placeholder": "0000 0000 0000 0000"
                    },
                    // noinspection SpellCheckingInspection
                    ccexp: {
                        "selector": `#${CCV_EXPIRATION}`,
                        "title": "Expiration Date",
                        "placeholder": "00 / 00"
                    },
                    cvv: {
                        display: "required",
                        "selector": `#${CCV}`,
                        "title": "CVV Code",
                        "placeholder": "000"
                    },
                },
                validationCallback: function (field, status, message) {
                    console.log(field, status, message)
                    switch (field) {
                        case 'ccnumber':
                            console.log('ccnumber valid: ' + status + ' ' + message)
                            break;
                        case 'ccexp':
                            console.log('ccexp valid: ' + status + ' ' + message)
                            break;
                        case 'cvv':
                            console.log('ccv valid: ' + status + ' ' + message)
                            break;
                        default:
                            break;
                    }
                },
                timeoutDuration: 5000,
                timeoutCallback: function () {
                    console.log("The tokenization didn't respond in the expected timeframe. " +
                        "This could be due to an invalid or incomplete field or poor connectivity");
                },
                fieldsAvailableCallback: function () {
                    //TODO Add any handling; Collect.js loaded the fields onto the form
                },
                callback: function (response) {
                    setTransactionInProgress(true);

                    const {firstName, lastName, zip, selectedCountry, state, town, street, apt} = Billing.billing?.state ?? {};

                    if (false === isProduction) {

                        console.log('NMI response', response);

                    }

                    PostWalletTransaction({
                        action: 'deposit',
                        amount: Amount.amount?.state.monetaryValue.toString() ?? '0.00',
                        auth_status: 1,
                        cc_addr_line_1: street ?? '',
                        cc_addr_line_2: apt,
                        cc_auth_code: response.token,
                        cc_city: town,
                        cc_country: selectedCountry?.abbreviation ?? '',
                        cc_fname: firstName ?? '',
                        cc_lname: lastName ?? '',
                        cc_mask: response.card.number,
                        cc_response_code: response.card.code,
                        cc_state: state,
                        cc_zip: zip,
                        cvv_response: response.card.type,
                        kt_sid: Cookies.get('KOUNT_SID'),
                        payment_token: response.token,
                        success: "Successfully added your card transaction!",
                        user_id: Bootstrap.bootstrap.state.id
                    })?.finally(() => {
                        setTransactionInProgress(false);
                    });

                }
            })
        })();
    })

    if (transactionInProgress) {

        return <Loading message={"Transaction in progress!"}/>

    }

    const triggerNMI = () => {

        document.getElementById(NMI_SUBMIT)?.click();

    }

    return <div id="dig-wallet-payment-cc" className={dig.mt5}>
        <div className={"dig wallet payment container"}>
            <div className={dig.mt1} id="billing_ccnum_field" data-priority="16">
                <label htmlFor="billing_ccnum" className={classNames(dig.digHighlight2)}>
                    Card Number&nbsp;
                    <abbr className={"required"} title="required">*</abbr></label>
                <div className={dig.woocommerceInputWrapper}>
                    <div id={CC_NUMBER} className={classNames({
                        [dig.woocommerceInvalidRequiredField]: true
                    })}>
                    </div>
                </div>
            </div>

            <div className={classNames(dig.row, dig.mt3)} id="dig_ccexp_ccdvv">
                <div className={classNames(dig.colMd8, dig.ps0, dig.pe0)}
                     id="dig_ccexp">
                    <p className={dig.m0} id="billing_ccexp_field"
                       data-priority="17">
                        <label htmlFor="billing_ccexp"
                               className={classNames(dig.digHighlight2)}>Expiration
                            Date&nbsp;
                            <abbr className={"required"}
                                  title="required">*</abbr></label>
                    </p>
                    <div id={CCV_EXPIRATION} className={classNames({
                        [dig.woocommerceInvalidRequiredField]: true
                    })}>

                    </div>
                </div>
                <div
                    className={classNames(dig.colMd4, dig.psLg3, dig.ps0, dig.pe0, dig.mt3, dig.mtLg0)}
                    id="dig_ccdvv">
                    <div className={""} id="billing_ccv_field"
                         data-priority="18"
                         style={{
                             marginLeft: "15px !important",
                             marginTop: "-15px !important"
                         }}>
                        <label htmlFor="billing_ccv"
                               className={""}>CVV&nbsp;
                            <abbr className={"required"}
                                  title="required">*</abbr></label>
                        <span
                            className={dig.woocommerceInputWrapper}/>
                    </div>
                    <div id={CCV} className={classNames({
                        [dig.woocommerceInvalidRequiredField]: true
                    })}>
                    </div>

                </div>
            </div>
            <br/>
            {isProduction || <div className="mb-3" style={{backgroundColor: "grey"}}>
                <div className="dig-cc-test-info">
                    <p>Test CC Information:</p>
                    <p>Num: 4111 1111 1111 1111</p>
                    <p>Exp: 02/25 CVV: 999</p>
                </div>
            </div>}
            {children}
            <div
                className={classNames(dig.row, "dig-add-funds-btn-groups", dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.mb1)}>
                <SubmitButtonNMI triggerNMI={triggerNMI}/>
                <button type="button" id={NMI_SUBMIT} hidden={true}/>
            </div>
        </div>
    </div>
}

import {useEffect, useState} from "react";
import getTeams from "src/api/rest/getTeams";
import {ReactComponent as TeamSVG} from "src/assets/img/svg-icons/team_profile.svg"
import Loading from "src/components/Loading/Loading";
import Popup from "src/components/Popup/Popup";
import ProfilePicture from "src/components/ProfilePicture/ProfilePicture";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {PROFILE_PATH} from "src/views/Profile/Profile";
import EditTeamName from "src/views/Team/Modal/EditTeamName";
import {Link} from "react-router-dom";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import classNames from "classnames";
import Bootstrap from "src/Bootstrap";
import RemoveRoster from "src/views/Team/Modal/RemoveRoster";
import SearchUser from "../../components/SearchUser/SearchUser";

export const TEAM_PATH = 'team';

// core components
export default function Team(props: WithRouter) {

    const {users, id, teams} = Bootstrap.bootstrap.state;

    const [editTeamNamePopup, setEditTeamNamePopup] = useState(false);
    const [removeRosterPopup, setRemoveRosterPopup] = useState(false);
    const [removeUserId, setRemoveUserId] = useState(0);

    const HandleJoinClose = () => setEditTeamNamePopup(false);
    const handleRemoveRosterPopupClose = () => setRemoveRosterPopup(false);

    const teamId = parseInt(props.params.teamId);

    useEffect(() => {
        id !== 0 && getTeams({
            userId: id
        });

    }, [])


    const team = teams?.find(team => team.user_team_id === teamId);

    if (undefined === team) {

        return <Loading message={"Loading Team Information!"}/>

    }

    const teamSize = team.team_size_id;

    team.rosterAccepted ??= []

    team.rosterPending ??= []

    const teamRoster = team?.rosterAccepted.concat(team.rosterPending)

    const teamFull = teamRoster.length === team.team_size_id

    const isTeamManager = team.uid === id

    const dig = getStyles()

    return <div className={dig.container}>

        <Popup
            handleClose={HandleJoinClose}
            open={editTeamNamePopup}
        >
            <EditTeamName handleClose={HandleJoinClose} currentTeam={team}/>
        </Popup>

        <Popup
            handleClose={handleRemoveRosterPopupClose}
            open={removeRosterPopup}
        >
            <RemoveRoster handleClose={handleRemoveRosterPopupClose} teamId={teamId} userId={removeUserId}/>
        </Popup>

        <div className={classNames(dig.row, dig.mx0, dig.my3)}>
            <div className={classNames(dig.col12, dig.p0)}>
                <div className={dig.mb5}>
                </div>
            </div>
            <div className={classNames(dig.dFlex, dig.justifyContentBetween, dig.alignItemsLgCenter, dig.w100)}>
                <Link className={dig.mb4}
                      to={"/profile/" + id + '/teams/'}>
                    <i className={classNames("fas fa-long-arrow-alt-left", dig.me2)}></i>
                    Go to My Teams </Link>
            </div>

        </div>

        <div className={dig.containerFluid}>
            <div className={dig.row}>
                <div className={classNames(dig.col12, dig.colLg6, dig.mb3)}>
                    <div
                        className={classNames(dig.row, dig.mx0, dig.digBgNeutral9, dig.p4, dig.rounded0, "team-header", dig.col, dig.dFlex, dig.alignItemsCenter)}>
                        <div className={classNames(dig.colSm3, dig.teamProfile, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter)}>
                            <TeamSVG/>
                        </div>
                        <div className={classNames(dig.colSm9, "media-body", dig.textWhite, dig.positionRelative)}>
                            <h4 className={classNames(dig.mt0, dig.mb2, dig.textStart, "team-size-text", dig.digLightBlue)}>
                                {DropVariables.teamSizeToReadableText(teamSize)}
                            </h4>
                            <h1 className={classNames(dig.mt0, dig.mb2, dig.textStart)}>
                                {team.team_name}
                            </h1>
                            {team.created && <h4 className={classNames(dig.digColorNeutral3, dig.textStart)}>Founded {DropVariables.convertPstToLocal(team.created)}</h4>}
                            <i data-action="generate-edit-team-form"
                               className={classNames("fas fa-pen", dig.teamProfileEditIcon, dig.cursorPointer)}
                               onClick={() => setEditTeamNamePopup(true)}>
                            </i>
                        </div>
                    </div>
                </div>
                <div className={classNames(dig.col12, dig.colLg6)}>
                    <div
                        className={classNames(dig.row, dig.mx0, dig.digBgNeutral9, dig.p4, dig.teamHeaderCol, dig.dFlex, dig.alignItemsCenter, dig.rowCols3)}>
                        <div className={dig.col}>
                            <span className={classNames(dig.digLightBlue, dig.fwBold)}>WIN</span>
                            <h1>{team.win || 0}</h1>
                        </div>
                        <div className={dig.col}>
                            <span className={classNames(dig.digLightRed, dig.fwBold)}>LOSS</span>
                            <h1>{team.lose || 0}</h1>
                        </div>
                        <div className={dig.col}>
                            <span className={classNames(dig.digLightBlue, dig.fwBold, dig.textUppercase)}>Earnings</span>
                            <h1><span className={"dig-positive-balance"}>${team.cumulate_funds || '0.00'}</span></h1>
                        </div>
                        <div className={dig.col12}>
                            <span className={classNames(dig.digLightBlue, dig.fwBold, dig.textUppercase)}>
                                Avg. Placement
                            </span>
                            <h1 className={dig.textWhite}>0</h1>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div className={classNames(dig.containerFluid, dig.mt4)}>
            <div className={classNames(dig.row, dig.mx0, dig.digBgNeutral9)}>
                <div className={dig.col12}>
                    <h2 className={dig.m4}>Members</h2>
                </div>
            </div>
            <div className={classNames(dig.row, dig.mx0, dig.digBgNeutral9)}>

                {/*    const myTeams = bootstrap.state.users.filter(user => team.roster.includes(user.ID)) */}

                {team.rosterAccepted.map((userId, index) => {

                    const user = users?.find(user => user.ID === userId)

                    return <div className={classNames(dig.col12, dig.colLg6, dig.p0)} key={index}>


                        <div className={classNames(dig.row, dig.mx4, dig.mb4, dig.digBgNeutral6)}>
                            <div className={classNames(dig.inputGroup, dig.p3, dig.borderLeft2, dig.digBorderGreen)}>
                                <div className={"input-group-prepend"}>

                                    <ProfilePicture user={user} width={35} height={35}/>

                                </div>
                                <Link className={classNames(dig.formControl, dig.digBgNeutral6, dig.border0, dig.stretchedLink, dig.h100)}
                                      to={"/" + PROFILE_PATH + "/" + user?.ID + '/'}>
                                    <h3 className={classNames(dig.textStart)}>
                                        {user?.display_name}
                                    </h3>
                                </Link>
                                <div className={classNames("input-group-append", dig.dFlex, dig.alignItemsCenter, dig.px3)}>
                                    {
                                        user?.ID && user.ID === team.uid ?
                                            <span
                                                className={classNames(dig.dFlex, dig.alignItemsCenter, dig.digBgNeutral6, dig.border0, dig.rounded0, dig.digColorNeutral3)}>
                                                MANAGER
                                            </span>
                                            :
                                            <i
                                                onClick={() => {
                                                    setRemoveRosterPopup(true)
                                                    user?.ID && setRemoveUserId(user?.ID)
                                                }}
                                                className={classNames("fas fa-times m-0 dig-color-neutral3 ", dig.fontSize1P5Em, dig.cursorPointer)}></i>
                                    }

                                </div>
                            </div>
                        </div>
                    </div>

                })}

                {team.rosterPending.map((userId, index) => {

                    const user = users?.find(user => user.ID === userId)

                    return <div className={classNames(dig.col12, dig.colLg6, dig.p0)} key={index}>
                        <div className={classNames(dig.row, dig.mx4, dig.mb4, dig.digBgNeutral6)}>
                            <div className={classNames(dig.inputGroup, dig.p3, dig.borderLeft2, dig.digBorderYellow)}>
                                <div className={"input-group-prepend"}>

                                    <ProfilePicture user={user} width={35} height={35}/>

                                </div>
                                <Link className={classNames(dig.formControl, dig.digBgNeutral6, dig.border0, dig.stretchedLink, dig.h100)}
                                      to={"/" + PROFILE_PATH + "/" + user?.ID + '/'}>
                                    <h3 className={classNames(dig.textStart)}>
                                        {user?.display_name}
                                    </h3>
                                </Link>
                                <div className={classNames("input-group-append", dig.dFlex, dig.alignItemsCenter, dig.px3)}>

                                    <i
                                        onClick={() => {
                                            setRemoveRosterPopup(true)
                                            user?.ID && setRemoveUserId(user?.ID)
                                        }}
                                        className={classNames("fas fa-times m-0 dig-color-neutral3 ", dig.fontSize1P5Em, dig.cursorPointer)}></i>


                                </div>
                            </div>
                        </div>
                    </div>

                })}

                {false === teamFull && isTeamManager && <div className={classNames(dig.colLg6, dig.p0)}>
                    <SearchUser
                        className={classNames(dig.mx4, dig.mb4, dig.digBgNeutral6, dig.positionRelative)}
                        teamId={teamId}/>
                </div>}


            </div>


        </div>


        <div className={classNames(dig.containerFluid, dig.mt4)}>
            <div className={classNames(dig.row, dig.mx0)}>
                <div className={dig.col12}>
                    <h2 className={dig.my4}>Upcoming Joined Games</h2>
                </div>
            </div>
            <div className={classNames(dig.row, dig.mx0, dig.mt4)}>
                <div className={dig.col12}>
                    <h4>There are no results to display.</h4>
                </div>
            </div>
        </div>
        <br/>
    </div>

}

import classNames from "classnames";
import {useEffect, useState} from "react";
import getCompetitions from "src/api/rest/getCompetitions";
import getInvitations from "src/api/rest/getInvitations";
import getTeams from "src/api/rest/getTeams";
import getVendors from "src/api/rest/getVendors";
import Bootstrap from "src/Bootstrap";
import Invitation from "src/components/Invitation/Invitation";
import Popup from "src/components/Popup/Popup";
import SocialShareLinks from "src/components/SocialShareLinks/SocialShareLinks";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {
    eCOMPETITION,
    eCOMPETITION_CATEGORY, eCOMPETITION_STATUS,
    eCOMPETITION_TIMING,
    iCompetitions
} from "src/variables/sharedInterfaces";
import CompetitionHeader from "src/views/Competition/CompetitionHeader/CompetitionHeader";
import JoinWithTeam from "src/views/Competition/Modals/JoinWithTeam";
import RegisteredTeams from "src/views/Competition/RegisteredTeams/RegisteredTeams";
import Roster from "src/views/Competition/Roster/Roster";
import Rules from "src/views/Competition/Rules/Rules";
import JoinStatus from "src/views/Competition/JoinStatus/JoinStatus";
import Details from "src/views/Competition/Details/Details";
import Prize from "src/views/Competition/Prize/Prize";
import Bracket from "src/views/Competition/Bracket/Bracket";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import LiveScores from "src/views/Competition/LiveScores/LiveScores";
import {Navigate, Route, Routes} from "react-router-dom";


import {Helmet} from "react-helmet";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import Wallet, {WALLET_PATH} from "src/components/Wallet/Wallet";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import RoundRobin from "src/views/Competition/Bracket/RoundRobin";
import isLoggedIn from "src/variables/isLoggedIn";
import getSubMode from "src/api/rest/getSubMode";
import getResultUpload from "src/api/rest/getResultUpload";
export const COMPETITION_PATH = "competition";
export const COMPETITION_JOIN_PATH = "join";
export const COMPETITION_LOGIN_PATH = "login";

export default function Competition(props: WithRouter) {

    const {registrations, competitions, id, bootstrap, loginPopup} = Bootstrap.bootstrap.state

    const [useRedirect, setUseRedirect] = useState<string>('');

    const [hasToggledLoginPopup, setHasToggledLoginPopup] = useState<boolean>(false);

    const [timeLeft, setTimeLeft] = useState<number>(999)

    const competitionId = parseInt(props.params.id);

    useEffect(() => {

        const request = {
            competition: eCOMPETITION.ALL,
            page: 1,
            userID: undefined,
            category: eCOMPETITION_CATEGORY.ALL,
            timing: eCOMPETITION_TIMING.ALL,
            gameID: undefined,
            competitionIds: [
                parseInt(props.params.id)
            ],
            fetchDependencies: true,
            cacheResults: false,
        }

        // Grab parent comp if it has a parent
        competition && competition?.parent_comp && competition?.parent_comp !== 0 && request.competitionIds.push(competition?.parent_comp)


        getCompetitions(request);

        getVendors()

        id !== 0 && getTeams({
            userId: id
        });

        getInvitations();

        getSubMode({});

        getResultUpload({comp_id: competitionId});

    }, [Bootstrap.bootstrap.state.competitions, Bootstrap.bootstrap.state.registrations, Bootstrap.bootstrap.state.teams])



    const getCurrentCompetition = (): iCompetitions|undefined => competitions?.find(competition => competition.comp_id === competitionId) || undefined;

    const competition: iCompetitions|undefined = getCurrentCompetition();

    const myRegistration = registrations?.find(registration =>
        registration.competitions_id === competition?.comp_id &&
        registration.user_id === id)

    const myAccount = getCurrentLoggedInUser()

    if (undefined === competition) {

        return <Loading message={'Loading competition information'}/>

    }

    const hasEnoughFunds = undefined !== myAccount && ((myAccount?.funds ?? 0) - parseFloat(competition?.buy_in ?? '0') ) >= 0

    const thisCompetitionUri = '/' + COMPETITION_PATH + '/' + competitionId + '/'

    const handleJoinOpen = () => {

        if (false === isLoggedIn()) {

            setUseRedirect(thisCompetitionUri + COMPETITION_LOGIN_PATH + '/');

        } else if (hasEnoughFunds) {

            setUseRedirect(thisCompetitionUri + COMPETITION_JOIN_PATH + '/');

        } else {

            setUseRedirect(thisCompetitionUri + WALLET_PATH + '/');

        }

    }

    if (undefined === competition ||
        1 === Object.keys(competition).length ||
        (competition.comp_type_id !== eCOMPETITION.MATCHES && competition.comp_type_id !== eCOMPETITION.ROUND_ROBIN &&
            competition.sub_mode === undefined)) {

        return <Loading message={"Loading Competition Information"}/>

    } else {

        console.log(competition)

    }

    const invitations = bootstrap.state.invitations?.filter(invitation => invitation.comp_id === competition.comp_id)

    const dig = getStyles();

    if (window.location.pathname === useRedirect) {
        setUseRedirect('')
        return null;
    }

    if ('' !== useRedirect) {
        return <Navigate to={useRedirect} />
    }

    if (window.location.pathname.endsWith(COMPETITION_LOGIN_PATH + '/') && false === loginPopup) {

        if (hasToggledLoginPopup){
            setUseRedirect(thisCompetitionUri)
            return null;
        }

        setHasToggledLoginPopup(true)

        bootstrap.setState({
            loginPopup: true
        })
    }

    const competitionStatusInt = competition.status

    // we use || here to help with timing, we don't want join and delete to show at the same time
    const joinedCompetition = undefined !== registrations?.find(registration => registration.user_id === id
        && registration.competitions_id === competition.comp_id) || true === competition.registered_users?.includes(id)

    const eligibleToShowClantag = (true === joinedCompetition && (competitionStatusInt === eCOMPETITION_STATUS.WAITING || competitionStatusInt === eCOMPETITION_STATUS.STARTED) && competition?.clan_tag !== '' && competition?.clan_tag !== undefined && competition?.clan_tag !== null)

    const timeFromChild = (data) => {
        setTimeLeft(data)
    }

    if (typeof competition.datetime === 'string') {
        let timeToStart = DropVariables.unixTimeUntil(competition.datetime);

        if (300 >= timeToStart && timeToStart < timeLeft) {
            setTimeLeft(timeToStart);
        }

    }

    return <div className={classNames(dig.container, dig.px4)}>

        <Helmet>
            <meta property="og:url" content={"" + window.location}/>
            <meta property="og:type" content="website"/>
            <meta property="og:title"
                  content={`${competition.competition_title} | Drop-In Gaming : Where We Droppin'?`}/>
            <meta property="og:description"
                  content={`Starts: ${DropVariables.convertPstToLocal(competition.datetime)} | Prize: $0.00 | Buy-In: Free`}/>
            <meta property="og:image" content="/wp-content/themes/dropingaming/assets/img/og-default-image.png"/>
        </Helmet>

        <Routes>
            <Route path={WALLET_PATH + '/*'} element={
                <Popup
                    handleClose={() => {setUseRedirect(thisCompetitionUri)}}
                    open={true}
                    maxWidth={"768px"}
                >
                    <ExpandableBox header={"Wallet"}>
                        <Wallet />
                    </ExpandableBox>
                </Popup>
            } />
            <Route path={COMPETITION_JOIN_PATH + '/*'} element={
                <Popup
                    handleClose={() => {setUseRedirect(thisCompetitionUri)}}
                    open={true}
                >
                    <JoinWithTeam {...props} competition={competition} handleClose={() => {
                        setUseRedirect(thisCompetitionUri)
                    }}/>
                </Popup>
            } />
            <Route path={COMPETITION_LOGIN_PATH + '/*'} element={loginPopup} />
        </Routes>

        <CompetitionHeader competition={competition}/>

        <div className={classNames(
            dig.bgTransparent, dig.border0, dig.overflowHidden
        )}>

            <div className={classNames(
                dig.row, dig.justifyContentBetween
            )}>

                {/*<!-- Left side -->*/}

                <div className={classNames(dig.colLg3, dig.mb4)}>
                    <div className={classNames(dig.row, dig.m0, dig.w100)}>
                        <div className={classNames(
                            dig.accordion, dig.w100, dig.border0, dig.rounded0, dig.p0
                        )}>

                            <JoinStatus sendTimeleftToParent={timeFromChild} competition={competition}
                                        openJoinPopup={handleJoinOpen}/>

                            <Details competition={competition}/>

                        </div>

                        <SocialShareLinks/>

                    </div>

                </div>

                {/*<!--\ Left side -->*/}


                {/*<!-- Right side -->*/}

                <div className={classNames(dig.colLg9, dig.tabContent)}>

                    <div id="info"
                         className={classNames(
                             dig.tabPane, dig.fade, dig.show, dig.active, dig.cardBody, dig.p0, dig.flexColumn, dig.alignItemsStart
                         )} role="tabpanel">

                        <>

                            {
                                timeLeft <= 300 && eligibleToShowClantag ?
                                    <div className={classNames(dig.col12, dig.digBtnGreen, dig.p3, dig.mb3, dig.mt2)}>
                                        <h4 className={classNames(dig.cardTitle, dig.textStart, dig.fwLight, dig.mb3)}>Join with your team clan Tag:</h4>
                                        <h2 className={classNames(dig.cardText, dig.textStart, dig.textWhite)}>
                                            {competition?.clan_tag}
                                        </h2>
                                    </div>
                                    :
                                    ''
                            }

                        {invitations?.filter(invite => invite.sender_id !== bootstrap.state.id && competition?.comp_id === invite.comp_id)
                            .map((invitation, key) =>
                                <Invitation {...props} key={key} invitation={invitation}
                                            openJoinPopup={handleJoinOpen}
                                            viewOnly={false}/>) || <Loading message={"Loading Invitations"}/>}



                        {competition.comp_type_id !== eCOMPETITION.MATCHES && <>
                            {myRegistration && myRegistration.team_id &&
                            <Roster teamId={myRegistration.team_id}
                                    competition={competition}
                                    canEdit={competition.status === eCOMPETITION_STATUS.WAITING}/>}
                            {competition.comp_type_id !== eCOMPETITION.ROUND_ROBIN && <Prize competition={competition}/>}
                        </> || (competition.max_players && <RegisteredTeams
                            competition={competition} maxTeams={competition.max_players}/>)}

                             {invitations?.filter(invite => invite.sender_id === bootstrap.state.id && competition?.comp_id === invite.comp_id)
                            .map((invitation, key) =>
                                <Invitation {...props} key={key} invitation={invitation}
                                            openJoinPopup={handleJoinOpen}
                                            viewOnly={true}/>) || <Loading message={"Loading Sent Invitations"}/>}

                        <Bracket competition={competition}/>

                        {competition.comp_type_id === eCOMPETITION.ROUND_ROBIN && (competition.status ?? 0) > eCOMPETITION_STATUS.WAITING && (competition.status ?? 0) < eCOMPETITION_STATUS.CANCELED &&
                        <ExpandableBox header={'Round Robin'}>
                            <RoundRobin {...competition}/>
                        </ExpandableBox>}

                        {competition.comp_type_id !== eCOMPETITION.ROUND_ROBIN && <LiveScores competition={competition}/>}

                        <Rules competition={competition}/>



                        {competition.comp_type_id !== eCOMPETITION.MATCHES && competition.max_players &&
                        <RegisteredTeams
                            maxTeams={competition.max_players}
                            competition={competition}/>}

                        <br/>


                        </>
                    </div>

                </div>

            </div>

            {/*<!--\ Right side -->*/}

        </div>

    </div>


}



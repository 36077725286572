
import restApi, {GET} from "src/api/API";
import DropVariables from "src/variables/DropVariables";
import {C6, dig_vendor_game, iDig_Game} from "src/variables/C6";
import {iGame} from "src/variables/sharedInterfaces";
import Bootstrap from "src/Bootstrap";
import {PLATFORM_IDS} from "src/api/rest/getGameList";

export interface iGetGamesForCreateComp {
    compTypeId: number
}

export default restApi<iGetGamesForCreateComp, iDig_Game>({
        axios: DropVariables.axios,
        tableName: C6.dig_game.TABLE_NAME,
        requestMethod: GET,
        queryCallback: (request: iGetGamesForCreateComp) => {
            return {
                [C6.SELECT]: [
                    C6.dig_game.GAME_ID,
                    C6.dig_game.NAME,
                    C6.dig_game.CODE,
                    C6.dig_game.GAME_LOGO_IMAGE,
                    C6.dig_game.HEADER_BACKGROUND_IMAGE,
                    C6.dig_game.DESCRIPTION,
                    C6.dig_game.FEATURED_IMAGE,
                    [C6.GROUP_CONCAT, dig_vendor_game.PLATFORM_ID, PLATFORM_IDS],
                ],
                [C6.JOIN]: {
                    [C6.INNER]: {
                        [C6.dig_mode_game.TABLE_NAME]: [
                            C6.dig_mode_game.GAME_ID,
                            C6.dig_game.GAME_ID
                        ],
                        [C6.dig_mode.TABLE_NAME]: [
                            C6.dig_mode.MODE_ID,
                            C6.dig_mode_game.MODE_ID,
                        ]
                    },
                    [C6.LEFT]: {
                        [dig_vendor_game.TABLE_NAME]: [
                            dig_vendor_game.GAME_ID,
                            C6.dig_game.GAME_ID
                        ]
                    },
                },
                [C6.WHERE]: {
                    [C6.dig_mode.COMP_TYPE_ID]: request.compTypeId,
                },
                [C6.GROUP_BY]: [
                    C6.dig_game.GAME_ID,
                    C6.dig_game.GAME_ORDER
                ],
                [C6.PAGINATION]: {
                    [C6.ORDER]: {
                        [C6.dig_game.GAME_ORDER]: C6.ASC
                    },
                    [C6.LIMIT]: 100
                }
            };
        },
        responseCallback: (response, _request) => {

            // any is the type for the map as we have not cast
            const games: iGame[] = response.data?.rest.map((game: any) => {

                game[PLATFORM_IDS] = game[PLATFORM_IDS]?.split(',')

                return game;

            }) || [];


            Bootstrap.bootstrap.setState({
                games: games
            })

        }

    })
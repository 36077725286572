import classNames from "classnames";
import {SyntheticEvent} from "react";
import Boss from 'src/assets/img/gg-imgs/newbie_subscription.jpg';
import NeedCoin from 'src/assets/img/gg-imgs/need_coins.png';
import {Link} from "react-router-dom";
import {getStyles} from "src/variables/DropVariables";
import {iUser, iSubscription} from "src/variables/sharedInterfaces";
import Skeleton from "react-loading-skeleton";
import MediaQuery from "react-responsive";
import {AxiosInstance} from "axios";
import swal from "sweetalert";
import {toast} from "react-toastify";

// core components

export const SUBSCRIPTION_PATH = 'subscription'

export default function Subscription(props: {
    subscriptions?: Array<iSubscription>,
    currentEditUser: iUser,
    params: any,
    axios: AxiosInstance,
    updateCurrentEditUser: Function,
    toastOptions: any
}) {

    // noinspection TypeScriptRedundantGenericType
    function handleClick(e: SyntheticEvent<Element>) {
        const userid = (e.target as HTMLButtonElement).dataset.userid;
        let swalOptions = {
            content: {
                element: 'p',
                attributes: {
                    className: 'text-dark',
                    innerHTML: `
                        <div class = 'dig.dFlex, dig.flexColumn, dig.justifyContentCenter'>
                            <h2 class ='dig.textDark, dig.mt5, dig.mb4, dig.textCenter'>
                                ${`Do you really want to <span class="text-danger">remove</span> subscription plan from ${props.currentEditUser.display_name} account?`}
                                
                            </h2>
                        </div>                         
                    `
                }
            },
            buttons: {
                cancel: {
                    text: "No, Cancel",
                    value: null,
                    closeModal: true,
                    className: "gg-swal-cancel-btn",
                    visible: true
                },
                confirm: {
                    text: "Yes, Remove",
                    value: 'yes',
                    className: "gg-swal-hold-btn btn danger",
                }
            }
        };

        swal(swalOptions as any).then((value) => {
            switch (value) {
                case 'yes':
                    props.axios({
                        method: "PUT",
                        url: '/wp-json/api/parent-portal/subscriptionEntry/' + userid
                    }).then((res) => {
                        toast.success(res.data.msg, props.toastOptions);
                        props.updateCurrentEditUser(
                            {
                                ID: userid,
                                activeSubscription: false,
                                subscriptions: []
                            }
                        );
                    }).catch((err) => {
                        console.log(err)
                    })
                    break;
                default:
                    swal.close?.();
                    break;
            }
        })
    }

    const {subscriptions, currentEditUser} = props;

    const dig = getStyles()

    return <div className={classNames(dig.containerFluid, dig.p0, dig.my5, dig.ggSubscription)}>
        {subscriptions === null ?
            <div className={dig.row}>
                <div className={dig.col4}>
                    <Skeleton count={1} height={450} inline={true}/>
                </div>
                <div className={dig.col4}>
                    <Skeleton count={1} height={450} inline={true}/>
                </div>
                <div className={dig.col4}>
                    <Skeleton count={1} height={450} inline={true}/>
                </div>
            </div>
            :
            <>
                <div className={dig.row}>
                    {
                        subscriptions && subscriptions.map((subscription, key) => {
                                let cardBgColor = 'gg__subscription_body--blue',
                                    cardTextColor = 'gg-text-color-blue';

                                if (key === 1) {
                                    cardBgColor = 'gg__subscription_body--green';
                                    cardTextColor = 'gg-text-color-green';
                                } else if (key === 2) {
                                    cardBgColor = 'gg__subscription_body--orange';
                                    cardTextColor = 'gg-text-color-orange';
                                }

                                return subscription.charge_interval !== 0 &&
                                    <div key={key} className={classNames(dig.col12, dig.colSm4, dig.mb4)}>
                                        <div className={classNames(dig.card, dig.border0, dig.mb3)}>
                                            <div
                                                className={classNames(cardTextColor, dig.cardHeader,dig.border0, dig.textUppercase, dig.textCenter, dig.fwBold, dig.dFlex, dig.flexColumn)}>
                                                <span className={dig.fontSize2Em}>{subscription.model_descriptor}</span>
                                                <span className={classNames(cardTextColor, dig.my2, dig.fontSize1P2Em)}>
                                                        {subscription.percent_savings && `${subscription.percent_savings}% Savings`}
                                                    </span>
                                            </div>

                                            <div
                                                style={{
                                                    backgroundImage: `url(${subscription.background_photo || Boss})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: 'cover',
                                                    borderRadius: '10px 10px 0 0'
                                                }}
                                                className={classNames(cardBgColor, dig.cardBody, dig.ggSubscriptionBody, dig.dFlex, dig.alignItemsCenter, dig.justifyContentBetween, dig.flexColumn)}>
                                                <h1 className={classNames(cardTextColor, dig.textUppercase)}>{subscription.model_name}</h1>
                                                <h1 className={classNames(dig.textWhite, dig.mt3, dig.fwNormal)}>{parseInt(subscription?.receive_amount || '0')} Coins/<span
                                                    className={dig.fontSize0P8Em}>mo.</span></h1>
                                            </div>
                                            <div
                                                style={{
                                                    borderRadius: '0 0 10px 10px'
                                                }}
                                                className={classNames(cardBgColor, dig.cardFooter, dig.dFlex, dig.justifyContentCenter, dig.flexColumn, dig.alignItemsCenter, dig.border0)}>
                                                <h1 className={classNames(dig.textWhite, dig.fontSize3Em, dig.textCenter, dig.my3)}>${parseFloat(subscription?.charge_amount || '0.00').toFixed(2)}</h1>
                                                <span
                                                    className={classNames(dig.textWhite, dig.mb5)}>{subscription.charge_interval === 1 ? `Per Month` : `Per ${subscription.charge_interval} months`}</span>
                                                {false !== props.currentEditUser.activeSubscription && props.currentEditUser?.activeSubscription?.toString() === subscription.model_id ?
                                                    <div
                                                        className={classNames(dig.btn, dig.textWhite, dig.px3, dig.py2, dig.mb3, dig.fontSize1P5Em, dig.fwBold, dig.ggSubscriptionBtn, dig.border0, dig.borderRounded5)}>
                                                        <i className={classNames(dig.fas, "fa-check", dig.ms4)}></i> Current Plan
                                                    </div>
                                                    :
                                                    <a href={`/user/?profiletab=dig_deposit_tab&model_id=${subscription.model_id}&user_id=${props.currentEditUser?.ID}`}
                                                       className={classNames(dig.btn, dig.textWhite, dig.px3, dig.py2, dig.mb3, dig.fontSize1P5Em, dig.fwBold, dig.ggSubscriptionBtn, dig.border2, dig.borderRounded5)}>
                                                        Select Plan
                                                    </a>
                                                }
                                            </div>
                                            {/*<div*/}
                                            {/*    className={classNames("card-footer gg__subscription_description d-flex justify-content-center flex-column align-items-center border-0")}>*/}
                                            {/*    <h2 className={`${cardTextColor} font-size-2em text-center my-3 font-weight-normal`}>Plan includes</h2>*/}
                                            {/*    <p className={classNames("gg-text-color-2 font-size-1p5em")}>{staticContent[key]?.planInclude}</p>*/}
                                            {/*</div>*/}
                                        </div>
                                        <MediaQuery maxWidth={425}>
                                            <hr className={classNames(dig.borderTop1, dig.borderColorGg, dig.w100)}/>
                                        </MediaQuery>
                                    </div>

                            }
                        )

                    }
                </div>
                <div
                    style={{
                        backgroundImage: `url(${NeedCoin})`
                    }}
                    className={classNames(dig.row, dig.ggSubscriptionNeedCoin, dig.g0, dig.my5, dig.bgDark, dig.dFlex, dig.flexColumn, dig.justifyContentStart, dig.p5, dig.borderRounded5, dig.positionRelative)}>
                    <div className={classNames(dig.fontSize3Em, dig.ggTextGold)}>
                        Need coins ASAP?
                    </div>
                    <div className={classNames(dig.fontSize2Em, dig.textWhite)}>
                        <Link
                            className={dig.stretchedLink}
                            to={`/parent-portal/profile/${props.params.userId}/coin/`}>
                            Buy individual coin packs!
                        </Link>

                    </div>
                </div>
                {false !== currentEditUser.activeSubscription ?
                    <>
                        <hr className={classNames(dig.borderTop1, dig.borderColorGg)}/>
                        <div className={classNames(dig.row, dig.g0, dig.my5, dig.dFlex, dig.justifyContentCenter, dig.p4)}>
                            <div className={classNames(dig.fontSize2Em, dig.ggTextColor2, dig.textCenter)}>
                                <a data-userid={currentEditUser.ID}
                                   onClick={handleClick}
                                   className={dig.ggTextColorBlue}
                                   href="#!">Remove</a> subscription plan
                                from {currentEditUser.display_name}&apos;s account
                            </div>
                        </div>
                    </>
                    :
                    null
                }
            </>
        }
    </div>
}


import {Navigate, Route, Routes} from "react-router-dom";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import ChildActivation, {CHILD_ACTIVATION_PATH} from "src/components/ChildActivation/ChildActivation";
import isCOM from "src/variables/isCOM";
import Bootstrap from "src/Bootstrap";
import {PASSWORD_RESET_PATH} from "src/components/ForgotPasswordReset/ForgotPasswordReset";
import SignUpAndIn, {eSignUpAndIn, iSignUpAndIn} from "src/components/Header/Modal/SignUpAndIn";
import {ppr} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import ApiDocumentation, {API_DOCUMENTATION_PATH} from "src/views/ApiDocumentation/ApiDocumentation";
//import Community, {COMMUNITY_PATH} from "src/views/Community/Community";
import Competition, {COMPETITION_PATH} from "src/views/Competition/Competition";
import CreateCompetition, {CREATE_COMPETITION_PATH} from "src/views/CreateCompetition/CreateCompetition";


import AccessDenied from "src/views/Errors/AccessDenied";
import PageNotFound from 'src/views/Errors/PageNotFound';
import Game, {GAME_PATH} from "src/views/Games/Game";

import Games, {GAMES_PATH} from "src/views/Games/Games";
import Categories, {CATEGORIES_PATH} from "src/views/Category/Categories";
import Category, {CATEGORY_PATH} from "src/views/Category/Category";
import Home, {HOME_PATH} from "src/views/Home/Home";
import IAM, {IAM_PATH} from "src/views/IAM/IAM";
import Article, {ARTICLE_PATH} from "src/views/Intel/Article";
import Intel, {INTEL_PATH} from "src/views/Intel/Intel";
import Matches, {MATCHES_PATH} from "src/views/Matches/Matches";
//import ApiDocumentation, {API_DOCUMENTATION_PATH} from "src/views/ApiDocumentation/ApiDocumentation";
import ParentalPortal, {PARENTAL_PORTAL_PATH} from "src/views/ParentalPortal/ParentalPortal";
import Profile, {PROFILE_PATH} from "src/views/Profile/Profile";
import Store, {STORE_PATH} from "src/views/Store/Store";
import Product, {PRODUCT_PATH} from "src/views/Store/Product/Product";


//footer links
import Support, {SUPPORT_PATH} from "src/views/Support/Support";
import Team, {TEAM_PATH} from "src/views/Team/Team";
import Tournaments, {TOURNAMENTS_PATH} from "src/views/Tournaments/Tournaments";
import AdminOptions, { ADMIN_OPTIONS_PATH } from "./views/AdminOptions/AdminOptions";
import Activation, { ACTIVATION_PATH } from "./components/Activation/Activation";


export const BOOTSTRAP_CUSTOM_PATH = 'bootstrapCustom';

// @link https://create-react-app.dev/docs/code-splitting/
// @link https://reactjs.org/docs/code-splitting.html#route-based-code-splitting
// ^ have not gotten this to work. clear caches and check npm build when attempting

export default function DropInGaming() {

    const {id} = Bootstrap.bootstrap.state

    // we have to pass components through ppr as is the only way to get url props.

    const loggedInUser = getCurrentLoggedInUser();

    const authenticated = 0 !== id;

    const imAdmin = true === loggedInUser?.user_is_admin;

    // TODO: when it is time, restore <Route path={COMMUNITY_PATH} element={ppr(Community, {})}/> to the line below TOURNAMENTS_PATH
    return <Routes>
        <Route path={HOME_PATH} element={<Home/>}/>
        <Route path={PASSWORD_RESET_PATH + '/:userId?/:activationKey?/*'} element={ppr<iSignUpAndIn>(SignUpAndIn, {
            display: eSignUpAndIn.FORGOT_PASSWORD,
        })}/>
        <Route path={GAMES_PATH} element={ppr(Games, {})}/>
        <Route path={ACTIVATION_PATH + '/:id/:key/*'} element={ppr(Activation, {})}/>
        <Route path={CHILD_ACTIVATION_PATH + '/:child/:key/*'} element={ppr(ChildActivation, {})}/>
        <Route path={GAME_PATH + '/:id/*'} element={ppr(Game, {})}/>

        <Route path={CATEGORIES_PATH + '/:cat_code/*'} element={ppr(Categories, {})}/>
        <Route path={CATEGORY_PATH + '/:cat_code/:id/*'} element={ppr(Category, {})}/>

        <Route path={COMPETITION_PATH + '/:id/*'} element={ppr(Competition, {})}/>
        <Route path={TOURNAMENTS_PATH + '/*'} element={ppr(Tournaments, {})}/>

        <Route path={STORE_PATH} element={ppr(Store, {})}/>
        <Route path={PRODUCT_PATH} element={ppr(Product, {})}/>
        <Route path={MATCHES_PATH} element={ppr(Matches, {})}/>

        <Route path={CREATE_COMPETITION_PATH + '/:competitionType/*'}
               element={authenticated
                   ? ppr(CreateCompetition, {})
                   : <AccessDenied/>}/>

        <Route path={INTEL_PATH + '/:filter?/:search?/'} element={ppr(Intel, {})}/>

        <Route path={API_DOCUMENTATION_PATH} element={ppr(ApiDocumentation, {})}/>

        <Route path={IAM_PATH}
               element={imAdmin
                   ? ppr(IAM, {})
                   : <AccessDenied/>}/>

        <Route path={ARTICLE_PATH + '/:articleId/'} element={ppr(Article, {})}/>

        <Route path={ARTICLE_PATH + '/:year/:month/:day/:title/'} element={ppr(Article, {})}/>

        <Route path={TEAM_PATH + '/:teamId/*'} element={ppr(Team, {})}/>
        <Route path={ADMIN_OPTIONS_PATH} element={ppr(AdminOptions, {})}/>

        <Route path={PROFILE_PATH + '/:userId?/*'}
               element={authenticated
                   ? ppr(Profile, {})
                   : <AccessDenied/>}/>

        <Route path={PARENTAL_PORTAL_PATH + '/*'}
               element={authenticated && (false === isCOM()) && (false === loggedInUser?.isChild)
                   ? ppr(ParentalPortal, {})
                   : <AccessDenied/>}/>

        <Route path={SUPPORT_PATH} element={ppr(Support, {})}/>

        <Route path='/confirmAccount/*' element={<Home/>}/>
        <Route path='*' element={<PageNotFound/>}/>
        <Route index element={<Navigate to={HOME_PATH}/>}/>
    </Routes>;


}

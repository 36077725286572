import classNames from "classnames";
import {useEffect, useState} from "react";
// import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import getPayoutTable, {iCustomPayoutTable} from "src/api/rest/getPayoutTable";
import getTournamentPayoutTable, {iTournamentPayoutTable} from "src/api/rest/getTournamentPayoutTable";
import Bootstrap from "src/Bootstrap";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import DropVariables, {eMONEY_OUTPUT_CONTEXT, getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, iCompetitions} from "src/variables/sharedInterfaces";
import {ReactComponent as FirstPlace} from "src/assets/img/svg-icons/1st-place.svg"
import {ReactComponent as SecondPlace} from "src/assets/img/svg-icons/2nd-place.svg"
import {ReactComponent as ThirdPlace} from "src/assets/img/svg-icons/3rd-place.svg"

interface iPrize {
    competition: iCompetitions,
}

export default function Prize({competition}: iPrize) {

    useEffect(() => {

        // standard payouts based on users joined.
        getTournamentPayoutTable()

        // weird name convention, ie none. this is where custom payout tables are stored
        getPayoutTable()

    }, [])

    const {tournamentPayoutTable, payoutTable} = Bootstrap.bootstrap.state;

    // prize here is custom prize if a fixed, guaranteed prize has been set. Will otherwise be null (not undefined; important to distinguish)
    const {prize, buy_in, max_players, comp_type_id, comp_percentage, comp_percentage_custom, team_size, complete_teams} = competition

    const minimumNumberOfTeams = (() => {

        switch (competition?.comp_type_id) {
            case undefined:
                return -1; //
            case eCOMPETITION.MATCHES:
            case eCOMPETITION.TOURNAMENTS:
                return 2;
            case eCOMPETITION.BRACKETS:
                return 4;
            default:
                throw Error('Bad competition type!')
        }

    })()

    const customPayoutMeta = competition.competitionsMeta?.find(meta => meta.meta_key === '_payout_table')

    const customTotalPrize = competition.competitionsMeta?.find(meta => meta.meta_key === '_custom_total_prize')
    const customFirstPlace = competition.competitionsMeta?.find(meta => meta.meta_key === '_custom_prize_first')
    const customSecondPlace = competition.competitionsMeta?.find(meta => meta.meta_key === '_custom_prize_second')
    const customThirdPlace = competition.competitionsMeta?.find(meta => meta.meta_key === '_custom_prize_third')

    const isCustomPayout = undefined !== customPayoutMeta;

    const teamsJoined = (0 === (complete_teams?.length ?? 0))
        ? 1
        : complete_teams?.length ?? 1;

    let reactParsedPayoutInfo: undefined | number[];

    const hasMinimumNumberOfTeamsToStart = teamsJoined >= minimumNumberOfTeams;

    const [prizePoolPlayers, setPrizePoolPlayers] = useState<number>(hasMinimumNumberOfTeamsToStart ? teamsJoined : minimumNumberOfTeams)

    if (undefined === prize
        || undefined === buy_in
        || undefined === max_players
        || undefined === comp_type_id
        || undefined === comp_percentage
        || undefined === team_size
        || undefined === complete_teams
    ) {
        return <Loading message={'Loading prize information!'}/>
    }

    if (undefined === competition.comp_type_id) {
        return <Loading message={'Loading competition prize information!'}/>
    }

    let percentage = comp_percentage_custom ?? comp_percentage;

    let totalPrize: number = parseFloat(prize) ||
        (parseFloat(buy_in) * prizePoolPlayers * team_size) *
        (comp_type_id === 2 || comp_type_id === 3 ? (1 - parseFloat(percentage)) : 1);

    const loadingPrizeInformation = DropVariables.undefinedOrNull(payoutTable) ||
        DropVariables.undefinedOrNull(tournamentPayoutTable);

    if (isCustomPayout) {

        const customPayout: iCustomPayoutTable | undefined = payoutTable?.find(info => info.id === parseInt(customPayoutMeta.meta_value ?? '0'))

        const currentRate = customPayout?.parsedCustomPercentage?.find(payoutInfo => {

            return payoutInfo.min <= prizePoolPlayers && (isNaN(payoutInfo.max) || payoutInfo.max >= prizePoolPlayers)

        })

        console.log('currentRate', currentRate)

        reactParsedPayoutInfo = currentRate?.rate.filter(rate => undefined !== rate && false === isNaN(rate))

    } else {

        console.log('non custom!!!!')

        const currentRate: iTournamentPayoutTable | undefined = tournamentPayoutTable?.find(rateRow => {

            const min = rateRow.min;

            const max = rateRow.max;

            return min <= teamsJoined && teamsJoined <= max;

        })

        reactParsedPayoutInfo = currentRate?.parsedPercentage

    }

    const loaded = DropVariables.undefinedOrNull(reactParsedPayoutInfo) ||
        0 === reactParsedPayoutInfo?.length;

    const parseEarnings = (percent: number) => percent * totalPrize

    // const whoami = getCurrentLoggedInUser()

    // const iAmAdmin = whoami?.user_is_admin || false

    const numberOfPollPositions = reactParsedPayoutInfo?.length || 0

    const dig = getStyles();

    return <ExpandableBox header={
        'Prize Pool'
    }>
        <div id="prize" className={classNames(dig.collapse, dig.show, dig.p3)} aria-labelledby="prize_accordion"
             data-parent="#tournament_prize_accordion">
            <div className={dig.tabContent}>

                <div className={classNames(dig.tabPane, dig.fade, dig.show, dig.active)}
                     id="potential_prize" role="tabpanel" aria-labelledby="pills-profile-tab">
                    <div className={classNames(dig.row, dig.mb3, dig.dFlex, dig.justifyContentCenter)}>
                        <h1 className={dig.digOrange}>
                            <div className={classNames(dig.textCenter)}>
                                {customTotalPrize !== undefined && customTotalPrize.meta_value !== ''
                                    ? customTotalPrize.meta_value
                                    : DropVariables.formatMoney(totalPrize, eMONEY_OUTPUT_CONTEXT.PAY_OUT)}
                            </div>
                        </h1>
                    </div>

                    {(loadingPrizeInformation || loaded) ?
                        <Loading message={'Loading prize information!'} count={2}/> : <>
                            <div className={classNames(
                                dig.row, dig.m0, dig.borderBottom, dig.borderColorNeutral6, dig.dFlex, dig.alignItemsEnd
                            )}>
                                {1 <= numberOfPollPositions &&
                                <div className={classNames(dig.col, dig.py2, dig.digBgNeutral2)}>
                                    <div className={classNames(dig.digPrizeGrid)}>
                                        <div className={dig.trophies}>
                                            <FirstPlace />
                                        </div>
                                        <div className={dig.digPrizeLable}>
                                            <h2 className={classNames(dig.digPrize, dig.digTrophies1)}>
                                                {
                                                    customFirstPlace !== undefined && customFirstPlace.meta_value !== ''
                                                    ? customFirstPlace.meta_value
                                                    : DropVariables.formatMoney(parseEarnings(reactParsedPayoutInfo?.shift() ?? 0))

                                                }
                                            </h2>
                                        </div>
                                    </div>
                                </div>}
                                {2 <= numberOfPollPositions &&
                                <div className={classNames(dig.col, dig.py2, dig.digBgNeutral2)}>
                                    <div className={classNames(dig.digPrizeGrid)}>
                                        <div className={dig.trophies}>
                                            <SecondPlace />
                                        </div>
                                        <div className={dig.digPrizeLable}>
                                            <h2 className={classNames(dig.digPrize, dig.digTrophies2)}>
                                                {
                                                    customSecondPlace !== undefined && customSecondPlace.meta_value !== ''
                                                        ? customSecondPlace.meta_value
                                                        : DropVariables.formatMoney(parseEarnings(reactParsedPayoutInfo?.shift() ?? 0))

                                                }
                                            </h2>
                                        </div>
                                    </div>
                                </div>}
                                {3 <= numberOfPollPositions &&
                                <div className={classNames(dig.col, dig.py2, dig.digBgNeutral2)}>
                                    <div className={classNames(dig.digPrizeGrid)}>
                                        <div className={dig.trophies}>
                                            <ThirdPlace/>
                                        </div>
                                        <div className={dig.digPrizeLable}>

                                            <h2 className={classNames(dig.digPrizeLable, dig.digTrophies3)}>
                                                {
                                                    customThirdPlace !== undefined && customThirdPlace.meta_value !== ''
                                                        ? customThirdPlace.meta_value
                                                        : DropVariables.formatMoney(parseEarnings(reactParsedPayoutInfo?.shift() ?? 0))

                                                }

                                            </h2>
                                        </div>
                                    </div>
                                </div>}
                            </div>

                            {customSecondPlace === undefined && customThirdPlace === undefined && 3 <= numberOfPollPositions && <div className={classNames(dig.row, dig.mx0, dig.mt2)}>
                                <div className={classNames(dig.cardHeader, dig.digBgNeutral2, dig.border0, dig.w100)}
                                     id="headingOne">
                                    <h5 className={classNames(dig.mb0, dig.mt2, dig.cardTitle, dig.digOrange, dig.textCenter,
                                        dig.textUppercase, dig.fwBold)}
                                        data-toggle="collapse" data-target="#remaining_prize_pool" aria-expanded="true"
                                        aria-controls="remaining_prize_pool">
                                        Prize pool for remaining places
                                        {/* <i className={classNames("fas fa-angle-down rotate-icon ml-2")}></i> */}
                                    </h5>
                                </div>
                                <div className={classNames(dig.w100)}>
                                    <div className={classNames(dig.row, dig.m0, dig.w100)}>
                                        {reactParsedPayoutInfo?.map((payout, index) => {

                                            return <div key={index}
                                                        className={classNames(
                                                            dig.col12, dig.dFlex, dig.justifyContentCenter, dig.mt2
                                                        )}>
                                                <div
                                                    className={classNames(
                                                        dig.dFlex, dig.colSm4, dig.justifyContentBetween, dig.digBgNeutral6, dig.py2, dig.px4
                                                    )}>
                                                    <div>{DropVariables.ordinalSuffix(3 + index)}</div>
                                                    <div>
                                                        {DropVariables.formatMoney(parseEarnings(payout))}
                                                    </div>
                                                </div>
                                            </div>

                                        })}
                                    </div>
                                </div>
                            </div>}
                        </>}
                </div>
            </div>
            <h5 className={classNames(dig.fwNormal, dig.textWhite, dig.textCenter, dig.mb0, dig.mt4)}>
                {prizePoolPlayers !== max_players
                    ? <>* Prize pool with {hasMinimumNumberOfTeamsToStart ? <>current ({teamsJoined}) teams
                        joined.</> : <>minimum ({minimumNumberOfTeams}) teams required to start. </>}
                        <b style={{cursor: "pointer"}}
                           onClick={() => setPrizePoolPlayers(max_players)}>
                            &nbsp;View pool for max ({max_players}) teams here.
                        </b>
                    </>
                    : <>* Prize pool with max ({max_players}) teams joined.
                        <b style={{cursor: "pointer"}}
                           onClick={() => setPrizePoolPlayers(teamsJoined)}>
                            &nbsp;View pool for {hasMinimumNumberOfTeamsToStart ? <>current ({teamsJoined}) teams
                            joined.</> : <>minimum ({minimumNumberOfTeams}) teams required to start. </>}
                        </b>
                    </>}
            </h5>
        </div>
    </ExpandableBox>


}


import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import styles from "src/style.module.scss";
import {WithRouter} from "../../../components/PassPropertiesAndRender/PassPropertiesAndRender";

interface iTournamentProperties extends WithRouter {
    handleClose: () => void,
    popupData?: {
        button_link: any;
        button_text: any;
        header: any;
    }
}

export default function CategoryPopup(props: iTournamentProperties) {

    const dig = getStyles(styles)


    console.log('currentCategory', props)

    const {handleClose, popupData} = props;


    return <div className={classNames("model-content", dig.rounded0, dig.border0)}>
        <div className={classNames(dig.modalHeader, dig.rounded0, dig.border0, dig.dFlex, dig.digBgNeutral6)}>
            <button className={classNames(dig.border0, 'close', dig.msAuto)} onClick={handleClose} type="button" data-dismiss="modal" aria-label="Close">
                <i className="fas fa-times m-0 text-white"/>
            </button>
        </div>
        <div className={classNames(dig.modalBody, dig.border0, dig.digBgNeutral8, dig.digTextWhite)}>
            <form className={classNames(dig.mb0, dig.w100)}>
                <div className={classNames(dig.row, dig.mx0)}>
                    <div className="card rounded-0 border-0 bg-transparent">
                        <div className="card-body dig-bg-neutral2">
                            <div className="row d-flex justify-content-between align-items-center no-gutters">

                                <h2 className="modal-title dig-text-white font-weight-normal text-left ml-3 text-white w-100">
                                    {popupData?.header}
                                </h2>

                            </div>
                        </div>
                    </div>

                </div>
            </form>
        </div>
        <div className={classNames(dig.modalFooter, dig.digBgNeutral7, dig.border0, dig.rounded0)}>
            <a target="_blank" href={popupData?.button_link} rel="noreferrer"
                className={classNames(dig.btn, dig.btnLg, dig.btnSuccess, dig.rounded0, dig.px4, dig.py2, dig.fontSize1Em, dig.textWhite, dig.fontWeightBold)}
                >{popupData?.button_text}
            </a>
        </div>
    </div>

}



import classNames from "classnames";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import {iDig_Comp_Score} from "src/variables/C6";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, iCompetitions, iTeam, iTournamentScoring} from "src/variables/sharedInterfaces";
import {useEffect, useState} from "react";
import Bootstrap from "src/Bootstrap";
import postCompScore from "src/api/rest/postCompScore";
import Loading from "src/components/Loading/Loading";
import moment from "moment/moment";
import getCompetitions from "src/api/rest/getCompetitions";
import Button from "src/components/Button/Button";
import Input from "src/components/Input/Input";
import TournamentScoreSort from "src/api/hoc/TournamentScoreSort";
import {uploadCompImage} from "src/api/hoc/uploadImage";
import {toast} from "react-toastify";
import postCompEvidence from "src/api/rest/postCompEvidence";
import DefaultPhoto from "src/assets/img/default_profile_photo.png"
import Popup from "../../../components/Popup/Popup";
import ConfirmMatchResults from "../Modals/ConfirmMatchResults";

interface iShowLiveScores {
    competition: iCompetitions
}

export default function LiveScores(props: iShowLiveScores) {

    const {teams, users, registrations, results, posts, id} = Bootstrap.bootstrap.state;

    const dig = getStyles()

    const [scoresHaveChanged, setScoresHaveChanged] = useState(false);

    const [updatedScores, setUpdatedScores] = useState<iDig_Comp_Score[]>([]);

    const currentUser = users?.find(user => user.ID === id);

    const [updatedTournamentScores, setUpdatedTournamentScores] = useState<object>({});

    let needToFetchComp: number[] = [];

    if (undefined === props.competition.status) {
        needToFetchComp.push(props.competition.comp_id);
    }

    // create object with all registered team data for reference by key. re-running teams.find for every score was too painful for me.
    let teamData: { [key: string]: iTeam } = {},
        scoreboxData = {};

    useEffect(() => {

        needToFetchComp.length > 0 && getCompetitions({
            competitionIds: needToFetchComp,
            fetchDependencies: true
        })

    })

    const [showDispute, setShowDispute] = useState(false);

    const [disputeUrl, setDisputeUrl] = useState('');

    const [disputeReason, setDisputeReason] = useState('');


    if (props.competition.comp_type_id === eCOMPETITION.BRACKETS
        || props.competition.status === eCOMPETITION_STATUS.WAITING
        || props.competition.status === eCOMPETITION_STATUS.CANCELED) {
        return null;
    }


    if (undefined === props.competition.complete_teams) {
        return <Loading message={'Loading competition team information'}/>
    }

    const myRegistration = registrations?.find(registration => registration.competitions_id === props.competition.comp_id && registration.user_id === id);

    const isFrontEndAdmin = currentUser !== undefined && currentUser.roles?.includes('front_end_admin');


    props.competition.complete_teams.map((teamId) => {

        let team = teams?.find(team => team.user_team_id === teamId);

        if (undefined !== team) {

            teamData[teamId] = team;

        }

        scoreboxData[teamId] = [];

    });

    if (eCOMPETITION.MATCHES === props.competition.comp_type_id) {
        const winningTeam = results?.find(result => result.comp_id === props.competition.comp_id && (result.dispute_status === 0 || result.dispute_status === 3))?.team_id;

        const onWinningTeam = winningTeam !== undefined && teams?.find(team => team.user_team_id === winningTeam)?.rosterDetails?.find(roster => roster.uid === id) !== undefined;

        const disputeInfo = props.competition.match_dispute_evidence;

        // includes everything from the results table + time_delay and prize_percentage from result_type
        const matchResults = results?.filter(result => result.comp_id === props.competition.comp_id) || [];

        // need to display minimum of competition.best_of number of scoreboxes per team regardless of score entries. Shit js arrays do little to help us here

        let rawData: iDig_Comp_Score[] = (props.competition.scores ?? []).sort(TournamentScoreSort.sortScoresByTeamScoreboxAsc),
            evenScoreCount = (rawData.length % 2) === 0,
            // we should ultimately end up with identical entries per team, so round up to the next even number
            roundedDataCount = evenScoreCount ? rawData.length : rawData.length + 1,
            // We must assume if we have an even number of score entries at or beyond best_of and the match is still active, that we need an extra tiebreaker row (2 entries)
            tieBreakerAddition = props.competition.status === eCOMPETITION_STATUS.STARTED && rawData.length >= (2 * (props.competition.best_of ?? 0)) && evenScoreCount ? rawData.length + 2 : roundedDataCount,
            // if below best_of entries for each team, we need to fill in the gaps for the UI generator
            entryCount = Math.max(2 * (props.competition.best_of ?? 0), tieBreakerAddition),
            // used to know when one team's entries are done and it's time to start the other.
            teamSplitIndex = Math.max((props.competition.best_of ?? 0), tieBreakerAddition / 2),
            // sort the teams by their id so we can display them in the correct order
            ascSortedCompleteTeams = props.competition.complete_teams?.sort((a, b) => a - b) ?? [],
            // cant reliably just use i to know what score index to check
            scoreDataIndex = 0;

        // there are 2 teams, hence the multiplication
        for (let i = 0; i < entryCount; i++) {

            let expectedScorebox = (i % teamSplitIndex) + 1,
                teamIndex = i >= teamSplitIndex ? 1 : 0,
                expectedTeamId = ascSortedCompleteTeams[teamIndex] ?? 0;

            if (rawData[scoreDataIndex] === undefined || expectedScorebox > (rawData[scoreDataIndex].scorebox ?? 0)) {

                scoreboxData[expectedTeamId] ??= []

                // pad the team's values with an undefined
                scoreboxData[expectedTeamId].push(undefined);

            } else {

                scoreboxData[expectedTeamId] ??= []

                scoreboxData[expectedTeamId].push(rawData[scoreDataIndex]);

                scoreDataIndex++;

            }

        }

        const disputeEndTime = matchResults.length > 0 ? moment(matchResults[0].created).add(matchResults[0].time_delay, 'minutes') : undefined;

        const disputeEnded = undefined !== disputeEndTime ? moment().isAfter(disputeEndTime) : true;

        // ask the losing team to confirm/deny the results to potentially skip the dispute period. (useful for bracket matches)
        const canDispute = props.competition.status === eCOMPETITION_STATUS.COMPLETED && !disputeEnded && myRegistration !== undefined && !onWinningTeam && (disputeInfo === null || disputeInfo === undefined);

        const hasMatchResponse = (props.competition.match_responses?.length ?? 0) > 0;

        // if we have uploaded evidence, load it here
        let dispute_upload_guids: string[] = [];

        props.competition.match_dispute_uploads && props.competition.match_dispute_uploads.forEach(postId => {
            const post = posts?.find(post => post.ID === postId);

            if (post !== undefined && post.post_content !== undefined) {
                dispute_upload_guids.push(post.post_content);
            }
        });

        return <ExpandableBox>
            <><Popup open={canDispute && !hasMatchResponse} handleClose={() => {
            }}>
                <ConfirmMatchResults handleClose={() => setShowDispute(true)} compId={props.competition.comp_id} teamId={myRegistration?.team_id ?? 0}/>
            </Popup>
                <div className={classNames(dig.card, dig.digBgNeutral2)}>
                    <div id={dig.digDeclareWinner}>
                        {props.competition.status === eCOMPETITION_STATUS.STARTED && <div className={dig.row}>
                            <div className={dig.col12}>
                                <h2 id={dig.digMatchScoreWarning}>If you submit scores prior to game end, you could
                                    be disqualified.</h2>
                            </div>
                        </div>}
                        <div className={dig.row}>
                            <div className={dig.col12}>
                                <h2>Scoreboard</h2>
                            </div>
                        </div>

                        <div className={dig.row} id={dig.killRaceScoreBoard} data-cols="6">
                            {props.competition.complete_teams?.map((teamId) => {

                                if (teamData[teamId] === undefined) {
                                    return null;
                                }

                                // both the score data and team data are sorted by team_id asc first, so even without scores, labels will be ordered properly
                                let curTeam = teamData[teamId];

                                return <>
                                    <div className={dig.colSm6}>
                                        <div className={dig.digKillRaceWrapper}>
                                            <h4 className={classNames(dig.py2, dig.bgNeutral1)}>{curTeam.team_name}</h4>
                                            {scoreboxData[teamId].map((entry, index) => {
                                                let curBoxIndex = index + 1,
                                                    scoreboxEnabled = props.competition.status === eCOMPETITION_STATUS.STARTED && (isFrontEndAdmin || myRegistration !== undefined) && entry === undefined && (curBoxIndex === 1 || scoreboxData[teamId][index - 1] !== undefined);

                                                return <>
                                                    <div className={classNames(dig.row, dig.py2)}>
                                                        <div className={dig.colSm6}>
                                                            <div className={dig.digKillRaceLabel}>
                                                                <h4>Game {curBoxIndex}</h4>
                                                            </div>
                                                        </div>
                                                        <div className={dig.colSm6}>
                                                            {(scoreboxEnabled &&
                                                                <Input
                                                                    value={entry === undefined ? "" : entry.score}
                                                                    data-box-number={curBoxIndex}
                                                                    data-team-id={teamId}
                                                                    className={dig.digKillScores}
                                                                    min="0" max="999999999" maxLength={9}
                                                                    type="text"
                                                                    onChange={(e) => {
                                                                        e.preventDefault();

                                                                        const entry: iDig_Comp_Score = {
                                                                            score: parseInt(e.target.value),
                                                                            scorebox: parseInt(e.target.getAttribute("data-box-number") ?? '0'),
                                                                            user_id: id,
                                                                            team_id: parseInt(e.target.getAttribute("data-team-id") ?? '0'),
                                                                            comp_id: props.competition.comp_id,
                                                                        };

                                                                        const curUpdatedScores: iDig_Comp_Score[] = updatedScores.filter(score => score.team_id !== entry.team_id || score.scorebox !== entry.scorebox);

                                                                        curUpdatedScores.push(entry);
                                                                        setUpdatedScores(curUpdatedScores);
                                                                        setScoresHaveChanged(true);
                                                                    }}/>)
                                                            || <span
                                                                className={dig.killScoreText}>{entry === undefined ? "-" : entry.score}</span>}
                                                        </div>
                                                    </div>
                                                </>
                                            })}
                                        </div>
                                    </div>
                                </>
                            })}
                        </div>

                        {props.competition.status === eCOMPETITION_STATUS.STARTED && (isFrontEndAdmin || myRegistration !== undefined) &&
                        <div className={dig.col12}>
                            <div className={dig.row}>
                                <div className={classNames(dig.col12, dig.dFlex, dig.justifyContentCenter)}>
                                    <Button
                                        className={classNames(dig.digMatchScoreUpdate, dig.px3, dig.fontSize1P2Em, dig.mb5, dig.textWhite, dig.border0)}
                                        disabled={!scoresHaveChanged}
                                        onClick={(e) => {
                                            e.preventDefault();

                                            postCompScore({
                                                dataInsertMultipleRows: updatedScores
                                            });

                                            setUpdatedScores([]);
                                            setScoresHaveChanged(false);
                                        }}>
                                        Update Scores
                                    </Button>
                                </div>

                                <div id={dig.digDisputeWindowContainer}>
                                    <span>If your opponent has not joined within 15 minutes of the start time, reach out to support to report them as a &quot;No-Show&quot;.</span>
                                </div>
                            </div>
                        </div>
                        }

                        {/* Only want to show dispute options for the losing team. Why would a winning team ever dispute? */}
                        {true === canDispute &&
                        <>

                            <div className={dig.col12}>

                                <div className={dig.col12}>
                                </div>

                                <div>
                                    <div className={dig.row}>
                                        <div className={dig.col12}>

                                            <div id={dig.digDisputeWindowContainer}>
                                                <Button
                                                    className={classNames(dig.border0, dig.textWhite)}
                                                    onClick={() => setShowDispute(!showDispute)}>
                                                    <i className={classNames(dig.fas)}></i>

                                                    Click here to file a dispute before the dispute window closes
                                                    at {disputeEndTime?.format("h:mm A")}
                                                </Button>
                                            </div>
                                            {showDispute && <>
                                                <div className={classNames(dig.row)}>
                                                    <div className={classNames(dig.col12, dig.colMd6, dig.mb5, dig.mbMd0, dig.dFlex, dig.justifyContentCenter, dig.flexColumn, dig.alignItemsCenter)}>
                                                        <Button
                                                            className={classNames(dig.textWhite, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.border0, dig.cursorPointer)}
                                                            onClick={uploadCompImage('dispute_photo', {comp_id: props.competition.comp_id}, () => {

                                                                toast.success('Dispute Evidence Photo Updated!', DropVariables.toastOptions);

                                                            })}>
                                                            Click to select and upload evidence
                                                        </Button>
                                                        {dispute_upload_guids?.map((image, index) => <img
                                                            key={index}
                                                            src={image}
                                                            className={classNames(dig.mt4)}
                                                            width={40}
                                                            height={40}
                                                            alt={'Dispute image'}
                                                            onError={(event) => {
                                                                // @ts-ignore
                                                                event.target.src = DefaultPhoto
                                                            }}
                                                        />)}
                                                    </div>
                                                    <div className={classNames(dig.col12, dig.colMd6)}>
                                                        <div className={classNames(dig.dFlex, dig.flexColumn, dig.justifyContentCenter)}>
                                                            <h4>External URL (YouTube, Twitch, Google Drive,
                                                                etc.)</h4>
                                                            <input type="text" maxLength={2048}
                                                                   placeholder="https://" value={disputeUrl}
                                                                   onChange={(e) => setDisputeUrl(e.target.value)}/>

                                                            <h4>Why are you disputing the results of this
                                                                match?</h4>
                                                            <textarea maxLength={2048} value={disputeReason}
                                                                      className={classNames(dig.textBlack)}
                                                                      placeholder="Cheated, Incorrect Scores, No-Show, etc."
                                                                      onChange={(e) => setDisputeReason(e.target.value)}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={classNames(dig.row, dig.dFlex, dig.justifyContentCenter, dig.mt4)}>

                                                    <Button
                                                        className={classNames(dig.digW250,dig.textWhite, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.border0, dig.cursorPointer)}
                                                        disabled={disputeReason === ""}
                                                        onClick={(e) => {
                                                            e.preventDefault();

                                                            //myRegistration cannot be undefined here but the editor is not smart enough to know
                                                            postCompEvidence({
                                                                comp_id: props.competition.comp_id,
                                                                user_id: id,
                                                                team_id: myRegistration?.team_id ?? 0,
                                                                evidence_desc: disputeReason,
                                                                evidence_external_url: disputeUrl,
                                                            });
                                                        }}>SUBMIT DISPUTE
                                                    </Button>
                                                </div>

                                            </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </>
        </ExpandableBox>

    }

    // handle tournaments
    const scoreboxCount = props.competition?.competitionsMeta?.find(meta => meta.meta_key === "_dig_comp_score_boxes")?.meta_value || 1;

    let teamScores: iTournamentScoring[] = props.competition?.complete_teams?.map((teamId) => {
            let data: iTournamentScoring = {
                teamId: teamId, // score entries will also have team id, but having this here makes it easier to work with
                scores: [] as iDig_Comp_Score[] // need the "as" so the editor doesn't lose it's shit when setting this later.
            };

            data.scores = props.competition?.scores?.filter(entry => entry.team_id === teamId) || [];

            data.scores.sort(TournamentScoreSort.sortScoresByScoreboxAsc);

            data.scores.map((entry) => {
                // pretty sure it is impossible for entry.score to be undefined, but add the coalesce to please our intellij overlords
                (data.scoreTotal !== undefined && (data.scoreTotal += entry.score ?? 0)) || (data.scoreTotal = entry.score ?? 0);
            });

            return data;
        }) || [],
        adminButtonActivationTracker = {}; // object which will contain key/value pairs of team_id and bool marking if the score has been changed;

    teamScores.sort(TournamentScoreSort.sortTournamentScoreTotal);

    const myTeamScores = teamScores.find(teamScore => teamScore.teamId === myRegistration?.team_id)?.scores;

    let defaultBoxGenerationMap: number[] = [];

    let myUpdatedScores = updatedTournamentScores;

    // unless there is a way I'm not aware of to use a for loop in the middle of a return, I need to set this abomination
    for (let i = 0; i < scoreboxCount; i++) {
        // i+1 = scorebox number
        defaultBoxGenerationMap.push(i + 1);
    }

    return <>
        <ExpandableBox header="Live Scores">
            <table
                className={classNames(dig.table, dig.tableDark, dig.m0, dig.tableBorderless, dig.tableHover)}>
                <thead>
                <tr>
                    <th scope="col">Place</th>
                    <th scope="col">Team Name</th>
                    <th scope="col" className={dig.tourneyResultRosterCol}>Team Rosters</th>
                    <th scope="col">Team Score</th>
                </tr>
                </thead>
                <tbody>
                {teamScores.map((entry, index) => {
                    let team = teams?.find(team => team.user_team_id === entry.teamId);

                    if (team === undefined) {
                        return <Loading message={'Loading team info'} key={index}/>
                    }

                    adminButtonActivationTracker[entry.teamId] = entry.scoreTotal ?? -1;

                    return <tr key={index}>
                        <td>
                            <label htmlFor={"checkbox_" + entry.teamId}>{index + 1}</label>
                        </td>
                        <td>{team.team_name}</td>
                        <td className={dig.tourneyResultRosterCol}>
                            {team.rosterDetails?.map((roster, index) => {
                                let currentUser = users?.find(user => user.ID === roster.uid),
                                    currentRegistration = registrations?.find(registration => registration.competitions_id === props.competition.comp_id &&
                                        registration.user_id === roster.uid);

                                if (currentUser === undefined) {
                                    return <Loading message={'Loading roster info'} key={index}/>
                                }

                                return <div className={classNames(dig.row, dig.my1, dig.textLowercase, dig.p0)}
                                            key={index}>
                                    <div className={classNames(dig.col, dig.textWhite)}>
                                        {currentUser.user_login}
                                        <span
                                            className={classNames(dig.badge, dig.digBgNeutral6, dig.p2)}>{currentRegistration?.vendor_gamertag}</span>
                                    </div>
                                </div>
                            })}
                        </td>
                        <td>
                            {(isFrontEndAdmin && props.competition.status === eCOMPETITION_STATUS.STARTED &&
                                <><Input type="number" className={classNames(dig.border0, "tournament_admin_input")}
                                         id={"tournament_admin_input_" + entry.teamId}
                                         data-team-id={entry.teamId} value={entry.scoreTotal ?? ''}
                                         onChange={(e) => {
                                             adminButtonActivationTracker[entry.teamId] = e.target.value;
                                         }}/>
                                    <Button
                                        className={classNames(dig.border0,dig.textWhite,dig.py2, dig.px3)}
                                        onClick={(e) => {
                                        e.preventDefault();
                                        if (adminButtonActivationTracker[entry.teamId] !== (entry.scoreTotal ?? -1)) {
                                            console.log('starting score submit...');
                                            // Legacy code handled total score separately from individual boxes, so admin-entered scores were not reflected in user boxes.
                                            // Now, that is not an option. We know all tournaments will have at least 1 score box, so target scorebox 1 for admin score updates.
                                            let adminScoreSubmit: iDig_Comp_Score = {
                                                    score: parseInt(adminButtonActivationTracker[entry.teamId]),
                                                    scorebox: 1,
                                                    user_id: id,
                                                    team_id: entry.teamId,
                                                    comp_id: props.competition.comp_id
                                                },
                                                scoreSubmitArray: iDig_Comp_Score[] = [adminScoreSubmit];

                                            for (let i = 2; i <= scoreboxCount; i++) {
                                                // fill in zeroed entries to submit for each remaining scorebox
                                                scoreSubmitArray.push({
                                                    score: 0,
                                                    scorebox: i,
                                                    user_id: id,
                                                    team_id: entry.teamId,
                                                    comp_id: props.competition.comp_id
                                                })
                                            }

                                            postCompScore({
                                                dataInsertMultipleRows: scoreSubmitArray
                                            });
                                        }
                                    }}>Submit</Button></>
                            ) || (entry.scoreTotal ?? '-')}
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </ExpandableBox>
        {props.competition.status === eCOMPETITION_STATUS.STARTED && myRegistration !== undefined && <ExpandableBox header="My Scores">
            <table>
                <tr>
                    {defaultBoxGenerationMap.map((scorebox) => {
                        let currentBoxEnteredScore = myTeamScores?.find(score => score.scorebox === scorebox)?.score,
                            currentBoxUploads = props.competition.score_uploads?.filter(upload => upload.scorebox === scorebox && upload.team_id === myRegistration.team_id);

                        myUpdatedScores[scorebox] ??= undefined;

                        console.log('score for scorebox ' + scorebox, currentBoxEnteredScore);

                        // create inputs for each scorebox, as well as photo upload for each
                        return <td key={scorebox}>
                            <label htmlFor={"scorebox_" + scorebox}>Scorebox {scorebox}</label>
                            <Input type="number" value={currentBoxEnteredScore ?? ''} id={"scorebox_" + scorebox}
                                   onChange={(e) => {
                                       myUpdatedScores[scorebox] = e.target.value;
                                   }}/>

                            <Button
                                className={classNames(dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.border0, dig.cursorPointer, dig.textWhite)}
                                onClick={() => {
                                    // set now to avoid issues with non-updated scores
                                    setUpdatedTournamentScores(myUpdatedScores);
                                    let callback = uploadCompImage(
                                        'tournament_score',
                                        {comp_id: props.competition.comp_id, team_id: myRegistration.team_id, scorebox: scorebox},
                                        () => {
                                            toast.success('Tournament Evidence Photo Updated!', DropVariables.toastOptions);
                                        }
                                    );

                                    callback();
                                }}>{'Upload Evidence (Score ' + scorebox + ')'}
                            </Button>

                            {currentBoxUploads?.map((uploadData, index) => {
                                let post = posts?.find(post => post.ID === uploadData.post_id);

                                return <img
                                    key={index}
                                    src={post?.post_content ?? post?.guid}
                                    width={40}
                                    height={40}
                                    alt={'Evidence Image'}
                                    onError={(event) => {
                                        // @ts-ignore
                                        event.target.src = DefaultPhoto
                                    }}
                                />
                            })}
                        </td>;
                    })}
                </tr>
                <tr>
                    <td>
                        <Button
                            className={classNames(dig.textWhite)}
                            onClick={(e) => {
                                e.preventDefault();

                                let scoreSubmitArray: iDig_Comp_Score[] = [];

                                for (let i = 1; i <= scoreboxCount; i++) {
                                    if (myUpdatedScores[i] !== undefined) {
                                        // impossible for myRegistration to be undefined here
                                        scoreSubmitArray.push({
                                            score: myUpdatedScores[i],
                                            scorebox: i,
                                            user_id: id,
                                            team_id: myRegistration?.team_id,
                                            comp_id: props.competition.comp_id
                                        })
                                    }
                                }

                                if (scoreSubmitArray.length === 0) {
                                    toast.error('No score changes to submit!', DropVariables.toastOptions);
                                    return;
                                }


                                setUpdatedTournamentScores({});
                                postCompScore({
                                    dataInsertMultipleRows: scoreSubmitArray
                                });
                            }}>Update Scores</Button>
                    </td>
                </tr>
            </table>
        </ExpandableBox>}
    </>


}


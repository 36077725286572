import classNames from "classnames";
import {getStyles} from "../../variables/DropVariables";
import HomeStyles from "./style.module.scss";
import DefaultPhoto from "src/assets/img/default_profile_photo.png"

const mockData = [
    {
        name: "Nicte Nowell",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },{
        name: "Róbert Ververs",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },{
        name: "Ferit Street",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },{
        name: "Mariyam Cuyper",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },{
        name: "Rahela Ready",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 100),
        custom: ''
    },{
        name: "Rahul Snaaijer",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },{
        name: "Neptune Demetriou",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },{
        name: "Isebella Penny",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },{
        name: "Ashlea Cantrell",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },{
        name: "Redd David",
        wins: Math.floor(Math.random() * 10),
        losses: Math.floor(Math.random() * 10),
        avg_placement: (Math.random() * 10).toFixed(2),
        comps_played: Math.floor(Math.random() * 10),
        custom: ''
    },

]

export default function LeaderBoard() {
    const dig = getStyles(HomeStyles)
    return <>
        <section className={classNames(dig.heroCol, dig.dFlex, dig.justifyContentCenter)}>
            <div className={classNames(dig.py4, dig.w100)}>
                <h2 className={classNames(dig.textCenter, dig.mb4)}>LEADERBOARDS</h2>

                <div className={classNames(dig.leaderBoardTabs)}>
                    <div className={classNames(dig.tabs)}>
                        <ul>
                            <li className={dig.active} data-li="today">All Time</li>
                            <li data-li="month">Last 30 Days</li>
                            <li data-li="year">Last 7 days</li>
                        </ul>
                    </div>
                </div>

                <div className={classNames(dig.leaderBoardWrapper)}>

                    <table className={classNames(dig.leaderBoardItem, dig.w100, dig.p3)}>
                        <thead>
                        <tr className={classNames(dig.leaderBoardHeader)}>
                            <th>Rank</th>
                            <th></th>
                            <th>Wins</th>
                            <th>Losses</th>
                            <th>Avg. Placement</th>
                            <th>Comps Played</th>
                            <th>Custom</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            mockData.map((item, index) => {
                                return <tr key={index} className={classNames(dig.leaderBoardRow)}>
                                        <td>
                                            {index + 1}
                                        </td>
                                        <td className={classNames(dig.profile)}>
                                            <img className={classNames(dig.me2)} src={DefaultPhoto} alt="profile image"/>
                                            <span>{item.name}</span>
                                        </td>
                                        <td className="points">{item.wins}</td>
                                        <td className="points">{item.losses}</td>
                                        <td className="points">{item.avg_placement}</td>
                                        <td className="points">{item.comps_played}</td>
                                        <td className="points">{item.custom}</td>
                                    </tr>

                            })
                        }

                        </tbody>

                    </table>

                </div>

            </div>
        </section>
    </>

}

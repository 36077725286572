//import "./wdyr"; // <-- first import and
import React from "react";
import {createRoot} from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Bootstrap from "./Bootstrap";
import { v4 as uuidv4 } from 'uuid';
import {Helmet} from "react-helmet";
import isWWW from "./variables/isWWW";

/**
 * @note Please use only absolutely relative paths :P
 * Do not use relative paths bases on the file you are in, but where
 * that file is from the root. Ie, the imports above would not change
 * regardless of where this file (or any file importing them) is placed.
 */

console.info('Please @note https://stackoverflow.com/questions/41004631/trace-why-a-react-component-is-re-rendering');

const rootElement = document.getElementById('root');

const root = createRoot(rootElement!);

const kountSessionId = uuidv4().replace(/-/g, '');
const DIG_KOUNT_DC_URI = isWWW() ? 'https://ssl.kaptcha.com' : 'https://tst.kaptcha.com';

root.render(<React.StrictMode>
    <Helmet>
        <script type="text/javascript"
                src={`${DIG_KOUNT_DC_URI}/collect/sdk?m=${process.env.REACT_APP_DIG_KOUNT_MID}&s=${kountSessionId}`}
                id="kount-core-js"/>
    </Helmet>
    <Bootstrap />
</React.StrictMode>);

reportWebVitals(console.info);

import classNames from "classnames";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import {getStyles} from "src/variables/DropVariables";

// core components
export default function Comments(_props) {

    const dig = getStyles()

    return <div className={classNames(dig.m2)}>
        <h2>2 Comments</h2>

        <ExpandableBox>
            <div id="div-comment-1406" className={"comment-body"}>
                <div className={classNames(dig.commentAuthor, dig.vcard)}>
                    <img
                        src="https://www.dropingaming.com/wp-content/uploads/ultimatemember/337/profile_photo-190x190.jpg?1654035134"
                        className={classNames("gravatar avatar avatar-32 um-avatar um-avatar-uploaded")} width="32"
                        height="32"
                        alt="richard.miles"
                        data-default="https://www.dropingaming.com/wp-content/uploads/2021/06/Default-Avatar.png"/>
                    <cite className={dig.m5}>
                        <a href="https://www.dropingaming.com/user/richard.miles/">
                            richard.miles
                        </a>
                    </cite>
                </div>

                <div className={classNames(dig.commentMeta, "commentmetadata")}>
                    <a href="https://www.dropingaming.com/2021/11/30/wraith-bug-in-apex-legends/#comment-1406">May
                        31, 2022 at
                        10:12 pm
                    </a> &nbsp;&nbsp;
                    <a className={"comment-edit-link"}
                                                    href="https://www.dropingaming.com/wp-admin/comment.php?action=editcomment&amp;c=1406">(Edit)</a>
                </div>

                <p>what does this do?</p>
                <div className={classNames("cld-like-dislike-wrap cld-template-1")}>
                    <div className={classNames("cld-like-wrap  cld-common-wrap")}>
                        <a href="#" className={classNames("cld-like-trigger cld-like-dislike-trigger ")}
                           title=""
                           data-comment-id="1406" data-trigger-type="like" data-restriction="cookie"
                           data-already-liked="0">
                            <i className={classNames(dig.fas, "fa-thumbs-up")}></i>
                        </a>
                        <span className={classNames(dig.cldLikeCountWrap, dig.cldCountWrap)}>1</span>
                    </div>
                    <div className={classNames("cld-dislike-wrap", dig.cldCommonWrap)}>
                        <a href="#" className={classNames("cld-dislike-trigger cld-like-dislike-trigger ")}
                           title=""
                           data-comment-id="1406" data-trigger-type="dislike" data-restriction="cookie"
                           data-already-liked="0">
                            <i className={classNames(dig.fas, dig.faThumbsDown)}></i>
                        </a>
                        <span className={classNames("cld-dislike-count-wrap cld-count-wrap")}></span>
                    </div>
                </div>

                <div className={"reply"}><a rel="nofollow" className={"comment-reply-link"} href="#comment-1406"
                                            data-commentid="1406" data-postid="12468"
                                            data-belowelement="div-comment-1406"
                                            data-respondelement="respond" data-replyto="Reply to richard.miles"
                                            aria-label="Reply to richard.miles">Reply</a></div>
            </div>

        </ExpandableBox>

        <ExpandableBox>
            <div id="respond" className={"comment-respond"}>
                <h3 id="reply-title" className={"comment-reply-title"}>Leave a Reply <small>
                    <a rel="nofollow"
                       id="cancel-comment-reply-link"
                       href="/2021/11/30/wraith-bug-in-apex-legends/#respond">Cancel
                        reply</a>
                </small>
                </h3>
                <form action="https://www.dropingaming.com/wp-comments-post.php" method="post" id="commentform"
                      className={"comment form"}><p className={"logged-in-as"}><a
                    href="https://www.dropingaming.com/wp-admin/profile.php"
                    aria-label="Logged in as richard.miles. Edit your profile.">Logged in as richard.miles</a>. <a
                    href="https://www.dropingaming.com/wp-login.php?action=logout&amp;redirect_to=https%3A%2F%2Fwww.dropingaming.com%2F2021%2F11%2F30%2Fwraith-bug-in-apex-legends%2F&amp;_wpnonce=eeb316cf7c">Log
                    out?</a> <span className={"required-field-message"}
                                   aria-hidden="true">Required fields are marked <span
                    className={"required"} aria-hidden="true">*</span></span></p><p className={"comment-form-comment"}>
                    <label
                        htmlFor="comment">Comment <span className={"required"} aria-hidden="true">*</span></label>
                    <textarea
                        id="comment" name="comment" cols={45} rows={8} maxLength={65525} required/>
                </p>
                </form>
            </div>
        </ExpandableBox>

    </div>


}


import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import Button from "../../components/Button/Button";
import styles from "./style.module.scss";

export const STORE_PATH = 'store';

// core components

const dig = getStyles(styles)

export default function Store() {

    const mockStoreData: {
        itemName: string,
        itemPrice: number,
        itemOldPrice?: number,
        itemImage: string,
        href: string,
        addToCart?: string,
    }[] = [
        {
            itemName: "Cougar Armor Pro",
            itemPrice: 296.10,
            itemOldPrice: 329.00,
            itemImage: "/wp-content/uploads/2021/03/cougar-arnor-pro-black-1-300x300.png",
            href: "http://local.dropingaming.com:3000/product/",
        },
        {
            itemName: "Drop-In Gaming Retro Logo Cap Black/Gray",
            itemPrice: 35.00,
            itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/retro-logo-cap-black-gray.png",
            href: "http://local.dropingaming.com:3000/product/",
        },
        {
            itemName: "Drop-In Gaming Retro Logo Cap Gray/Black",
            itemPrice: 35.00,
            itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/retro-logo-cap-gray-black-300x300.png",
            href: "http://local.dropingaming.com:3000/product/",
        },
        {
            itemName: "Drop-In Gaming Retro Logo Cap Gray/White",
            itemPrice: 35.00,
            itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/retro-logo-cap-gray-white-300x300.png",
            href: "http://local.dropingaming.com:3000/product/",
        },
        {
            itemName: "Drop-In Gaming Retro Logo Light-Grey/Black",
            itemPrice: 35.00,
            itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/retro-logo-cap-light-gray-black-300x300.png",
            href: "http://local.dropingaming.com:3000/product/",
        },
        {
            itemName: "DXRacer Racing Series Gaming Chair",
            itemPrice: 359.10,
            itemOldPrice: 399.00,
            itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/03/dxracer-oh-rv131-nr-1_e33ff88d-5a2e-45de-83d4-7bd2b4757f33-300x300.jpg",
            href: "http://local.dropingaming.com:3000/product/",
        },
        {
            itemName: "Ewin Gaming Chair",
            itemPrice: 224.10,
            itemOldPrice: 299.00,
            itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/03/cld-pink-1_fe3e5699-19f9-4e35-a763-fac447062f69-300x300.jpg",
            href: "http://local.dropingaming.com:3000/product/",
        },
        {
            itemName: "Where We Droppin’ T-Shirt (Blue)",
            itemPrice: 25.00,
            itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/wwd-blue-300x300.png",
            href: "http://local.dropingaming.com:3000/product/",
        },
        {
            itemName: "Where We Droppin’ T-Shirt (Red)",
            itemPrice: 25.00,
            itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/wwd-red-300x300.png",
            href: "http://local.dropingaming.com:3000/product/",
        },
    ]


    return <div className={classNames(dig.container, dig.mt5)}>

        <h1> Merchandise </h1>

        <div id="post-3804" className={classNames("parent-0 post-3804 page type-page status-publish hentry")}>
            <div className={classNames(dig.woocommerce, dig.w100)}>
                <div className={dig.woocommerceNotice}></div>
                <p className={classNames(dig.woocommerceResultCount)}>
                    Showing all 9 results</p>
                <form className={dig.woocommerceOrdering} method="get">
                    <select name="orderby" className={"orderby"} aria-label="Shop order">
                        <option value="menu_order" selected>Default sorting</option>
                        <option value="popularity">Sort by popularity</option>
                        <option value="date">Sort by latest</option>
                        <option value="price">Sort by price: low to high</option>
                        <option value="price-desc">Sort by price: high to low</option>
                    </select>
                    <input type="hidden" name="paged" value="1"/>
                </form>
                <ul className={classNames(dig.dFlex, dig.flexWrap, dig.textBlack)}>

                    {mockStoreData.map((data, key) => <li key={key}
                                                          className={classNames(dig.col3, dig.m2, dig.bgWhite, dig.p2, dig.merchCard)}>
                            <a href={data.href} className={classNames()}>
                                <span className={"onsale"}>Sale!</span>
                                <a href={
                                    data.href
                                }>
                                <img width="100" height="100"
                                     src={data.itemImage}
                                     className={classNames(dig.merchImg)}
                                     alt="" loading="lazy"/>
                                </a>

                                <h2 className={classNames(dig.textBlack)}>{data.itemName}</h2>
                                <div className={classNames(dig.dFlex, dig.textPrimary, dig.fwBold)}>
                                    {data.itemOldPrice &&
                                        <div className={classNames(dig.me3, dig.opacity75)}>
                                            {/**TODO - create woocommerce-Price-amount & woocommerce-Price-currencySymbol classes in style.module.scss.d.ts and link them similar to the other woocommerce classnames*/}
                                            <div className={classNames(dig.textDecorationLineThrough)}>
                                                ${data.itemOldPrice}</div>
                                        </div>}


                                    <div>
                                        <div>$ {data.itemPrice}</div>
                                    </div>

                                </div>
                            </a>

                            <Button href="?add-to-cart=8078" data-quantity="1"
                            className={classNames(dig.button)}
                            data-product_id="8078" data-product_sku="DIG-RL-BG"
                            aria-label="Add Item to your cart"
                            rel="nofollow">Add to cart</Button>
                        </li>
                    )}
                </ul>
            </div>
            <a className={dig.postEditLink}
               href="http://local.dropingaming.com:8080/wp-admin/post.php?post=3804&amp;action=edit">Edit
                This</a>
        </div>

    </div>


}


import classNames from "classnames";
import {ChangeEvent, useState} from "react";
import {toast} from "react-toastify";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import putUser from "src/api/rest/putUser";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Input from "src/components/Input/Input";
import Loading from "src/components/Loading/Loading";
import DropVariables, {getStyles} from "src/variables/DropVariables";

import {ReactComponent as ComSVG} from "src/assets/img/svg/DIG-logo-lockup-beta-horizontal-white.svg"
import {ReactComponent as GgSVG} from "src/assets/img/svg/DIG-logo-lockup-horizontal-white-GG.svg"
import isGG from "src/variables/isGG";

export default function VerifyAccountTrollBridge() {

    const currentUser = getCurrentLoggedInUser()

    const [userEmail, setUserEmail] = useState<string | undefined>(currentUser?.user_email)

    const [sending, setSending] = useState<boolean>(false)

    const [sent, setSent] = useState<boolean>(false)

    const [isError, setIsError] = useState<boolean>(false)

    const dig = getStyles()


    let boxContent: JSX.Element = <></>

    if (sent) {
        boxContent = <>The verification email was successfully sent! Please be sure to check your spam folder.</>
    } else if (sending) {
        boxContent = <Loading message={'We are processing your request now!'}/>
    } else {
        boxContent = <>
            <h1 className={dig.textCenter}>Let&apos;s get verified!</h1>
            <br/>
            <p>
                Looks like we need to verify your email address.
            </p>
            <div className={classNames(dig.col6, dig.offset3)}>
                {isError && <p className={dig.textCenter} style={{color: "var(--dig_accent_color)"}}>The replacement email address you provided is already in use. Please try another email.</p>}
                <Input type={"email"}
                       value={currentUser?.user_email}
                       label={isError ? '' : 'You may update the current email below!'}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => {
                           setUserEmail(e.target.value)
                       }}/>
                After you have verified your email above is correct, you may continue to{' '}
                <a href={'#'}
                   style={{color: "var(--dig_primary_color)"}}
                   onClick={async (event) => {

                       if (undefined === currentUser) {

                           toast.error('You must be logged in to verify your email address. Please contact support if problems persist.')

                           return;

                       }

                       const target = event.target as HTMLElement;

                       target.innerHTML = 'Sending, please be patient!';

                       target.onclick = null;

                       setSending(true)

                       const response = await putUser({
                           ID: currentUser?.ID,
                           user_email: userEmail
                       })

                       if (null === response || undefined === response) {

                           toast.error('An error occurred while sending the verification email. Please contact support if problems persist.', DropVariables.toastOptions)

                           return;

                       }

                       if ('object' === typeof response) {

                           if (response.data.updated) {
                               setSending(false)
                               setSent(true)
                           } else {
                               setIsError(true);
                               setSending(false);
                           }

                       }

                   }}>
                    send the verification email by clicking here!
                </a>
            </div>
        </>
    }


    return <div className={classNames(dig.container, dig.justifyContentCenter, dig.mt5)}
                style={{color: "white", fontSize: "1.5rem", textAlign: "center"}}>

        <div className={classNames(dig.col6, dig.offset3)}>
            {isGG()
                ? <GgSVG style={{width: "100%", display: "flex"}}/>
                : <ComSVG style={{width: "100%", display: "flex"}}/>}
        </div>


        <ExpandableBox>

            {boxContent}

        </ExpandableBox>

    </div>
}

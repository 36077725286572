import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import {C6, iDig_Region} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";

export default restApi<{}, iDig_Region, {}, iGetC6RestResponse<iDig_Region>>({
    axios: DropVariables.axios,
    tableName: C6.dig_region.TABLE_NAME,
    requestMethod: GET,
    queryCallback: {
        [C6.SELECT]: [
            C6.dig_region.REGION_ID,
            C6.dig_region.REGION_NAME
        ],
        [C6.PAGINATION]: {
            [C6.LIMIT]: 100
        }
    },
    responseCallback: (response, _request) => {

        let restData: iDig_Region[] = response?.data?.rest ?? [];
        updateRestfulObjectArray<iDig_Region>(restData, 'region_id', 'regions');

    }
})

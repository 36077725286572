import restApi, {GET, iGetC6RestResponse, RequestGetPutDeleteBody} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import Bootstrap from "src/Bootstrap";
import {C6, iPosts} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iWpPost} from "src/variables/sharedInterfaces";
import getPostMeta from "./getPostMeta";

export default restApi<{
    postId?: number,
    datetime?: string
}, iPosts, {}, iGetC6RestResponse<iWpPost>>({
    axios: DropVariables.axios,
    tableName: C6.posts.TABLE_NAME,
    requestMethod: GET,
    queryCallback: () => {

        const {sliders} = Bootstrap.bootstrap.state;

        if (null === sliders) {

            return undefined;

        }

        if (sliders === undefined) {

            Bootstrap.bootstrap.setState({
                sliders: null
            })

        }

        let query: RequestGetPutDeleteBody = {
            [C6.SELECT]: [
                ...Object.keys(C6.posts.COLUMNS)
            ],
            [C6.WHERE]: {
                [C6.posts.POST_TYPE]: 'slider_feature',
                [C6.posts.POST_STATUS]: 'publish'
            },
            [C6.JOIN]: {},
            [C6.PAGINATION]: {
                [C6.ORDER]: {
                    [C6.posts.POST_DATE]: C6.DESC
                },
                [C6.LIMIT]: 500
            }
        };

        return query

    },
    responseCallback: (response) => {

        let posts = response.data.rest;

        updateRestfulObjectArray<iWpPost>(posts, "ID", "sliders")

        posts.map(post => {
            getPostMeta({
                ID: post.ID

            })

        })

    }
});


import classNames from "classnames";
import {useEffect} from "react";
import getInvitations from "src/api/rest/getInvitations";
import Bootstrap from "src/Bootstrap";
import {getStyles} from "src/variables/DropVariables";
import ProfileInvitationCompCard from "src/components/Invitation/ProfileInvitationCompCard";
import ProfileInvitationTeamCard from "src/components/Invitation/ProfileInvitationTeamCard";

// core components

export const INVITATIONS_PATH = 'invitations'

export default function Invitations() {

    const {invitations, darkMode, id} = Bootstrap.bootstrap.state;

    useEffect(() => {
        getInvitations();
    })

    const dig = getStyles() // todo - realize this page

    return <div className={classNames(dig.container, dig.digUserActionGroup, dig.digProfileCompList, dig.upcomingInvitations, dig.p0)}>
        <div className={classNames(dig.row, dig.pb5, dig.mb2, dig.mbMd4, dig.m0, dig.p0)}>

            {Array.isArray(invitations)
            && invitations.length === 0
            && <div className={classNames(dig.tabPane, dig.fade, dig.active, dig.show)}>
                <div className={classNames(dig.alert, dig.alertWarning, dig.my5)}>
                    <strong>
                        <i className={classNames("fas fa-radiation-alt")}/>
                    </strong>
                    <div>
                        {"No invitations!"}
                    </div>
                </div>
            </div>}


            {invitations?.filter(invite => invite.user_id === id).map((invitation) => {

                return invitation.invitation_type === 'team' ?
                    <ProfileInvitationTeamCard invitation={invitation} darkMode={darkMode}/>
                    : <ProfileInvitationCompCard invitation={invitation} darkMode={darkMode}/>
            })}

        </div>
    </div>

}


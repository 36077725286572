import classNames from "classnames";
import {toast} from "react-toastify";
import forgotPassword from "src/api/hoc/forgotPassword";
import Button from "src/components/Button/Button";
import Input from "src/components/Input/Input";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {users} from "src/variables/C6";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {ChangeEvent, useState} from "react";

export const PASSWORD_RESET_PATH = 'PasswordReset';

export default function ForgotPasswordReset(props: WithRouter) {

    const [userInformation, setUserInformation] = useState<string>('')
    const [userPasswordA, setUserPasswordA] = useState<string>('')
    const [userPasswordB, setUserPasswordB] = useState<string>('')

    const dig = getStyles()


    const enterNew = props.params.userId && props.params.activationKey;

    return <div className={classNames(dig.row, dig.mt5)} style={{paddingLeft: '20px'}}>

        <section>
            <h1>Password Reset </h1>

            <article className="parent-0 post-3926 page type-page status-publish">
                <form style={{textAlign: "center"}}>
                    <div>
                        {enterNew
                            ? 'Please enter your new password below.'
                            : 'To reset your password, please enter your email address or username below.'}
                    </div>

                    {enterNew ? <>
                            <Input
                                type="password"
                                id="password_a"
                                value={userPasswordA}
                                placeholder="Enter your new password"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setUserPasswordA(e.target.value)
                                }}
                                regexValidations={users.REGEX_VALIDATION[users.USER_PASS]}
                                className={classNames(dig.col6)}/>
                            <Input
                                type="password"
                                id="password_b"
                                value={userPasswordB}
                                placeholder="Confirm your new password"
                                regexValidations={users.REGEX_VALIDATION[users.USER_PASS]}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setUserPasswordB(e.target.value)
                                }}
                                className={classNames(dig.col6)}/></>
                        : <Input
                            type="text"
                            id="username_b"
                            value={userInformation}
                            placeholder="Enter your username or email"
                            data-validate=""
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setUserInformation(e.target.value)
                            }}
                            className={classNames(dig.col6)}/>
                    }


                    <div className={classNames(dig.col4, dig.offset4)}>
                        <Button onClick={() => {

                            if (0 === userInformation.length && '' === userPasswordB && '' === userPasswordA) {
                                toast.error('Please enter the required information!', DropVariables.toastOptions);
                                return
                            }

                            if (userPasswordA !== userPasswordB) {
                                toast.error('Passwords do not match!', DropVariables.toastOptions);
                                return
                            }

                            forgotPassword({
                                emailAddressOrUsername: userInformation,
                                userID: props.params.userId,
                                userPassword: userPasswordA,
                                userPin: props.params.activationKey,
                            })

                        }}>
                            Reset Password
                        </Button>
                    </div>
                </form>
            </article>
        </section>

    </div>
}


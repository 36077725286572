// @link https://stackoverflow.com/questions/6735414/php-data-uri-to-file
// @link https://www.tutorialspoint.com/convert-image-to-data-uri-with-javascript
import {ChangeEvent, lazy} from "react";
import {toast} from "react-toastify";
import postPosts from "src/api/rest/postPosts";
import Bootstrap from "src/Bootstrap";


// quite possibly one of the sexiest files we have

export interface iUploadCompImage {
    comp_id: number,
    team_id?: number,
    scorebox?: number
}

export async function toDataURL(src: string, fileType: string, callback: (dataUriEncoded: string) => void): Promise<void> {

    console.log('toDataURL', src, fileType);

    const image = new Image();

    image.crossOrigin = 'Anonymous';

    image.onload = function () {

        console.log('image', image);

        const canvas = document.createElement('canvas');

        const context = canvas.getContext('2d');

        canvas.height = image.naturalHeight;

        canvas.width = image.naturalWidth;

        if (context === null) {

            console.error('Unable to get canvas context');

            toast.error('Unable to upload image. Please try another image or bowser. If issues persist, please contact support.');

            return;

        }

        context?.drawImage(image, 0, 0);

        const dataURL = canvas.toDataURL(fileType); // 'image/jpeg'

        callback(dataURL);

    };

    console.log('src', src);

    image.src = src;

}

export async function uploadImageChange(post_excerpt: string, event: ChangeEvent<HTMLInputElement>,
                                  prePost?: (postData: any) => any,
                                  uploadCallback: ((ID: number, dataUriBase64: string) => void) | undefined = undefined) {

    if (event.target.files === null || undefined === event.target?.files?.[0]) {

        toast.error('Please upload a valid image file type (jpg, jpeg, png, gif, heic).');

        return;

    }

    Object.keys(event.target.files).forEach((index) => {

        (async () => {
            let file = event.target.files?.[index];

            // loop through all files and create data url then post to postPost
            if (false === file.type.match('image.*')) {

                toast.error('Please upload a valid image file type (jpg, jpeg, png, gif, heic). (E_IMAGE_*<>' + file.type + ')');

                return;

            }

            // get file extension
            const fileExtension = file.name.split('.').pop();

            // check file extension is valid data uri
            if (fileExtension !== 'jpg'
                && fileExtension !== 'jpeg'
                && fileExtension !== 'heic'
                && fileExtension !== 'png'
                && fileExtension !== 'gif') {

                toast.error('Please upload a valid image file type (jpg, jpeg, png, gif, heic). (E_IMAGE_EXT)');

                return;

            }

            const isHeic = fileExtension === 'heic';

            if (isHeic) {

                // todo - this should be code split and lazy loaded, but doesn't work
                // look up code splitting, it could be an issue with rewired
                file = (await lazy(() => require("heic2any")))({
                    blob: file,
                    toType: "image/webp",
                    quality: 0.5, // cuts the quality and size by half
                });

            }

            // @link https://github.com/palantir/tslint/issues/4653
            // @link https://github.com/Microsoft/TypeScript/issues/13376#issuecomment-273289748
            void toDataURL(URL.createObjectURL(file), 'image/webp', (dataUriEncoded => {

                console.log('dataUriEncoded', dataUriEncoded);

                let postData = {
                    guid: '',
                    post_author: Bootstrap.bootstrap.state.id,
                    post_content: dataUriEncoded,
                    post_content_filtered: '',
                    post_excerpt: post_excerpt,
                    post_title: 'Original file name: (' + file.name + ') type: (' + file.type + ') and size: (' + file.size + ') bytes',
                    post_status: 'inherit',
                    comment_status: 'open',
                    ping_status: 'open',
                    to_ping: '',
                    pinged: '',
                    post_name: file.name,
                    post_parent: 0,
                    post_type: 'attachment',
                    post_mime_type: 'image/webp', // we convert to jpeg using canvas.toDataURL
                    success: "Image uploaded successfully!",
                    uploadCallback: uploadCallback,
                    cacheResults: false
                };

                if (undefined !== prePost && 'function' === typeof prePost) {

                    postData = prePost(postData)

                }

                postPosts(postData);

            }));

        })();

    });


}


// dataUriEncoded is the base64 encoded string which is posted in column post_content
export default function uploadImage(post_excerpt: string, prePost?: (postData : any) => any,
                uploadCallback: ((ID: number, dataUriBase64: string) => void) | undefined = undefined) {

    console.log('uploadImage')

    return () => {
        const input: HTMLInputElement = document.createElement('input')
        input.type = 'file'
        input.accept = 'image/*, .heic'
        input.style.display = 'none'

        // the element must be appended to the document in order to fire on safari
        // @link https://stackoverflow.com/questions/47664777/javascript-file-input-onchange-not-working-ios-safari-only
        document.body.appendChild(input);

        // safari also requires addEventListener rather than .onChange
        input.addEventListener('change', (e) => {
            console.log('uploadImage', e)
            uploadImageChange(post_excerpt, e as unknown as ChangeEvent<HTMLInputElement>, prePost, uploadCallback)
        })

        input.click()
    }
}

export function uploadCompImage(post_excerpt: string, comp_data: iUploadCompImage,
                                uploadCallback: ((ID: number, dataUriBase64: string) => void) | undefined = undefined) {

    return uploadImage(post_excerpt, (postData) => {
        if (comp_data !== undefined) {
            // comp id is required, so it will always be here
            postData['comp_id'] = comp_data.comp_id;

            if (comp_data.team_id !== undefined && comp_data.scorebox !== undefined) {
                postData['team_id'] = comp_data.team_id;
                postData['scorebox'] = comp_data.scorebox;
            }
        }

        return postData;
    }, uploadCallback)


}




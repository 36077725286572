import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import getUsers from "src/api/rest/getUsers";
import Bootstrap from "src/Bootstrap";
import {addAlert} from "src/components/Alert/Alert";
import {C6, iUsers} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";

export interface iAuthenticateUser {
    user: iUsers
}

// noinspection JSIgnoredPromiseFromCall
export default restApi<iAuthenticateUser, iUsers, {}, iPostC6RestResponse<{
    success: boolean,
    user: {
        // this is an instance of user from wp, not rest. We can ignore the rest of the data atm
        ID: number
    },
    invalidPasswordResetKey?: string
}>>({
    axios: DropVariables.axios,
    tableName: C6.users.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {

        // no success message

        request.error = 'An unknown issue occurred authenticating your account. Please try again.';

        request.cacheResults = false;

        return request.user

    },
    responseCallback: response => {

        if (response?.data?.rest?.success &&
            response?.data?.rest?.user?.ID) {

            Bootstrap.bootstrap.setState({
                id: response.data.rest.user.ID,
                authenticating: false
            }, () => {
                getUsers({
                    invalidPasswordResetKey: response?.data?.rest?.invalidPasswordResetKey ?? undefined,
                    userIds: [
                        response.data.rest.user.ID
                    ],
                    cacheResults: false
                });
            });


        } else {

            addAlert({
                title: "Login Failed!",
                text: "Please make sure your username and password is correct.",
                icon: "warning",
                dangerMode: true,
            })

        }

    }
});
import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";

export default function Tabs(props: {
    tabs: string[],
    activeTab: string,
    onTabClick: (tab: string) => void
}) {

    const dig = getStyles()

    const {tabs, activeTab, onTabClick} = props;

    return <ul id="myTab" role="tablist"
               className={classNames(
                   dig.nav, dig.navTabs, dig.navPills, dig.alignItemsCenter, dig.digBgNeutral3,
                   dig.flexSmRow, dig.textCenter, dig.bgLight, dig.border0, dig.roundedNav
               )}>
        {tabs.map((tab, index) => <li
            key={index}
            className={classNames(dig.navItem, dig.flexSmFill, dig.m0, dig.cursorPointer)}>
            <div data-toggle="tab"
                 role="tab"
                 onClick={() => onTabClick(tab)}
                 className={classNames(
                     dig.navLink, dig.border0, dig.textUppercase, dig.fwBold, dig.rounded0,
                     {[dig.active]: activeTab === tab}
                 )}>
                {tab}
            </div>
        </li>)}
    </ul>
}

import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import {eCOMPETITION, eCOMPETITION_STATUS, iCompetitions} from "src/variables/sharedInterfaces";
import classNames from "classnames";
import {Helmet} from "react-helmet";
import {getStyles} from "src/variables/DropVariables";

import {faMinus, faPlus, faUndo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useEffectOnce} from "src/api/hoc/useEffectOnce";

import zoom from './Zoom'
import bracketJs from './BracketJs'
import style from './style.module.scss';


interface iBracketTeam {
    name: string,
    team_id: number
}

export interface iBracketAPI {
    teams: Array<iBracketTeam[]>,
    comp_id: number,
    results: any,
    references: any,
    starting_rounds: number,
    ELIMINATION_STYLE: string,
    skipSecondaryFinal: boolean,
    skipConsolationRound: boolean,
    skipGrandFinalComeback: boolean
}

export default function Bracket({competition}: {
    competition?: iCompetitions
}) {
    useEffectOnce(() => {

        const bracket: iBracketAPI | undefined = undefined === competition?.bracket || '' === competition.bracket ? undefined : JSON.parse(competition?.bracket);

        if (undefined === bracket ||
            null === bracket) {

            return;

        }


        const nonReact = `
           jQuery('#bracket').bracket({
            init: ` + JSON.stringify(bracket) + `,
            centerConnectors: true,
            teamWidth: 160,
            scoreWidth: 40,
            matchMargin: 30,
            roundMargin: 30,
            showingScore: false,
            hideByeMatch: true,
            skipConsolationRound: ` + bracket.skipConsolationRound + `,
            skipGrandFinalComeback: ` + bracket.skipGrandFinalComeback + `,
            skipSecondaryFinal: ` + bracket.skipSecondaryFinal + `,
            decorator: {
                edit: function () {
                },
                render: function (container, data, score, state) {
                    switch (state) {
                        case "empty-bye":
                            container.append("Bye");
                            return;
                        case "empty-tbd":
                            container.append("TBD")
                            return;
                        case "entry-no-score":
                        case "entry-default-win":
                        case "entry-complete":
                            if (data.disqualified) {
                                container.append('<del>' + data.name + '</del>');
                                container.parent().attr('data-disqualified', data.disqualified);
                            } else {
                                container.append(data.name);
                            }
                            container.parent().attr('data-user_team_id', data.team_id);
                            container.parent().attr('id', 'team_' + data.team_id);
                            container.parent().addClass('team_' + data.team_id);
                            return;
                    }
                }
            }
        })

        
`;


        setTimeout(() => eval(nonReact), 300)

        setTimeout(() => eval(zoom()), 500)

        setTimeout(() => eval(bracketJs()), 700)


    });

    if (competition?.comp_type_id !== eCOMPETITION.BRACKETS) {

        return null;

    }

    const dig = getStyles(style)

    return <>
        <Helmet>
            <link rel="stylesheet" href="/wp-content/themes/dropingaming/assets/css/jquery.bracket.css" media="all"/>
            <link rel="stylesheet" href="/wp-content/themes/dropingaming/assets/css/bracket.css" media="all"/>
        </Helmet>
        <ExpandableBox header={'Bracket'}>

            <div className={classNames(dig.row, dig.m0, dig.borderColorNeutral6, "bracket_container")}>

                {
                    eCOMPETITION_STATUS.WAITING === competition?.status
                        ? <h3>The bracket match-ups will display when the Tournament has started!</h3>
                        : <>
                            <div className={classNames(dig.dFlex, dig.flexColumn,dig.bracketButtons, "bracket_buttons")}>
                                <button title="Zoom In" className={classNames(dig.mb2, dig.border0, dig.px4, dig.py3, dig.textWhite, dig.digBtnBlue)} id="zoom-in">
                                    <FontAwesomeIcon
                                        icon={faPlus}
                                        size={'1x'}/>
                                </button>
                                <button title="Zoom Out" className={classNames(dig.mb2, dig.border0, dig.px4, dig.py3, dig.textWhite, dig.digBtnBlue)} id="zoom-out">
                                    <FontAwesomeIcon
                                        icon={faMinus}
                                        size={'1x'}/>
                                </button>
                                <button title="Reset" className={classNames(dig.border0, dig.px4, dig.py3, dig.textWhite, dig.digBtnBlue)} id="reset">
                                    <FontAwesomeIcon
                                        icon={faUndo}
                                        size={'1x'}/>
                                </button>
                            </div>
                            <div id="bracket" data-comp_id={competition.comp_id}/>
                        </>
                }

            </div>


        </ExpandableBox>
    </>

}



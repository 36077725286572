import restApi, {POST} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Match_Confirm} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {eCOMPETITION_STATUS, iCompetitions} from "src/variables/sharedInterfaces";

export default restApi<{
        comp_id: number,
        team_id: number,
        agreement: number
    }, iDig_Match_Confirm>({
        axios: DropVariables.axios,
        tableName: C6.dig_match_confirm.TABLE_NAME,
        requestMethod: POST,
        queryCallback: (request) => {
            request.success = 'Successfully reported match results!'
            request.error = 'An unknown issue occurred. We will be looking into this shortly.'
            return request
        },
        responseCallback: (_response, request) => {

            updateRestfulObjectArray<iCompetitions>([{
                comp_id: request.comp_id,
                match_responses: [request],
                status: request.agreement === 1 ? eCOMPETITION_STATUS.PAID : eCOMPETITION_STATUS.COMPLETED
            }], "comp_id", "competitions");

        }
});

import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import isCOM from "src/variables/isCOM";


export default function(gameId: number) {

    const whoami = getCurrentLoggedInUser()

    const isChild = whoami?.isChild === true

    return false === isChild
        ? false
        : false === isCOM() && isChild && false === whoami?.allowedGames?.includes(gameId)

}
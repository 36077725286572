import style from "./style.module.scss"

export default function MaintenanceMode() {


    return <div className={style.maintenanceHero}>
        <h1 className={style.httpStatusCode}>200</h1>
        <div className={style.centeredContainer}>
               <h1 className={style.errorKeys}> We are excited! At this moment our developers are dropping in to bring new features to the site. We will be back up and running shortly. Thank you for your patience. If you need anything please contact support ( <a href={'mailto:support@dropingaming.com'} style={{color:'blue'}}>support@dropingaming.com</a>. )</h1>
        </div>
    </div>

}
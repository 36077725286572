import restApi, {DELETE, iDeleteC6RestResponse} from "src/api/API";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";
import {C6, iDig_Comp_Invitation} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iInvitation} from "src/variables/sharedInterfaces";

// the only time the frontend should call this is when denying a match challenge invite (comp invitation with no team id)

export default restApi<{invitation_id: number}, iDig_Comp_Invitation, {}, iDeleteC6RestResponse>(
        {
            axios: DropVariables.axios,
            tableName: C6.dig_comp_invitation.TABLE_NAME,
            requestMethod: DELETE,
            queryCallback: (request) => {
                request.success = 'Successfully removed the invitation!'
                request.error = 'An unknown issue occurred removing the invitation. We will be looking into this shortly.'
                return request
            },
            responseCallback: (_response, request) => {

                deleteRestfulObjectArrays<iInvitation>([{
                    invitation_id: request.invitation_id
                }], "invitation_id", "invitations")

            }
        });

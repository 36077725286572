import classNames from "classnames";
import {Link} from "react-router-dom";
import isLoggedIn from "src/variables/isLoggedIn";
import getCompetitions from "src/api/rest/getCompetitions";
import Bootstrap from "src/Bootstrap";
import CompetitionGrid from "src/components/CompetitionGrid/CompetitionGrid";
import {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, eCOMPETITION_TIMING} from "src/variables/sharedInterfaces";
import {CREATE_MATCH_PATH} from "src/views/CreateCompetition/CreateCompetition";
import moment from "moment/moment";
import CompetitionSort from "../../api/hoc/CompetitionSort";
import {useEffect} from "react";

export const MATCHES_PATH = 'matches';

// core components
//Maybe someone else updated, TW
export default function Matches() {

    const {competitions} = Bootstrap.bootstrap.state;

    const getMatchesFromCompetitions = () => (competitions?.filter(comp =>
        comp.comp_type_id === eCOMPETITION.MATCHES &&
        comp.status !== eCOMPETITION_STATUS.CANCELED &&
        comp.status !== eCOMPETITION_STATUS.COMPLETED &&
        comp.status !== eCOMPETITION_STATUS.PAID &&
        comp.public === 1 &&
        (comp.now_flag === 1 || moment(comp.datetime).isSameOrAfter(moment()))
    ) || []).sort(CompetitionSort.sortCompetitionsDateTime)
        .sort(CompetitionSort.sortCompetitionsMoreRegistrationsFirst);

    useEffect(() => {
        getCompetitions({
            competition: eCOMPETITION.MATCHES,
            timing: eCOMPETITION_TIMING.FUTURE
        });
    });

    const dig = getStyles()

    return <div className={classNames(dig.px4, dig.container, dig.pt5)}>

        {isLoggedIn() &&
            <Link to={"/" + CREATE_MATCH_PATH} className={classNames(dig.digPrimaryButton, dig.matchesStartMatch)}>
                Create a Match
            </Link>}

        <h1 className={dig.mt5}>Matches</h1>

        <div className={classNames(dig.digUserActionGroup, dig.upcomingEvents, dig.tournamentDashboard)}>
            <div className={classNames(dig.digUserActionGroup, dig.upcomingEvents)}>
                <CompetitionGrid
                    // TODO: apply sorting to loadMore data
                    displaySort={true}
                    competitionsToDisplay={getMatchesFromCompetitions()}
                />
            </div>
        </div>
    </div>


}

import {Link} from "react-router-dom";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import Bootstrap from "src/Bootstrap";
import Button from "src/components/Button/Button";
import CompetitionsJoined from "src/components/CompetitionsJoined/CompetitionsJoined";
import {
    ppr
} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {getStyles} from "src/variables/DropVariables";
import isWWW from "src/variables/isWWW";
import {SUPPORT_PATH} from "src/views/Support/Support";
import ImageSlider from '../../components/Slider/ImageSlider';
import classNames from "classnames";
import {GAMES_PATH} from "src/views/Games/Games";
import {iUser} from "src/variables/sharedInterfaces";
import {MATCHES_PATH} from "src/views/Matches/Matches";
import {BRACKETS_PATH, PROFILE_PATH} from "src/views/Profile/Profile";
import {TOURNAMENTS_PATH} from "src/views/Tournaments/Tournaments";

import {ReactComponent as LandingBracket} from "src/assets/img/new-landing-page-brackets.svg"
import {ReactComponent as LandingTour} from "src/assets/img/new-landing-page-tour.svg"
import {ReactComponent as LandingMatch} from "src/assets/img/new-landing-page-matches.svg"

import HomeStyles from "./style.module.scss"
import {WALLET_PATH} from "src/components/Wallet/Wallet";
import isPRO from "../../variables/isPRO";
import isCOM from "../../variables/isCOM";
// import { SliderData } from '../../components/Slider/SliderData';
import LeaderBoard from "./LeaderBoard"

export const HOME_PATH = "home";

// core components
export default function Home() {

    console.log("HOME TSX RENDER");

    const {id} = Bootstrap.bootstrap.state;

    const DynamicContent = id === 0 ? '' : ppr(CompetitionsJoined, {});

    const dig = getStyles(HomeStyles)

    const myAccount: undefined | iUser = getCurrentLoggedInUser();

    return <>
        <div className={dig.middle}>
            <ImageSlider/>

            {false === isWWW() && id !== 0 && <LeaderBoard/>}

            {DynamicContent}

            <section className={classNames(dig.heroCol, dig.dFlex, dig.justifyContentCenter)}>
                <div className={classNames(dig.digLandingPageGetStarted, dig.hero, dig.py4)}>
                    {isPRO() ?
                        <h2 className={classNames(dig.textCenter, dig.pt5)}>Start in two easy steps</h2>
                        :
                        <h2 className={classNames(dig.textCenter, dig.pt5)}>Start in three easy steps</h2>
                    }
                    <div className={classNames(dig.digGetStartedRow, dig.container)}>
                        <div className={dig.row}>
                            <div className={classNames({
                                [dig.colMd6]: isPRO(),
                                [dig.colMd4]: !isPRO(),
                            })}>
                                <h1 className={dig.digSuper}>1</h1>
                                <div className={dig.digGetStartedElement}>
                                    <h1>Sign Up</h1>
                                    <div className={dig.info}>
                                        {myAccount ?
                                            <Link to={"/" + GAMES_PATH} className={classNames(dig.digBlue)}>
                                                Create your free Drop-in profile
                                            </Link>
                                            :
                                        <a title="Create your free Drop-in profile" data-popup_type="register"
                                              className={dig.digBlue}
                                           onClick={() => {
                                               Bootstrap.bootstrap.setState({joinPopup: true})
                                           }}>
                                            Create your free Drop-in profile
                                        </a>
                                        }
                                    </div>
                                </div>
                            </div>
                            {isCOM() &&
                            <div className={dig.colMd4}>
                                <h1 className={dig.digSuper}>2</h1>
                                <div className={dig.digGetStartedElement}>
                                    <h1>Deposit</h1>
                                    <div className={dig.info}>


                                        {myAccount ?
                                            <Link title="Add credits or play for free"
                                                  className={dig.digBlue}
                                              to={'/' + PROFILE_PATH + '/' + myAccount?.ID +'/' + WALLET_PATH}>
                                                Add funds or play for free
                                            </Link>
                                            :
                                            <a title="Add credits or play for free"
                                               className={dig.digBlue}
                                               onClick={() => {
                                                   Bootstrap.bootstrap.setState({loginPopup: true})
                                               }}>
                                                Add funds or play for free
                                            </a>
                                        }

                                    </div>
                                </div>
                            </div>
                            }
                            <div className={classNames({
                                [dig.colMd6]: isPRO(),
                                [dig.colMd4]: !isPRO(),
                            })}>
                                {isPRO() ? <h1 className={dig.digSuper}>2</h1>
                                : <h1 className={dig.digSuper}>3</h1>
                                }
                                <div className={dig.digGetStartedElement}>
                                    <h1>Play</h1>
                                    <div className={dig.info}>
                                        {myAccount ?
                                            <Link to={"/" + GAMES_PATH} className={classNames(dig.digBlue)}>
                                                Play games to win big prizes!
                                            </Link>
                                            :
                                        <a title="Play games to win big prizes!"
                                              className={dig.digBlue}
                                              onClick={() => {
                                                  Bootstrap.bootstrap.setState({loginPopup: true})
                                              }}>
                                            Play games to win big prizes!
                                        </a>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classNames(
                        dig.dFlex, dig.justifyContentCenter
                    )}>

                        {
                            myAccount ?
                                <Link to={"/" + GAMES_PATH} className={classNames(dig.digPrimaryButton, dig.getStarted)}>
                                    Get started
                                </Link>
                                :
                                <a className={classNames(dig.digPrimaryButton, dig.getStarted)}
                                   onClick={() => {
                                       Bootstrap.bootstrap.setState({loginPopup: true})
                                   }}>
                                    Get started
                                </a>
                        }

                    </div>
                </div>
            </section>
            <section className={classNames(dig.heroCol, dig.dFlex, dig.flexColumn, dig.justifyContentCenter, dig.pt0)}>
                <h1 className={classNames(dig.bg, dig.textCenter, dig.py5, dig.mt1, dig.px3)}>Three different ways to play!</h1>
                <div className={classNames(dig.dFlex, dig.justifyContentCenter)}>
                    <div className={classNames(dig.colMd12, dig.discover, dig.brackets)}>
                        <div className={classNames(dig.playingTypePicsMobile, dig.brackets)}>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16.02">
                                <g id="Layer_2" data-name="Layer 2">
                                    <g id="Artwork">
                                        <path d="M0,5.54H5.54V0H0ZM1,1H4.54V4.54H1Z"></path>
                                        <path d="M0,16H5.54V10.48H0Zm1-4.54H4.54V15H1Z"></path>
                                        <polygon
                                            points="8.5 2.27 6.23 2.27 6.23 3.27 7.5 3.27 7.5 12.75 6.23 12.75 6.23 13.75 8.5 13.75 8.5 8.5 9.61 8.5 9.61 7.5 8.5 7.5 8.5 2.27"></polygon>
                                        <path d="M10.46,5.23v5.54H16V5.23ZM15,9.77H11.46V6.23H15Z"></path>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className={classNames(dig.playingTypeInfo, dig.col6)}>
                            <h1>Brackets</h1>
                            <p>Single elimination, Double elimination and Round Robin tournaments. The rules are
                                simple: Win and advance to become the champion!</p>


                            <Link to={"/" + TOURNAMENTS_PATH + "/" + BRACKETS_PATH}
                                  className={classNames(dig.digPrimaryButton, dig.ldBrackets)}>
                                Play in a Bracket
                            </Link>


                        </div>
                        <div className={classNames(dig.playingTypePics, dig.brackets, dig.col6)}>
                            <LandingBracket fill='var(--dig_secondary2)'/>
                        </div>
                    </div>
                </div>
                <div className={classNames(dig.heroCol, dig.row)}>
                    <div className={classNames(dig.colMd12, dig.discover, dig.tournament)}>

                        <div className={classNames(dig.playingTypePics, dig.tournament)}>
                            <LandingTour/>
                        </div>
                        <div className={dig.playingTypeInfo}>
                            <h1>Tournaments</h1>
                            <p>Hosted Daily, Weekly, and Monthly! Buy into the “Big One” or compete in qualifiers
                                for a chance to grab a spot in one of the Mega Tournaments for a chance to win
                                big money!</p>


                            <Link to={"/" + TOURNAMENTS_PATH}
                                  className={classNames(dig.digPrimaryButton, dig.ldTournament)}>
                                Play in a Tournament
                            </Link>


                        </div>
                    </div>
                </div>
                <div className={classNames(dig.heroCol, dig.row)}>
                    <div className={classNames(dig.colMd12, dig.discover, dig.match)}
                         data-bg="https://www.dropingaming.com/wp-content/themes/dropingaming/assets/img/new-bg-matches.jpg">
                        <div className={classNames(dig.playingTypePicsMobile, dig.matches)}>
                            <LandingMatch/>
                        </div>
                        <div className={dig.playingTypeInfo}>
                            <h1>Matches</h1>
                            <p>Compete at all levels for a chance to win big. Find a quick match, or customize your
                                own and invite friends or foes!</p>


                            <Link to={"/" + MATCHES_PATH}
                                  className={classNames(dig.digPrimaryButton, dig.ldMatch)}>
                                Play in a Match
                            </Link>


                        </div>
                        <div className={classNames(dig.playingTypePics, dig.matches)}>
                            <LandingMatch/>
                        </div>
                    </div>
                </div>
            </section>
            <section className={classNames(dig.heroCol, dig.dFlex, dig.justifyContentCenter, dig.pt0, dig.mtAuto)}>
                <div className={classNames(
                    dig.w100, dig.discover, dig.callToAction
                )}>
                    <div className={dig.digLandingPageContent}>
                        <ul className={dig.callToAction}>
                            <li className={"register"}>Are you looking to run an advertisement, sponsor, or lead an
                                event?
                            </li>
                        </ul>
                        <div className={dig.digUserActionGroup} id={dig.digWaitlistMatchesSelectorGroup}>
                            <Button to={'/' + SUPPORT_PATH} className={classNames(dig.textCenter, dig.textWhite, dig.border0)}>Contact Us!</Button>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </>

}



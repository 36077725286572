import DropVariables from "src/variables/DropVariables";

export function CountDown(
    date: string,
    cb: (time: number) => void,
    timeoutMs: number = 1000): () => void {

    const counter = setInterval(() => {

        cb(DropVariables.unixTimeUntil(date));

    }, timeoutMs);

    return () => {
        clearInterval(counter);
    };

}

CountDown.displayName = 'CountDown';

import React, { useEffect, useState } from 'react';

interface svgCache {
    src: string,
    svg: string,
}

const customCacheSVGs : svgCache[] = [];

export default function SvgInline (props: {
    url: string,
    ignoreCache?: boolean,
} & React.HTMLAttributes<HTMLDivElement>){
    const [svg, setSvg] = useState<string|null>(null);

    useEffect(() => {

        const cacheSVG = customCacheSVGs.find(svg => svg.src === props.url);

        if (undefined !== cacheSVG) {
            setSvg(cacheSVG.svg)
            return ;
        }

        fetch(props.url)
            .then(res => {
                return res.text();
            })
            .then(res => {
                customCacheSVGs.push({
                    src: props.url,
                    svg: res
                })
                setSvg(res)
            })

    }, [props.url]);

    return (
        <div {...props} dangerouslySetInnerHTML={{ __html: svg ?? ''}}/>
    );
}
import classNames from "classnames";
import {useEffect} from "react";
import {getStyles} from "src/variables/DropVariables";
import {Link} from "react-router-dom";
import {PROFILE_PATH} from "src/views/Profile/Profile";
import {INVITATIONS_PATH} from "src/views/Profile/Invitations/Invitations";
import Bootstrap from "src/Bootstrap";
import {iInvitation} from "../../variables/sharedInterfaces";

interface iDisplayInvitation {
    invitations: iInvitation[],
}

export default function ProfileInvitationBanner(props: iDisplayInvitation) {

    const {invitations} = props;
    const {id} = Bootstrap.bootstrap.state;

    if (undefined === invitations) {

        return null;

    }

    useEffect(() => {})

    const dig = getStyles()

    const notificationColorClass = dig.digBgOrange;

    return <div className={classNames(dig.container, dig.p0)}>
        <div className={classNames("game-invites",dig.row, dig.mx0, dig.g0, dig.mb3,dig.p2, dig.gameInvites, notificationColorClass, dig.justifyContentCenter)}>
            <div className={classNames(dig.col6, dig.dFlex, dig.justifyContentCenter)}>

                <div className={classNames(dig.py3,dig.w100, dig.invitationInfos,dig.ml3, dig.flexColumn)}>
                    <span className={classNames( dig.fontSize1P2Em, dig.dFlex, dig.alignItemsCenter)}>
                        You have <span className={classNames(dig.fontSize1P5Em, dig.mx2)}> {invitations.length}</span> new invitations
                    </span>
                </div>
            </div>
            <div className={classNames(dig.col6, dig.dFlex, dig.justifyContentEnd, dig.alignItemsCenter)}>
                <Link
                    className={classNames(
                        dig.btn, dig.ggBgBlue, dig.fwBold, dig.me2, dig.umFriendAcceptBtn, dig.btnLg, dig.digTextWhite, dig.w125, dig.border2, dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter
                    )}
                    type="button" to={'/' + PROFILE_PATH + '/' + id + '/' + INVITATIONS_PATH + '/'}>
                    View
                </Link>
            </div>
        </div>
    </div>


}

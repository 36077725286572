import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import {C6, iOptions, options} from "src/variables/C6";
import Bootstrap from "src/Bootstrap";
import DropVariables from "src/variables/DropVariables";

export default restApi<{
    option_name: string,
}, iOptions, {}, iGetC6RestResponse<iOptions>>({
    axios: DropVariables.axios,
    tableName: options.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {
        return {
            [C6.WHERE]: {
                [options.OPTION_NAME]: request.option_name,
            }
        }
    },
    responseCallback: response => {

        let restData: iOptions[] = response?.data?.rest || [];

        Bootstrap.bootstrap.setState({
            options: restData
        });

    }
})

import classNames from "classnames";
import React from "react";
import {getStyles} from "src/variables/DropVariables";
import styles from "src/components/Wallet/style.module.scss";

export default class Amount extends React.Component<{
    paymentMethod: 'paypal' | 'nmi' | 'paydev' | 'check'
}, {
    monetaryValue: number,
}> {

    // billing has to handle NMI's BS. This is an easy way to do it.
    static amount: Amount | undefined = undefined;

    constructor(props) {
        super(props)
        Amount.amount = this;
        this.state = {
            monetaryValue: 5
        }
    }

    // changing dollar amounts, both input and button
    // TODO: make this shit use the db entries instead of being hardcoded
    processingFee(input: number) {
        switch (this.props.paymentMethod) {
            case "check":
                return 10;
            case "paypal":
                return parseFloat((input * .078 + .30).toFixed(2));
            case "nmi":
            default:
                return parseFloat((input * .05 + .30).toFixed(2));
        }

    }

    render() {

        const {monetaryValue} = this.state

        const dig = getStyles(styles)

        const dollarAmountButtons = [5, 10, 20, 50]

        const currentProcessingValue = this.processingFee(monetaryValue)

        return <>

            <div className={classNames(dig.ps0)}>

                <div className={classNames(dig.pe0)} id="dig-deposit-fee-table">

                    <div
                        className={classNames(dig.dFlex, dig.flexWrap, "dig-deposit-amounts-table", dig.mt3, dig.mb4)}
                        id="dig-deposit-amounts-table"
                        style={{marginTop: "3px !important"}}>

                        {
                            dollarAmountButtons.map((amount) => {
                                return <div key={amount} className={classNames(dig.dFlex, dig.flexColumn, dig.me2)}>
                                    <button type="button"
                                            className={classNames(dig.btn, dig.me2, dig.presetAmount, dig.fontSize1Em, dig.textWhite, dig.digBgNeutral3, dig.border0, {
                                                [dig.active]: monetaryValue === amount
                                            })}
                                            onClick={() => {
                                                this.setState({monetaryValue: amount})
                                            }}
                                            data-amount={amount}>${amount}
                                    </button>
                                    {amount === 5 && <small className={"font-size-1rem"}>Minimum</small>}
                                    {amount === 20 && <small className={"font-size-1rem"}>Recommended</small>}
                                </div>
                            })
                        }
                    </div>
                    <div className={classNames(dig.row, dig.dFlex, dig.alignItemsCenter)}
                         id="dig_add_funds_container">
                        <div className={classNames(dig.flexColumn, dig.ps0)} id="dig-deposit-funds">
                            <div id="dig-add-funds-group"
                                 className={classNames(dig.inputGroup, dig.inputGroupLg, dig.digAddFundsAmount)}>
                                <span className={dig.inputGroupText}>$</span>
                                <input type="number" inputMode="numeric"
                                       name="amount" id="amount"
                                       className={classNames(dig.formControl, "dig_add_funds_money_field")}
                                       min={5}
                                       value={monetaryValue}
                                       onChange={(e) => {
                                           this.setState({
                                               monetaryValue: e.target.valueAsNumber
                                           })
                                       }}
                                       aria-label="Amount (to the nearest dollar)"
                                       placeholder={`${monetaryValue}`}
                                       data-minimum-deposit="4.9900"/>
                            </div>
                        </div>
                        <div className={dig.colMd4} id="dig-processing-fee">
                            <div className={dig.digCostPanel}>
                                <label>Processing Fee <a type="button"
                                                         data-toggle="popover"
                                                         data-content="This is a fee associated with processing your payment method."
                                                         data-original-title=""
                                                         title="">
                                    </a></label>
                                <p id="billing_surcharge"
                                   className={"dig_billing_updatepanel"}>${parseFloat((currentProcessingValue).toFixed(2))}</p>

                            </div>
                        </div>
                        <div className={dig.colMd4} id="dig-total-fee">
                            <div className={dig.digCostPanel}>
                                <label>Total</label>
                                <p id="billing_total_charge"
                                   className={classNames("dig_billing_updatepanel total")}>{`$${(monetaryValue + currentProcessingValue).toFixed(2)}`}</p>
                            </div>
                        </div>
                    </div>

                    <div className={classNames(dig.digRequiredField, dig.pt2, dig.mt2)}
                         id="dig-minimum-deposit-amount">The minimum deposit amount is $4.99.
                    </div>

                    <div id="dig-wallet-payment-paypal" style={{display: "none"}}>
                        <div id="dig-wallet-payment-container">
                            <div id="paypal-button-container"
                                 style={{opacity: "0.5"}}>
                                <div
                                    id="zoid-paypal-buttons-uid_2f5170dacc_mdy6nti6ntu"
                                    className={classNames("paypal-buttons paypal-buttons-context-iframe paypal-buttons-label-unknown paypal-buttons-layout-vertical")}
                                    data-paypal-smart-button-version="5.0.315"
                                    style={{
                                        height: "0px",
                                        transition: "all 0.2s ease-in-out 0s"
                                    }}>

                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </>
    }


}
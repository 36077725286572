import restApi, {GET} from "src/api/API";
import {C6, iDig_Pool_Matchup} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iCompetitions} from "src/variables/sharedInterfaces";
import getCompetitions from "src/api/rest/getCompetitions";


export default restApi<{comp_id: number}, iDig_Pool_Matchup>({
    axios: DropVariables.axios,
    tableName: C6.dig_pool_matchup.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => ({
        [C6.SELECT]: [
            C6.dig_pool_matchup.COMP_ID,
            C6.dig_pool_matchup.POOL_ID,
            C6.dig_pool_matchup.ROUND_ID
        ],
        [C6.JOIN]: {
            [C6.INNER]: {
                [C6.dig_competitions.TABLE_NAME]: [
                    C6.dig_competitions.COMP_ID,
                    C6.dig_pool_matchup.COMP_ID
                ]
            },
        },
        [C6.WHERE]: {
            [C6.dig_competitions.PARENT_COMP]: request.comp_id
        },
        [C6.PAGINATION]: {
            [C6.ORDER]: {
                [C6.dig_pool_matchup.POOL_ID]: C6.ASC,
                [C6.dig_pool_matchup.ROUND_ID]: C6.ASC
            }
        }
    }),
    responseCallback: (response, request) => {

        let restData: iDig_Pool_Matchup[] = response?.data?.rest ?? [];

        console.warn('matchup rest data ', restData);

        let compIds : number[] = []

        restData.forEach((matchup) => {

            if (undefined !== matchup.comp_id && matchup.comp_id !== null) {

                compIds.push(matchup.comp_id)

            }

        });

        if (compIds.length > 0) {
            getCompetitions({competitionIds: compIds, fetchDependencies: true});
        }

        updateRestfulObjectArray<iCompetitions>([{
            comp_id: request.comp_id,
            round_robin_matchups: restData
        }], 'comp_id', 'competitions');

    }
})

import restApi, {DELETE, iDeleteC6RestResponse} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_User_Groups} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iGroups, iUser} from "src/variables/sharedInterfaces";

export default restApi<{
    userId: number,
    groupId: number
}, iDig_User_Groups, {}, iDeleteC6RestResponse>({
    axios: DropVariables.axios,
    tableName: C6.dig_user_groups.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success = 'Successfully deleted the group from the user.'
        request.error = 'Failed to remove the group from the user. Please try again later.'
        return {
            [C6.WHERE]: {
                [C6.dig_user_groups.GROUP_ID]: request.userId,
                [C6.dig_user_groups.USER_ID]: request.groupId,
            }
        }
    },
    responseCallback: (_response, request, id) => {

        id && updateRestfulObjectArray<iUser>((previousState) => {

            const groupToDeleteFromUser: iGroups | undefined = previousState.groups?.find((group: iGroups) => group.entity_id !== request.groupId);

            const fullUser: iUser | undefined = previousState.users?.find((user: iUser) => user.ID === request.userId);

            if (undefined === fullUser?.permissions) {
                throw new Error('Failed to parse user permissions')
            }

            let permissions = fullUser.permissions

            // adjust groups
            permissions.forEach(permisssion => {

                if (groupToDeleteFromUser?.organization_id !== permisssion.organization_id) {
                    return;
                }

                permisssion.group_name = permisssion.group_name?.filter((groupName) => groupName !== groupToDeleteFromUser?.group_name)

                const groupsLeft = previousState.groups?.filter((group: iGroups) => group.organization_id === permisssion.organization_id
                    && permisssion.group_name?.includes(group.group_name ?? ''))

                permisssion.feature_code = permisssion.group_name?.reduce((accumulator, groupName) => {

                    const group = groupsLeft?.find(group => group.group_name === groupName)

                    if (group?.feature_code) {
                        accumulator.push(...group.feature_code)
                    }

                    return accumulator;


                }, [] as any[])

            })

            return [{
                ID: fullUser.ID,
                permissions: permissions,
            }]

        }, "ID", "users")

    }
});



import classNames from "classnames";
import {Link} from "react-router-dom";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import isCOM from "src/variables/isCOM";
import GoogleTranslate from "src/components/GoogleTranslate/GoogleTranslate";
import {getStyles} from "src/variables/DropVariables";
import {ADMIN_OPTIONS_PATH} from "src/views/AdminOptions/AdminOptions";
// import {API_DOCUMENTATION_PATH} from "src/views/ApiDocumentation/ApiDocumentation";
//import {COMMUNITY_PATH} from "src/views/Community/Community";
import {IAM_PATH} from "src/views/IAM/IAM";
import {INTEL_PATH, RULES_PATH, PRIVACY_PATH, COOKIES_PATH, TERMS_PATH} from "src/views/Intel/Intel";
import {PARENTAL_PORTAL_PATH} from "src/views/ParentalPortal/ParentalPortal";
import {PROFILE_PATH} from "src/views/Profile/Profile";

// CSS
import FooterStyle from "./style.module.scss";
import {SUPPORT_PATH} from "src/views/Support/Support";


export default function Footer() {

    const dig = getStyles(FooterStyle);

    const iAm = getCurrentLoggedInUser();

    const iAmAdmin = iAm?.user_is_admin ?? false;

    // TODO: when it is time, restore below as the first entry to dig.digFooterMenu
    /*
        <li className={classNames(dig.menuItem)}>
            <Link to={"/" + COMMUNITY_PATH + "/"}>
                Community
            </Link>
        </li>
     */
    return (
        <footer className={classNames(dig.mtAuto, dig.dFlex, dig.justifyContentCenter, dig.w100, dig.p0)}>
            <div className={classNames(dig.digFooter, dig.p0, dig.pt4, dig.pLg5)}>
                <div className={classNames(dig.row, dig.alignContentCenter, dig.textCenter)}>
                    <div className={dig.colMd3}>
                        <h2>GAMER RESOURCES</h2>
                        <ul className={dig.digFooterMenu}>
                            {iAm && <li className={classNames(dig.menuItem)}>
                                <Link to={"/" + PROFILE_PATH + "/"}>
                                    Gamer Profile
                                </Link>
                            </li>}
                            <li className={classNames(dig.menuItem)}>
                                <Link to={'/' + INTEL_PATH + '/' + RULES_PATH}>
                                    Game Rules
                                </Link>
                            </li>
                            <li id="menu-item-8781"
                                className={classNames(dig.menuItem)}>
                                <Link to={'/' + INTEL_PATH}>
                                    Gamer Articles
                                </Link>
                            </li>
                            <li id="menu-item-11012"
                                className={classNames(dig.menuItem)}>
                                <a href="https://www.youtube.com/playlist?list=PL0P-3OPDX7CBE590mG3aUriDiPfbe16-O">
                                    How-To Videos
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={dig.colMd3}>
                        <h2>THE SMALL PRINT</h2>
                        <ul id="menu-footer-middle" className={classNames(dig.digFooterMenu)}>
                            <li className={classNames(dig.menuItem)}>
                                <Link to={"/" + INTEL_PATH + "/" + TERMS_PATH}>
                                    Terms and Conditions
                                </Link>
                            </li>
                            <li className={classNames(dig.menuItem)}>
                                <Link to={"/" + INTEL_PATH + "/" + PRIVACY_PATH}>
                                    Privacy
                                </Link>
                            </li>
                            <li className={dig.menuItem}>
                                <Link to={"/" + INTEL_PATH + "/" + COOKIES_PATH}>
                                    Cookies
                                </Link>
                            </li>
                        </ul>
                    </div>
                    <div className={dig.colMd3}>
                        <h2>COMPANY</h2>
                        <ul id="menu-footer-right" className={dig.digFooterMenu}>
                            <li className={dig.menuItem}>
                                <a href={'https://dropingaming.co/'}>
                                    About DropIn Gaming
                                </a>
                            </li>
                            <li className={dig.menuItem}>
                                <Link to={'/' + INTEL_PATH}>
                                    Intel
                                </Link>
                            </li>
                            <li className={dig.menuItem}>
                                <a href={'https://dropingaming.co/careers'}>
                                    Careers
                                </a>
                            </li>
                            <li className={dig.menuItem}>
                                <Link to={'/' + INTEL_PATH}>
                                    Press Releases
                                </Link>
                            </li>
                            {/* TODO - Parental portal */}
                            {(false === isCOM()) && <li className={dig.menuItem}>
                                <Link to={'/' + PARENTAL_PORTAL_PATH}>
                                    Parental Portal
                                </Link>
                            </li>}

                            {iAmAdmin && <><li className={dig.menuItem}>
                                <Link to={"/" + IAM_PATH + "/"}>
                                    IAM (Identity and Access Management)
                                </Link>
                            </li>
                                <li className={dig.menuItem}>
                                    <Link to={"/" + ADMIN_OPTIONS_PATH + "/"}>
                                        Admin Options
                                    </Link>
                                </li>
                            </>}
                        </ul>
                    </div>
                    <div className={classNames(dig.colMd3, dig.contactCol)}>
                        <h2>CONTACT</h2>
                        <ul className={dig.digFooterMenu}>
                            <li className={dig.menuItem}>
                                <i className={classNames("fas fa-map-marker-alt")}/>
                                <a href="https://www.google.com/maps/place/19363+Willamette+Dr+Suite+108+West+linn,+OR+97068">
                                    19363 Willamette Dr, Suite 108
                                    <br/>
                                    West linn, OR 97068
                                </a>

                            </li>
                            <li className={dig.menuItem}>
                                <i className={classNames("far fa-envelope")}/>
                                <Link to={"/" + SUPPORT_PATH + "/"}>
                                    support@dropingaming.com
                                </Link>
                            </li>
                            <li className={dig.menuItem}>
                                <i className={classNames("fas fa-mobile-alt")}/>
                                <a href="tel:1-866-376-7464">1-866-DROPIN4</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={classNames(dig.row, dig.digFooterMenuGrid, dig.digSecondRow, dig.dFlex, dig.alignItemsCenter)}>
                    <div className={classNames(dig.colMd7, dig.pt5, dig.ptLg0)}>
                        <div id="dig-social-media" className={classNames(dig.digSocial)}>
                            <a href="https://www.twitch.tv/dropingaming" target="_blank" rel="noreferrer">
                                <i className={classNames("fab fa-twitch")}/>
                            </a>
                            <a href="https://discord.gg/rhCQgHkNg3" target="_blank" rel="noreferrer">
                                <i className={classNames("fab fa-discord")}/>
                            </a>
                            <a href="https://www.instagram.com/dropingamingllc/" target="_blank" rel="noreferrer">
                                <i className={classNames("fab fa-instagram")}/>
                            </a>
                            <a href="https://www.youtube.com/channel/UCSUf54jwrIzFMpVT4iK9xjg" target="_blank"
                               rel="noreferrer">
                                <i className={classNames("fab fa-youtube-square")}/>
                            </a>
                            <a href="https://twitter.com/dropingamingllc" target="_blank" rel="noreferrer">
                                <i className={classNames("fab fa-twitter")}/>
                            </a>
                            <a href="https://www.facebook.com/dropingaming/" target="_blank" rel="noreferrer">
                                <i className={classNames("fab fa-facebook-square")}/>
                            </a>
                        </div>
                    </div>
                    <div className={dig.colMd5}>
                        <p className={dig.footerSlogan}>
                            <i>WHERE WE DROPPIN&apos;?</i>
                        </p>
                        <p> Translate this page in
                            your preferred language:
                        </p>
                        <GoogleTranslate />
                        <p> You can translate the content of this
                            page by selecting a language in the
                            select box.
                        </p>
                    </div>
                </div>
                <div className={classNames(dig.row, dig.digFooterMenuGrid)}>
                    <div className={classNames(dig.colMd12, dig.px5)}>
                        <p className={dig.copyright}>
                            © 2021 Drop-In Gaming, all rights reserved. Drop-In
                            Gaming LLC is not endorsed by, affiliated with, maintained by, or sponsored by Take-Two
                            Interactive Software, Inc., Electronic Arts Inc., Activision Blizzard, Inc., Epic Games,
                            Inc, and all corporations that publish the games played in tournaments and related names
                            (e.g., Microsoft Corporation, Sony Corporation, Apple Inc., Nintendo Company, Ltd., Xbox,
                            Playstation, etc.). All copyrights, trademarks, content, game titles, trade names, trade
                            dress, artwork, imagery, and other rights are the trademark, copyrighted material, or
                            other property of their respective owners. All rights reserved.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );

}

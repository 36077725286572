import classNames from "classnames";
import React from "react";
import {Link} from "react-router-dom";
import CompetitionSort from "src/api/hoc/CompetitionSort";
import getCompetitions from "src/api/rest/getCompetitions";
import Bootstrap from "src/Bootstrap";
import CompetitionGrid from "src/components/CompetitionGrid/CompetitionGrid";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import Tabs from "src/components/Tabs/Tabs";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, eCOMPETITION_TIMING, iCategories, iCompetitions} from "src/variables/sharedInterfaces";
import moment from "moment";
import getTournamentCategories from "src/api/rest/getTournamentCategories";
import isJsonString from "../../variables/isJsonString";
import Popup from "src/components/Popup/Popup";
import CategoryPopup from "./Modal/Popup";

enum eGAME_PAGE_TABS {
    ALL,
    BRACKETS,
    FREE
}

export const CATEGORY_PATH = 'category';

// core components
export default class Category extends React.Component<WithRouter, {
    category: eCOMPETITION,
    subCategory: eGAME_PAGE_TABS,
    createPopup: boolean,
}> {
    constructor(props) {
        super(props);

        this.state = {
            category: eCOMPETITION.TOURNAMENTS,
            subCategory: eGAME_PAGE_TABS.ALL,
            createPopup: false,
        }

    }

    componentDidMount() {

        const {categories, games, competitions} = Bootstrap.bootstrap.state;



        if (null === categories || null === games || null === competitions) {
            return;
        }

        if (undefined === categories) {

            getTournamentCategories();

        }

        // Get the current category
        const currentCategory: iCategories | undefined = categories?.find(x => parseInt(this.props.params.id) === x.id)

        const categoryMeta = isJsonString(currentCategory?.metadata) ? JSON.parse(currentCategory?.metadata as string) : currentCategory?.metadata;

        if(categoryMeta?.popup.show_popup === '1'){
            this.setState({
                createPopup: true

            })

        }

        const quickGetCompetitions = (competition: eCOMPETITION) => getCompetitions({
            competition: competition,
            timing: eCOMPETITION_TIMING.ALL
        });

        const displayable = competitions?.filter(comp =>
            comp.comp_type_id !== eCOMPETITION.MATCHES &&
            comp.status !== eCOMPETITION_STATUS.CANCELED &&
            comp.status !== eCOMPETITION_STATUS.COMPLETED &&
            comp.status !== eCOMPETITION_STATUS.PAID &&
            moment(comp.datetime).isSameOrAfter(moment())
        )

        if (undefined === displayable || displayable.filter(comp => comp.comp_type_id === eCOMPETITION.TOURNAMENTS).length < 20) {

            quickGetCompetitions(eCOMPETITION.TOURNAMENTS)

        }

        if (undefined === displayable || displayable.filter(comp => comp.comp_type_id === eCOMPETITION.ROUND_ROBIN).length < 20) {

            quickGetCompetitions(eCOMPETITION.ROUND_ROBIN)

        }

        if (undefined === displayable || displayable.filter(value => value.comp_type_id === eCOMPETITION.BRACKETS).length < 20) {

            quickGetCompetitions(eCOMPETITION.BRACKETS)

        }

    }

    switchSubCategory = (c: eGAME_PAGE_TABS) => this.setState({subCategory: c})

    switchCategory = (c: eCOMPETITION) => this.setState({category: c})

    handleClose = () => {
        this.setState({
            createPopup: !this.state.createPopup
        })
    }

    render() {

        const {categories, competitions} = Bootstrap.bootstrap.state;

        const currentCatID: number = parseInt(this.props.params.id);
        const currentCategory: iCategories | undefined = categories?.find(x => currentCatID === x.id)
        const currentCategoryParent: iCategories | undefined = categories?.find(x => currentCategory?.parent === x.id)

        const categoryMeta = isJsonString(currentCategory?.metadata) ? JSON.parse(currentCategory?.metadata as string) : currentCategory?.metadata;

        if (undefined === currentCategory ||
            DropVariables.undefinedOrNull(competitions)) {

            return <Loading message={"Loading current category!"}/>

        }

        let currentCompetitions: iCompetitions[] = competitions?.filter(comp =>
            comp.categories?.toLowerCase().includes(currentCategory.category_name?.toLowerCase() || '') &&
            comp.comp_type_id !== eCOMPETITION.MATCHES &&
            comp.status !== eCOMPETITION_STATUS.CANCELED &&
            comp.status !== eCOMPETITION_STATUS.COMPLETED &&
            comp.status !== eCOMPETITION_STATUS.PAID
            //&& moment(comp.datetime).isSameOrAfter(moment())
        ) ?? [];

        console.log('currentCompetitions',currentCompetitions)

        if (eCOMPETITION.MATCHES === this.state.category) {

            currentCompetitions = currentCompetitions.filter(value => value.comp_type_id === eCOMPETITION.MATCHES)

        } else {

            switch (this.state.subCategory) {

                case eGAME_PAGE_TABS.ALL:

                    currentCompetitions = currentCompetitions.filter(value => value.comp_type_id !== eCOMPETITION.MATCHES)


                    break;

                case eGAME_PAGE_TABS.BRACKETS:

                    currentCompetitions = currentCompetitions.filter(value => value.comp_type_id === eCOMPETITION.BRACKETS)

                    break;

                case eGAME_PAGE_TABS.FREE:

                    currentCompetitions = currentCompetitions
                        .filter((value) => '0.0000' === value.buy_in)


                    break;

            }

        }



        currentCompetitions
            .sort(CompetitionSort.sortCompetitionsMoreRegistrationsFirst)
            .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.PAID))




        const dig = getStyles()

        return <div className={classNames(dig.digUserActionGroup, dig.upcomingEvents)}>
            {/*<!-- Card deck -->*/}
            <div className={classNames(
                dig.row, dig.g0, dig.positionRelative, dig.gameHeroHeaderContainer,
            )}
                 style={{backgroundImage: "url(" + currentCategory.header_background_image + ")"}}>
                <div className={classNames(dig.gameHeroHeader, dig.col12)} style={{flex: "0 0 400px"}}>
                    <div className={classNames(dig.row, dig.dFlex, dig.justifyContentCenter)}>
                        <div className={classNames(dig.col8, dig.colLg2)}>
                            <img width="100%" alt="Featured image"
                                 src={currentCategory.featured_image}
                                 data-ll-status="loading"/>
                            <Link
                                className={classNames(dig.dFlex, dig.justifyContentCenter, dig.digBtnBlue,
                                    dig.fwBold, dig.textUppercase, dig.alignItemsCenter, dig.backToGames
                                )} to={"/categories/" + currentCategoryParent?.category_code}>
                                <i className={classNames(classNames("fas fa-chevron-left", dig.me1))}></i>
                                Back to {currentCategoryParent?.category_name}
                            </Link>
                        </div>
                        <div className={classNames(
                            dig.col12, dig.colLg10, dig.dFlex, dig.flexColumn, dig.justifyContentEnd
                        )}>
                            <div className={classNames(dig.gameTitle, dig.mb4)}>
                                <h1 className={classNames(
                                    dig.fwBolder, dig.textWhite, dig.mb0, dig.mt0, dig.fontSize3Em
                                )}>
                                    {currentCategory.category_name} </h1>
                                <h4 className={classNames(
                                    dig.fwBold, dig.textWhite, dig.alignContentStart,
                                    dig.dFlex, dig.alignItemsCenter
                                )}>
                                    <i title="PC" className={classNames("fas fa-desktop font-size-1em mr-2 game_platforms_icon")}></i>
                                    <i title="Playstation"
                                       className={classNames("fab fa-playstation mr-2 font-size-1em game_platforms_icon")}></i>
                                    <i title="Xbox" className={classNames("fab fa-xbox font-size-1em mr-2 game_platforms_icon")}></i>
                                    <i className={classNames("fas fa-random font-size-1em game_platforms_icon")}
                                       title="Crossplay"></i>
                                </h4>
                            </div>
                            <div className={dig.gamesDescription}>
                                <p>{currentCategory.description}</p>
                            </div>
                            <Tabs tabs={[eCOMPETITION[eCOMPETITION.TOURNAMENTS], eCOMPETITION[eCOMPETITION.MATCHES]]}
                                  activeTab={eCOMPETITION[this.state.category]}
                                  onTabClick={(tab) => this.switchCategory(eCOMPETITION[tab])}/>

                        </div>
                    </div>
                </div>
            </div>

            {this.state.category === eCOMPETITION.TOURNAMENTS
            && <div className={classNames(dig.tabContent, dig.mtSm1, dig.mtMd4)}>
                <div role="tabpanel" aria-labelledby="tournament-tab"
                     className={classNames(dig.tabPane, dig.fade, dig.gamesDashboard, dig.active, dig.show)}>
                    {/*<!-- Sub tabs -->*/}
                    <Tabs tabs={[eGAME_PAGE_TABS[eGAME_PAGE_TABS.ALL], eGAME_PAGE_TABS[eGAME_PAGE_TABS.BRACKETS], eGAME_PAGE_TABS[eGAME_PAGE_TABS.FREE]]}
                          activeTab={eGAME_PAGE_TABS[this.state.subCategory]}
                          onTabClick={(tab) => this.switchSubCategory(eGAME_PAGE_TABS[tab])}/>
                </div>
            </div>}

            <CompetitionGrid competitionsToDisplay={currentCompetitions}
                             displaySort={true}
            />


            <Popup open={this.state.createPopup} handleClose={this.handleClose}>
                <CategoryPopup {...this.props} popupData={categoryMeta?.popup} handleClose={this.handleClose}/>
            </Popup>


            {/*<!--\ Card deck -->*/}
        </div>

    }
}



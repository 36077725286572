import restApi, {DELETE, iDeleteC6RestResponse} from "src/api/API";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";
import {C6, iDig_Comp_Invitation, iDig_Competitions, iDig_Team_Roster} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iCompetitions, iTeam} from "src/variables/sharedInterfaces";
import {iRegistrations} from "src/Bootstrap";
import getUsers from "./getUsers";
import isTest from "../../variables/isTest";

// Used when denying an invitation or withdrawing from a comp
export interface iDeleteRoster extends iDig_Team_Roster {
    uid: number,
    comp_id?: number,
    invitation_id?: number,
    user_team_id: number
}

export function deleteTeamRosterCallback(request) {
    deleteRestfulObjectArrays<iDig_Comp_Invitation>(prev => {
        return prev.invitations?.filter(invitation => invitation.user_id === request.uid && invitation.team_id === request.user_team_id) ?? []
    }, "invitation_id", "invitations");

    updateRestfulObjectArray<iTeam>((prev) => {
        const prevTeam = prev.teams?.find(team => team.user_team_id === request.user_team_id);

        if (prevTeam === undefined) {
            return [];
        }

        const preRosterPending = prevTeam?.rosterPending;
        const preRosterAccepted = prevTeam?.rosterAccepted;

        return [{
            user_team_id: request.user_team_id,
            rosterAccepted: preRosterAccepted?.filter(uid => uid !== request.uid) ?? [],
            rosterPending: preRosterPending?.filter(uid => uid !== request.uid) ?? [],
            rosterDetails: prevTeam?.rosterDetails?.filter(roster => roster.uid !== request.uid) ?? []
        }]
    }, 'user_team_id', 'teams');

    if (request.comp_id !== undefined) {
        // update registrations and competition
        deleteRestfulObjectArrays<iRegistrations>(prev => {
            return prev.registrations?.filter(registration => registration.competitions_id === request.comp_id
                && registration.user_id === request.uid) ?? [];
        }, "comp_registration_id", "registrations");

        updateRestfulObjectArray<iCompetitions>((prev) => {

            // we'll update this entry and return it
            let prevComp = prev.competitions?.find(competition => competition.comp_id === request.comp_id);

            if (prevComp === undefined) {
                return [];
            }

            prevComp.complete_teams = prevComp?.complete_teams?.filter(team => team !== request.user_team_id) ?? []

            if ((prevComp.team_size ?? 0) > 1 && !prevComp.incomplete_teams?.includes(request.user_team_id)) {
                prevComp.incomplete_teams ??= [];

                prevComp.incomplete_teams.push(request.user_team_id);
            }

            prevComp.registered_users = prevComp?.registered_users?.filter(user => user !== request.uid) ?? []


            return [prevComp];
        }, "comp_id", "competitions");
    }
}

export default restApi<iDeleteRoster, iDig_Team_Roster & iDig_Competitions & iDig_Comp_Invitation, {}, iDeleteC6RestResponse>({
    axios: DropVariables.axios,
    tableName: [C6.dig_team_roster.TABLE_NAME, C6.dig_competitions.TABLE_NAME, C6.dig_comp_invitation.TABLE_NAME],
    requestMethod: DELETE,
    skipPrimaryCheck: true,
    queryCallback: (request) => {
        // success message will depend on args received
        request.success ??= 'Successfully removed roster!'
        request.error ??= 'An unknown issue occurred during your competition registration.'
        return request
    },
    responseCallback: (_response, request) => {

        deleteTeamRosterCallback(request);

        if (request.comp_id !== undefined && false === isTest) {
            // re-fetch user for updated wallet
            getUsers({
                userIds: [
                    request.uid
                ],
                cacheResults: false,
            })
        }

    }
});


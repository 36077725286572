import React from "react";
import getTeams, {iGetTeams} from "src/api/rest/getTeams";

import {ReactComponent as TeamSVG} from "src/assets/img/svg-icons/team_profile.svg"

import {Link} from "react-router-dom";
import Bootstrap from "src/Bootstrap";
import DropDown from "src/components/DropDown/DropDown";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import Popup from "src/components/Popup/Popup";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import DisbandTeam from "src/views/Profile/Teams/Modals/DisbandTeam";
import CreateTeam from "src/views/Profile/Teams/Modals/CreateTeam";
import {TEAM_PATH} from "src/views/Team/Team";
import classNames from "classnames";

interface iTeamProps extends WithRouter {
    userId?: number,
    teamIds?: number[]
}

export const TEAMS_PATH = 'teams'

// core components
export default class Teams extends React.Component<iTeamProps, {
    disbandTeamPopup: boolean,
    createTeamPopup: boolean,
    selectedTeam?: number,
    selectedTeamSize?: number,
}> {
    constructor(props) {
        super(props);
    }

    state = {
        disbandTeamPopup: false,
        createTeamPopup: false,
        selectedTeam: undefined,
        selectedTeamSize: undefined
    }

    componentDidMount() {

        const {teams, id} = Bootstrap.bootstrap.state

        if (undefined === teams) {

            let params: iGetTeams = {};

            if (undefined !== this.props.userId) {
                params.userId = this.props.userId;
            } else if (undefined !== this.props.teamIds) {
                params.teamIds = this.props.teamIds;
            } else {
                params.userId = id;
            }

            getTeams(params);

        }

    }

    handleDisbandTeam = (teamId: number | undefined = undefined) => {
        this.setState({
            disbandTeamPopup: !this.state.disbandTeamPopup,
            selectedTeam: teamId
        })

    }

    handleCreateTeam = () => this.setState({
        createTeamPopup: !this.state.createTeamPopup
    })

    render() {

        const {teams, users} = Bootstrap.bootstrap.state

        const filteredTeams = teams?.filter(team => team.team_size_id === this.state.selectedTeamSize);

        const dig = getStyles()

        return <div className={classNames(
            dig.containerFluid, dig.pb5, dig.my2, dig.mbMd4, dig.m0, dig.p0
        )}>
            {this.state.selectedTeam && <Popup
                handleClose={this.handleDisbandTeam}
                open={this.state.disbandTeamPopup}
            >
                <DisbandTeam handleClose={this.handleDisbandTeam} teamId={this.state.selectedTeam}/>
            </Popup>}

            <Popup open={this.state.createTeamPopup} handleClose={this.handleCreateTeam}>
                <CreateTeam {...this.props} handleClose={this.handleCreateTeam}/>
            </Popup>
            <form className={classNames(dig.row, dig.my4, "form-team-search")} method="GET">
                <div
                    className={classNames(dig.col12, dig.colLg6, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.mb3)}>
                    <DropDown onChange={(value) => {
                        let size = value === 'Solos' ? 1 : (value === 'Duos' ? 2 : (value === 'Trios' ? 3 : (value === 'Squads' ? 4 : (value === 'Fives' ? 5 : 0))));
                        this.setState({selectedTeamSize: size})
                    }}
                              defaultValue={"All Sizes"}
                              options={['Solos', 'Duos', 'Trios', 'Squads', 'Fives']}/>
                </div>
                <div
                    className={classNames(dig.col12, dig.colLg6, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.mb3)}>
                    <a
                        onClick={() => this.setState({selectedTeamSize: undefined})}
                        className={classNames(dig.btn, dig.btnLg, dig.textWhite, dig.py2, dig.px4, dig.border0, dig.rounded0, dig.fwBold, dig.fontSize1Em, dig.digBgNeutral3, "reset_search")}>
                        RESET
                    </a>
                    <button
                        onClick={() => this.handleCreateTeam()}
                        type="button"
                        className={classNames("add-new-team", dig.btn, dig.fwBold, dig.btnLg, dig.textWhite, dig.py2, dig.px4, dig.digBtnBlue, dig.border0, dig.rounded0, dig.fontSize1Em)}>
                        <i className={classNames(dig.fas, "fa-plus", dig.me3)}></i>
                        ADD TEAM
                    </button>
                </div>
            </form>
            <div className={classNames(dig.row, dig.mx0, dig.mb3)}>
                <div className={classNames(dig.col12, dig.borderColorNeutral2, dig.borderBottom2)}></div>
            </div>
            <div className={classNames(dig.row, dig.pt3)}>
                {
                    this.state.selectedTeamSize !== undefined
                    &&  filteredTeams?.length === 0
                    && <h2>{`Sorry, we couldn't find any results`}</h2>
                }
                {
                    (this.state.selectedTeamSize === undefined ? teams : filteredTeams)
                        ?.map((team, key) => {
                            return <div key={key}
                                        className={classNames(dig.colLg3, dig.colMd4, dig.colSm6, dig.mb5, dig.myTeamsCard)}>
                                <div className={classNames(dig.card, dig.border0, dig.rounded0)}>
                                    <div
                                        className={classNames(dig.cardHeader, dig.dFlex, dig.justifyContentBetween, dig.digBgNeutral2, dig.border0, dig.rounded0)}>
                                        <div className={dig.matchTitle}>
                                            <i className={"match-icon"}>
                                                <TeamSVG/>
                                            </i>
                                            <h4 className={classNames(dig.digLightBlue, dig.fwBold)}>
                                                Team
                                            </h4>
                                        </div>
                                        <div
                                            className={classNames(dig.matchTitle, dig.digLightBlue, dig.dFlex, dig.alignItemsCenter, dig.fwBold)}>
                                    <span
                                        className={"mr-2"}>{DropVariables.teamSizeToReadableText(team.team_size_id)}</span>
                                        </div>
                                    </div>
                                    <div
                                        className={classNames(dig.py3, dig.myTeamImg, dig.overflowHidden, dig.dFlex, dig.w100, dig.justifyContentCenter, dig.digBgNeutral2)}>
                                        <TeamSVG/>
                                    </div>

                                    <div
                                        className={classNames(dig.cardBody, dig.py2, dig.digBgNeutral2, dig.dFlex, dig.flexColumn, dig.alignItemsCenter)}>
                                        <div className={dig.dFlex}>
                                    {/*<span*/}
                                    {/*    className={classNames(dig.digLightBlue, dig.fwBold, dig.me4)}>WIN: {team.win || 0}</span>*/}
                                    {/*        <span*/}
                                    {/*            className={classNames(dig.digLightRed, dig.fwBold)}>LOSS: {team.lose || 0}</span>*/}
                                        </div>
                                        <h4 className={classNames("font-size-me", dig.textWhite, "min-height-70px", dig.dFlex, dig.alignItemsCenter)}>
                                            <Link className={classNames(dig.textWhite, dig.textBreak)}
                                                  to={"/" + TEAM_PATH + "/" + team.user_team_id}>
                                                {team.team_name} </Link>
                                        </h4>
                                        <div
                                            className={classNames(dig.dFlex, dig.alignItemsCenter, dig.flexColumn, dig.minHeight90Px)}>

                                            {[...(team?.rosterAccepted ?? []), ...(team?.rosterPending ?? [])].map((member, key) => <div
                                                key={key} className={classNames(dig.dBlock, dig.digTrophies2)}>
                                                {
                                                    (() => {

                                                        const Account = users?.find(user => user.ID === member)
                                                        return <>{Account?.display_name || member} {team.uid === member ?
                                                            <span
                                                                className={classNames(dig.badge, dig.digBgNeutral6, dig.p2, dig.me2)}>Manager</span> : ''}</>;
                                                    })()}
                                            </div>)}

                                        </div>
                                    </div>
                                    <div
                                        className={classNames(dig.cardFooter, dig.border0, dig.rounded0, dig.p0, dig.dFlex, dig.alignItemsCenter, dig.justifyContentEvenly)}>
                                        <div className={classNames(dig.digCompStackableContainer, dig.available)}>
                                            <Link
                                                className={classNames(dig.borderColorNeutral6, dig.digTextWhite, dig.digBtnBlue, dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter, "dig-comp-view-match", dig.textWhite)}
                                                to={"/" + TEAM_PATH + "/" + team.user_team_id}>
                                                Manage
                                            </Link>
                                        </div>
                                        <div className={classNames(dig.digCompStackableContainer, dig.view, dig.secondary)}>
                                            <a
                                                className={classNames(dig.cursorPointer, dig.borderColorNeutral6, dig.digCompViewMatch, dig.digTextWhite, dig.digBgOrange, dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter, "hide-team")}
                                                onClick={() => this.handleDisbandTeam(team.user_team_id)}
                                            >
                                                {team.hide === 0 ? 'Hide' : 'Unhide'} </a>
                                        </div>
                                        <div className={classNames(dig.digCompStackableContainer, dig.view, dig.secondary)}>
                                            <a className={classNames(dig.cursorPointer, dig.borderColorNeutral6, dig.digCompViewMatch, dig.digTextWhite, dig.digBtnLightRed, dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter, "disband-btn"
                                            )}
                                               data-team_name="GSQAD" data-team_id="117"
                                               onClick={() => this.handleDisbandTeam(team.user_team_id)}>
                                                Disband
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                    || <Loading message={"Loading Teams"}/>
                }


            </div>
        </div>

    }
}

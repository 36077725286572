import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import styles from "src/components/Wallet/style.module.scss";
import putTeams from "src/api/rest/putTeams";
import Bootstrap from "src/Bootstrap";

interface iTournamentProperties {
    handleClose: () => void,
    teamId: number
}

export default function DisbandTeam(props: iTournamentProperties) {

    const dig = getStyles(styles)

    const {teamId} = props;

    const {teams} = Bootstrap.bootstrap.state;

    const team = teams?.find(team => team.user_team_id === teamId);

    const handleUpdate = () => {

        putTeams({
            user_team_id: teamId,
            hide: team?.hide === 0 ? 1 : 0
        });

        (() => {
            setTimeout(function() {
                props.handleClose();
            }, 1000);
        })();
    }

    return <div className={classNames("model-content", dig.rounded0, dig.border0)}>
        <div className={classNames(dig.modalHeader, dig.rounded0, dig.border0, dig.digBgCardHeader)}>
            <h3 className={classNames(dig.modalTitle, dig.textDark)} id="staticBackdropLabel">Disband Team</h3>
        </div>
        <div className={classNames(dig.modalBody, dig.border0, dig.digBgNeutral8, dig.digTextWhite)}>
            <div className={dig.textCenter}>Are you sure you want to delete the <strong>{team?.team_name}</strong> team permanently?
                This action cannot be reverted.
            </div>
        </div>
        <div className={classNames(dig.modalFooter, dig.digBgNeutral7, dig.border0, dig.rounded0)}>
            <a className={classNames(dig.btn, dig.btnLg, dig.digBtnGreen, "btn-Yes", dig.rounded0)}
               onClick={handleUpdate}>Yes</a>
            <a className={classNames(dig.btn, dig.btnLg, dig.digBtnRed, "btn-no", dig.rounded0)} onClick={props.handleClose}>No</a>
        </div>
    </div>

}



import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Features, iDig_Group_References, iDig_Groups} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iGroups} from "src/variables/sharedInterfaces";
import Bootstrap from "../../Bootstrap";


export default restApi<{}, iDig_Groups, {}, iGetC6RestResponse<iDig_Groups & iDig_Features & iDig_Group_References, {
    allowed_to_grant_group_id: string,
    feature_entity_id: string,
    feature_code: string,
}>>({
    axios: DropVariables.axios,
    tableName: C6.dig_groups.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {
        request.error = 'An unknown issue occurred fetching groups!'
        return {
            [C6.SELECT]: [
                C6.dig_groups.ENTITY_ID,
                C6.dig_groups.GROUP_NAME,
                C6.dig_organizations.ORGANIZATION_ID,
                [C6.GROUP_CONCAT, C6.dig_features.FEATURE_CODE],
                [C6.GROUP_CONCAT, C6.dig_features.FEATURE_ENTITY_ID],
                [C6.GROUP_CONCAT, C6.dig_group_references.ALLOWED_TO_GRANT_GROUP_ID]
            ],
            [C6.JOIN]: {
                [C6.LEFT]: {
                    [C6.dig_organizations.TABLE_NAME]: [
                        C6.dig_organizations.ORGANIZATION_ID,
                        C6.dig_groups.ORGANIZATION_ID
                    ],
                    [C6.dig_group_references.TABLE_NAME]: [
                        C6.dig_group_references.GROUP_ID,
                        C6.dig_groups.ENTITY_ID
                    ],
                    [C6.dig_feature_group_references.TABLE_NAME]: [
                        C6.dig_feature_group_references.GROUP_ENTITY_ID,
                        C6.dig_groups.ENTITY_ID,
                    ],
                    [C6.dig_features.TABLE_NAME]: [
                        C6.dig_features.FEATURE_ENTITY_ID,
                        C6.dig_feature_group_references.FEATURE_ENTITY_ID
                    ]
                }
            },
            [C6.GROUP_BY]: [
                C6.dig_groups.ENTITY_ID,
                C6.dig_organizations.ORGANIZATION_ID
            ],
            [C6.PAGINATION]: {
                [C6.LIMIT]: 100
            }
        }
    },
    responseCallback: (response, _request) => {

        console.log('response:', response);

        let groups: iGroups[] = response?.data?.rest.map((group) => {
            return {
                entity_id: group.entity_id,
                group_name: group.group_name,
                organization_id: group.organization_id,
                feature_code: group.feature_code?.split(','),
                feature_entity_id: group.feature_entity_id?.split(',').map((id) => parseInt(id)),
                allowed_to_grant_group_id: group.allowed_to_grant_group_id?.split(',').map((id) => parseInt(id))
            }
        }) ?? [];

        updateRestfulObjectArray<iGroups>(groups, "entity_id", "groups")

        console.log('bootstrap.state.groups:', Bootstrap.bootstrap.state.groups);

    }

})

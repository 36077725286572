import restApi, {GET} from "src/api/API";
import {C6, iDig_Categories} from "src/variables/C6";
import Bootstrap from "src/Bootstrap";
import DropVariables from "src/variables/DropVariables";
import {iCategories} from "src/variables/sharedInterfaces";

export default restApi<{}, iDig_Categories>({
        axios: DropVariables.axios,
        tableName: C6.dig_categories.TABLE_NAME,
        requestMethod: GET,
        queryCallback: {
            [C6.SELECT]: [
                C6.dig_categories.ID,
                C6.dig_categories.CATEGORY_NAME,
                C6.dig_categories.CATEGORY_CODE,
                C6.dig_categories.DESCRIPTION,
                C6.dig_categories.CAT_ORDER,
                C6.dig_categories.PARENT,
                C6.dig_categories.HEADER_BACKGROUND_IMAGE,
                C6.dig_categories.FEATURED_IMAGE,
                C6.dig_categories.CARD_IMAGE,
                C6.dig_categories.METADATA,
                C6.dig_categories.CREATED,
                C6.dig_categories.UPDATED,
            ],
            [C6.PAGINATION]: {
                [C6.ORDER]: {
                    [C6.dig_categories.CAT_ORDER]: C6.ASC
                },
                [C6.LIMIT]: 100
            }
        },
        responseCallback: (response, _request) => {

            // any is the type for the map as we have not cast
            const categories: iCategories[] = response.data?.rest || []

            Bootstrap.bootstrap.setState({
                categories
            })

        }

    })



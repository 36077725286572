import {Link} from "react-router-dom";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import {ReactComponent as DigCoin} from "src/assets/img/svg-icons/dig_coin.svg";
import Bootstrap from "src/Bootstrap";
import ProfilePicture from "src/components/ProfilePicture/ProfilePicture";
import isGG from "src/variables/isGG";
import {iUser, iSubscription} from "src/variables/sharedInterfaces";
import moment from 'moment';
import ActivationButton from "src/components/Button/ActivationButton";
import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import {ALLOWED_GAMES_PATH} from "src/views/Profile/Overview/AllowBlockUserGames";
import {PROFILE_PATH} from "src/views/Profile/Profile";
import Loading from "../../../components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {WALLET_PATH} from "src/components/Wallet/Wallet";
import {INFORMATION_PATH} from "../../Profile/Information/Information";

// core components

export default function FamilyList(props: WithRouter) {

    const {users} = Bootstrap.bootstrap.state

    const currentUser = getCurrentLoggedInUser();

    let childrenInfo: iUser[] = []

    currentUser?.children?.forEach((userId) => {

        const child = users?.find(user => user?.ID === userId);

        if (undefined !== child) {

            childrenInfo.push(child)

        }

    })

    if (currentUser?.children?.length !== childrenInfo.length) {
        return <Loading
            message={"Loading children " + childrenInfo.length + "/" + currentUser?.children?.length + "..."}/>
    }

    const dig = getStyles()

    return (
        <div className={classNames(dig.containerFluid, dig.p0, dig.mb5, "gg-family-list")}>
            <div
                className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.pb4)}>
                <div className={dig.colSm4}>
                    <h3 className={classNames(dig.ggTextPrimary, dig.textStart, dig.textUppercase)}>
                        {childrenInfo.length > 0 ? "Parent/Guardian" : "Gamer"}
                    </h3>
                </div>
                <div className={dig.colSm2}>
                    <h3 className={classNames(dig.ggTextPrimary, dig.textStart, dig.fwNormal, dig.textUppercase)}>status</h3>
                </div>
                <div className={dig.colSm2}>
                    <h3 className={classNames(dig.ggTextPrimary, dig.textStart, dig.fwNormal, dig.textUppercase)}>subscription</h3>
                </div>
                <div className={dig.colSm2}>
                    <h3 className={classNames(dig.ggTextPrimary, dig.textEnd, dig.fwNormal, dig.textUppercase)}>wallet</h3>
                </div>
                <div className={dig.colSm2}>
                    <h3 className={classNames(dig.ggTextPrimary, dig.textEnd, dig.me3)}></h3>
                </div>
            </div>
            <div
                className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.p4, dig.mb3, dig.border1, dig.borderColorGgLightBlue, dig.borderRounded5, dig.bgWhite)}>
                <div
                    className={classNames(dig.col8, dig.colSm4, dig.dFlex, dig.alignItemsCenter, dig.justifyContentStart)}>

                    <ProfilePicture user={currentUser} width={60} height={60}/>

                    <div className={classNames(dig.dFlex, dig.flexColumn, dig.ms4)}>
                        <h3 className={classNames(dig.ggTextColor2, dig.textStart, dig.textUppercase)}>{currentUser?.display_name}</h3>
                        <span
                            className={classNames(dig.ggTextColor2, dig.fontSize1P5Rem)}>{currentUser?.user_login}</span>
                    </div>
                </div>

                <div className={dig.colSm2}>
                    <h3 className={classNames(dig.ggTextColor2, dig.textStart, dig.fwNormal)}>Active</h3>
                </div>
                <div className={dig.colSm2}>
                    {currentUser?.subscriptions?.[0] && currentUser.subscriptions[0].entry_active === '1' ?
                        <>
                            {currentUser.subscriptions[0]?.receive_amount
                                && <h2 className={classNames(dig.ggTextColor2, dig.textStart, dig.fwNormal)}>
                                    {parseInt(currentUser.subscriptions[0]?.receive_amount)} Coins/mo.
                                </h2>}
                            <h3 className={classNames(dig.ggTextColor2, dig.textStart, dig.fwNormal)}>
                                Renews {moment(currentUser.subscriptions[0].modified).add(currentUser.subscriptions[0]?.charge_interval, "M").format('MM-DD-YYYY')}
                            </h3>
                        </>
                        :
                        <Link
                            to={'/' + PROFILE_PATH + '/' + (currentUser?.ID) + '/' + WALLET_PATH}
                            className={classNames(dig.btn, dig.ggBgOrange, dig.textWhite, dig.px3, dig.py2, dig.borderRounded5, dig.fwBold, dig.fontSize1P2Em)}>
                            Withdraw Funds
                        </Link>
                    }
                </div>
                <div className={dig.colSm2}>
                    <h3 className={classNames(dig.ggTextColor2, dig.textEnd, dig.fwNormal, dig.dFlex, dig.justifyContentEnd, dig.alignItemsCenter)}>
                        {isGG() ? <DigCoin style={{width: '15px'}}/> : <div>$</div>}
                        <span className={dig.ms2}>{currentUser?.funds}</span>
                    </h3>
                </div>
                <div className={classNames(dig.colSm2, dig.dFlex, dig.alignItemsCenter, dig.justifyContentEnd)}>
                    {/* eslint-disable-next-line no-undef */}
                    <Link to={'/' + PROFILE_PATH + '/' + (currentUser?.ID) + '/' + INFORMATION_PATH}
                          className={classNames(dig.btn, dig.ggPrimaryBtn, dig.textWhite, dig.px3, dig.py2, dig.borderRounded5, dig.fwBold, dig.fontSize1P2Em)}>
                        Edit
                    </Link>
                </div>
            </div>
            {
                childrenInfo.length > 0
                    ?
                    <div
                        className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.py4, dig.mt4)}>
                        <div className={dig.colSm4}>
                            <h3 className={classNames(dig.ggTextPrimary, dig.textStart, dig.textUppercase)}>Managed
                                Games</h3>
                        </div>
                        <div className={dig.colSm2}>
                            <h3 className={classNames(dig.ggTextPrimary, dig.textStart, dig.fwNormal, dig.textUppercase)}>status</h3>
                        </div>
                        <div className={dig.colSm2}>
                            <h3 className={classNames(dig.ggTextPrimary, dig.textStart, dig.fwNormal, dig.textUppercase)}>subscription</h3>
                        </div>
                        <div className={dig.colSm2}>
                            <h3 className={classNames(dig.ggTextPrimary, dig.textEnd, dig.fwNormal, dig.textUppercase)}>wallet</h3>
                        </div>
                        <div className={dig.colSm2}>
                            <h3 className={classNames(dig.ggTextPrimary, dig.textEnd, dig.me3)}></h3>
                        </div>
                    </div>
                    : undefined
            }
            {
                childrenInfo.length > 0 && childrenInfo?.map((child, key) => {

                    const firstSubscription: iSubscription | undefined = child?.subscriptions?.find(e => !!e);


                    if (child.ID === undefined) {
                        throw new Error('Child ID is undefined');
                    }

                    return <div key={key}
                                className={classNames((child?.user_active === 0 ? dig.borderColorGgRed : dig.borderColorGgLightBlue), dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.p4, dig.mb4, dig.border1, dig.borderRounded5, dig.bgWhite)}>
                        <div
                            className={classNames(dig.col7, dig.colSm4, dig.dFlex, dig.alignItemsLgCenter, dig.justifyContentStart)}>


                            <ProfilePicture user={child} width={60} height={60}/>

                            <div className={classNames(dig.dFlex, dig.flexColumn, dig.ms4)}>
                                <Link to={`/parent-portal/profile/${child?.ID}/` + INFORMATION_PATH}>
                                    <h3 style={{wordBreak: 'break-word'}}
                                        className={classNames(dig.ggTextColor2, dig.textStart, dig.textUppercase)}>{child?.display_name}</h3>
                                    <span
                                        className={classNames(dig.ggTextColor2, dig.fontSize1P5Rem)}>{child?.user_login}</span>
                                </Link>
                            </div>
                        </div>
                        <div className={classNames(dig.colSm2, dig.dFlex, dig.alignItemsCenter)}>
                            <ActivationButton
                                enabledText="Enable"
                                disabledText="Disable"
                                user={child}
                                {...props}
                            />
                        </div>
                        <div className={dig.colSm2}>
                            {firstSubscription && firstSubscription.entry_active === '1' ?
                                <>
                                    <h2 className={classNames(dig.ggTextColor2, dig.textStart, dig.fwNormal)}>
                                        {parseInt(firstSubscription?.receive_amount || '0')} Coins/mo.
                                    </h2>
                                    <h3 className={classNames(dig.ggTextColor2, dig.textStart, dig.fwNormal)}>
                                        Renews {moment(firstSubscription?.modified).add(firstSubscription?.charge_interval, "M").format('MM-DD-YYYY')}
                                    </h3>
                                </>
                                :
                                <Link
                                    to={'/' + PROFILE_PATH + '/' + (currentUser?.ID) + '/' + WALLET_PATH}
                                    className={classNames(dig.btn, dig.ggBgOrange, dig.textWhite, dig.px3, dig.py2, dig.borderRounded5, dig.fwBold, dig.fontSize1P2Em)}>
                                    Withdraw Funds
                                </Link>
                            }
                        </div>
                        <div className={dig.colSm2}>
                            <h3 className={classNames(dig.ggTextColor2, dig.textEnd, dig.fwNormal, dig.dFlex, dig.justifyContentEnd, dig.alignItemsCenter)}>
                                {isGG() ? <DigCoin style={{width: '15px'}}/> : <div>$</div>}
                                <span className={dig.ms2}>{child.funds}</span>
                            </h3>
                        </div>
                        <div className={classNames(dig.colSm2, dig.dFlex, dig.alignItemsCenter, dig.justifyContentEnd)}>
                            <Link to={'/' + PROFILE_PATH + '/' + child.ID + '/' + ALLOWED_GAMES_PATH + '/'}
                                  className={classNames(dig.btn, dig.ggPrimaryBtn, dig.textWhite, dig.px2, dig.py2, dig.borderRounded5, dig.fwBold, dig.ms2)}>
                                Edit Games
                            </Link>
                        </div>
                    </div>
                })}
            {/* Children section */}

        </div>
    );

}


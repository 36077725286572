import classNames from "classnames";
import React from "react";
import {Link} from "react-router-dom";
import {reValidate} from "src/api/hoc/walletInputsValid";
import Input from "src/components/Input/Input";
import {getStyles} from "src/variables/DropVariables";
import {INTEL_PATH, TERMS_PATH} from "src/views/Intel/Intel";


export default class TermsAndConditions extends React.Component<{}, {
    checked: boolean
}>{
    static termsAndConditions: TermsAndConditions;

    constructor(props) {
        super(props);
        TermsAndConditions.termsAndConditions = this
        this.state = {
            checked: false
        }
    }

    render() {

        const dig = getStyles()

        return <div className={classNames(dig.w100, dig.p2, dig.mt3)}>

            <Input type={'checkbox'}
                      checked={this.state.checked}
                      onChange={() => this.setState(prev => ({
                          checked: !prev.checked
                      }), () => reValidate())}>
                <span className={classNames(dig.me2,dig.digHighlight2)}> I agree to the</span>
                <Link to={"/" + INTEL_PATH + "/" + TERMS_PATH} style={{textDecoration: 'underline'}}
                      className={classNames(dig.m0)}>Terms and Conditions.</Link>
        </Input>
        </div>;
    }

}

import classNames from "classnames";
import {ChangeEvent, useEffect, useState} from "react";

import DataTable, {TableColumn} from "react-data-table-component";
// import {toast} from "react-toastify";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
//import deleteFeature from "src/api/rest/deleteFeature";
import deleteFeatureGroupReference from "src/api/rest/deleteFeatureGroupReference";
import deleteGroupGrantabillity from "src/api/rest/deleteGroupGrantabillity";
import deleteUserGroups from "src/api/rest/deleteUserGroups";
import getFeatures from "src/api/rest/getFeatures";
import getGroups from "src/api/rest/getGroups";
import getUsers from "src/api/rest/getUsers";
import postFeature from "src/api/rest/postFeature";
import postGroup from "src/api/rest/postGroup";
import postGroupGrantabillity from "src/api/rest/postGroupGrantabillity";
import postUser from "src/api/rest/postUser";
import postUserGroup from "src/api/rest/postUserGroup";
import Bootstrap from "src/Bootstrap";
import {addAlert} from "src/components/Alert/Alert";
import DropDown from "src/components/DropDown/DropDown";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
// import Input from "src/components/Input/Input";
import {
    iDig_Features,
} from "src/variables/C6";

import swal from '@sweetalert/with-react';
import Button from "src/components/Button/Button";
import {getStyles} from "src/variables/DropVariables";
import {iGroups, iOrganizations, iUser} from "src/variables/sharedInterfaces";
import postFeatureGroupReference from "src/api/rest/postFeatureGroupReference";
import {toast} from "react-toastify";
import Input from "../../components/Input/Input";
import getOrganizations from "../../api/rest/getOrganizations";
import postOrganization from "../../api/rest/postOrganization";
import deleteOrganization from "../../api/rest/deleteOrganization";
//import putOrganization from "../../api/rest/putOrganization";
import Loading from "../../components/Loading/Loading";


export const IAM_PATH = 'iam';

// @link https://react-data-table-component.netlify.app/?path=/docs/api-custom-themes--page
// createTheme creates a new theme named solarized that overrides the build in dark theme


enum eDisplayTable {
    GROUP_CAN_GRANT_GROUPS = 'GROUP_CAN_GRANT_GROUPS',
    GROUPS = 'GROUPS',
    USERS = 'USERS',
    ORGANIZATIONS = 'ORGANIZATIONS',
}

export default function IAM() {

    const whoAmI = getCurrentLoggedInUser();

    const [displayTable, setDisplayTable] = useState<eDisplayTable>(eDisplayTable.ORGANIZATIONS)
    const [memberSearch, setMemberSearch] = useState('')
    const [user, setUser] = useState<iUser>({ID: 0})
    const [group, setGroup] = useState<iGroups>({})
    const [feature, setFeature] = useState<iDig_Features>({})
    const [organization, setOrganization] = useState<iOrganizations>({})

    const siteAdmin = whoAmI?.user_is_admin

    useEffect(() => {

        if (false === siteAdmin) {
            return
        }

        getUsers({})

        getFeatures({})

        getGroups({})

        getOrganizations({})

    })


    const {features, groups, users, organizations} = Bootstrap.bootstrap.state;
    const userSearchRegExpression = new RegExp('.*' + memberSearch + '.*');
    const displayUsersGroups = '' === memberSearch
        ? users
        : users?.filter(user => user.user_nicename?.match(userSearchRegExpression) || user.display_name?.match(userSearchRegExpression));

    const handleUserSearch = (event) => {
        setMemberSearch(event.target.value);
    }

    console.log("features", features);


    function humanize(str) {
        let i, frags = str.split('_');
        for (i = 0; i < frags.length; i++) {
            frags[i] = frags[i].charAt(0).toUpperCase() + frags[i].slice(1);
        }
        return frags.join(' ');
    }

    const dig = getStyles()

    let content: JSX.Element | undefined = undefined

    const moveAroundOrganization = <ExpandableBox>
        <h1 style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>IAM ({organization.organization_name}) </h1>
        <br/>
        <p style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }}>Change views to see what groups and organizations other users are a part of.</p>

        <br/>

        <Button
            className={dig.m1}
            style={{width: "100%"}}
            onClick={() => {
                setDisplayTable(eDisplayTable.ORGANIZATIONS);
            }}
        >
            Organizations
        </Button>
        <Button
            className={dig.m1}
            style={{width: "100%"}}
            onClick={() => {
                setDisplayTable(eDisplayTable.GROUPS);
            }}
        >
            Groups
        </Button>
        <Button
            className={dig.m1}
            style={{width: "100%"}}
            onClick={() => {
                setDisplayTable(eDisplayTable.USERS);
            }}
        >
            Users
        </Button>
        <br/>
        <br/>
        <Button onClick={() => {

            if (undefined === organization.organization_id) {
                alert("Could not parse organization id")
                return
            }

            deleteOrganization({
                organization_id: organization.organization_id
            })

        }}>Delete organization</Button>
    </ExpandableBox>


    switch (displayTable) {
        case eDisplayTable.GROUP_CAN_GRANT_GROUPS: {

            if (undefined === group) {

                return <Loading message={'Gathering group information!'}/>

            }

            content = <DataTable<iGroups>
                theme="solarized"
                columns={[{
                    name: <>Group #</>,
                    selector: row => row.entity_id ?? '',
                    cell: row => <p>{row.entity_id}</p>,
                    sortable: true,
                }, {
                    name: <>Group Name</>,
                    selector: group => group.group_name ?? '',
                    cell: group => <p>{group.group_name}</p>,
                    sortable: true,
                }, {
                    name: <>Group ({group.group_name}) Can Grant</>,
                    cell: (SubGroup, index) => {

                        let enabled = SubGroup.entity_id && true === group.allowed_to_grant_group_id?.includes(SubGroup.entity_id)

                        return <div>
                            <Button
                                key={index}
                                style={{backgroundColor: enabled ? "blue" : "grey"}}
                                onClick={() => {
                                    if (undefined === group.entity_id
                                        || undefined === SubGroup.entity_id) {
                                        alert("Could not parse group id")
                                        return
                                    }
                                    if (enabled) {
                                        deleteGroupGrantabillity({
                                            group_id: group.entity_id,
                                            allowed_to_grant_group_id: SubGroup.entity_id
                                        })
                                    } else {
                                        postGroupGrantabillity({
                                            group_id: group.entity_id,
                                            allowed_to_grant_group_id: SubGroup.entity_id
                                        })
                                    }
                                }}>
                                {enabled ? " Can Give Access " : "Can not Grant"}
                            </Button>
                        </div>
                    },
                    sortable: false,
                }]}
                data={groups ?? []}/>
            break;
        }
        case eDisplayTable.GROUPS:
            content = (
                <ExpandableBox>

                    <h1 style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "10px"
                    }}>Groups</h1>

                    <Button
                        className={dig.m1}
                        color="success"
                        onClick={() => addAlert({
                            title: `Create a new feature flag.`,
                            text: 'Please input the feature flag name.',
                            component: <Input onChange={event => setFeature({
                                feature_code: event.target.value
                            })}/>,
                            buttons: [{text: "Cancel", color: "default"}, {text: "Submit", color: "success"}],
                            then: (shouldSubmit => {
                                if (shouldSubmit === "Cancel") {
                                    return
                                }
                                postFeature({
                                    feature_code: feature.feature_code,

                                })
                            })
                        })}
                    >
                        Start New Feature
                    </Button>
                    <br/>
                    <Button color="warning"
                            className={dig.m1}
                            onClick={() => addAlert({
                                title: `Create a new group.`,
                                text: 'Please input the group name.',
                                component: <Input onChange={event => setGroup({
                                    group_name: event.target.value
                                })}/>,
                                buttons: [{text: "Cancel", color: "default"}, {text: "Submit", color: "success"}],
                                then: (shouldSubmit => {

                                    if (shouldSubmit === "Cancel") {
                                        return
                                    }

                                    if (undefined === group.group_name) {

                                        throw new Error("Group name is undefined")

                                    }
                                    if (undefined === organization.organization_id) {
                                        throw new Error("Organization id is undefined")
                                    }

                                    postGroup({
                                        organization_id: organization.organization_id,
                                        group_name: group.group_name,
                                    })

                                })

                            })}>Create New Group</Button>

                    <DataTable<iGroups>
                        dense
                        striped
                        selectableRows
                        contextActions={<div>test</div>}
                        defaultSortAsc
                        pagination
                        responsive
                        expandableRows
                        expandableRowsComponent={(ExpanderComponentProps) => {

                            const group = ExpanderComponentProps.data

                            return <div className={dig.m5}>
                                <h5><b>You will be affecting 9 users already assigned to this role.</b>
                                </h5> <Button
                                onClick={() => addAlert({
                                    title: "Caution! You are about to delete a group.",
                                    text: "You will be affecting (todo - 9) users already assigned to this role.",
                                    dangerMode: true,
                                    buttons: [
                                        {text: "Delete Group", color: "danger"},
                                        {text: "Close Admin Settings", color: "default"}
                                    ],
                                    component:
                                        <>
                                            <div>
                                                <h5><b>You will be affecting 9 users already
                                                    assigned to this role.</b></h5>
                                                <br/>
                                                <p>DELETE .. the Grant Ability.... limit
                                                    this user to... create and manage
                                                    other users...</p>
                                            </div>

                                        </>

                                })}
                                color="danger">
                                Delete Group ({group.group_name})
                            </Button>

                                <br/>
                                <p>Moving to the Grant Ability.... will allow this user to... create and
                                    manage
                                    other users...</p>
                                TODO - change a state var to switch to the group grant table

                            <Button onClick={() => {
                                setGroup(group)
                                setDisplayTable(eDisplayTable.GROUP_CAN_GRANT_GROUPS)
                            }}>Groups this group can assign!</Button>

                            </div>
                        }}
                        columns={
                            [{
                                name: <>Group Name</>,
                                selector: group => group.group_name ?? '',
                                cell: group => <p><i
                                    className={classNames("fas fa-edit")}/>{humanize(group.group_name)}
                                </p>,
                                sortable: true,
                            },
                                ...(features?.map((feature, key): TableColumn<iGroups> => {

                                    return {
                                        name: <>{feature.feature_code}</>,
                                        selector: group => group.group_name ?? '',
                                        cell: group => {

                                            const unavailable = undefined === group.group_name
                                                || undefined === group?.entity_id
                                                || undefined === feature?.feature_entity_id;

                                            if (unavailable) {
                                                return <Button key={key}>Error (E_{group.feature_code}_{feature?.feature_entity_id})</Button>
                                            }

                                            const enabled = feature.feature_code && group.feature_code?.includes(feature.feature_code)

                                            return <Button
                                                style={{backgroundColor: enabled ? "green" : "grey"}}
                                                onClick={() => {
                                                    alert("group entity id " + group.entity_id + " feature entity id " + feature.feature_entity_id)

                                                    if (undefined === group.feature_code
                                                        || undefined === group?.entity_id
                                                        || undefined === feature?.feature_entity_id) {
                                                        return;
                                                    }

                                                    if (enabled) {
                                                        deleteFeatureGroupReference({
                                                            feature_entity_id: feature.feature_entity_id,
                                                            group_entity_id: group.entity_id
                                                        })
                                                    } else {
                                                        postFeatureGroupReference({
                                                            feature_entity_id: feature.feature_entity_id,
                                                            group_entity_id: group.entity_id
                                                        })
                                                    }
                                                }}>
                                                {enabled ? " Enabled " : "Disabled"}
                                            </Button>
                                        }
                                    }
                                }) ?? [])
                            ]
                        }
                        data={organization.groups?.map(groupId => groups?.find(group => group.entity_id === groupId) ?? {
                            group_name: "Loading..."
                        }) ?? []}
                        paginationRowsPerPageOptions={[10, 100, 500, 1000]}
                    />
                </ExpandableBox>
            )
            break;
        case eDisplayTable.USERS:
            content = (
                <div>
                    <ExpandableBox>

                        <h1 style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            paddingBottom: "10px"
                        }}>Users</h1>
                        <p>
                            Create and assign new users access to connect features
                        </p>
                        <Button
                            color="success"
                            onClick={() => swal({
                                buttons: true,
                                content:
                                    <form>
                                        <h2>Create a new user</h2><br/>
                                        <hr/>
                                        <Input

                                            label="user_email"
                                            id="user_email"
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setUser({
                                                ...user,
                                                user_email: e.target.value
                                            })}
                                        />

                                        <Input
                                            label="user_nicename"
                                            id="user_nicename"
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setUser({
                                                ...user,
                                                user_nicename: e.target.value
                                            })}

                                        />
                                        <Input
                                            label="user_login"
                                            id="user_login"
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setUser({
                                                ...user,
                                                user_login: e.target.value
                                            })}
                                        />
                                        <Input
                                            label="Email"
                                            id="user_email"
                                            onChange={(e: ChangeEvent<HTMLInputElement>) => setUser({
                                                ...user,
                                                user_email: e.target.value
                                            })}
                                        />
                                        <hr/>
                                    </form>
                            }).then(shouldSubmit => shouldSubmit && postUser(user))}
                        >
                            Create New User
                        </Button>


                    </ExpandableBox>

                    <br/>
                    <div className={dig.umForm}>
                        <div className={classNames(dig.row)}>
                            <div className={classNames(dig.colMd5)}>
                                <input type="search"
                                       placeholder="Search for a user's group"
                                       value={memberSearch}
                                       className={classNames(dig.w100)}
                                       onChange={handleUserSearch}
                                       aria-label="Search"/>
                            </div>
                        </div>
                    </div>

                    <ExpandableBox>
                        <DataTable
                            defaultSortAsc
                            style={{width: "100%", maxWidth: "fit-content"}}
                                   columns={[{
                                       name: <>User ID</>,
                                       cell: row => row[0],
                                       sortable: false,
                                   }, {
                                       name: <>user_status</>,
                                       cell: row => row[1],
                                       sortable: false,
                                   }, {
                                       name: <>Nicename</>,
                                       cell: row => row[2],
                                       sortable: false,
                                   },
                                       ...(groups?.filter(group => group.entity_id && organization.groups?.includes(group.entity_id)).map((group, index) => ({
                                           name: group.group_name,
                                           cell: row => row[index + 3],
                                           sortable: false,
                                       })) ?? [])
                                   ]}
                                   data={
                                       displayUsersGroups?.map((user, key) => [
                                           user.ID,
                                           user.user_status,
                                           user.user_nicename,
                                           ...(groups?.map(group => {

                                               if (undefined === group?.group_name) {
                                                   throw new Error('Unexpected data passed to the group table')
                                               }

                                               const groupName: string = group.group_name

                                               const permission = user.permissions?.find(permission => permission?.group_name?.includes(groupName))

                                               const enabled = undefined !== permission

                                               return <Button key={key}
                                                              style={{backgroundColor: enabled ? "green" : "default"}}
                                                              onClick={() => {

                                                                  if (undefined === group.entity_id || undefined === user.ID) {
                                                                      toast.error('invalid id information parsed')
                                                                      return;
                                                                  }

                                                                  if (enabled) {
                                                                      deleteUserGroups({
                                                                          userId: user.ID,
                                                                          groupId: group.entity_id
                                                                      })
                                                                  } else {
                                                                      postUserGroup({
                                                                          user_id: user.ID,
                                                                          group_id: group.entity_id
                                                                      })
                                                                  }
                                                              }}>
                                                   {enabled ? " Enabled " : "Disabled"}
                                               </Button>
                                           }) ?? [])
                                       ]) ?? []
                                   }
                        />

                    </ExpandableBox>
                </div>
            )
            break;
        case eDisplayTable.ORGANIZATIONS: {

            const whoami = getCurrentLoggedInUser()

            const myOrganizations = whoami?.permissions

            if (undefined === whoami) {

                return <Loading message={'Loading your IAM organizations...'}/>

            }

            content = (
                <ExpandableBox>

                    <h1 style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        paddingBottom: "10px"
                    }}>Organizations</h1>

                    <Button
                        className={dig.m1}
                        color="success"
                        onClick={() => addAlert({
                            title: `Create a new feature flag.`,
                            text: 'Please input the feature flag name.',
                            component: <Input onChange={event => setOrganization({
                                organization_name: event.target.value
                            })}/>,
                            buttons: [{text: "Cancel", color: "default"}, {text: "Submit", color: "success"}],
                            then: (shouldSubmit => {
                                if (shouldSubmit === "Cancel") {
                                    return
                                }
                                if (undefined === organization.organization_name) {
                                    toast.error('Invalid organization name')
                                    throw new Error('Invalid organization name')
                                }

                                postOrganization({
                                    organization_name: organization.organization_name,
                                    organization_owner: whoami.ID
                                })

                            })

                        })}

                    >
                        Start New Organization
                    </Button>
                    <br/>

                    <DropDown
                        defaultValue={'Your Organizations...'}
                        onChange={(value) => {

                            const org = organizations?.find(org => org.organization_name === value)

                            if (undefined === org) {
                                throw new Error('Unexpected error, could not find organization')
                            }

                            console.log('selected Organization', org);

                            setOrganization(org)

                            setDisplayTable(eDisplayTable.GROUPS)

                        }}
                        options={siteAdmin
                            ? organizations?.map(org => org.organization_name ?? 'Loading..') ?? []
                            : myOrganizations?.filter(org => org.organization_name)
                            .map(organization => organization.organization_name ?? 'Loading..') ?? []}/>

                </ExpandableBox>
            )
            break;
        }
        default:
            return null; // or any other default element you want to return
    }

    return (<div style={{width: "93.75em"}}>

            {displayTable !== eDisplayTable.ORGANIZATIONS && moveAroundOrganization}

            {content}

        </div>

    );

}



import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Competitions_Meta} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iCompetitions} from "src/variables/sharedInterfaces";


export interface iGetUserMeta {
    competitionIds: number[]
}

export default restApi<iGetUserMeta, iDig_Competitions_Meta, {}, iGetC6RestResponse<iDig_Competitions_Meta>>({
    axios: DropVariables.axios,
    tableName: C6.dig_competitions_meta.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {

        updateRestfulObjectArray<iCompetitions>(
            request.competitionIds?.map(compId => ({
                comp_id: compId,
                competitionsMeta: []
            })) ?? [], "comp_id", "competitions")

        return {
            [C6.WHERE]: {
                [C6.dig_competitions_meta.COMP_ID]: [C6.IN, request.competitionIds],
            },
            [C6.PAGINATION]: {
                [C6.LIMIT]: 1000
            }
        }

    },
    responseCallback: async (response, request) => {

        let restData = response?.data?.rest ?? [];

        updateRestfulObjectArray<iCompetitions>(request.competitionIds.map(compId => {

            return {
                comp_id: compId,
                competitionsMeta: restData.filter(meta => meta.comp_id === compId),
            }

        }), "comp_id", "competitions");



    }
});

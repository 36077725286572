import {toast} from "react-toastify";
import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import authenticated from "src/api/hoc/authenticated";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import getUsers from "src/api/rest/getUsers";
import Bootstrap from "src/Bootstrap";
import {
    C6,
    iDig_Parent_User,
    iDig_Temp_Invite,
    iDig_User_Info,
    iDig_User_Organizations,
    iUsermeta,
    iUsers
} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";

export interface iPostUser extends iUsermeta {
    responseCallback?: (id: boolean | string | number) => void
    // sign up page always sends the results of URLSearchParams.get('referenceId') as temp_invite_id (unregistered user invite)
    // Returns null instead of undefined if not found
    temp_invite_id?: number | null
}

export default restApi<iPostUser, iUsers & iDig_Temp_Invite & iDig_Parent_User & iDig_User_Info & iDig_User_Organizations, {}, iPostC6RestResponse<iUsers>>({
    axios: DropVariables.axios,
    tableName: [C6.users.TABLE_NAME, C6.dig_temp_invite.TABLE_NAME, C6.dig_parent_user.TABLE_NAME, C6.dig_user_info.TABLE_NAME],
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Successfully created your account! You may now log in using your new credentials.'
        request.error = 'An unknown issue occurred while creating your account.'
        return request
    },
    responseCallback: (_response, request, id) => {

        if (typeof id !== 'number') {

            toast.error('An internal error occurred while creating your account. Please contact Drop-In Gaming if problems persist.', DropVariables.toastOptions)

            console.error('An internal error occurred while creating your account. Please contact Drop-In Gaming if problems persist.', _response, request, id)

            return;

        }

        // if the user is signing up the current user is not authenticated; thus 0.
        // If the user is creating a new user the current user is authenticated
        if (0 === Bootstrap.bootstrap.state.id) {

            authenticated()

        } else {

            getUsers({
                userIds: [id],
            })

        }

        if (undefined !== request.parent_user_id) {

            updateRestfulObjectArray<iUsers>(previousState => ([{
                ID: request.parent_user_id,
                children: (previousState.users?.find(user => user.ID === request.parent_user_id)?.children ?? []).concat(id)
            }]), "ID", "users")


        }

        request.responseCallback?.(id)

    }

});

import {useEffect, useState} from "react";
import TermsAndConditions from "src/components/TermsAndConditions/TermsAndConditions";
import Billing from "src/components/Wallet/Billing/Billing";

// The wallet forum spans multiple components; some of which must not be re-rendered, this allows us to selectively
// re-render the components that need to be re-rendered.

const event = new Event("WalletInputsValid")

export function reValidate() {
    window.dispatchEvent(event)
}

export function validate(cb : () => void) {
    const listener = () => { cb() }
    useEffect(() => {
        window.addEventListener("WalletInputsValid", listener)
        return () => {
            window.removeEventListener("WalletInputsValid", listener)
        }
    })
}

export default function walletInputsValid() : [boolean, () => void]{

    const [forumValid, setForumValid] = useState<boolean>(false);

    console.log('forumValid', forumValid)

    const validateForum = () => {
        if (TermsAndConditions.termsAndConditions.state.checked
            && true === Billing.billing?.billingInformationValid()) {
            setForumValid(true);
        } else {
            setForumValid(false);
        }
    }

    validate(validateForum)

    useEffect(() => {
        validateForum()
    })

    return [forumValid, validateForum]

}
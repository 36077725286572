import 'react-loading-skeleton/dist/skeleton.css';
import {Navigate, Route, Routes} from "react-router-dom";
import CompetitionSort from "src/api/hoc/CompetitionSort";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import getCompetitions from "src/api/rest/getCompetitions";
import Bootstrap from "src/Bootstrap";
import CompetitionGrid from "src/components/CompetitionGrid/CompetitionGrid";
import Loading from "src/components/Loading/Loading";
import {
    ppr, WithRouter
} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {getStyles} from "src/variables/DropVariables";
import isGG from "src/variables/isGG";

import {
    eCOMPETITION,
    eCOMPETITION_STATUS,
    eCOMPETITION_TIMING,
    iUser
} from "src/variables/sharedInterfaces";
import CoinPack, {COIN_PATH} from "src/views/Profile/CoinPack/CoinPack";
import History, {HISTORY_PATH} from "src/views/Profile/History/History";
import Information, {INFORMATION_PATH} from "src/views/Profile/Information/Information";
import ProfileHeader from "src/views/Profile/ProfileHeader/ProfileHeader";
import Subscription, {SUBSCRIPTION_PATH} from "src/views/Profile/Subscription/Subscription";
import PageNotFound from "src/views/Errors/PageNotFound";
import Invitations, {INVITATIONS_PATH} from "./Invitations/Invitations";
import AllowBlockUserGames, {ALLOWED_GAMES_PATH} from "./Overview/AllowBlockUserGames";
import Teams, {TEAMS_PATH} from "./Teams/Teams";
import Wallet, {WALLET_PATH} from "src/components/Wallet/Wallet";
import isCOM from "../../variables/isCOM";


export const BRACKETS_PATH = 'brackets'
export const MATCHES_PATH = 'matches'
export const TOURNAMENTS_PATH = 'tournaments'


interface iNavigateProfile {
    index?: boolean,
    path: string,
    component: any
}

export type iAmProfileDescendant = WithRouter & {
    prefix: string,
    navigation: iNavigateProfile[],
    currentEditUser: iUser,
    updateCurrentEditUser: (currentEditUser: iUser) => void,
}

export const PROFILE_PATH = 'profile';

export default function Profile(props: WithRouter) {

    const bootstrap = Bootstrap.bootstrap

    const {id, users, competitions} = bootstrap.state;

    window.scrollTo(0, 0); // scroll top

    const updateCurrentEditUser = (currentEditUser: iUser) => {
        updateRestfulObjectArray<iUser>([{
            ...currentEditUser,
            ID: props.params.userId?.toString() || id,
        }], "ID", "users")
    }

    const loadMyCompetitions = (compType: eCOMPETITION) => getCompetitions({
        userID: id,
        timing: eCOMPETITION_TIMING.ALL,
        competition: compType
    });

    const searchUser = props.params?.userId && parseInt(props.params.userId);

    if (undefined === searchUser) {

        return <Navigate to={"/" + PROFILE_PATH + "/" + id + '/'} replace/>

    }

    let prefix = '/' + PROFILE_PATH + '/' + searchUser + '/';

    const account = users?.find(user => user.ID === searchUser);

    if (account === undefined) {

        return <Loading message={"Loading Profile Account (" + searchUser + ")"}/>

    }

    const navigation: iNavigateProfile[] = [];

    if (false === isCOM()) {

        navigation.push({
            index: true,
            path: ALLOWED_GAMES_PATH,
            component: AllowBlockUserGames
        });

    }

    navigation.push(
        {
            index: true,
            path: INFORMATION_PATH,
            component: Information
        },
        {
            path: HISTORY_PATH,
            component: History
        },
        {
            path: MATCHES_PATH,
            component: () => <CompetitionGrid
                title="Your Matches"
                error="You have no previous matches!"
                loadMore={() => loadMyCompetitions(eCOMPETITION.MATCHES)}
                competitionsToDisplay={null === competitions
                    ? null
                    : competitions?.filter(competition =>
                        competition.comp_type_id === eCOMPETITION.MATCHES &&
                        competition.registered_users?.includes(id) &&
                        competition.parent_comp === 0)
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.CANCELED))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.COMPLETED))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.PAID))
                }
            />
        }, {
            path: TOURNAMENTS_PATH,
            component: () => <CompetitionGrid
                title="Your Tournaments"
                error="You have no previous tournaments!"
                loadMore={() => loadMyCompetitions(eCOMPETITION.TOURNAMENTS)}
                competitionsToDisplay={null === competitions
                    ? null
                    : competitions?.filter(competition =>
                        competition.comp_type_id === eCOMPETITION.TOURNAMENTS &&
                        competition.registered_users?.includes(id))
                        .sort(CompetitionSort.sortCompetitionsDateTime)
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.WAITING))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.CANCELED))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.COMPLETED))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.PAID))
                }
            />
        }, {
            path: BRACKETS_PATH,
            component: () => <CompetitionGrid
                title="Your Brackets"
                error="You have no previous brackets!"
                loadMore={() => loadMyCompetitions(eCOMPETITION.BRACKETS)}
                competitionsToDisplay={null === competitions
                    ? null
                    : competitions?.filter(competition =>
                        competition.comp_type_id === eCOMPETITION.BRACKETS &&
                        competition.registered_users?.includes(id))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.WAITING))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.CANCELED))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.COMPLETED))
                        .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.PAID))
                }
            />
        }, {
            path: INVITATIONS_PATH,
            component: Invitations
        },
        {
            path: TEAMS_PATH,
            component: Teams
        })

    if (isGG()) {

        navigation.push({
                path: SUBSCRIPTION_PATH,
                component: Subscription
            },
            {
                path: COIN_PATH,
                component: CoinPack
            });

    } else {

        navigation.push({
            path: WALLET_PATH,
            component: Wallet
        });

    }


    const propsToPass: iAmProfileDescendant = {
        ...props,
        prefix: prefix,
        navigation: navigation,
        currentEditUser: account,
        updateCurrentEditUser: updateCurrentEditUser,
    }

    const dig = getStyles()

    return <div className={dig.container}>
        {ppr(ProfileHeader, propsToPass)}
        <div className={dig.container}>
            <Routes>
                {navigation.map((nav, key) => {
                    return <Route
                        key={key}
                        path={nav?.path + '/*'}
                        element={ppr(nav.component, propsToPass)}
                    />
                })}
                <Route index element={<Navigate to={INFORMATION_PATH + '/'}/>}/>
                <Route path='*' element={<PageNotFound/>}/>
            </Routes>
        </div>

    </div>


}


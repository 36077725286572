import classNames from "classnames";
import {toast} from "react-toastify";
import deleteRegistration from "src/api/rest/deleteRegistration";
import Bootstrap from "src/Bootstrap";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, iCompetitions} from "src/variables/sharedInterfaces";
import deleteTeamRoster from "src/api/rest/deleteTeamRoster";
import putCompetition from "src/api/rest/putCompetition";
import compStatusToSingular from "src/api/hoc/compStatusToSingular";
import {useState} from "react";

interface iJoinStatus {
    competition: iCompetitions
}

export default function Details({competition}: iJoinStatus) {

    if (undefined === competition) {

        return <Loading message={"Loading..."}/>

    }



    const {registrations, id, competitions} = Bootstrap.bootstrap.state;

    // Find parent comp
    const parentCompInfo = competitions?.find(parentComp => parentComp.comp_id === competition.parent_comp) || undefined;

    const myRegistration = registrations?.find(registration => registration.user_id === id && registration.competitions_id === competition.comp_id) || undefined;

    const iAmRegistered = competition.registered_users?.includes(id) &&
        undefined !== myRegistration;

    const iAmManager = iAmRegistered && myRegistration?.position === 1;

    const compWaiting = eCOMPETITION_STATUS.WAITING === competition.status;

    const [isLoadingPage, setIsLoadingPage] = useState(false);

    const isMatch = eCOMPETITION.MATCHES === competition.comp_type_id;

    const isOwner = isMatch && competition.organizer_id === id;

    const customStreamingMeta = competition.competitionsMeta?.find(meta => meta.meta_key === '_streamer_url')

    const dig = getStyles()

    return <ExpandableBox header={'Details'}>
        <div className={classNames(dig.cardBody, dig.py0)}>
            <div className={classNames(dig.colSm, dig.p0)}>
                <div className={classNames(
                    dig.listGroup, dig.listGroupFlush, dig.my1, dig.dBlock)}>
                    <h5 className={classNames(
                        dig.cardTitle, dig.textUppercase, dig.alignItemsStart, dig.digColorNeutral3
                    )}>
                        Game Mode
                    </h5>
                    <h4 className={classNames(
                        dig.cardText, dig.textWhite, dig.textStart
                    )}>
                        {competition.name}
                    </h4>
                </div>
            </div>
        </div>
        <div className={classNames(dig.cardBody, dig.py0)}>
            <div className={classNames(dig.colSm, dig.p0)}>
                <div className={classNames(
                    dig.listGroup, dig.listGroupFlush, dig.my1, dig.dBlock
                )}>
                    <h5 className={classNames(
                        dig.cardTitle, dig.textUppercase, dig.alignItemsStart, dig.digColorNeutral3
                    )}>Team size</h5>
                    <h4 className={classNames(
                        dig.cardText, dig.alignItemsStart, dig.textWhite, dig.textStart
                    )}>
                        {competition.team_size} </h4>
                </div>
            </div>
        </div>
        <div className={classNames(dig.cardBody, dig.py0)}>
            <div className={classNames(dig.colSm, dig.p0)}>
                <div className={classNames(
                    dig.listGroup, dig.listGroupFlush, dig.my1, dig.dBlock
                )}>
                    <h5 className={classNames(dig.cardTitle, dig.textUppercase, dig.alignItemsStart, dig.digColorNeutral3)}>
                        Max Teams
                    </h5>
                    <h4 className={classNames(dig.cardText, dig.alignItemsStart, dig.textWhite, dig.textStart)}>
                        {(competition.max_players && competition.max_players) || 'N/A' /* competition.max_players is actually max teams, which is confusing (and my fault for never changing the name after functionality changed) */}
                    </h4>
                </div>
            </div>
        </div>
        <div className={classNames(dig.cardBody, dig.py0)}>
            <div className={classNames(dig.colSm, dig.p0)}>
                <div className={classNames(dig.listGroup, dig.listGroupFlush, dig.my1, dig.dBlock)}>
                    <h5 className={classNames(dig.cardTitle, dig.textUppercase, dig.alignItemsStart, dig.digColorNeutral3)}>
                        Buy-in
                    </h5>
                    <h4 className={classNames(dig.cardText, dig.textWhite, dig.alignItemsStart, dig.textStart)}>
                        {parseInt(competition.buy_in ?? '0') === 0 ? 'Free' : DropVariables.formatMoney(competition.buy_in)}
                    </h4>
                </div>
            </div>
        </div>
        <div className={classNames(dig.cardBody, dig.py0)}>
            <div className={classNames(dig.colSm, dig.p0)}>
                <div className={classNames(dig.listGroup, dig.listGroupFlush, dig.my1, dig.dBlock)}>
                    <h5 className={classNames(dig.cardTitle, dig.textUppercase, dig.alignItemsStart, dig.digColorNeutral3)}>
                        Status
                    </h5>
                    <h4 className={classNames(dig.cardText, dig.textWhite, dig.alignItemsStart, dig.textStart)}>
                        {compStatusToSingular(competition.status)}
                    </h4>
                </div>
            </div>
        </div>
        {customStreamingMeta?.meta_value &&
        <div className={"live-stream"}>
            <div className={classNames(dig.cardBody, dig.py0)}>
                <div className={classNames(dig.colSm, dig.p0)}>
                    <div className={classNames(dig.listGroup, dig.listGroupFlush, dig.my1, dig.dBlock)}>
                        <h5 className={classNames(dig.cardTitle, dig.textUppercase, dig.alignItemsStart, dig.digColorNeutral3)}>Streaming</h5>
                        {/* if customStreamingMeta === yes then show this ternary */}\

                        {competition.datetime && parseInt(competition.datetime) - 300 < Math.floor(Date.now() / 1000) && parseInt(competition.datetime) > Math.floor(Date.now() / 1000) ?
                            <div className={classNames(dig.badge, dig.digBtnRed, dig.justifyContentCenter)}>
                                <a target="_blank" rel="noreferrer" href={
                                    customStreamingMeta?.meta_value
                                }>WATCH LIVE </a>
                            </div>
                            : competition.datetime && parseInt(competition.datetime) < Math.floor(Date.now() / 1000) ?
                                <div className={classNames(dig.badge, dig.digBtnRed, dig.justifyContentCenter)}>
                                    <a target="_blank" rel="noreferrer" href={
                                        customStreamingMeta?.meta_value
                                    }>WATCH REPLAY </a>
                                </div>
                                :
                                <div className={classNames(dig.badge, dig.digBtnRed, dig.justifyContentCenter)}>
                                    <a target="_blank" rel="noreferrer" href={
                                        customStreamingMeta?.meta_value
                                    }>LIVE STREAM COMING </a>
                                </div>}
                    </div>
                </div>
            </div>
        </div>}

        {iAmRegistered && compWaiting && competition.parent_comp === 0 && <>
            <div className={classNames(dig.cardBody, dig.pt2)}>
                <div className={classNames(dig.row, dig.m0)}>
                    <button disabled={isLoadingPage}
                        className={classNames(dig.fwBold, dig.textUppercase, dig.digBtnRed, dig.textCenter, dig.p2, dig.fontSize1P2Rem, dig.w100)}
                        style={{cursor: "pointer" , color: "white", border: "none"}}
                        onClick={ async () => {

                            console.log('iAmManager', iAmManager, myRegistration, iAmRegistered)

                            setIsLoadingPage(true);
                            if (iAmManager) {
                                if (myRegistration.team_id && competition.comp_id) {
                                    await deleteRegistration({
                                        competitions_id: competition.comp_id,
                                        team_id: myRegistration.team_id,
                                        user_id: id,
                                    });
                                } else {
                                    toast.error('Could not parse all required information to delete registration! Please try again later.', DropVariables.toastOptions)
                                }
                            } else {
                                if (myRegistration.team_id) {
                                    await deleteTeamRoster({
                                        uid: id,
                                        comp_id: competition.comp_id,
                                        user_team_id: myRegistration.team_id
                                    });
                                } else {
                                    toast.error('Could not parse all required information to delete roster! Please try again later.', DropVariables.toastOptions)
                                }
                            }
                            setIsLoadingPage(false);
                        }}>
                        {isLoadingPage? "Leaving..." :"Leave Competition"}
                    </button>
                </div>
            </div>
            {isMatch && competition.now_flag === 1 && ((isOwner && (competition.complete_teams?.length || 0) === competition.max_players &&
                    <div
                        className={classNames(dig.fwBold, dig.textUppercase, dig.digBtnGreen, dig.textCenter, dig.py3, dig.fontSize1P5Em, dig.w100)}
                        style={{cursor: "pointer"}}
                        onClick={() => {
                            putCompetition({
                                status: eCOMPETITION_STATUS.STARTED,
                                comp_id: competition.comp_id
                            });
                        }}>
                        START MATCH
                    </div>
                ) ||
                <div className={classNames(dig.digCountdownCounter, dig.digInProgress)}>
                    <p className={dig.textUppercase}>
                        {(isOwner ? 'You can ' : 'The manager will ') + 'start the match when all members have joined.'}
                    </p>
                </div>)}

        </>}

        {
            isMatch && competition.parent_comp !== 0 && (
                <div className={classNames(dig.cardBody, dig.pt0, dig.mt3)}>
                    <div className={classNames(dig.colSm, dig.p0)}>
                        <div className={classNames(dig.m0)} id="dig-bracket-link-ctn">
                            <div id={dig.digReturnToBracket} className={classNames(dig.digCountdownCounter,dig.digInProgress, dig.textCenter)}>
                                <a href={"/competition/"+competition.parent_comp} className={classNames(dig.textUppercase)}>
                                    Return to {parentCompInfo?.comp_type_id === eCOMPETITION.BRACKETS ? 'Bracket' : parentCompInfo?.comp_type_id === eCOMPETITION.ROUND_ROBIN ? 'Round Robin' : ''}
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }


    </ExpandableBox>


}


import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import copyToClipboard from "../../api/hoc/copyToClipboard";

export default function SocialShareLinks() {

    const dig = getStyles()

    interface iShare {
        url: string,
        locationName: string,
        element: any,
        className: string,
    }

    const shareLinks: iShare[] = [
        {
            url: `https://www.facebook.com/sharer/sharer.php?u=${window.location}&display=popup&ref=plugin&src=share_button`,
            locationName: 'Facebook',
            element: <svg
                version="1.1" xmlns="http://www.w3.org/2000/svg"
                width="36"
                height="26"
                fill={'#fff'}
                style={{backgroundColor: '#3B5998', borderRadius: '2px', paddingTop: '6px'}}
                viewBox="0 0 602 1524">
                <path
                    d="M548 6.857v150.857h-89.714q-49.143 0-66.286 20.571t-17.143 61.714v108h167.429l-22.286 169.143h-145.143v433.714h-174.857v-433.714h-145.714v-169.143h145.714v-124.571q0-106.286 59.429-164.857t158.286-58.571q84 0 130.286 6.857z"></path>
            </svg>,
            className: classNames(dig.digShareSvg, dig.shareLink)
        },
        {
            url: `https://twitter.com/share?url=${window.location}`,
            locationName: "Twitter",
            element: <svg version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="36" height="26"
                          fill={'#fff'}
                          style={{backgroundColor: '#00B6F1', borderRadius: '2px', paddingTop: '6px'}}
                          viewBox="0 0 951 1524">
                <path
                    d="M925.714 233.143q-38.286 56-92.571 95.429 0.571 8 0.571 24 0 74.286-21.714 148.286t-66 142-105.429 120.286-147.429 83.429-184.571 31.143q-154.857 0-283.429-82.857 20 2.286 44.571 2.286 128.571 0 229.143-78.857-60-1.143-107.429-36.857t-65.143-91.143q18.857 2.857 34.857 2.857 24.571 0 48.571-6.286-64-13.143-106-63.714t-42-117.429v-2.286q38.857 21.714 83.429 23.429-37.714-25.143-60-65.714t-22.286-88q0-50.286 25.143-93.143 69.143 85.143 168.286 136.286t212.286 56.857q-4.571-21.714-4.571-42.286 0-76.571 54-130.571t130.571-54q80 0 134.857 58.286 62.286-12 117.143-44.571-21.143 65.714-81.143 101.714 53.143-5.714 106.286-28.571z"></path>
            </svg>,
            className: classNames( dig.digShareSvg, dig.shareLink)
        },
        {
            url: `https://www.linkedin.com/shareArticle?mini=true&url=${window.location}`,
            locationName: "LinkedIn",
            element: <svg version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="36" height="26"
                          fill={'#fff'}
                          style={{backgroundColor: '#007BB6', borderRadius: '2px', paddingTop: '6px'}}
                          viewBox="0 0 878 1524">
                <path
                    d="M199.429 357.143v566.286h-188.571v-566.286h188.571zM211.429 182.286q0.571 41.714-28.857 69.714t-77.429 28h-1.143q-46.857 0-75.429-28t-28.571-69.714q0-42.286 29.429-70t76.857-27.714 76 27.714 29.143 70zM877.714 598.857v324.571h-188v-302.857q0-60-23.143-94t-72.286-34q-36 0-60.286 19.714t-36.286 48.857q-6.286 17.143-6.286 46.286v316h-188q1.143-228 1.143-369.714t-0.571-169.143l-0.571-27.429h188v82.286h-1.143q11.429-18.286 23.429-32t32.286-29.714 49.714-24.857 65.429-8.857q97.714 0 157.143 64.857t59.429 190z"></path>
            </svg>,
            className: classNames(dig.digShareSvg, dig.shareLink)
        },
        {
            url: `sms:?&body=Check this out on Drop-In Gaming: ${window.location}`,
            locationName: "Text SMS Message",
            element: <img alt={"sms"} className={classNames(dig.sms, dig.digShareSvg, dig.shareLink)} style={{backgroundColor: '#33c959'}}
                          src="/wp-content/themes/dropingaming/assets/img/svg-icons/sms-solid.svg"/>,
            className: classNames("sms")
        },
    ]

    const socialButton = ({url, element, locationName, className}: iShare, index) => <a href={url} key={index}
                                                                             title={"Share on " + locationName}
                                                                             target="_blank"
                                                                             rel="nofollow noopener noreferrer"
                                                                             className={classNames(dig.sharedCountsButton, dig.ms2,"shared-counts-button", "shared-counts-no-count", className)}
                                                                             data-postid="7948"
                                                                             data-social-network="Facebook"
                                                                             data-social-action="Share"
                                                                             data-social-target={window.location}>{element}</a>


    return <div className={classNames(dig.sharedCountsWrap, "shared-counts-wrap", "style-slim")}>
        {shareLinks.map((link, index) => socialButton(link, index))}
        <button title={"Copy Link"} onClick={() => copyToClipboard(window.location.href)} className={classNames(dig.sharedCountsButton, dig.ms0,dig.border0, "shared-counts-no-count")}>
            <img alt={"share popup"}
                 className={classNames(dig.digShareSvg, dig.shareLink)}
                 style={{backgroundColor: '#7289DA'}}
                 src="/wp-content/themes/dropingaming/assets/img/svg-icons/share-alt-solid.svg"/>
        </button>
        {/*{copied && <p>Copied!</p>}*/}
    </div>


}

import {ChangeEvent, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import compTypeToSingular from "src/api/hoc/compTypeToSingular";
import getVendors from "src/api/rest/getVendors";
import postRegistration from "src/api/rest/postRegistration";
import Bootstrap from "src/Bootstrap";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import DropVariables, {eMONEY_OUTPUT_CONTEXT, getStyles} from "src/variables/DropVariables";
import {iCompetitions, iInvitation} from "src/variables/sharedInterfaces";
import classNames from "classnames";

// custom styles
import style from './style.module.scss';
import deleteInvitation from "../../../api/rest/deleteInvitation";
import putTeamRoster from "../../../api/rest/putTeamRoster";
import CreateTeam from "../../Profile/Teams/Modals/CreateTeam";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import getTeams from "../../../api/rest/getTeams";
import {COMPETITION_PATH} from "../Competition";
import {globalNavigate} from "../../../api/hoc/GlobalHistory";
import isPRO from "../../../variables/isPRO";
import {getMetaValue} from "../../../api/hoc/getMetaValue";
import getUserMeta from "../../../api/rest/getUserMeta";
// import moment from "moment";

interface iTournamentProperties extends WithRouter {
    handleClose: () => void,
    competition: iCompetitions,
    invitation?: iInvitation
}


export const availableStreamingPlatforms = [
    'none', 'Twitch', 'YouTube', 'Facebook','Kick'
];

export default function JoinWithTeam(props: iTournamentProperties) {

    const {users, id, vendors, teams} = Bootstrap.bootstrap.state;

    const currentUser = users?.find(user => user.ID === id);

    useEffect(() => {

        getVendors();

        id !== 0 && getTeams({
            userId: id,
        });

        currentUser !== undefined && getUserMeta({user: currentUser});

    })

    const {handleClose, competition, invitation} = props;

    const [selectedVendor, setSelectedVendor] = useState<string>(competition.vendors?.[0] || '');

    if (selectedVendor === '' && undefined !== competition.vendors?.[0]) {
        setSelectedVendor(competition.vendors?.[0])
    }

    const [showCreateTeam, setShowCreateTeam] = useState(false);

    const [agreedTerms, setAgreedTerms] = useState(false);

    const [selectedTeamId, setSelectedTeamId] = useState(0);

    const [selectedStreamingPlatform, setSelectedStreamingPlatform] = useState('none');

    const [streamingPlatformUrl, setStreamingPlatformUrl] = useState<string | undefined>(undefined);

    const [joinCompetitionLoading, setJoinCompetitionLoading] = useState(false);

    const [passwordValue, setPasswordValue] = useState('');

    const [needsGamertagUpdate, setNeedsGamertagUpdate] = useState(false);

    const gamerTagMeta = currentUser?.userMeta?.find(meta => meta.meta_key === ("gamertag-" + selectedVendor));

    const [gamertag, setGamertag] = useState(gamerTagMeta?.meta_value ?? '');


    // not sure of a better way around this
    console.log('gamertag: ', gamertag, ' meta gamertag: ', gamerTagMeta?.meta_value, 'needs update: ', needsGamertagUpdate);
    needsGamertagUpdate && gamertag === '' && gamertag !== (gamerTagMeta?.meta_value ?? '') && setGamertag(gamerTagMeta?.meta_value ?? '');

    if (vendors === undefined || vendors === null || selectedVendor === '') {

        return <Loading message={'Loading vendor information!'}/>

    }

    if (currentUser === undefined || currentUser === null || currentUser.userMeta === null) {

        return <Loading message={'Loading user information!'}/>

    }

    // we have enough info to recheck gamertag
    !needsGamertagUpdate && setNeedsGamertagUpdate(true);

    const dig = getStyles(style);

    const customStreamingMeta = currentUser?.userMeta?.find(meta => meta.meta_key === ("_streamer_url" + selectedVendor));

    const customStreaming = customStreamingMeta?.meta_value;

    // replace whitespace with nothing to make sure someone cant just enter spaces
    // TODO: proper regex for validating this shit
    const hasGamertag = gamertag !== undefined && gamertag.replace(/\s+/g, '') !== '';

    const regularJoin = invitation === undefined || invitation.team_id === null || invitation.team_id === undefined;

    const requiresPassword = regularJoin && competition.pin === 1;

    console.warn('hasGamertag', hasGamertag, gamertag, gamerTagMeta, selectedVendor, customStreaming)

    function redirectToComp() {
        globalNavigate('/' + COMPETITION_PATH + '/' + competition.comp_id);
    }

    // gamer-tag
    const vendorInfo = vendors?.find(vendor => vendor.vendor_name === selectedVendor) || undefined

    const handleShowCreateTeam = () =>
        setShowCreateTeam(!showCreateTeam);


    let myTwitchStreamingPlatform, myYouTubeStreamingPlatform, myFacebookStreamingPlatform, myKickStreamingPlatform;

    if (currentUser !== undefined) {
        myTwitchStreamingPlatform = getMetaValue(currentUser, 'gamertag-twitch').value;
        myYouTubeStreamingPlatform = getMetaValue(currentUser, 'gamertag-youtube').value;
        myFacebookStreamingPlatform = getMetaValue(currentUser, 'gamertag-facebook').value;
        myKickStreamingPlatform = getMetaValue(currentUser, 'gamertag-kick').value;
    }


    // TODO: much needed value verification
    const join = async () => {

        if (undefined === competition.comp_id
            || undefined === vendorInfo?.vendor_id
            || undefined === vendorInfo?.vendor_name
            || undefined === gamertag
        ) {

            console.log('error reg', competition, vendors, vendorInfo, '(' + selectedVendor + ')', gamertag, '(' + competition.vendors?.[0] + ')', competition.vendors)

            toast.error('An unexpected issue has occurred.', DropVariables.toastOptions);

            return;

        }

        if (requiresPassword && passwordValue === "") {

            //TODO: highlight the password entry block?
            toast.error('This competition requires you to enter a password to join.', DropVariables.toastOptions);

            return;

        }

        setJoinCompetitionLoading(true);

        // invitation.team_id is null or undefined if this is a match challenge invite (joining as the opposing team)
        if (regularJoin) {

            await postRegistration({
                user_id: id,
                competitions_id: competition.comp_id,
                team_id: selectedTeamId,
                vendor_id: vendorInfo.vendor_id,
                vendor_name: vendorInfo?.vendor_name,
                vendor_gamertag: gamertag,
                streaming_platform: selectedStreamingPlatform,
                streaming_url: streamingPlatformUrl,
                meta_value: passwordValue,
                success: (_response) => {

                    const joinedWithTeam = teams?.find(team => team.user_team_id === selectedTeamId)

                    console.log('joinedWithTeam', joinedWithTeam)

                    handleClose();

                    return 'You have successfully joined the competition!';

                }
            });
        } else {
            let teamRoster = teams?.find(team => team.user_team_id === invitation.team_id)?.rosterDetails;

            if (Array.isArray(teamRoster)) {

                let inviteeRoster = teamRoster?.find(roster => roster.uid === invitation.user_id);

                if (inviteeRoster?.acceptance === 1) {

                    if (undefined === invitation.invitation_id
                        || undefined === inviteeRoster.id
                    ) {
                        toast.error('An unexpected error has occurred deleting the invitation, please try again.')
                        return;
                    }

                    await deleteInvitation({
                        id: inviteeRoster.id,
                        invitation_id: invitation.invitation_id,
                        vendor_id: vendorInfo?.vendor_id,
                        vendor_name: vendorInfo?.vendor_name,
                        vendor_gamertag: gamertag,
                        streaming_url: streamingPlatformUrl,
                        streaming_platform: selectedStreamingPlatform
                    });

                } else {

                    if (undefined === invitation.invitation_id
                        || undefined === inviteeRoster?.id
                    ) {
                        toast.error('An unexpected error has occurred, please try again.')
                        return;
                    }

                    await putTeamRoster({
                        id: inviteeRoster.id,
                        invitation_id: invitation.invitation_id,
                        vendor_id: vendorInfo?.vendor_id,
                        vendor_name: vendorInfo?.vendor_name,
                        vendor_gamertag: gamertag,
                        user_team_id: invitation.team_id,
                        streaming_url: streamingPlatformUrl,
                        streaming_platform: selectedStreamingPlatform
                    });

                }
            }
        }

        setJoinCompetitionLoading(false);


    }

    // loop through the Registration state
    // const foundSameTimeComp = registrations?.filter(reg => reg.status === 0).find(registration => {
    //     return !!(registration.user_id === id && competition.datetime &&
    //         registration.comp_datetime &&
    //         registration.end_datetime &&
    //         competition.end_datetime &&
    //         moment(registration.comp_datetime) <= moment(competition.end_datetime) &&
    //         moment(registration.end_datetime) >= moment(competition.datetime));
    //
    // })

    const foundSameTimeComp = false


    const correctlySizedTeams = teams?.filter((team) =>
        team.team_size_id === competition.team_size &&
        true === team.rosterAccepted?.includes(id)) || [];

    return <div className={classNames(dig.modalContent, dig.rounded3, dig.border0, dig.p3, dig.bgDark)}>
        <div className={classNames(dig.modalHeader, dig.rounded0, dig.border0, dig.py3)}>
            <h2 className={classNames(dig.modalTitle, dig.textWhite, "font-weight-normal text-left")}
                id="staticBackdropLabel">
                <small
                    className={classNames(dig.textMuted, dig.dBlock, dig.fontSize1P2Rem, "text-left")}>{compTypeToSingular(competition?.comp_type_id)}</small>
                {competition.name} </h2>
            <div onClick={handleClose} className={dig.close} data-dismiss="modal"
                 aria-label="Close">
                <i className={classNames(dig.fas, dig.m0, "fas fa-times")}></i>
            </div>
        </div>

        {

            foundSameTimeComp ?
                <>
                    <div className={classNames(dig.modalBody)}>
                        <small className={classNames(dig.mb3, dig.dBlock)}>You can not have multiple competition registrations at the same time.</small>
                    </div>
                </>
                :
                <>

                    <div className={classNames(dig.modalBody)}>
                        <form className={classNames(dig.rounded0, dig.border0, dig.bgTransparent, dig.digFormWizard, "needs-validation ")} role="form">
                            <div className={classNames(dig.accordion, dig.w100, dig.border0, dig.rounded0)}
                                 id="tournament_join_wizard">

                                <ExpandableBox header={hasGamertag ? ("Gamertag:  " + gamertag) : "Add Gamertag"}>
                                    <div className={classNames("card-body")}>
                                        <small className={classNames(dig.mb3, dig.dBlock)}>This tournament requires a gamertag
                                            from one of the available options below</small>
                                        <select
                                            className={classNames(dig.border0, dig.formControl)}
                                            id="tournament_gamertag_platform_select" name="vendor_id"
                                            style={{marginBottom: "10px"}}
                                            onChange={(event) => {
                                                setNeedsGamertagUpdate(false);
                                                setSelectedVendor(event.target.value);
                                                setGamertag(currentUser?.userMeta?.find(meta => meta.meta_key === ("gamertag-" + event.target.value))?.meta_value ?? '');
                                            }}>
                                            {competition.vendors?.map((vendor, index) =>
                                                <option key={index}>{vendor}</option>
                                            )}
                                        </select>
                                        <input
                                            className={classNames(dig.border0, dig.rounded0, dig.formControl)}
                                            id="dig_gamertag" name="dig_gamertag" type="text"
                                            value={gamertag}
                                            placeholder={"Enter gamertag"}
                                            checked={hasGamertag}
                                            onChange={(event: ChangeEvent<HTMLInputElement>) => setGamertag(event.target.value)}/>

                                    </div>
                                </ExpandableBox>

                                {requiresPassword && <ExpandableBox header={"Password Entry"}>
                                    <div className={classNames("card-body")}>
                                        <small className={classNames(dig.mb3, dig.dBlock)}>
                                            This competition requires a password to join.
                                        </small>
                                        <input
                                            className={classNames(dig.border0, dig.rounded0, dig.formControl)}
                                            id="dig_comp_password" name="dig_comp_password" type="text"
                                            placeholder={"Enter password"}
                                            onChange={(e) => {

                                                setPasswordValue(e.target.value);

                                            }}
                                        />
                                    </div>
                                </ExpandableBox>}

                                <ExpandableBox header="Are you streaming?" startCollapsed={true}>
                                    <div className={classNames(dig.colSm, dig.px3)}>

                                        <select
                                            className={classNames(dig.formControl, dig.border0, dig.rounded0, "join-streaming")}
                                            name="streaming" style={{marginBottom: "10px"}}
                                            onChange={(event: ChangeEvent<HTMLSelectElement>) => {
                                                setSelectedStreamingPlatform(event.target.value)
                                                setStreamingPlatformUrl(undefined);
                                            }}>
                                            {availableStreamingPlatforms.map((platform, index) =>
                                                <option key={index}
                                                        value={platform}>{platform === availableStreamingPlatforms[0] ? 'Not streaming' : platform}</option>)}
                                        </select>

                                        {selectedStreamingPlatform !== 'none' && <input
                                            className={classNames(dig.formControl, dig.border0, dig.rounded0, dig.textBlack)}
                                            id="streaming_url"
                                            name="streaming_url"
                                            style={{color: 'black'}}

                                            type="text"
                                            value={streamingPlatformUrl ?? (selectedStreamingPlatform === 'Twitch' ? (myTwitchStreamingPlatform ?? '') : selectedStreamingPlatform === 'YouTube' ? (myYouTubeStreamingPlatform ?? '') : selectedStreamingPlatform === 'Facebook' ? (myFacebookStreamingPlatform ?? '') : (myKickStreamingPlatform ?? ''))}

                                            onChange={(event) => setStreamingPlatformUrl(event.target.value)}
                                            placeholder="Enter a valid URL to your stream"/>}

                                    </div>
                                </ExpandableBox>


                                {regularJoin && <ExpandableBox header="Select Team" startCollapsed={agreedTerms}>
                                    {0 !== correctlySizedTeams.length && <>
                                        <div className={classNames(dig.colSm, dig.px4)}>

                                            <div className={classNames(dig.listGroup, dig.selectTeamGroup, dig.overflowAuto, dig.rounded0)}>

                                                {correctlySizedTeams?.map((team, index) => {

                                                    const teamFull = team.team_size_id === (team.rosterAccepted?.length || 0) + (team.rosterPending?.length || 0)

                                                    const teamBoxSelectableStyle = team.user_team_id === selectedTeamId ? dig.active : ""

                                                    return <div key={index}
                                                                className={classNames(dig.listGroupItem, dig.rounded0, dig.listGroupItemAction, dig.selectTeam) + teamBoxSelectableStyle}
                                                                onClick={() => team.user_team_id && setSelectedTeamId(team.user_team_id)}>
                                                        <div className={classNames(dig.container, dig.p0)}>
                                                            <div className={classNames(dig.row, dig.w100, "no-gutters ")}>
                                                                <div className={classNames(dig.col12, "font-weight-bold")}>
                                                                    <Link to={`/team/${team.user_team_id}`}>
                                                                        <h4 className={classNames(dig.dInline, dig.me2, "team_name_title")}>
                                                                            {team.team_name}

                                                                        </h4>
                                                                    </Link>
                                                                    <div
                                                                        className={classNames("dig-" + (teamFull ? "green" : "orange") + " font-weight-bold ", dig.dInline)}>
                                                                        <i className={"fas fa-" + (teamFull ? "check-square" : "flag")}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className={classNames(dig.row, dig.g0, dig.w100)}>
                                                                {team?.rosterAccepted?.map((userId, indexKey) => {

                                                                    const user = users?.find(user => user.ID === userId)

                                                                    return <div key={indexKey}
                                                                                className={classNames(dig.col12)}>
                                                                        <div
                                                                            className={classNames(dig.badge, dig.bgPrimary, dig.roundedPill)}>{user?.user_nicename}</div>

                                                                    </div>


                                                                })}

                                                                {team?.rosterPending?.map((userId, indexKey) => {

                                                                    const user = users?.find(user => user.ID === userId)

                                                                    return <div key={indexKey}
                                                                                className={classNames(dig.col12)}>
                                                                        <div
                                                                            className={classNames(dig.badge, dig.bgPrimary, dig.roundedPill)}>
                                                                            {user?.user_nicename ||
                                                                            <div className={classNames(dig.roundedPill)}><Loading message={'Loading User Info'}/></div>}
                                                                            <small>(pending)</small></div>
                                                                    </div>
                                                                })}

                                                            </div>
                                                            <div className={classNames(dig.row, dig.w100, "no-gutters ")}>
                                                                <div className={"dig.col12"}>
                                                                    <small className={classNames(dig.textMuted)}>
                                                                        {team.created && DropVariables.convertPstToLocal(team.created)}
                                                                    </small>
                                                                </div>
                                                                <small className={classNames(dig.textMuted)}>
                                                                </small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }) || <Loading message={"Loading Teams!`"}/>}

                                            </div>
                                        </div>
                                        <h4>or</h4>
                                    </>}

                                    {showCreateTeam && <CreateTeam {...props}
                                                                   onlyTeamSize={competition.team_size}
                                                                   handleClose={handleShowCreateTeam}/>}


                                    <div className={classNames(dig.colSm, dig.dFlex, dig.justifyContentCenter, dig.mt4, dig.p0)}>
                                        <button
                                            onClick={() => handleShowCreateTeam()}
                                            type="button"
                                            className={classNames("add-new-team", dig.btn, dig.fwBold, dig.btnLg, dig.textWhite, dig.py3, dig.px5, dig.digBtnBlue, dig.border0, dig.rounded0, dig.fontSize1Em)}>
                                            <i className={classNames(dig.fas, "fa-plus", dig.me3)}></i>
                                            Add New Team
                                        </button>
                                    </div>
                                </ExpandableBox>}

                                <ExpandableBox header="Agree to Terms & Rules">
                                    <div className={classNames(dig.row, dig.mx0, dig.mt2, dig.p3)} id="dig-enter-agree">
                                        <h6 className={"font-weight-bold"}>TERMS &amp; CONDITIONS</h6>
                                        <small>I understand that I am committing to entering this competition. I have read and
                                            understand the Game Rules as described on the page and I am authorizing Drop-In
                                            Gaming to debit my Drop-In Gaming Account for my buy-in. If you are registered for
                                            any type of competition or event, your payment is non-refundable once that event has
                                            started. Also, if you are disqualified for any reason you will not receive a refund.
                                            View all terms and conditions <a className={"dig-blue"} target="_blank"
                                                                             href="/terms-conditions/">here</a>.
                                        </small>
                                    </div>
                                    <div
                                        className={classNames(dig.mt4, dig.dFlex, dig.justifyContentCenter, "form-group form-check")}>
                                        <div className={classNames(dig.border, dig.py2, dig.px5, dig.dFlex, dig.aligncenter)}
                                             onClick={() => setAgreedTerms(!agreedTerms)}>
                                            {/* readOnly is here because setAgreedTerms happens in a parent element */}
                                            <input readOnly type="checkbox" id="terms_and_conditions" name="terms_and_conditions"
                                                   checked={agreedTerms}/>
                                            <label className={classNames("form-check-label", dig.ms2)} htmlFor="agree-to-terms">
                                                Agree to Terms &amp; Rules
                                            </label>
                                            <div
                                                className={classNames(dig.textCenter, dig.dNone, "dig-required-field invalid-feedback")}>
                                                You must agree to the terms to continue.
                                            </div>
                                        </div>
                                    </div>
                                </ExpandableBox>


                            </div>
                            <div
                                className={classNames(dig.row, dig.mx0, dig.dFlex, dig.alignItemsStart, dig.flexColumn, dig.mt2, dig.bgTransparent, dig.p3, dig.rounded)}>
                                <div className={classNames(dig.colSm, dig.p0)}>
                                    <div
                                        className={classNames(dig.colSm, dig.dFlex, dig.flexColumn, dig.justifyContentCenter, dig.p0, "join-entry-fee")}>
                                        {competition.buy_in && competition.comp_percentage
                                            ?
                                            <h3 className={classNames(dig.mb2, dig.dFlex, dig.justifyContentCenter, dig.textCenter, "font-weight-normal ")}>
                                                <div>Buy-in</div>
                                                &nbsp;&nbsp;
                                                <div className={classNames("dig-orange font-weight-bold ml-2 mr-2")}>
                                                    <div
                                                        className={"dig-positive-balance"}>
                                                        ${DropVariables.formatMoney(competition.buy_in, eMONEY_OUTPUT_CONTEXT.BUY_IN)}
                                                    </div>
                                                </div>
                                                {competition.comp_type === '2' || competition.comp_type === '3' && <>
                                                    &nbsp;&nbsp;&nbsp;•&nbsp;&nbsp;&nbsp;
                                                    <div className={classNames("dig-orange font-weight-bold ml-2 mr-2")}>
                                                        <div
                                                            className={"dig-positive-balance"}>
                                                            ${DropVariables.formatMoney(parseFloat(competition.buy_in) * (1 - parseFloat(competition.comp_percentage)),
                                                            eMONEY_OUTPUT_CONTEXT.ENTRY_FEE)}
                                                        </div>
                                                    </div>
                                                    &nbsp;
                                                    Entry
                                                </>}
                                            </h3> : <Loading message={'Loading buy in!'}/>}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={classNames(dig.modalFooter, dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.border0)}>
                        {isPRO() ?
                            <button
                                disabled={!agreedTerms || !hasGamertag || joinCompetitionLoading || (invitation === undefined && 0 === selectedTeamId) || (requiresPassword && passwordValue === '')}
                                className={classNames(dig.btn, dig.rounded0, dig.fontSize1Em, dig.w100, dig.btnLg, dig.btnSuccess, "pull-right dig-finish  font-weight-bold text-uppercase")}
                                type="button" onClick={async () => {
                                await join();
                                redirectToComp()
                            }}>{joinCompetitionLoading ? "Joining..." : "Join"}
                            </button>
                            :
                            <button
                                disabled={!agreedTerms || !hasGamertag || joinCompetitionLoading || (invitation === undefined && 0 === selectedTeamId) || (requiresPassword && passwordValue === '')}
                                className={classNames(dig.btn, dig.rounded0, dig.fontSize1Em, dig.w100, dig.btnLg, dig.btnSuccess, "pull-right dig-finish  font-weight-bold text-uppercase")}
                                type="button" onClick={async () => {
                                await join();
                                redirectToComp()
                            }}>{joinCompetitionLoading ? "Joining..." : "Pay & Join"}
                            </button>
                        }
                    </div>
                </>
        }

    </div>


}

import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iPermissions, iUser} from "src/variables/sharedInterfaces";


export default restApi<{
    user_ids: number[],
}, iPermissions, {}, iGetC6RestResponse<iPermissions>>({
        axios: DropVariables.axios,
        tableName: C6.dig_organizations.TABLE_NAME,
        requestMethod: GET,
        queryCallback: (request) => {
            request.error = 'An unknown issue occurred fetching permissions!'

            if (!request.user_ids?.length) {

                throw new Error('No user ids provided!')

            }

            // The order of the joins matter!!!
            return {
                [C6.SELECT]: [
                    C6.users.ID,
                    C6.dig_organizations.ORGANIZATION_ID,
                    C6.dig_organizations.ORGANIZATION_NAME,
                    [C6.GROUP_CONCAT, C6.dig_features.FEATURE_CODE],
                    [C6.GROUP_CONCAT, C6.dig_user_groups.GROUP_ID],
                    [C6.GROUP_CONCAT, C6.dig_group_references.ALLOWED_TO_GRANT_GROUP_ID]
                ],
                [C6.JOIN]: {
                    [C6.INNER]: {
                        [C6.dig_user_organizations.TABLE_NAME]: {
                            [C6.dig_user_organizations.ORGANIZATION_ID]: C6.dig_organizations.ORGANIZATION_ID,
                        },
                        [C6.users.TABLE_NAME]: [
                            C6.users.ID,
                            C6.dig_user_organizations.USER_ID
                        ]
                    },
                    [C6.LEFT]: {
                        [C6.dig_groups.TABLE_NAME]: {
                            [C6.dig_organizations.ORGANIZATION_ID]: C6.dig_groups.ORGANIZATION_ID,
                        },
                        [C6.dig_user_groups.TABLE_NAME]: {
                            [C6.dig_user_groups.GROUP_ID]: C6.dig_groups.ENTITY_ID,
                            [C6.dig_user_groups.USER_ID]: C6.users.ID
                        },
                        [C6.dig_group_references.TABLE_NAME]: [
                            C6.dig_user_groups.GROUP_ID,
                            C6.dig_group_references.GROUP_ID
                        ],
                        [C6.dig_feature_group_references.TABLE_NAME]: {
                            [C6.dig_feature_group_references.GROUP_ENTITY_ID]: C6.dig_user_groups.GROUP_ID,
                        },
                        [C6.dig_features.TABLE_NAME]: [
                            C6.dig_feature_group_references.FEATURE_ENTITY_ID,
                            C6.dig_features.FEATURE_ENTITY_ID
                        ],
                    }
                },
                [C6.WHERE]: {
                    1: {
                        [C6.users.ID]: [C6.IN, request.user_ids],
                    }
                },
                [C6.GROUP_BY]: [
                    C6.users.ID,
                ],
                [C6.PAGINATION]: {
                    [C6.LIMIT]: 1000
                }
            }
        },
        responseCallback: (response, _request) => {

            let updatedUserPermissions: iUser[] = []

            response?.data?.rest.forEach(permission => {

                const updatedUser = updatedUserPermissions.find(user => user.ID === permission.ID)

                if (undefined === permission.ID) {

                    throw new Error('Permission user id was not sent with request')

                }

                if (undefined === updatedUser) {

                    updatedUserPermissions.push({
                        ID: permission.ID,
                        permissions: [permission]
                    })

                }

            })


            updateRestfulObjectArray<iUser>(updatedUserPermissions, "ID", "users")

        }

    }
)

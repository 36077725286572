import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_User_Groups, iUsermeta} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iGroups, iPermissions, iUser} from "src/variables/sharedInterfaces";
import Bootstrap from "../../Bootstrap";

export interface iPutUserMeta extends iUsermeta {
    group_id: number;
    user_id: number;
}

export default restApi<{}, iDig_User_Groups, {}, iPostC6RestResponse<iDig_User_Groups>>({
    axios: DropVariables.axios,
    tableName: C6.dig_user_groups.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {

        // permissions aside on who can grant

        const changeUser = Bootstrap.bootstrap.state.users?.find(user => request.user_id === user.ID)

        if (changeUser === undefined) {

            throw new Error('State error, editing a user with no Bootstrap reference; this is unexpected')

        }

        const group = Bootstrap.bootstrap.state.groups?.find(group => group.entity_id === request.group_id)

        if (undefined === group) {

            throw new Error('The group we\'ve attempted to add the user to was not found in the Bootstrap state; this is unexpeted');

        }

        const permissions = changeUser.permissions

        if (undefined === permissions || undefined === permissions.find(organization => organization.organization_id === group.organization_id)) {

            throw new Error('The user we\'ve attempted to add the group to does not have permissions for the organization the group is in; this is unexpected')

        }

        request.success = 'Successfully updated user group association.'

        request.error = 'An unknown issue occurred adding the group to the user. We will be looking into this shortly.'

        return request

    },
    responseCallback: (_response, request, id) => {

            id && updateRestfulObjectArray<iUser>(previousState => {

                const fullGroup: iGroups | undefined = previousState.groups?.find((group: iGroups) => group.entity_id === request.group_id);

                const currentEditUser = previousState.users?.find(user => user.ID === request.user_id)

                if (undefined === currentEditUser) {
                    return []
                }

                currentEditUser.permissions ??= []

                const permissions: iPermissions[] = currentEditUser.permissions

                // permissions is a list of organizations with their associated groups and feature codes
                permissions.find((permission, index) => {

                    console.log('permission, permissions[index]', index, permission, fullGroup)

                    // if the organization_id doesn't match the group's organization_id, we don't care about this permission
                    if (permission.organization_id !== fullGroup?.organization_id) {
                        return false
                    }

                    if (undefined === fullGroup?.group_name || undefined === currentEditUser) {
                        throw new Error('Failed to parse user permissions (E_GROUP)')
                    }

                    (permissions[index].group_name ??= []).push(fullGroup.group_name)

                    permissions[index].feature_code ??= []

                    previousState.groups?.forEach(group => group.feature_code
                        && permissions[index].feature_code?.push(...group.feature_code.filter(featureCode => !permission.feature_code?.includes(featureCode))))

                    return true

                })


                currentEditUser.permissions = permissions

                console.log('currentEditUser.permissions', currentEditUser.permissions)

                return [currentEditUser]

            }, "ID", "users")

    }
});

import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import styles from "./style.module.scss";
import Button from "../../../components/Button/Button";


export const PRODUCT_PATH = 'product';

// core components

const dig = getStyles(styles)

export default function Product() {

    // const mockStoreData: {
    //     itemName: string,
    //     itemPrice: number,
    //     itemOldPrice?: number,
    //     itemImage: string,
    //     href: string,
    //     addToCart?: string,
    // }[] = [
    //     {
    //         itemName: "Cougar Armor Pro",
    //         itemPrice: 296.10,
    //         itemOldPrice: 329.00,
    //         itemImage: "/wp-content/uploads/2021/03/cougar-arnor-pro-black-1-300x300.png",
    //         href: "#",
    //     },
    //     {
    //         itemName: "Drop-In Gaming Retro Logo Cap Black/Gray",
    //         itemPrice: 35.00,
    //         itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/retro-logo-cap-black-gray.png",
    //         href: "http://local.dropingaming.com:8080/product/drop-in-gaming-retro-logo-trucker-cap-black-gray/",
    //     },
    //     {
    //         itemName: "Drop-In Gaming Retro Logo Cap Gray/Black",
    //         itemPrice: 35.00,
    //         itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/retro-logo-cap-gray-black-300x300.png",
    //         href: "http://local.dropingaming.com:8080/product/drop-in-gaming-retro-logo-trucker-cap-gray-black/"
    //     },
    //     {
    //         itemName: "Drop-In Gaming Retro Logo Cap Gray/White",
    //         itemPrice: 35.00,
    //         itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/retro-logo-cap-gray-white-300x300.png",
    //         href: "http://local.dropingaming.com:8080/product/drop-in-gaming-retro-logo-trucker-cap-gray-white/"
    //     },
    //     {
    //         itemName: "Drop-In Gaming Retro Logo Light-Grey/Black",
    //         itemPrice: 35.00,
    //         itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/retro-logo-cap-light-gray-black-300x300.png",
    //         href: "#",
    //     },
    //     {
    //         itemName: "DXRacer Racing Series Gaming Chair",
    //         itemPrice: 359.10,
    //         itemOldPrice: 399.00,
    //         itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/03/dxracer-oh-rv131-nr-1_e33ff88d-5a2e-45de-83d4-7bd2b4757f33-300x300.jpg",
    //         href: "#",
    //     },
    //     {
    //         itemName: "Ewin Gaming Chair",
    //         itemPrice: 224.10,
    //         itemOldPrice: 299.00,
    //         itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/03/cld-pink-1_fe3e5699-19f9-4e35-a763-fac447062f69-300x300.jpg",
    //         href: "#",
    //     },
    //     {
    //         itemName: "Where We Droppin’ T-Shirt (Blue)",
    //         itemPrice: 25.00,
    //         itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/wwd-blue-300x300.png",
    //         href: "#"
    //     },
    //     {
    //         itemName: "Where We Droppin’ T-Shirt (Red)",
    //         itemPrice: 25.00,
    //         itemImage: "http://local.dropingaming.com:8080/wp-content/uploads/2021/01/wwd-red-300x300.png",
    //         href: "#"
    //     },
    // ]


    return <div className={classNames(dig.container, dig.mt2)}>

        <h1 className={
            classNames(dig.textCapitalize, dig.textPrimary, dig.fwBold, dig.px5)
        }>Product Page </h1>

        <div className={classNames(dig.dFlex)}>

            <img width="100" height="100"
                 src="/wp-content/uploads/2021/03/cougar-arnor-pro-black-1-300x300.png"
                 className={classNames(dig.productImg)}
                 alt="" loading="lazy"/>

            <div className={classNames(dig.bgSecondary, dig.w50, dig.mAuto, dig.p3, dig.bgOpacity25)}>
                <div className={classNames(dig.dFlex, dig. my2)}>
                    <div className={classNames(dig.textCapitalize, dig.fwBold, dig.px1)}>Price:</div>
                    <div className={classNames(dig.textPrimary)}>$35.00</div>
                </div>
                <div className={classNames(dig.textWhite)}>Drop-In Gaming’s patch collection hats are here! Grab the ultimate gamers hat featuring mesh back, retro-style DG patch logo, and adjustable back.</div>
                <div className={classNames(dig.textCapitalize, dig.fwBold, dig.px1, dig.mt5)}>Quantity:</div>
                <div className={classNames(dig.dFlex)}>
                    <input className={classNames(dig.w50)}/>
                    <Button
                        className={classNames(dig.btn, dig.ggPrimaryBtn, dig.textWhite, dig.px2, dig.py2, dig.borderRounded5, dig.fwBold, dig.ms2)}>
                    Add to Cart</Button>
                </div>

            </div>

        </div>

    </div>


}


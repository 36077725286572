import restApi, {DELETE, iDeleteC6RestResponse} from "src/api/API";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";
import {C6, iDig_Organizations} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";

export default restApi<{
    organization_id: number;
}, iDig_Organizations, {}, iDeleteC6RestResponse<iDig_Organizations>>({
    axios: DropVariables.axios,
    tableName: C6.dig_organizations.TABLE_NAME,
    requestMethod: DELETE,
    skipPrimaryCheck: true, // todo - @RichardTMiles primary key checks with structured data (WHERE &| JOINS)
    queryCallback: (request) => {
        request.success = 'Successfully deleted the organization.'
        request.error = 'Failed to remove the organization. Please try again later.'
        return {
            [C6.WHERE]: {
                [C6.dig_organizations.ORGANIZATION_ID]: request.organization_id,
            }
        }
    },
    responseCallback: (_response, request, id) => {

        id && deleteRestfulObjectArrays<iDig_Organizations>([request], "organization_id", "organizations")

    }
});



import classNames from "classnames";
import React, {useState} from "react";
import {apiReturn, iGetC6RestResponse} from "src/api/API";
import Bootstrap from "src/Bootstrap";
import Button from "src/components/Button/Button";
import CompetitionCard from "src/components/CompetitionCard/CompetitionCard";
import {getStyles} from "src/variables/DropVariables";
import {iCompetitions} from "src/variables/sharedInterfaces";
import Loading from "../Loading/Loading";
import CompetitionSort from "../../api/hoc/CompetitionSort";
import styles from "./style.module.scss";

export default function CompetitionGrid(props: {
    title?: string,
    error?: string,
    loadMore?: () => apiReturn<iGetC6RestResponse<iCompetitions, any>>,
    competitionsToDisplay?: iCompetitions[] | null,
    displaySort?: boolean,
}) {

    let {competitionsToDisplay, title, error, displaySort, loadMore} = props;

    const [loadNextPage, setLoadNextPage] = React.useState<Awaited<apiReturn<iGetC6RestResponse<iCompetitions>>>>(loadMore)

    const [isClicked, setIsClicked] = useState(false);


    interface iSortCompetitionInfo {
        sortName: string,
        sortFunction: (a: iCompetitions, b: iCompetitions, asc: boolean) => number,
        sortToggle: any[] // useState<boolean>(false);
    }

    let sortCompetitionInfo: iSortCompetitionInfo[] = [
        {
            sortName: 'title',
            sortFunction: CompetitionSort.sortCompetitionsNameAlphabetical,
            sortToggle: useState<boolean>(false)
        },
        {
            sortName: 'status',
            sortFunction: CompetitionSort.sortCompetitionsStatus,
            sortToggle: useState<boolean>(false)
        },
        {
            sortName: 'prize',
            sortFunction: CompetitionSort.sortCompetitionsPrize,
            sortToggle: useState<boolean>(false)
        },
        {
            sortName: 'buy-in',
            sortFunction: CompetitionSort.sortCompetitionsBuyIn,
            sortToggle: useState<boolean>(false)
        }
    ]


    const [sortOrder, setSortOrder] = useState<string>("");

    const {id} = Bootstrap.bootstrap.state


    if (undefined === competitionsToDisplay ||
        null === competitionsToDisplay ||
        null === loadNextPage
    ) {
        return <Loading message={"Loading competitions!"}/>

    }

    console.log('competitionsToDisplay',competitionsToDisplay)


    // use sortOrder to sort competitions
    if(sortOrder){
        const sortInfo = sortCompetitionInfo.find((sortInfo) => sortInfo.sortName === sortOrder);
        if (undefined !== sortInfo) {
            competitionsToDisplay = competitionsToDisplay?.sort((a, b) => sortInfo.sortFunction(a, b, sortInfo.sortToggle[0]));
        }
    }

    const dig = getStyles(styles)


    const sortButton = (sortInfo: iSortCompetitionInfo | undefined, index: number) => {

        if (undefined === sortInfo) {
            return null;
        }


        return <button key={index}
                       className={classNames(dig.btn, dig.btnSort, dig.colMd2)}
                       type="button"
                       onClick={() => {
                           setSortOrder(sortInfo.sortName);

                           sortInfo.sortToggle[1](!sortInfo.sortToggle[0]);
                           setIsClicked(prev => !prev);

                       }}>
            {sortInfo.sortName}
            <i className={classNames(sortInfo.sortToggle[0] ?? isClicked ? "fa fa-caret-up" : "fa fa-caret-down", dig.caret)}/>
        </button>
    }


    return <div
        className={classNames(dig.container, dig.digUserActionGroup, dig.upcomingEvents, dig.tournamentDashboard)}>
        <div className={classNames(dig.digUserActionGroup, dig.upcomingEvents)}>
            {null === competitionsToDisplay
            || 0 === competitionsToDisplay?.length
                ?
                <div id="myTabContent" className={dig.tabContent}>
                    <div id="all_tournament" role="tabpanel" aria-labelledby="all_tournament-tab"
                         className={classNames(dig.tabPane, dig.fade)}>
                        <div className={classNames(dig.row, dig.mt5)}></div>
                    </div>
                    <div id="brackets" role="tabpanel" aria-labelledby="bracketstab"
                         className={classNames(dig.tabPane, dig.fade, dig.active, dig.show)}>
                        <div className={classNames(dig.alert, dig.alertWarning, dig.my5)}>
                            <strong>
                                <i className={classNames("fas fa-radiation-alt")}/>
                            </strong>
                            <div>
                                {error || "All Competitions have completed. Check back daily for updates."}
                            </div>
                        </div>
                    </div>
                    <div id="free" role="tabpanel" aria-labelledby="freetab"
                         className={classNames("tab-pane fade")}>
                        <div className={classNames(dig.row, dig.mt5)}></div>
                    </div>
                </div>
                : <>
                    {displaySort ?
                        <div className={classNames(dig.dFlex, dig.justifyContentBetween, dig.sortMenu)}>
                            {sortCompetitionInfo.map((sort, index) => sortButton(sort, index))}
                        </div> : null}

                    {title !== undefined && <><h1 className={classNames(dig.mt4, dig.fs1)}>{title}</h1></>}
                    <div className={classNames(dig.my5, dig.mobileCompCard)}>
                        {competitionsToDisplay
                            .filter(value => true === (value?.registered_users?.includes(id) || false))
                            .concat(competitionsToDisplay
                                .filter(value => false === (value?.registered_users?.includes(id) || false)))
                            .map((value, key) =>
                                <CompetitionCard
                                    id={id}
                                    competition={value}
                                    key={key}/>
                            )}
                    </div>
                </>}
        </div>
        {loadMore && <Button onClick={async () => setLoadNextPage(await loadMore?.())} className={classNames(dig.btn, dig.btnPrimary, dig.mb5)}>
            Load More
        </Button>}
    </div>

}

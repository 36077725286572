import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Group_References} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iGroups} from "src/variables/sharedInterfaces";

export default restApi<{
    group_id: number,
    allowed_to_grant_group_id: number
}, iDig_Group_References, {}, iPostC6RestResponse<iDig_Group_References & iDig_Group_References>>({
    axios: DropVariables.axios,
    tableName: C6.dig_group_references.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Group can now grant access to group!'
        request.error ??= 'There was an issue creating this team. Please try again.'
        return request;
    },
    responseCallback: (_response, request, success) => {

        if ('boolean' !== typeof success) {
            return;
        }

        updateRestfulObjectArray<iGroups>(previousState => {
            const allOfTheGroups = (previousState.groups ?? []).map(group => {

                if (group.entity_id === request.group_id) {

                    group.allowed_to_grant_group_id ??= [];

                    group.allowed_to_grant_group_id.push(request.allowed_to_grant_group_id);

                }

                return group;

            })
            return allOfTheGroups ?? [];

        }, "entity_id", "groups")
    }

});
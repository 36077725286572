import classNames from "classnames";
import React, {useEffect} from "react";
import {Link} from "react-router-dom";
import Bootstrap from "src/Bootstrap";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {getStyles} from "src/variables/DropVariables";
import Loading from "src/components/Loading/Loading";
import getTournamentCategories from "src/api/rest/getTournamentCategories";


export const CATEGORIES_PATH = 'categories';

// core components
export default function Categories(props: {
    handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
} & WithRouter) {

    useEffect(() => {

        getTournamentCategories();

    }, [])

    const bootstrap = Bootstrap.bootstrap;

    const dig = getStyles()

    const cat_code = props.params.cat_code;
    const cat_id = bootstrap.state.categories?.filter(cat => cat.category_code === cat_code)[0].id

    const gameGrid: any = bootstrap.state.categories
        ?.filter(cat => cat.parent === cat_id)
        .map((value, key) => {

            if (undefined === value.id) {
                return undefined
            }

            const styleWrap = classNames(dig.cursorPointer, dig.game_row_item,
                dig.digBgNeutral2, dig.positionRelative, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter,
                dig.borderRounded5);

            const wrap = (children: JSX.Element) =>
                <Link
                    to={"/category/" + value.category_code + '/' + value.id}
                    key={key}
                    className={styleWrap}>{children}</Link>

            return wrap(("" === value.featured_image)
                ? <div
                    className={classNames(dig.gameOverlay, dig.h100, dig.positionAbsolute, dig.dFlex,
                        dig.alignItemsCenter, dig.justifyContentCenter, dig.borderRounded5
                    )}>
                    <img src={''}
                         className={classNames(dig.styleSvg, dig.digSecondaryFillColor, dig.borderRounded5)}
                         alt={''}/>
                </div>
                : <div
                    className={classNames(dig.gameFeaturedImage, dig.positionRelative, dig.borderRounded5)}
                    style={{background: "url(" + value.featured_image + ")"}}
                    data-bg={value.featured_image}
                >
                </div>);

        });

    return <div className={classNames(dig.container, dig.p4, dig.pt5)}>

        <h1 className={classNames(dig.dFlex, dig.flexColumn, dig.digBorderGreen, dig.borderLeft3, dig.ps4)}>
            <span className={classNames(dig.fontSize0P8Em)}>Tournaments</span>
            <span>{props.params.cat_code.toUpperCase()}</span>
        </h1>

        {undefined === gameGrid &&
            <Loading message={"Loading data!"}/> ||
            <div className={classNames(dig.gameRow, dig.my5, dig["gg__overview-game-list"])}>{gameGrid}</div>}

    </div>;

}



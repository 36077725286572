import {faEarthAmericas, faTimes,
    // faEdit, faEllipsisV, faUsers
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import Bootstrap from "src/Bootstrap";
import {eGLOBAL_CHAT_TABS} from "src/components/Chat/Chat";
import {getStyles} from "src/variables/DropVariables";
import DropVariables from "src/variables/DropVariables";

import ChatStyle from "../style.module.scss";

export default function ChatHeader() {

    const {bootstrap, rightSideBarTabOpened} = Bootstrap.bootstrap.state;

    const dig = getStyles(ChatStyle)

    const chatClosed = eGLOBAL_CHAT_TABS.NONE === rightSideBarTabOpened;

    const styleNavItem = classNames(dig.chatIcons)

    const color = DropVariables.getPrimaryColor()

    return <div className={classNames(dig.row, dig.g0, dig.w100, dig.chatHeader)}>
            {false === chatClosed && <div className={dig.col4}>
                <h3 className={classNames(
                    dig.textStart,
                    dig.m3,
                )} style={{color: color}}>
                    {eGLOBAL_CHAT_TABS[rightSideBarTabOpened]}
                </h3>
            </div>}
            <div className={classNames({
                [dig.col8]: false === chatClosed,
                [dig.col12]: chatClosed,
            })}>
                {/*The following icons are missing the functionality of... group chat, direct messaging, and displaying previous chats*/}

                <div className={classNames(dig.row, dig.g0,dig.h100, dig.justifyContentEnd, dig.alignContentCenter)}>

                    {/*<div className={classNames(styleNavItem, dig.col3, dig.dFlex, dig.justifyContentCenter)}>*/}
                    {/*    <FontAwesomeIcon color={color} icon={faUsers} size={'2x'}/>*/}
                    {/*</div>*/}
                    {/*<div className={classNames(styleNavItem, dig.col3, dig.dFlex, dig.justifyContentCenter)}>*/}
                    {/*    <FontAwesomeIcon color={color} icon={faEdit} size={'2x'}/>*/}
                    {/*</div>*/}
                    {/*<div className={classNames(styleNavItem, dig.col3, dig.dFlex, dig.justifyContentCenter)}>*/}
                    {/*    <FontAwesomeIcon color={color} icon={faEllipsisV} size={'2x'}/>*/}
                    {/*</div>*/}

                    <div className={classNames(styleNavItem, dig.col3, dig.dFlex, dig.justifyContentCenter)} onClick={() => {
                        bootstrap.setState({
                            rightSideBarTabOpened: chatClosed ? eGLOBAL_CHAT_TABS.CHAT : eGLOBAL_CHAT_TABS.NONE
                        })}}>
                        {chatClosed
                          ? <FontAwesomeIcon color={color} icon={faEarthAmericas} size={'2x'}/>
                          : <FontAwesomeIcon color={color} icon={faTimes} size={'2x'}/>}
                    </div>

                </div>
            </div>
        </div>
}

import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Transaction_Log, iDig_Wallet_Transactions} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iUser} from "src/variables/sharedInterfaces";


export default restApi<{
    amount: string,
    auth_status?: number;
    cc_addr_line_1: string;
    cc_addr_line_2?: string;
    cc_auth_code?: string;
    cc_city?: string;
    cc_country: string;
    cc_fname: string;
    cc_lname: string;
    cc_mask?: string;
    cc_response_code?: number;
    cc_state?: string;
    cc_zip?: string;
    cvv_response?: string;
    kt_sid: string;
    payment_token?: string;
    pp_authorization_amount?: string;
    pp_authorization_payer_email?: string;
    pp_authorization_status?: string;
    user_id: number;
}, iDig_Wallet_Transactions & iDig_Transaction_Log, {}, iPostC6RestResponse<iDig_Wallet_Transactions & iDig_Transaction_Log>>({
        axios: DropVariables.axios,
        tableName: [C6.dig_transaction_log.TABLE_NAME, C6.dig_wallet_transactions.TABLE_NAME, C6.dig_wallet_withdrawal_requests.TABLE_NAME],
        requestMethod: POST,
        queryCallback: (request) => {
            request.error ??= ()=> 'An unknown issue occurred while adding funds. Please try again later or reach out to support for assistance.';
            return request
        },
        responseCallback: (_response, request, id) => {

            id && updateRestfulObjectArray<iUser>(previousState => {
                return [{
                    ID: request.user_id,
                    funds: (previousState.users?.find(user => user.ID === previousState.id)?.funds || 0) + parseFloat(request.amount),
                }]
            }, "ID", "users")
        }
    });

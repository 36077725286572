import isTest from "src/variables/isTest";
import {useEffectOnce} from "src/api/hoc/useEffectOnce";

declare const window: Window &
    typeof globalThis & {
    google: any,
    googleTranslateElementInit
}

const GoogleTranslate = () => {

    const elementId = 'google_translate_element'


    isTest || useEffectOnce(() => {
        try {
            const tryGoogle = () => setTimeout(() => {
                if (undefined === window.google) {
                    console.log('google translate not loaded');
                    tryGoogle();
                } else {
                    console.log('loading google translate');
                    return new window.google.translate.TranslateElement(
                        {
                            pageLanguage: "en",
                            layout: window.google.translate.TranslateElement.InlineLayout.BUTTON,
                        },
                        elementId
                    );
                }
            }, 1000);
            tryGoogle();
        } catch (error) {
            console.log('google translate error', error);
        }
    });


    return <>
        <div id={elementId}></div>
    </>;
};

export default GoogleTranslate;
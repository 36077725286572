import classNames from "classnames";
import {useEffect, useState} from "react";
import DropVariables, {eMONEY_OUTPUT_CONTEXT, getStyles} from "src/variables/DropVariables";
import isGG from "src/variables/isGG";
import {eCOMPETITION, iInvitation} from "src/variables/sharedInterfaces";
import {useNavigate, useParams} from "react-router-dom";
import compTypeToSingular from "src/api/hoc/compTypeToSingular";
import Loading from "../Loading/Loading";
import Bootstrap from "src/Bootstrap";
import getTeams from "src/api/rest/getTeams";
import getUsers from "src/api/rest/getUsers";
import getCompetitions, {removeCompetitionFromCache} from "src/api/rest/getCompetitions";
import deleteTeamRoster from "src/api/rest/deleteTeamRoster";
import JoinWithTeam from "src/views/Competition/Modals/JoinWithTeam";
import Popup from "../Popup/Popup";
import getVendors from "../../api/rest/getVendors";
import deleteMatchChallengeInvitation from "../../api/rest/deleteMatchChallengeInvitation";
import {COMPETITION_PATH} from "../../views/Competition/Competition";
import {globalNavigate} from "../../api/hoc/GlobalHistory";

interface iDisplayInvitation {
    invitation: iInvitation,
    openJoinPopup?: () => void,
    darkMode: boolean
}


export default function ProfileInvitationCompCard(props: iDisplayInvitation) {

    const {invitation, darkMode} = props;
    const {users, competitions} = Bootstrap.bootstrap.state;
    const [acceptPopupOpened, setAcceptPopupOpened] = useState(false);
    const handleAcceptClose = () => setAcceptPopupOpened(false);

    const navigate = useNavigate();
    const params = useParams();

    useEffect(() => {
        invitation.team_id && getTeams({
            teamIds: [invitation.team_id]
        });

        invitation.sender_id && getUsers({
            userIds: [invitation.sender_id]
        });

        invitation.comp_id && getCompetitions({
            competitionIds: [invitation.comp_id]
        });

        getVendors();
    })

    const sender = users?.find(user => user.ID === invitation.sender_id);

    const buyer = users?.find(user => user.ID === invitation.paid_by);

    const competition = competitions?.find(comp => comp.comp_id === invitation.comp_id);

    const dig = getStyles()

    if (competition === undefined || DropVariables.objectLoading(competition)) {

        return <div className={classNames(dig.col12, dig.colLg3, dig.colMd4, dig.colSm6)}>
            <Loading count={3}  message={'Loading competition information'}/>
        </div>

    }



    const fullBuyIn = competition?.custom_buy_in || competition?.buy_in || '0.0000';

    const isMatch = eCOMPETITION.MATCHES === competition?.comp_type_id;

    let prizeDisplay: string;

    let totalPrize: number = DropVariables.totalPrize(competition)

    if (totalPrize <= 0) {

        prizeDisplay = 'Bragging Rights'

    } else {

        prizeDisplay = (isGG() ? totalPrize.toFixed(0) + ' Coin' : '$' + totalPrize.toFixed(2)) + ' Prize'

    }

    function redirectToComp() {
        if(competition?.comp_id !== undefined) {
            removeCompetitionFromCache(competition?.comp_id);
            globalNavigate('/' + COMPETITION_PATH + '/' + competition?.comp_id);
        }
    }


    return <>
    <Popup
        handleClose={handleAcceptClose}
        open={acceptPopupOpened}>
            <JoinWithTeam
                location={{
                    pathname: '/competition/' + competition?.comp_id,
                    hash: '',
                    search: '',
                    state: null
                }}
                navigate={navigate}
                params={params}
                competition={competition}
                invitation={invitation}
                handleClose={handleAcceptClose}
            />
    </Popup>
    <div className={classNames(dig.colLg3, dig.colMd4, dig.colSm6, dig.mb4)}>
        <div className={classNames("card-border-0", dig.bgSuccess)}>
            <div className={classNames(dig.cardHeader, dig.dFlex, dig.justifyContentBetween, dig.digBgCardHeader, dig.p3)}>
                <div className={dig.matchTitle}>
                    <h4 className={classNames(dig.digColorNeutral1, dig.textUppercase)}>
                        {compTypeToSingular(competition?.comp_type_id)}
                    </h4>
                </div>
                <div className={classNames("match-id", dig.digColorNeutral1, dig.fwBold)}>
                    <span>ID: {competition?.comp_id}</span>
                </div>
            </div>
            <div className={classNames(dig.overflowHidden, dig.dFlex, dig.w100, dig.justifyContentCenter, dig.minHeight100Px, dig.alignItemsCenter)}>
                {competition?.game_logo_image ? <img className={classNames(dig.styleSvg, dig.digSecondaryFillColor, dig.w100, dig.h100)}
                                                    src={competition?.game_logo_image}
                                                    alt={competition?.name}/>
                    :
                    <h2 className={classNames(dig.fontSize3Em, dig.textCenter, dig.py4)}>{competition?.name}</h2>
                }
            </div>
            <div className={classNames(dig.cardBody, dig.px4, dig.py2 + (darkMode), dig.textLight, dig.textDark, dig.pb4)}>
                <div className={classNames(dig.dBlock, "font size xs", dig.pb1)} >
                    <strong>{buyer !== undefined ? buyer.user_nicename : sender?.user_nicename}</strong> {buyer !== undefined ? 'paid for your entry to this ' : 'invited you to this '}
                    <span className={classNames(dig.textUppercase, dig.dInlineBlock)}>{compTypeToSingular(competition?.comp_type_id)}</span>
                </div>
                <h4 className={classNames("font size sm", dig.textStart)}>
                    <div>
                        {competition?.name}
                    </div>
                </h4>
                <div className={"d-flex"}>
                    <div className={classNames("text accent")}>
                        {isMatch && competition.now_flag === 1 ? "Available Now" : competition?.datetime && DropVariables.convertPstToLocal(competition?.datetime)}
                    </div>
                </div>
                <div className={classNames(dig.dFlex, dig.justifyContentBetween)}>
                    <div className={classNames("text accent", dig.fwBold)}>
                        <span className={classNames(dig.mt2, dig.textWhite, dig.dBlock)}>{prizeDisplay}</span>
                        <span className={classNames(dig.mt2, dig.textWhite, dig.dBlock)}>
                            {DropVariables.formatMoney(fullBuyIn, eMONEY_OUTPUT_CONTEXT.BUY_IN)}
                            {isMatch && " + " + DropVariables.formatMoney(parseFloat(fullBuyIn) * parseFloat(competition?.comp_percentage || '0'), eMONEY_OUTPUT_CONTEXT.ENTRY_FEE)}
                        </span>
                    </div>
                </div>
            </div>
            <div className={classNames(dig.cardBody, dig.px4, "card-body-hidden", dig.dFlex, dig.mt0, dig.py0)}>
                <button
                    className={classNames(dig.btn, dig.w100, "accept-btn", dig.btnLg, dig.digTextWhite, dig.digBtnGreen, dig.rounded0, dig.mb2, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter)}
                    type="button"
                    onClick={() => {
                        // need the roster id
                        setAcceptPopupOpened(true);
                    }}>
                    Accept
                </button>
                <button
                    className={classNames(dig.btn, dig.w100, dig.btnLg, dig.digTextWhite, dig.digBtnRed, dig.rounded0, dig.mb2, "deny-btn", dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter)}
                    type="button"
                    onClick={() => {
                        if (invitation.team_id === undefined || invitation.team_id === null) {
                            deleteMatchChallengeInvitation({
                                invitation_id: invitation.invitation_id ?? 0 // impossible to be undefined/null here
                            });
                        } else {
                            deleteTeamRoster({
                                uid: invitation.user_id ?? 0, // impossible to be undefined/null here
                                invitation_id: invitation.invitation_id,
                                user_team_id: invitation.team_id
                            });
                        }
                    }}>
                    Deny
                </button>
            </div>
            <div className={classNames(dig.cardBody, "card-body-hidden", dig.dFlex, dig.mt0, dig.pt0, dig.px4, dig.pb4)}>
                <div
                    className={classNames(dig.btn, dig.btnLg, dig.rounded0, dig.dFlex, dig.w100, dig.digTextWhite, dig.digBtnBlue, dig.justifyContentCenter, dig.alignItemsCenter)}
                    onClick={redirectToComp}
                >
                    View
                </div>
            </div>
        </div>
    </div>
    </>


}

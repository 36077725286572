import classNames from "classnames";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import compTypeToSingular from "src/api/hoc/compTypeToSingular";
import getCompetitions from "src/api/rest/getCompetitions";
import getUsers from "src/api/rest/getUsers";
import Bootstrap from "src/Bootstrap";
import Loading from "src/components/Loading/Loading";
import ProfilePicture from "src/components/ProfilePicture/ProfilePicture";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {COMPETITION_PATH} from "src/views/Competition/Competition";
import deleteTeamRoster from "src/api/rest/deleteTeamRoster";
import JoinWithTeam from "src/views/Competition/Modals/JoinWithTeam";
import Popup from "src/components/Popup/Popup";
import {iInvitation} from "src/variables/sharedInterfaces";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import Button from "src/components/Button/Button";
import deleteMatchChallengeInvitation from "src/api/rest/deleteMatchChallengeInvitation";

interface iDisplayInvitation extends WithRouter {
    invitation: iInvitation,
    viewOnly: boolean,
    openJoinPopup: () => void
}


export default function Invitation(props: iDisplayInvitation) {

    const {competitions, users, id} = Bootstrap.bootstrap.state;
    const {invitation, viewOnly} = props;
    const [acceptPopupOpened, setAcceptPopupOpened] = useState(false);

    useEffect(() => {
        invitation.comp_id && getCompetitions({
            competitionIds: [
                invitation.comp_id
            ]
        })

        const notMyId = id === invitation.sender_id
            ? invitation.user_id
            : invitation.sender_id;

        notMyId && getUsers({
            userIds: [notMyId]
        })

    }, [Bootstrap.bootstrap.state.invitations])

    if (undefined === invitation) {

        return null;

    }

    const competition = competitions?.find(competition => competition.comp_id === invitation.comp_id)

    const fromUser = users?.find(user => user.ID === invitation.sender_id)

    const toUser = users?.find(user => user.ID === invitation.user_id)

    //const inviteeRoster = teams.find(team => team.user_team_id === invitation.team_id)?.rosterDetails.find(roster => roster.uid === invitation.user_id);

    if (DropVariables.objectLoading(competition) ||
        DropVariables.objectLoading(fromUser) ||
        DropVariables.objectLoading(toUser)) {

        return <Loading message={'Loading invitation details!'} count={1}/>

    }

    const inviteFromMe = invitation.sender_id === id;

    const dig = getStyles()

    const notificationColorClass = inviteFromMe ? dig.digBgOrange : dig.digBtnGreen;

    const handleAcceptClose = () => setAcceptPopupOpened(false);

    if (undefined === competition) {

        return <Loading message={'Loading competition invitation'}/>

    }

    return <div className={classNames(dig.container, dig.p0)}>
        <Popup
            handleClose={handleAcceptClose}
            open={acceptPopupOpened}
        >
            <JoinWithTeam {...props} competition={competition} invitation={invitation} handleClose={handleAcceptClose}/>
        </Popup>
        <div className={classNames("game-invites",dig.row, dig.mx0, dig.g0, dig.mb3,dig.p2, dig.gameInvites, notificationColorClass)}>
            <div className={classNames("align-items-center", dig.col6, dig.dFlex, dig.justifyContentStart)}>
                <div className={"invitation_avatar"}>
                    <ProfilePicture user={inviteFromMe ? toUser : fromUser} width={50} height={50}/>
                </div>
                <div
                    className={classNames(dig.invitationInfos, dig.dFlex, dig.justifyContentStart, dig.ml3, dig.flexColumn, dig.alignItemsStart)}>
                    <h4>{inviteFromMe ? toUser?.display_name : fromUser?.display_name}</h4>
                    <span>{inviteFromMe ? "Waiting for " + toUser?.display_name + " to accept" : "Invited you to"} this {compTypeToSingular(competition.comp_type_id)}!</span>
                </div>
            </div>
            <div className={classNames(dig.col6, dig.dFlex, dig.justifyContentEnd, dig.alignItemsCenter)}>
                {viewOnly ? <Link
                    className={classNames(
                    dig.btn, dig.ggBgBlue, dig.fwBold, dig.me2, dig.umFriendAcceptBtn, dig.btnLg, dig.digTextWhite, dig.w125, dig.border2, dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter
                    )}
                    type="button" to={'/' + COMPETITION_PATH + '/' + competition.comp_id + '/'}>
                    View
                </Link> : <>
                    <Button
                        className={classNames(
                            dig.btn,dig.digBgDarkgreen,dig.fwBold,dig.me2,dig.textWhite,dig.w125,dig.border2,dig.rounded0,dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter,
                            )}
                        type="button"
                        data-invitation_type="match"
                        onClick={() => {
                            // if team id is undefined, then this is a match opponent invite
                            if (invitation.team_id === undefined) {
                                props.openJoinPopup();
                            } else {
                                setAcceptPopupOpened(true);
                            }
                        }}>
                        Accept
                    </Button>
                    <Button
                        className={classNames(
                            dig.btn,dig.digBtnRed,dig.fwBold,dig.me2,dig.textWhite,dig.w125,dig.border2,dig.rounded0,dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter,
                            )}
                        type="button"
                        onClick={() => {
                            if (invitation.team_id === undefined || invitation.team_id === null) {
                                deleteMatchChallengeInvitation({
                                    invitation_id: invitation.invitation_id ?? 0 // impossible to be undefined/null here
                                });
                            } else {
                                deleteTeamRoster({
                                    uid: invitation.user_id ?? 0, // impossible to be undefined/null here
                                    invitation_id: invitation.invitation_id,
                                    user_team_id: invitation.team_id
                                });
                            }
                        }}>
                        Deny
                    </Button>
                </>}
            </div>
        </div>
    </div>


}

import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import {C6, iDig_Sub_Mode} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";

export default restApi<{}, iDig_Sub_Mode, {}, iGetC6RestResponse<iDig_Sub_Mode>>({
    axios: DropVariables.axios,
    tableName: C6.dig_sub_mode.TABLE_NAME,
    requestMethod: GET,
    queryCallback: {
        [C6.SELECT]: [
            C6.dig_sub_mode.SUB_MODE_ID,
            C6.dig_sub_mode.MODE_ID,
            C6.dig_sub_mode.NAME,
            C6.dig_sub_mode.TIME_LIMIT,
            C6.dig_sub_mode.CREATED,
            C6.dig_sub_mode.UPDATED
        ],
        [C6.PAGINATION]: {
            [C6.LIMIT]: 1000
        }
    },
    responseCallback: (response, _request) => {

        let restData: iDig_Sub_Mode[] = response?.data?.rest ?? [];
        updateRestfulObjectArray<iDig_Sub_Mode>(restData, 'sub_mode_id', 'subModes');

    }
})

import classNames from "classnames";
import React from "react";
import MediaQuery from "react-responsive";
import {toast } from 'react-toastify';
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iUser} from "src/variables/sharedInterfaces";
import {getStyles} from "src/variables/DropVariables";
// core components

class Settings extends React.Component<any, any> {
    constructor(props) {
        super(props);
        // State of your application
        this.state = {
            familyName: "",
            setting_notification_email: false,
            setting_notification_text: false,
            isLoading: true,
            isOnSubmit: false
        };
    }

    componentDidMount() {
        const {currentEditUser, currentUser} = this.props;
        this.setState({
            familyName: currentUser.familyName,
            setting_notification_email: currentEditUser.setting_notification_email,
            setting_notification_text: currentEditUser.setting_notification_text,
            isLoading: false
        })
    }

    handleSubmit = ({target}) => {
        target.setAttribute('disabled', true);
        this.setState({
            isOnSubmit: true
        });
        this.props.axios({
            method: "POST",
            url: '/wp-json/api/parent-portal/settings/',
            data: {
                userID: this.props.currentEditUser.ID,
                isChild: this.props.currentEditUser.isChild,
                data: this.state
            }
        }).then((res) => {
            toast.success(res.data.msg,this.props.toastOptions);

            updateRestfulObjectArray<iUser>([{
                ID: this.props.currentUser.ID,
                familyName: this.state.familyName
            }], "ID", "users")


            this.props.updateCurrentEditUser({
                setting_notification_email: this.state.setting_notification_email,
                setting_notification_text: this.state.setting_notification_text,
                familyName: this.state.familyName
            })

            this.setState({
                isOnSubmit: false
            });
            target.removeAttribute('disabled');

        }).catch((err) => {
            console.log(err)
        })

    }


    render() {

        const {familyName, setting_notification_email, setting_notification_text, isLoading} = this.state;

        if (true === isLoading) {
            return <></>
        }

        const dig = getStyles()

        return (
            <div className={classNames(dig.containerFluid, dig.p0, dig.my5, dig.ggSettings, dig.dFlex, dig.justifyContentCenter, dig.alignItemsStart, dig.flexColumn)}>
                <div className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsStart, dig.py4, dig.mb3)}>
                    <div className={classNames(dig.col12, dig.colSm6)}>
                        <h2 className={classNames(dig.ggTextColor2, dig.fwNormal)}>Notifications</h2>
                        <div className={classNames(dig.w100, dig.dFlex, dig.alignItemsCenter, dig.mt4)}>
                            <input
                                onChange={() => this.setState({...this.state,'setting_notification_email': !setting_notification_email})}
                                checked={setting_notification_email}
                                name="setting_notification_email"
                                type="checkbox"
                                className={classNames(dig.me4, dig.ggSettingsCheckbox)}/>
                            <h2 className={classNames(dig.ggTextColor2, dig.ggSettingsLabel, dig.textStart, dig.fwNormal)}>Email</h2>
                        </div>
                        <div className={classNames(dig.w100, dig.dFlex, dig.alignItemsCenter, dig.mt3)}>
                            <input
                                onChange={() => this.setState({...this.state,'setting_notification_text': !setting_notification_text})}
                                checked={setting_notification_text}
                                name="setting_notification_text"
                                type="checkbox"
                                className={classNames(dig.me4, dig.ggSettingsCheckbox)}/>
                            <h2 className={classNames(dig.ggTextColor2, dig.ggSettingsLabel, dig.textStart, dig.fwNormal)}>Text</h2>
                        </div>
                    </div>
                    <MediaQuery maxWidth={425}>

                        <hr className={classNames(dig.borderTop1, dig.borderColorGg, dig.w100, dig.my5)}/>

                    </MediaQuery>
                    <div className={classNames(dig.col12, dig.colSm6)}>
                        <h2 className={classNames(dig.ggTextColor2, dig.ggSettingsLabel, dig.textStart, dig.mb4, dig.fwNormal)}>Family Name</h2>
                        <div className={classNames(dig.inputGroup, dig.inputGroupLg)}>
                            {
                                false === this.props.currentEditUser.isChild
                                    ? <input
                                        onChange={(e) => this.setState({...this.state, 'familyName': e.target.value})}
                                        value={familyName}
                                        type="text" placeholder="Family's name" className={dig.formControl} aria-label="Large" aria-describedby="inputGroup-sizing-sm"/>
                                    : <h3 className={dig.ggTextPrimary}>{familyName}</h3>
                            }
                        </div>
                    </div>
                </div>

                <hr className={classNames(dig.borderTop1, dig.borderColorGg, dig.w100)}/>

                <div className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentStart, dig.alignItemsStart, dig.mt3)}>
                    <button
                        onClick={this.handleSubmit}
                        className={classNames(dig.btn, dig.ggPrimaryBtn, dig.textWhite, dig.px5, dig.py2, dig.fontSize1P5Em, dig.borderRounded5)}
                        type="button">
                        {
                            true === this.state.isOnSubmit ? `Submitting...` : `Update Settings`
                        }
                    </button>
                </div>
            </div>);
    }
}


export default (Settings);

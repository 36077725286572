import restApi, {iPutC6RestResponse, PUT, removeInvalidKeys} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, dig_user_info, iDig_User_Info, iUsers, users} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";


export default restApi<{ user_id: number }, iDig_User_Info, {}, iPutC6RestResponse<iDig_User_Info>>({
    axios: DropVariables.axios,
    tableName: C6.dig_user_info.TABLE_NAME,
    requestMethod: PUT,
    queryCallback: (request) => {
        request.success = 'Successfully updated user information!'
        request.error = 'An error occurred while updating user information!'
        return request
    },
    responseCallback: (_response, request) => {

        updateRestfulObjectArray<iUsers>([
            {
                ID: request.user_id,
                ...removeInvalidKeys(request, [users, dig_user_info])
            }
        ], "ID", "users");

    }

});

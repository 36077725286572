import restApi, {PUT} from "src/api/API";
import {C6, iDig_Comp_Invitation, iDig_Comp_Registration, iDig_Team_Roster, iDig_Vendor} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iCompetitions, iTeam} from "src/variables/sharedInterfaces";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";
import getRegistrations from "src/api/rest/getRegistrations";
import Bootstrap from "src/Bootstrap";
import getUsers from "./getUsers";
import isTest from "../../variables/isTest";

// Only used to accept an invitation for a team they have not accepted before (roster entry exists, acceptance = 0)
export interface iPutTeamRoster extends iDig_Team_Roster {
    id: number,
    invitation_id: number,
    user_team_id: number,
    vendor_id?: number,
    vendor_name?: string,
    vendor_gamertag?: string,
    streaming_url?: string,
    streaming_platform?: string
}

export function putTeamRosterCallback(request) {
    let rosterUserId = 0;

    updateRestfulObjectArray<iTeam>((prev) => {

        let prevTeam = prev?.teams?.find(team => team.user_team_id === request.user_team_id),
            prevTeamRosters = prevTeam?.rosterDetails,
            rosterIndex = prevTeamRosters?.findIndex(roster => roster.id === request.id) || -1;

        // this check doesnt really need prevTeam === undefined, but it helps avoid a TS error below
        if (prevTeam === undefined || prevTeamRosters === undefined || rosterIndex === -1) {
            return [];
        }


        prevTeamRosters[rosterIndex].acceptance = 1;

        // should be impossible for these to be undefined, but to calm the editor down....
        prevTeam.rosterAccepted ??= [];
        prevTeam.rosterPending ??= [];

        // even with an if statement returning [] if the uid is undefined, the editor still complains here unless I add the ?? 0. Maybe strict mode was a mistake
        rosterUserId = prevTeamRosters[rosterIndex].uid ?? 0;

        !prevTeam.rosterAccepted.includes(rosterUserId) && prevTeam.rosterAccepted?.push(rosterUserId);
        prevTeam.rosterPending = prevTeam.rosterPending?.filter(uid => uid !== rosterUserId) ?? [];

        return [prevTeam];

    }, "user_team_id", "teams");

    deleteRestfulObjectArrays<iDig_Comp_Invitation>(prev => {
        return prev.invitations?.filter(invitation => invitation.invitation_id === request.invitation_id) ?? []
    }, "invitation_id", "invitations");

    let comp_id = request.vendor_id !== undefined ?
        Bootstrap.bootstrap.state.competitions?.find(comp => comp.comp_id === Bootstrap.bootstrap.state.invitations
            ?.find(invitation => invitation.invitation_id === request.invitation_id)
            ?.comp_id)?.comp_id : undefined;

    if (comp_id !== undefined) {

        getRegistrations({competitionIds: [comp_id], cacheResults: false});

        updateRestfulObjectArray<iCompetitions>((prev) => {
            // we'll update this entry and return it
            let prevComp = prev.competitions?.find(competition => competition.comp_id === comp_id);

            if (prevComp === undefined) {
                return [];
            }

            let thisTeamRegistrations = prev.registrations?.filter(registration => registration.team_id === request.user_team_id && registration.competitions_id === comp_id)?.length ?? 0;


            // we do plus one because our current registration isn't in state just yet
            if (thisTeamRegistrations + 1 === (prevComp.team_size ?? -1)) {
                prevComp.complete_teams ??= [];

                !prevComp.complete_teams.includes(request.user_team_id) && prevComp.complete_teams.push(request.user_team_id);

                prevComp.incomplete_teams = prevComp?.incomplete_teams?.filter(team => team !== request.user_team_id) ?? [];
            }

            prevComp.registered_users ??= [];

            !prevComp.registered_users.includes(rosterUserId) && prevComp.registered_users.push(rosterUserId);

            return [prevComp];

        }, "comp_id", "competitions");

        if (false === isTest && rosterUserId !== 0 && rosterUserId === Bootstrap.bootstrap.state.id) {
            // re-fetch user for updated wallet (will also pull meta updates)
            getUsers({
                userIds: [
                    rosterUserId
                ],
                cacheResults: false,
            })
        }

    }
}

export default restApi<iPutTeamRoster, iDig_Team_Roster & iDig_Comp_Invitation & iDig_Vendor & iDig_Comp_Registration>({
        axios: DropVariables.axios,
        tableName: [C6.dig_team_roster.TABLE_NAME, C6.dig_comp_invitation.TABLE_NAME, C6.dig_vendor.TABLE_NAME, C6.dig_comp_registration.TABLE_NAME],
        requestMethod: PUT,
        queryCallback: (request) => {
            request.success = 'Successfully accepted the invite!'
            request.error = 'An unknown issue occurred. We will be looking into this shortly.'
            return request
        },
        responseCallback: (_response, request) => {

            putTeamRosterCallback(request);

        }
    });

import {Link} from "react-router-dom";
import Loading from "src/components/Loading/Loading";
import DefaultPhoto from "src/assets/img/default_profile_photo.png"
import {iUser} from "src/variables/sharedInterfaces";
import {PROFILE_PATH} from "src/views/Profile/Profile";


interface iProfilePicture {
    user?: iUser,
    width?: number,
    height?: number,
    className?: string
    alt?: string
}

export default function ProfilePicture(props: iProfilePicture) {

    const {
        user,
    } = props

    if (user === undefined || null === user) {

        return  <Loading count={1} message={undefined}/>

    }

    let profilePhoto = user?.profile_photo


    if (undefined === profilePhoto) {

        profilePhoto = DefaultPhoto

    } else if (!profilePhoto.startsWith('/') && !profilePhoto.startsWith('data')) {

        profilePhoto = '/' + profilePhoto

    }

    return <Link to={"/" + PROFILE_PATH + "/" + user.ID + "/"} >
        <img
            className={props.className}
            src={profilePhoto}
            width={props.width || props.height || 40}
            height={props.height || props.width || 40}
            alt={props.alt ? props.alt : 'profile picture for ' + user.user_nicename }
            onError={(event) => {
                // @ts-ignore
                event.target.src = DefaultPhoto
            }}
        />
    </Link>


}

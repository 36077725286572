import walletInputsValid from "src/api/hoc/walletInputsValid";
import Button from "src/components/Button/Button";
import {getStyles} from "src/variables/DropVariables";
import swal from "sweetalert";
import classNames from "classnames";
import Billing from "../Billing/Billing";
import {toast} from "react-toastify";


export default function submitButtonNMI({triggerNMI}: {
    triggerNMI: () => void,
}) {

    const [walletInputsValidBool] = walletInputsValid()

    const dig = getStyles()

    return <Button type="submit"
                   style={{opacity: !walletInputsValidBool ? '0.5' : '1', minHeight: '3em'}}
                   className={classNames(dig.bgSuccess, dig.textWhite, dig.border0)}
                   onClick={(event) => {
                       const {
                           firstName,
                           lastName,
                           zip,
                           selectedCountry,
                           state,
                           town,
                           street
                       } = Billing.billing?.state ?? {};

                       if (
                           firstName === undefined ||
                           firstName === '' ||
                           lastName === undefined ||
                           lastName === '' ||
                           zip === undefined ||
                           zip === '' ||
                           selectedCountry === undefined ||
                           state === undefined ||
                           (state === '' && selectedCountry?.abbreviation === 'US') ||
                           town === undefined ||
                           town === '' ||
                           street === undefined ||
                           street === ''
                       ) {
                           toast.error('Please fill out all billing information!');
                           return;
                       }

                       if (walletInputsValidBool) {
                           (event.target as HTMLElement).innerHTML = 'Securing…';
                           (event.target as HTMLElement).onclick = null;
                           triggerNMI()
                       } else {
                           swal({
                               title: 'Oh no!',
                               text: "Please fill out all required fields!",
                           })
                       }

                   }}>
        Add Funds
    </Button>
}

import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import {C6, iDig_Match_Confirm} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iCompetitions} from "src/variables/sharedInterfaces";


export default restApi<{ compIds: number[] }, iDig_Match_Confirm, {}, iGetC6RestResponse<iDig_Match_Confirm>>({
    axios: DropVariables.axios,
    tableName: C6.dig_match_confirm.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {

        if (request.compIds === undefined || request.compIds.length === 0) {
            throw new Error('compIds must be defined and have at least one value');
        }

        return {
            [C6.SELECT]: [
                C6.dig_match_confirm.TEAM_ID,
                C6.dig_match_confirm.AGREEMENT
            ],
            [C6.WHERE]: {
                [C6.dig_match_confirm.COMP_ID]: [C6.IN, request.compIds]
            }
        }
    },
    responseCallback: (response, request) => {

        let restData: iDig_Match_Confirm[] = response?.data?.rest ?? [];

        updateRestfulObjectArray<iCompetitions>(request.compIds.map(compId => ({
            comp_id: compId,
            match_responses: restData
        })) ?? [], 'comp_id', 'competitions');

    }
})

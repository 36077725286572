import Button from "src/components/Button/Button";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {getStyles} from "src/variables/DropVariables";
import Sidebar from "src/views/Intel/Sidebar";
import classNames from "classnames";


export const API_DOCUMENTATION_PATH = "api-documentation";
// core components
export default function ApiDocumentation(props: WithRouter) {

    const dig = getStyles()

    return <div className={classNames(dig.digMainBlogContent, dig.container)}>
        <div className={dig.row}>
            <Sidebar {...props}/>
            <div className={classNames(dig.colSm9, dig.mt5, dig.archive)}>
                <h1>Drop-In Gaming Developer API</h1>
                <Button href={"https://drop-in-gaming.github.io/node-core/"}>GitHub Pages</Button>
            </div>
            <br/>
            <br/>
        </div>
    </div>
}

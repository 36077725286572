import classNames from "classnames";
import getPosts, {ePost} from "src/api/rest/getPosts";
import Bootstrap from "src/Bootstrap";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import {getStyles} from "src/variables/DropVariables";
import {iCompetitions} from "src/variables/sharedInterfaces";

interface iShowGameRules {
    competition: iCompetitions
}

export default function Rules(props: iShowGameRules) {

    const ruleId = props.competition.rules_post_id

    const removeMoreButton = (html: string): string => {

        return html?.replaceAll(/<div class="wp-block-buttons">(\n|.)*?(?=(<\/div>))<\/div>(\n|.)*?(?=(<\/div>))<\/div>/g, '<br/><br/>') || ''

    }

    const dig = getStyles()

    const rules = Bootstrap.bootstrap.state.posts?.find(post => post.ID === ruleId);

    if (undefined === rules) {

        getPosts({
            postId: ruleId,
            queryPostType: ePost.GAME_RULES
        })

        return <Loading message={'Loading rules!'}/>

    }

    const {post_content = undefined} = rules


    // Owens requested just showing "Game Rules" dont
    return <ExpandableBox header={'Game Rules'} startCollapsed={false}>
        <div className={classNames(dig.cardBody, dig.digBgNeutral2)}>
            {undefined === rules
                ? <Loading message={'Loading Rules'}/>
                : <div dangerouslySetInnerHTML={{__html: post_content ? removeMoreButton(post_content) : ''}}/>}
        </div>
    </ExpandableBox>

}


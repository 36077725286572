import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import {iUser} from "src/variables/sharedInterfaces";
import {C6, iDig_Wallet_Transactions} from "src/variables/C6";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import DropVariables from "src/variables/DropVariables";
import Bootstrap from "../../Bootstrap";
// import {iGetUsers} from "./getUsernames";

// export default class History extends React.Component<iAmProfileDescendant, {}> {

export default restApi<{}, iDig_Wallet_Transactions, {}, iGetC6RestResponse<iDig_Wallet_Transactions>>({
    axios: DropVariables.axios,
    tableName: C6.dig_wallet_transactions.TABLE_NAME,
    requestMethod: GET,
    queryCallback: () => {
        // const currentSelectedUser = request.ID;

        return {
            [C6.SELECT]: [
                C6.dig_wallet_transactions.ID,
                C6.dig_wallet_transactions.AMOUNT,
                C6.dig_wallet_transactions.COMMENT,
                C6.dig_wallet_transactions.ACTION,
                C6.dig_wallet_transactions.STATUS,
                C6.dig_wallet_transactions.CREATED,
                C6.dig_wallet_transactions.UPDATED,
            ],
            [C6.WHERE]: {
                0: {
                    [C6.dig_wallet_transactions.BUYER_ID]: Bootstrap.bootstrap.state.id,
                    1: {
                        [C6.dig_wallet_transactions.USER_ID]: Bootstrap.bootstrap.state.id,
                        [C6.dig_wallet_transactions.BUYER_ID]: 0
                    }
                },

            },
            [C6.PAGINATION]: {
                [C6.ORDER]: {
                    [C6.dig_wallet_transactions.CREATED]: C6.DESC
                },
                [C6.LIMIT]: 10000
            }
        } as any;
    },
    responseCallback: (response) => {

        const replaceHistory = (response?.data?.rest || [])

        updateRestfulObjectArray<iUser>(prevState => {

            const myUser = prevState.users?.find(user => user.ID == Bootstrap.bootstrap.state.id)

            return [{
                ID: Bootstrap.bootstrap.state.id,
                history: [...(myUser?.history ?? []), ...replaceHistory]
            }]
        }, "ID", "users")

    }
});
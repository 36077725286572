import {faArrowsLeftRight} from '@fortawesome/free-solid-svg-icons'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import classNames from "classnames";
import moment, {Moment} from "moment";
import {ChangeEvent, useEffect, useState} from "react";
import {Link, Navigate} from "react-router-dom";
import {toast} from "react-toastify";
import parseEnumKeyValues from "src/api/hoc/parseEnumKeyValues";
import getGameModes from "src/api/rest/getGameModes";
import Bootstrap from "src/Bootstrap";
import Button from "src/components/Button/Button";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import SvgInline from "src/components/SvgInline/SvgInline";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, ePLATFORM, eREGION, iGame} from "src/variables/sharedInterfaces";
import {COMPETITION_PATH} from "src/views/Competition/Competition";
import AccessDenied from "src/views/Errors/AccessDenied";
import Datetime from 'react-datetime';
import postCompetition from "src/api/rest/postCompetition";
import getGamesForCreateComp from "src/api/rest/getGamesForCreateComp";
import isPRO from "src/variables/isPRO";

// custom styles

import CreateCompetitionStyle from './style.module.scss';


export const CREATE_COMPETITION_PATH = "create-competition";
export const CREATE_TOURNAMENT_PATH_PART = "tournament";
export const CREATE_MATCH_PATH_PART = "match";
export const CREATE_TOURNAMENT_PATH = CREATE_COMPETITION_PATH + '/' + CREATE_TOURNAMENT_PATH_PART;
export const CREATE_MATCH_PATH = CREATE_COMPETITION_PATH + '/' + CREATE_MATCH_PATH_PART;

// core components
export default function CreateCompetition(props: WithRouter) {

    const [selectedPlatform, setSelectedPlatform] = useState<ePLATFORM>()
    const [otherPlatforms, setOtherPlatforms] = useState<ePLATFORM[]>([])
    const [selectedGameID, setSelectedGameID] = useState<number>()
    const [selectedRegions, setSelectedRegions] = useState<eREGION[]>([])
    const [selectedMode, setSelectedMode] = useState<number>()
    const [selectedModeGame, setSelectedModeGame] = useState<number>()
    const [dateTime, setDateTime] = useState<string>()
    const [buyIn, setBuyIn] = useState<string>('0.00')
    const [showCustomBuyInInput, setShowCustomBuyInInput] = useState<boolean>(false)
    const [bestOf, setBestOf] = useState<number>(1)
    const [counter, setCounter] = useState<number>(0)
    const [nowFlag, setNowFlag] = useState<number>(1)
    const [createdCompetition, setCreatedCompetition] = useState<number | undefined>(undefined)

    const forceRerender = () => setCounter(1 + counter)

    useEffect(() => {

        (async () => {

            const {games} = Bootstrap.bootstrap.state;

            if (null === games) {

                return;

            }

            if (undefined === games) {

                await getGamesForCreateComp({compTypeId: props.params?.competition_type ?? eCOMPETITION.MATCHES});

                forceRerender();

            }

            if (undefined !== selectedGameID) {

                // todo - we should either no-cache or do this check in the api too
                const selectedGame = games?.find(game => game.game_id === selectedGameID);

                if (selectedGame === undefined) {

                    console.log(games)

                    return undefined;

                }

                if (undefined === selectedGame.modes) {

                    let gameSearch = games?.find(game => game.game_id === selectedGameID)

                    if (gameSearch) {
                        await getGameModes({
                            game: gameSearch,
                            comp_type_id: 1
                        })

                        forceRerender()

                    }

                }

            }
        })()

    }, [selectedGameID])


    const {bootstrap, games} = Bootstrap.bootstrap.state;

    const {params} = props

    if (undefined !== createdCompetition) {
        return <Navigate to={"/" + COMPETITION_PATH + "/" + createdCompetition + "/join/"} replace={true}/>
    }

    if (undefined === games || null === games) {

        return <Loading message={"Loading Games!"}/>

    }

    const dig = getStyles(CreateCompetitionStyle);

    const competitionTypeMatch = params.competitionType === CREATE_MATCH_PATH_PART;

    const whoami = bootstrap.getLoggedInUser()

    const iAmAdmin = whoami?.user_is_admin || false

    const userFunds = whoami?.funds || 0.00

    if (false === iAmAdmin && false === competitionTypeMatch) {

        return <AccessDenied/>

    }

    const addOrRemoveRegion = (addOrRemove: eREGION) => setSelectedRegions((selectedRegions) => {

        const prevRegion = selectedRegions || []

        return prevRegion.includes(addOrRemove)
            ? prevRegion.filter(region => region !== addOrRemove)
            : [...prevRegion, addOrRemove]

    })

    const selectedGame = games?.find(game => game.game_id === selectedGameID);

    let gamesFilterByPlatform = games.filter(game => game.platform_ids?.some(id => selectedPlatform == id));

    const today = moment();

    const disableFutureDt = current => {
        return current.isAfter(today)
    }

    const otherAvailablePlatforms = parseEnumKeyValues(ePLATFORM).filter(platform => ePLATFORM[platform] !== selectedPlatform);

    return <div className={classNames(dig.container, dig.mb5, dig.px4, dig.pxLg0)}>

        <div className={classNames(dig.row, dig.my5)}>
            <h1 className={classNames(dig.colMd6, dig.colSm12)}>
                Create a {competitionTypeMatch ? 'Match' : 'Tournament'}
            </h1>
            {iAmAdmin &&
                <div className={classNames(dig.colMd6, dig.my3)}>
                    <Link style={{float: "right"}}
                          to={'/' + (competitionTypeMatch ? CREATE_TOURNAMENT_PATH : CREATE_MATCH_PATH)}>
                        * Only administrators may create a tournament.&nbsp;
                        <FontAwesomeIcon icon={faArrowsLeftRight} size="lg"/>
                    </Link>
                </div>}
        </div>

        <ExpandableBox
            centered
            header={undefined === selectedPlatform ? "Select Your Platform" : ePLATFORM[selectedPlatform]}
            startCollapsed={undefined !== selectedPlatform}>

            {parseEnumKeyValues(ePLATFORM).map((platform: string, index) => {
                    return <div key={index}
                                className={classNames(dig.digYourPlatformSelector,
                                    {
                                        [dig.uiSelected]: ePLATFORM[platform] === selectedPlatform
                                    }
                                )}
                                onClick={() => {
                                    setSelectedPlatform(ePLATFORM[platform])
                                    setOtherPlatforms([]);
                                }}>
                        <div>{platform}</div>
                    </div>
                }
            )}

        </ExpandableBox>

        <ExpandableBox
            centered
            header={undefined === selectedGameID ? 'Select Game' : selectedGame?.name ?? 'Loading'}
            startCollapsed={undefined !== selectedGameID}>

            {
                gamesFilterByPlatform.length <= 0
                    ? <>Please choose another platform to play</>
                    : gamesFilterByPlatform?.filter(game => {

                    if (game.game_id === -1) {
                        console.log('No games for this platform')
                        return false;
                    }

                    if ('' === game.game_logo_image) {

                        console.log('invalid game.game_logo_image', game)

                        return false;

                    }

                    return true;

                }).map((game: iGame, index) => {

                    const url = new URL(game.game_logo_image || '')

                    return <div key={index}
                                data-is-crossplay="1"
                                onClick={() => setSelectedGameID(game.game_id)}
                                className={classNames(dig.digBuySelectorCustom, dig.p2, {
                                    [dig.uiSelected]: game.game_id === selectedGameID
                                })}>
                        <SvgInline url={url.pathname}/>
                    </div>

                }) || <Loading message={"Loading Platforms"} count={3}/>

            }

        </ExpandableBox>

        <ExpandableBox centered header={
            ((() => {

                let selectedRegionsLength = selectedRegions?.length || 0

                switch (selectedRegionsLength) {
                    case 0:
                        return 'Select Region(s)';
                    case 1:
                        return ('Region: ' + (selectedRegions.includes(eREGION.NA) ? 'North America' : '')
                            + (selectedRegions.includes(eREGION.EU) ? 'European Union' : ''))
                    case 2:
                        return ('Regions: ' + (selectedRegions.includes(eREGION.NA) ? 'North America, ' : '')
                            + (selectedRegions.includes(eREGION.EU) ? 'European Union' : ''))
                }
                throw Error('Bad region data')
            })())
        }>
            <div className={dig.row}>
                Select your region and any others you&apos;d like to make available to opponents.
            </div>
            <br/><br/>
            <div
                className={classNames(
                    {[dig.uiSelected]: selectedRegions?.includes(eREGION.NA)},
                    dig.digRegionSelector, dig.p2
                )}
                onClick={() => addOrRemoveRegion(eREGION.NA)}>
                <div>NA</div>
            </div>
            <div
                className={classNames(
                    {[dig.uiSelected]: selectedRegions?.includes(eREGION.EU)},
                    dig.digRegionSelector, dig.p2
                )}
                onClick={() => addOrRemoveRegion(eREGION.EU)}>
                <div>EU</div>
            </div>
        </ExpandableBox>

        <ExpandableBox centered
                       header={"Mode" + (undefined === selectedGameID && undefined === selectedMode
                           ? '(Select a Game First)'
                           : ': ' + selectedGame?.modes?.find(mode => mode.mode_id === selectedMode)?.name)}
                       startCollapsed={undefined === selectedGameID}>
            {undefined === selectedGameID
                ? <div className={dig.digNoWrap}>(Select a Game First)</div>
                : games.find(game => game.game_id === selectedGameID)?.modes?.map((mode, index) =>
                <div key={index}
                     onClick={() => {
                         setSelectedMode(mode.mode_id);
                         setSelectedModeGame(mode.mode_game_id)
                     }}
                     className={classNames(dig.digModeSelector, dig.p2,
                         (mode.mode_id === selectedMode && dig.uiSelected))}>
                    <div>{mode.name}</div>
                </div>) || <Loading message={"Loading game modes!"}/>}
        </ExpandableBox>

        {!isPRO() &&
        <ExpandableBox header={"Buy-In" + (undefined === buyIn ? '' : ': ' + buyIn)} centered>

            {['0.00', '1.00', '2.00', '5.00', '10.00', '20.00'].map((defaultBuyIn, index) => <div
                className={classNames(
                    dig.digBuySelectorCustom,
                    dig.p2,
                    {[dig.uiSelected]: buyIn === defaultBuyIn}
                )}
                key={index}
                onClick={() => {
                    setBuyIn(defaultBuyIn)
                    setShowCustomBuyInInput(false)
                }}>
                <div>{'0.00' === defaultBuyIn ? 'Free' : '$'+defaultBuyIn}</div>
            </div>)}

            <div onClick={() => {
                setShowCustomBuyInInput(true)
                setBuyIn('0.00')
            }}
                 className={classNames(
                     dig.digBuySelectorCustom, dig.p2,
                     {
                         [dig.uiSelected]: showCustomBuyInInput
                     }
                 )}>
                <div>Custom</div>
            </div>

            {showCustomBuyInInput &&
                <input className={dig.colMd6}
                       onChange={(event: ChangeEvent<HTMLInputElement>) => {
                           setBuyIn(event.target.value)
                       }}
                       type="text"
                       name="custom_buy_in"
                       id="custom_buy_in"
                       pattern="^$\d{1,3}(,\d{3})*(\.\d+)?$"
                       placeholder="$100.00"/>}
        </ExpandableBox>
        }

        <ExpandableBox
            centered
            header={0 === otherPlatforms.length
                ? "Other Platforms (Optional)"
                : otherPlatforms.map(platform => ePLATFORM[platform]).join(', ')}>
            {otherAvailablePlatforms.map((platform: string, index) => {
                    return <div key={index}
                                className={classNames(
                                    dig.digYourPlatformSelector,
                                    {[dig.uiSelected]: otherPlatforms.includes(ePLATFORM[platform])}
                                )}
                                onClick={() => setOtherPlatforms(otherPlatforms => otherPlatforms.includes(ePLATFORM[platform])
                                    ? otherPlatforms.filter(prev => prev !== ePLATFORM[platform])
                                    : [...otherPlatforms || [], ePLATFORM[platform]]
                                )}>
                        <div>{platform}</div>
                    </div>
                }
            )}
        </ExpandableBox>

        <ExpandableBox header={undefined === dateTime ? "Schedule Date/Time " : dateTime.toString()}>
            <div className={classNames(dig.row, dig.w100)}>

                {[1, 0].map((now_flag, index) => {
                    return <div key={index} className={dig.col2}>
                        <Button
                            className={classNames(dig.minWidth150Px, dig.border1, dig.textWhite)}
                            key={index}
                                isSelectable
                                isSelected={nowFlag === now_flag}
                                onClick={() => setNowFlag(now_flag)}>
                            {now_flag === 1 ? 'Now' : 'Later'}
                        </Button>
                    </div>
                })}
            </div>

            {
                nowFlag === 1
                    ? ''
                    : <div className={classNames(dig.row, dig.w100)}>
                        <div className={classNames(dig.col5, dig.mt5)}>
                            <div style={{color: "black"}}>
                                <Datetime
                                    isValidDate={disableFutureDt}
                                    dateFormat={DropVariables.momentGeneralDateFormat}
                                    initialValue={moment(dateTime).toDate()}
                                    initialViewDate={moment(dateTime).toDate()}
                                    onChange={(value: Moment | string) => setDateTime('string' === typeof value ? value : value.toString())}
                                    timeConstraints={
                                        {
                                            hours: {min: 0, max: 72, step: 1}
                                        }}
                                />
                            </div>
                        </div>
                        <div className={classNames(dig.col7, dig.mt3)} style={{color: "white", textAlign: "center"}}>
                            {dateTime?.toString()} which is
                            also {moment(dateTime).tz("America/Vancouver").toString()} here at Drop-In
                            Gaming. If the time selected/submitted is less than the server&apos;s current time, ie in
                            the past,
                            the time the request was received will be used.
                        </div>
                    </div>
            }


        </ExpandableBox>

        <ExpandableBox header={'Best of' + (undefined !== bestOf ? ' ' + bestOf + ' Rounds' : '')} centered>
            {[1, 3].map((bestOfListItem, index) => {
                return <div key={index} className={dig.col6}>
                    <Button
                        className={classNames(dig.minWidth150Px, dig.border1, dig.textWhite)}
                        isSelectable
                        isSelected={bestOf === bestOfListItem}
                        onClick={() => setBestOf(bestOfListItem)}>
                        Best of {bestOfListItem}
                    </Button>
                </div>
            })}
        </ExpandableBox>

        <div className={classNames(dig.digUserActionGroup, dig.createMatch, dig.mt5)}>

            <Button
                className={classNames(dig.minWidth150Px, dig.border1, dig.textWhite)}
                onClick={(e) => {
                    // pretty sure match fees are not accounted for in buyIn, so we need to check against userFunds + matchFee
                    e.preventDefault();

                    // admins can create matches with any amount
                    if (!iAmAdmin) {
                        let numberBuyIn = parseFloat(buyIn),
                            matchFee = numberBuyIn * 0.10;

                        if (userFunds < (numberBuyIn + matchFee)) {
                            alert('You do not have enough funds to create this match. Select an amount less than or equal to your current funds.');
                            return;
                        }
                    }

                    postCompetition({
                        comp_type_id: 1,
                        mode_game_id: selectedModeGame,
                        best_of: bestOf,
                        buy_in: buyIn,
                        now_flag: nowFlag,
                        public: 1,
                        pin: 0,
                        organizer_id: 1, // formerly id, but we no longer add ourselves until after creation, so should be system id (1)
                        status: 0,
                        deleted: 0,
                        datetime: moment(dateTime).format('YYYY-MM-DD HH:mm:ss'), //2020-11-25 12:00:00
                        region_id: selectedRegions,
                        platform_id: [...otherPlatforms, selectedPlatform] as number[],
                        success: (response) => {

                            if ('number' !== typeof response?.data?.created) {

                                toast.error('Error creating match. Please try again.')

                                return undefined;

                            }

                            setCreatedCompetition(response?.data?.created)

                            return "Posted your competition!"

                        }
                    })
                }}>
                Create Match
            </Button>

        </div>

    </div>

}

import MediaQuery from "react-responsive";
import {useNavigate} from "react-router-dom";
import DropDown from "src/components/DropDown/DropDown";
import Tabs from "src/components/Tabs/Tabs";
import {getStyles} from "src/variables/DropVariables";
import {iAmProfileDescendant} from "src/views/Profile/Profile";
import classNames from "classnames";


export default function Menu({location, navigation, prefix}: iAmProfileDescendant) {

    const navigate = useNavigate();

    const tabActive = location.pathname.slice(prefix.length, -1)

    const dig = getStyles();

    const tabs = navigation.map((nav) => nav.path)

    return <div className={classNames(dig.mt3, dig.roundedNav)}>
        <div
            className={classNames(dig.my4, dig.navTabs, dig.navPills, dig.border0, dig.gameHeroHeaderContainer, dig.row, dig.g0, dig.positionRelative)}>
            <MediaQuery minWidth={768}>

                <Tabs tabs={tabs}
                      activeTab={tabActive}
                      onTabClick={(tab) => {
                          const path = prefix + tab.toLowerCase() + '/';

                          return navigate(path);

                      }}/>

            </MediaQuery>
            <MediaQuery maxWidth={425}>
                <DropDown
                    defaultValue={tabActive.charAt(0).toUpperCase() + tabActive.slice(1)}
                    onChange={(value) => {
                        const path = prefix + value.toLowerCase() + '/';

                        return navigate(path);
                    }}
                    options={tabs.map((tab) => tab.charAt(0).toUpperCase() + tab.slice(1))}
                />

                <MediaQuery maxWidth={425}>
                    <hr className={classNames("border-top-1 border-color-gg w-100 mb-0 mt-4")}/>
                </MediaQuery>
            </MediaQuery>
        </div>
    </div>


}

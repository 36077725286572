import {ChangeEvent, useState} from "react";
import classNames from "classnames";
import {Link, Navigate} from "react-router-dom";
import getPosts from "src/api/rest/getPosts";
import Bootstrap from "src/Bootstrap";
import Button from "src/components/Button/Button";
import Input from "src/components/Input/Input";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {WatchInputElementChangeEvent} from "src/components/Timeout/Timeout";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {DATE_SEARCH_PATH, INTEL_PATH, POST_SEARCH_PATH} from "src/views/Intel/Intel";
import ExpandableBox from "../../components/ExpandableBox/ExpandableBox";
import useWindowDimensions from "../../api/hoc/windowDimensions";

// core components
export default function Sidebar(props: WithRouter) {

    const dig = getStyles()

    const {posts} = Bootstrap.bootstrap.state;

    const [search, setSearch] = useState<string>(props.params.search || '')

    const getMorePosts = getPosts()

    if ('' !== search
        && search !== props.params.search) {

        return <Navigate to={'/' + INTEL_PATH + '/' + search}/>
    }

    let categories : string[] = [];

    let archive : string[] = [];

    posts?.forEach(post => {

        post.categories?.forEach(category => {

            if (false === categories.includes(category)) {

                categories.push(category);

            }

        })

        if (post?.post_date && false === archive.includes(post?.post_date)) {

            archive.push(post?.post_date)

        }

    })

    const {width} = useWindowDimensions()

    return <div className={classNames(dig.digBlogSidebar, dig.colSm3)}>
        <aside className={classNames(dig.sidebar)} role="complementary">

            {DropVariables.objectLoading(posts)
                ? <Loading message={'Loading posts!'}/>
                : <>
                    <Input type={'text'} label={'Intel search'}
                           placeholder={'Drop your keywords here!'}
                           onChange={(event: ChangeEvent<HTMLInputElement>) => WatchInputElementChangeEvent(event,
                               async (event) => {

                                   setSearch(event.target.value);

                                   return true; // turn green

                               })}/>
                    <br/>
                    <div className={"sidebar-widget"} >
                        <ExpandableBox startCollapsed={400 >= width} header={"Archive"} transparent={true}>
                        <div id="categories-3" className={"widget_categories"}>

                            <ul style={{
                                maxHeight: "40vh",
                                overflowY: "scroll",
                            }}>
                                {archive.map((date, index) => <li key={index}
                                                                  className={classNames(dig.catItem, "cat-item-90")}>
                                    <Link to={'/' + INTEL_PATH + '/' + DATE_SEARCH_PATH + '/' + date + '/'}>
                                        {DropVariables.convertPstToLocal(date)}
                                    </Link>
                                </li>)}
                            </ul>
                        </div>
                        </ExpandableBox>
                        <br/>
                        <ExpandableBox startCollapsed={400 >= width} header={"Categories"} transparent={true}>

                        <div id="archives-3" className={dig.widgetArchive}>

                            <ul style={{
                                maxHeight: "40vh",
                                overflowY: "scroll"
                            }}>
                                {categories.map((category: string, key) => <li key={key}>
                                    <Link to={'/' + INTEL_PATH + '/' + POST_SEARCH_PATH + '/' + category + '/'}>
                                        {category.replace('&amp;', '&')}
                                    </Link>
                                </li>)}
                            </ul>

                        </div>
                        </ExpandableBox>
                    </div>

                    <div className={dig.sidebarWidget}>
                    </div>
                </>}

            {typeof getMorePosts === 'function' && <Button onClick={() => {
                getMorePosts()
            }}>Load More</Button>}
        </aside>
    </div>


}
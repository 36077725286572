import classNames from "classnames";
import {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import getCompetitions from "src/api/rest/getCompetitions";
import Bootstrap from "src/Bootstrap";
import CompetitionGrid from "src/components/CompetitionGrid/CompetitionGrid";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, iCompetitions, iUser} from "src/variables/sharedInterfaces";
import {BRACKETS_PATH, MATCHES_PATH, PROFILE_PATH, TOURNAMENTS_PATH} from "src/views/Profile/Profile";
import getCurrentLoggedInUser from "../../api/hoc/getCurrentLoggedInUser";
import moment from "moment/moment";


export default function CompetitionsJoined(_props: WithRouter) {

    const {competitions, id} = Bootstrap.bootstrap.state;

    useEffect(() => {
        setToday(today)

        if (0 !== id) {

            getCompetitions({
                userID: id
            })

        }

    });

    const myAccount: iUser | undefined = getCurrentLoggedInUser();

    const [today, setToday] = useState(() => moment().format('YYYY-MM-DD HH:mm:ss'));

    const getMyUpcomingCompetitions = (): iCompetitions[] | undefined => {
        if (undefined === myAccount) {
            return []
        }
        const filteredCompetitions = competitions?.filter(
            competition => myAccount.ID && competition.registered_users?.includes(myAccount.ID) &&
                (competition.datetime ? Date.parse(competition.datetime) : 0) > Date.parse(today)
        );
        return filteredCompetitions || [];
    };

    const myCompetitions: undefined | iCompetitions[] = getMyUpcomingCompetitions();

    const myJoinedTournaments = myCompetitions?.filter(value => value.comp_type_id === eCOMPETITION.TOURNAMENTS)
        .slice(0, 4)

    const myJoinedMatches = myCompetitions?.filter(value => value.comp_type_id === eCOMPETITION.MATCHES)
        .slice(0, 4)

    const myJoinedBrackets = myCompetitions?.filter(value => value.comp_type_id === eCOMPETITION.BRACKETS).slice(0, 4)

    const dig = getStyles()

    const MyTournaments = 0 === (myJoinedTournaments?.length ?? 0) ? null : <>
        <div className={classNames(dig.digDashboardNav, dig.container)}>
            <div className={"dig-left"}><h3>My Tournaments</h3></div>
            <div className={classNames("dig-dashboard-elem-container dig-right")}>
                <Link to={'/'+PROFILE_PATH+'/'+ myAccount?.ID +'/' + TOURNAMENTS_PATH}>
                    Go To All Your Tournaments
                    <i className={classNames("fas fa-angle-right")}></i>
                </Link>
            </div>
        </div>
        <CompetitionGrid competitionsToDisplay={myJoinedTournaments}/></>

    const MyMatches = 0 === (myJoinedMatches?.length ?? 0) ? null : <>
        <div className={classNames(dig.digDashboardNav, dig.container)}>
            <div className={"dig-left"}><h3>My Matches</h3></div>
            <div className={classNames("dig-dashboard-elem-container dig-right")}>
                <Link to={'/'+PROFILE_PATH+'/'+ myAccount?.ID +'/' + MATCHES_PATH}>
                    Go To All Your Matches
                    <i className={classNames("fas fa-angle-right")}></i>
                </Link>
            </div>
        </div>
        <CompetitionGrid competitionsToDisplay={myJoinedMatches}/>
    </>

    const MyBrackets = 0 === (myJoinedBrackets?.length ?? 0) ? null : <>
        <div className={classNames(dig.digDashboardNav, dig.container)}>
            <div className={"dig-left"}><h3>My Brackets</h3></div>
            <div className={classNames("dig-dashboard-elem-container dig-right")}>
                <Link to={'/'+PROFILE_PATH+'/'+ myAccount?.ID +'/' + BRACKETS_PATH}>
                    Go To All Your Brackets<i className={classNames("fas fa-angle-right")}></i>
                </Link>
            </div>
        </div>
        <CompetitionGrid
            competitionsToDisplay={myJoinedBrackets}
            displaySort={false}
        /></>

    return <>
        {MyTournaments}
        {MyMatches}
        {MyBrackets}
    </>

}

import {Link} from "react-router-dom";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";

import {ReactComponent as BracketSVG} from "src/assets/img/svg-icons/bracket.svg";
import {ReactComponent as InvitationsSVG} from "src/assets/img/svg-icons/invitations.svg";
import {ReactComponent as MatchSVG} from "src/assets/img/svg-icons/match.svg";
// import {ReactComponent as NotificationsSVG} from "src/assets/img/svg-icons/notifications.svg";
import {ReactComponent as ProfileSVG} from "src/assets/img/svg-icons/profile.svg";
import {ReactComponent as TournamentSVG} from "src/assets/img/svg-icons/tournament.svg";
import Bootstrap from "src/Bootstrap";
// import {eGLOBAL_CHAT_TABS} from "src/components/Chat/Chat";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import isGG from "src/variables/isGG";
import {INFORMATION_PATH} from "src/views/Profile/Information/Information";
import {INVITATIONS_PATH} from "src/views/Profile/Invitations/Invitations";
import {BRACKETS_PATH, MATCHES_PATH, PROFILE_PATH, TOURNAMENTS_PATH} from "src/views/Profile/Profile";
import classes from "./style.module.scss"
import classNames from "classnames";
import {WALLET_PATH} from "src/components/Wallet/Wallet";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMinusCircle, faPlusCircle} from "@fortawesome/free-solid-svg-icons";
import {HISTORY_PATH} from "src/views/Profile/History/History";
import Loading from "../../Loading/Loading";


interface iNotification {
    notificationCounts: {
        invitationCount: number,
        tournamentCount: number,
        bracketCount: number,
        matchCount: number
    }
}

export default function UserBarMobile(props: iNotification) {

    const currentUser = getCurrentLoggedInUser();

    const {id, bootstrap} = Bootstrap.bootstrap.state;

    const isLoggedIn = 0 !== id

    if (false === isLoggedIn) {
        return null;
    }

    const dig = getStyles(classes)

    const color = bootstrap.state.darkMode ? 'var(--dig_neutral5)' : DropVariables.getPrimaryColor();

    const {invitationCount, tournamentCount, bracketCount, matchCount} = props.notificationCounts;

    return <>
        {
            true === isLoggedIn && (undefined === currentUser
                ? <Loading message={"Loading Current User"} count={1}/>
                :
                <>
                    <div className={classNames(dig.userBarMobile, dig.py3)}>

                        <Link to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + INFORMATION_PATH + '/'}>
                            <ProfileSVG
                                fill={color}
                                style={{width: "35px"}}/>
                        </Link>

                        <Link to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + INVITATIONS_PATH + '/'}>
                            {0 !== invitationCount && <span
                                className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                    dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                    dig.positionAbsolute, dig.top0, dig.translateMiddle)}
                                style={{display: "block"}}>{invitationCount}</span>}
                            <InvitationsSVG
                                fill={color}
                                style={{width: "35px"}}/>
                        </Link>

                        {/*<Link to={'#'}*/}
                        {/*      onClick={() => {*/}
                        {/*          bootstrap.setState({rightSideBarTabOpened: eGLOBAL_CHAT_TABS.NOTIFICATIONS})*/}
                        {/*      }}>*/}
                        {/*    <NotificationsSVG*/}
                        {/*        fill={color} style={{*/}
                        {/*        width: "35px"*/}
                        {/*    }}/>*/}
                        {/*</Link>*/}

                        <Link to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + TOURNAMENTS_PATH + '/'}>
                            {0 !== tournamentCount && <span
                                className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                    dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                    dig.positionAbsolute, dig.top0, dig.translateMiddle)}
                                style={{display: "block"}}>{tournamentCount}</span>}
                            <TournamentSVG fill={color} style={{width: "35px"}}/>
                        </Link>

                        <Link to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + MATCHES_PATH + '/'}>
                            {0 !== matchCount && <span
                                className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                    dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                    dig.positionAbsolute, dig.top0, dig.translateMiddle)}
                                style={{display: "block"}}>{matchCount}</span>}
                            <MatchSVG fill={color} style={{width: "35px"}}/>
                        </Link>

                        <Link to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + BRACKETS_PATH + '/'}>
                            {0 !== bracketCount && <span
                                className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                    dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                    dig.positionAbsolute, dig.top0, dig.translateMiddle)}
                                style={{display: "block"}}>{bracketCount}</span>}
                            <BracketSVG fill={color} style={{width: "35px"}}/>
                        </Link>

                    </div>

                    <div className={classNames(dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter, dig.pt4, dig.userBarMobileFunds)}>
                        <Link to={'/' + PROFILE_PATH + '/' + id + "/" + WALLET_PATH + '/'}>
                            <FontAwesomeIcon
                                style={{color: color}}
                                icon={faPlusCircle}
                                size={'2x'}/>
                        </Link>
                        <h3 className={classNames(dig.colMd3)}>
                            <a href={'/' + PROFILE_PATH + '/' + id + '/' + HISTORY_PATH + '/'} title="Account History"
                               className={classNames(dig.col2, dig.mt1)} style={{color: color, fontSize: '32px'}}>
                                {isGG() ? '' : '$'}{currentUser.funds?.toFixed(2) || 0.00}
                            </a>
                        </h3>

                        <Link to={'/' + PROFILE_PATH + '/' + id + "/" + WALLET_PATH + '/'}>
                            <FontAwesomeIcon
                                style={{color: color}}
                                icon={faMinusCircle}
                                size={'2x'}/>
                        </Link>
                    </div>
                </>
            )}
    </>
}

import moment from "moment/moment";
import {toast} from "react-toastify";
import restApi, {GET} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import getInvitations from "src/api/rest/getInvitations";
import getPosts, {ePost} from "src/api/rest/getPosts";
import getUmMeta from "src/api/rest/getUmMeta";
import {iUser} from "src/variables/sharedInterfaces";
import DropVariables from "src/variables/DropVariables";
import {C6, dig_wallet_transactions, um_followers, users} from "src/variables/C6";
import Bootstrap from "src/Bootstrap";
import getUserMeta from "./getUserMeta";
import getRegistrations from "./getRegistrations";
import getPermissions from "./getPermissions";
import isCOM from "src/variables/isCOM";
import getCurrentLoggedInUser from "../hoc/getCurrentLoggedInUser";
import {addAlert} from "src/components/Alert/Alert";
import isTest from "../../variables/isTest";

export interface iGetUser {
    userIds?: number[],
    search?: string
}

// bump commit for rebuild
const getUsers = restApi<iGetUser>({
    axios: DropVariables.axios,
    tableName: C6.users.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {

        const bootstrap: Bootstrap = Bootstrap.bootstrap;

        let lookUpQueue: number[] = [];

        if (undefined !== request.userIds && undefined !== request.search) {

            throw Error('Should not pass both userIds and search explicitly!');

        }

        if (undefined !== request.userIds) {

            if (request.userIds.length === 0) {

                console.log('an empty array of userIds was passed to getUsers.tsx')

                return null

            }

            let userIds: number[] = request.userIds;

            userIds.map(userId => {

                if (false === isTest && userIds.length > 1 && userId === bootstrap.state.id) {
                    // there is a race condition with the authenticate event that will prevent wallet info from being fetched
                    return undefined;
                }

                const cached = bootstrap.state.users?.find(user => user.ID === userId);

                if (undefined === cached || (false === isTest && request.cacheResults === false)) {

                    let tempUserInfo = {
                        ID: userId
                    };

                    lookUpQueue.push(userId);

                    // this is our flag not to re-fire the ajax request for this user
                    updateRestfulObjectArray<iUser>([tempUserInfo], "ID", "users")

                } else {

                    if (DropVariables.isLocal && Object.keys(cached).length === 1) {

                        toast.warning("DEVS: User (" + cached.ID + ") is being looked up!", DropVariables.toastOptionsDevs);

                    }

                }

            })

            if (0 === lookUpQueue.length) {

                console.log('getUsers: nothing in the lookup Queue')

                return undefined;

            } else if (DropVariables.isLocal) {

                toast.warning("DEVS: User(s) (" + lookUpQueue.join(',') + ") are being looked up!", DropVariables.toastOptionsDevs);

            }

        } else if (request.search === '') {

            throw Error('Do not pass an empty search request')

        }

        let params = {
            [C6.SELECT]: [
                C6.users.ID,
                C6.users.USER_NICENAME,
                C6.users.DISPLAY_NAME,
                C6.users.USER_STATUS,
                C6.dig_user_info.USER_ACTIVE,
                C6.dig_user_info.USER_BIRTHDAY,
                [
                    C6.SELECT,
                    C6.posts.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            C6.posts.GUID
                        ],
                        [C6.WHERE]: {
                            [C6.posts.POST_EXCERPT]: 'profile_photo',
                            [C6.posts.POST_TYPE]: 'attachment',
                            [C6.posts.POST_AUTHOR]: C6.users.ID,
                        },
                        [C6.PAGINATION]: {
                            [C6.ORDER]: {
                                [C6.posts.POST_DATE]: C6.DESC
                            },
                            [C6.PAGE]: 1,
                            [C6.LIMIT]: 1
                        }
                    },
                    'profile_photo'
                ],
                [
                    C6.SELECT,
                    C6.um_friends.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.um_friends.USER_ID1]
                        ],
                        [C6.WHERE]: {
                            0: {
                                [C6.um_friends.USER_ID1]: C6.users.ID,
                                [C6.um_friends.USER_ID2]: C6.users.ID,
                            },
                            [C6.um_friends.STATUS]: 1
                        }
                    },
                    'friends'
                ],
                [
                    C6.SELECT,
                    C6.um_friends.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.um_friends.USER_ID2]
                        ],
                        [C6.WHERE]: {
                            0: {
                                [C6.um_friends.USER_ID1]: C6.users.ID,
                            },
                            [C6.um_friends.STATUS]: 0
                        }
                    },
                    'sentFriendRequests'
                ],
                [
                    C6.SELECT,
                    C6.um_friends.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.um_friends.USER_ID1]
                        ],
                        [C6.WHERE]: {
                            0: {
                                [C6.um_friends.USER_ID2]: C6.users.ID,
                            },
                            [C6.um_friends.STATUS]: 0
                        }
                    },
                    'receivedFriendRequests'
                ],
                [
                    C6.SELECT,
                    C6.um_followers.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.um_followers.USER_ID2]
                        ],
                        [C6.WHERE]: {
                            [C6.um_followers.USER_ID1]: C6.users.ID,
                        }
                    },
                    'followers'
                ],
                [
                    C6.SELECT,
                    um_followers.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.um_followers.USER_ID1]
                        ],
                        [C6.WHERE]: {
                            [um_followers.USER_ID2]: C6.users.ID,
                        }
                    },
                    'following'
                ],
            ],
            [C6.JOIN]: {
                /*[C6.LEFT_OUTER]: {
                    [um_metadata.TABLE_NAME]: {
                        [um_metadata.USER_ID]: users.ID,
                        [um_metadata.UM_KEY]: 'profile_photo',
                    },
                }*/
                [C6.LEFT]: {
                    [C6.dig_user_groups.TABLE_NAME]: [
                        C6.users.ID,
                        C6.dig_user_groups.USER_ID
                    ],
                    [C6.dig_user_info.TABLE_NAME]: [
                        C6.dig_user_info.USER_ID,
                        C6.users.ID,
                    ]
                }
            },
            [C6.GROUP_BY]: [
                C6.users.ID
            ],
            [C6.WHERE]: {},
            [C6.PAGINATION]: {
                [C6.ORDER]: {
                    [C6.users.ID]: C6.DESC
                },
                [C6.LIMIT]: 0 !== lookUpQueue.length ? lookUpQueue.length : 20,
                [C6.PAGE]: 1
            },
        } as any;

        if (request.search !== undefined && request.search !== '') {

            params[C6.WHERE][0] = [
                [C6.users.DISPLAY_NAME, C6.LIKE, '%' + request.search + '%'],
                [C6.users.USER_NICENAME, C6.LIKE, '%' + request.search + '%']
            ];

        }

        console.log("getUsers",
            bootstrap.state.id,
            lookUpQueue.length,
            lookUpQueue,
            params);

        const loggedInUser = getCurrentLoggedInUser()

        const lookupIsChildrenOrParents = lookUpQueue.find(id => !(loggedInUser?.children?.includes(id) || loggedInUser?.parents?.includes(id))) === undefined

        console.log("lookupIsChildrenParents", lookupIsChildrenOrParents, loggedInUser, loggedInUser?.children, lookUpQueue.find(id => !(loggedInUser?.children?.includes(id) || loggedInUser?.parents?.includes(id))))

        // lookUpQueue maybe children or parents, if so we need to fetch the funds
        if (bootstrap.state.id !== 0
            && (
                (lookUpQueue.length === 1
                    && lookUpQueue[0] === bootstrap.state.id)
                || lookupIsChildrenOrParents)
        ) {

            params[C6.SELECT].push(C6.users.USER_EMAIL)

            params[C6.SELECT].push([C6.SUM, dig_wallet_transactions.AMOUNT, 'funds']);

            params[C6.JOIN][C6.LEFT][dig_wallet_transactions.TABLE_NAME] = {
                0: {
                    [dig_wallet_transactions.BUYER_ID]: users.ID,
                    1: {
                        [C6.dig_wallet_transactions.USER_ID]: users.ID,
                        [C6.dig_wallet_transactions.BUYER_ID]: 0
                    }
                }
            };

            if (false === isCOM()) {

                params[C6.SELECT].push([
                    C6.SELECT,
                    C6.dig_parent_user.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.dig_parent_user.CHILD_USER_ID]
                        ],
                        [C6.WHERE]: {
                            [C6.dig_parent_user.PARENT_USER_ID]: C6.users.ID,
                        }
                    },
                    'children'
                ]);

                params[C6.SELECT].push([
                    C6.SELECT,
                    C6.dig_parent_user.TABLE_NAME,
                    null,
                    {
                        [C6.SELECT]: [
                            [C6.GROUP_CONCAT, C6.dig_parent_user.PARENT_USER_ID]
                        ],
                        [C6.WHERE]: {
                            [C6.dig_parent_user.CHILD_USER_ID]: C6.users.ID,
                        }
                    },
                    'parents'
                ]);

                params[C6.SELECT].push([C6.GROUP_CONCAT, C6.dig_user_allowed_games.GAME_ID, 'allowedGames'])

                params[C6.JOIN][C6.LEFT][C6.dig_user_allowed_games.TABLE_NAME] = [
                    C6.users.ID,
                    C6.dig_user_allowed_games.USER_ID
                ];

            }

        }

        if (lookUpQueue.length === 1 && 0 === lookUpQueue[0]) {

            throw new Error("Invalid user ID given. The id 0 may not be queried.");

        }

        if (0 !== lookUpQueue.length) {

            if (DropVariables.isLocal) {

                toast.success("DEVS: User(s) (" + lookUpQueue.join(',') + ") request for remote.", DropVariables.toastOptionsDevs);

            }

            params[C6.WHERE][C6.users.ID] = [C6.IN, lookUpQueue];

        }

        return params;
    },
    responseCallback: (response, _request) => {

        const bootstrap: Bootstrap = Bootstrap.bootstrap;


        let getChildrenOrParentsFn : undefined | (() => void) = undefined;

        const parseUser = (user): iUser => {

            user.funds = parseFloat(user.funds) || 0.00

            user.allowedGames = user.allowedGames?.split(',').map((userID) => parseInt(userID)) ?? undefined

            user.children = user.children?.split(',').map((userID) => parseInt(userID)) || []

            user.parents = user.parents?.split(',').map((userID) => parseInt(userID)) || []

            user.following = user.following?.split(',').map((userID) => parseInt(userID)) || []

            user.followers = user.followers?.split(',').map((userID) => parseInt(userID)) || []

            user.friends = user.friends?.split(',').map((userID) => parseInt(userID)) || []

            user.sentFriendRequests = user.sentFriendRequests?.split(',').map((userID) => parseInt(userID)) || []

            user.receivedFriendRequests = user.receivedFriendRequests?.split(',').map((userID) => parseInt(userID)) || []


            // is child needs another check on birthdate
            const isChild = (user: iUser) => {
                const today = moment();
                const birthdateMoment = moment(user.user_birthday, "YYYY-MM-DD");
                const age = today.diff(birthdateMoment, "years");
                console.log("age", age, user.user_birthday, birthdateMoment, today)

                // this birthday null check is for our existing admin accounts
                return (user.user_birthday !== null && age <= 15)
            }

            user.isChild = user.parents.length > 0 || isChild(user)

            console.log('isChild', user)

            user.isParent = user.children.length > 0

            // In a perfect world, all users who now have referenced id in the info pulled above be fetched here.
            if (user.ID === bootstrap.state.id) {

                // todo - this causes a timing issue
                getChildrenOrParentsFn = () => {
                    getUsers({
                        userIds: [
                            ...user.parents, ...user.children
                        ]
                    })
                }

            }

            return user;

        };

        const restData = response?.data?.rest || [];

        if (0 === restData.length) {

            toast.error('Failed to fetch user info!', DropVariables.toastOptions)

            return;

        }

        const parsedNewUsers = restData.map(parseUser);

        updateRestfulObjectArray<iUser>(parsedNewUsers, "ID", "users", undefined, getChildrenOrParentsFn)

        if (parsedNewUsers.length > 0) { // todo - what is the zero check

            getPermissions({
                user_ids: parsedNewUsers.map(user => user.ID)
            })

        }

        parsedNewUsers.map(user => {

            if (user.ID === bootstrap.state.id) {

                if (user.user_birthday === undefined) {

                    addAlert({
                        title: 'Missing birthday',
                        text: 'Please update your birthday in your profile settings.',
                    })

                }

                getInvitations()

                getUserMeta({
                    user: user,
                    cacheResults: _request.cacheResults ?? false
                })

                getUmMeta({
                    user: user
                })

                getPosts({
                    queryPostType: ePost.MY_IMAGES
                })

                getRegistrations({})

            }

        })

    }
});


// we have a recursive call, which is why we need to export the function and not the call.
export default getUsers;
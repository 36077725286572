import classNames from "classnames";
import {ReactNode, useState} from "react";
import {Link, Navigate} from "react-router-dom";
import Bootstrap from "src/Bootstrap";
import ChatStyle from "src/components/Chat/style.module.scss";
import Loading from "src/components/Loading/Loading";
import ProfilePicture from "src/components/ProfilePicture/ProfilePicture";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {COMPETITION_JOIN_PATH, COMPETITION_LOGIN_PATH, COMPETITION_PATH} from "src/views/Competition/Competition";
import {PROFILE_PATH} from "src/views/Profile/Profile";
import isLoggedIn from "../../../variables/isLoggedIn";
import {WALLET_PATH} from "../../Wallet/Wallet";
import {iUser} from "../../../variables/sharedInterfaces";
import getCurrentLoggedInUser from "../../../api/hoc/getCurrentLoggedInUser";


export default function Messages() {

    // const [reasonForReport, setReasonForReport] = useState<string | undefined>(undefined);

    // const imAdmin = Bootstrap.bootstrap.state.users?.map((user) => {
    //     return user.user_is_admin === true
    // })

    const {globalMessages} = Bootstrap.bootstrap.state;

    const dig = getStyles(ChatStyle)


    return <>
        {null !== globalMessages && globalMessages?.slice(0).reverse().map((value, key) => {

            // todo - we might have better performance if we reverse the array after the axios request
            // slice(0) - preforms a deep copy, remove it and explore the classes to see reverse occur every state change on the globally referenced object
            const isLink = value.global_message
                ?.replace(/\\'/, '\'')
                .match(/^(.*)\[::([^\]]*)]\(([^)]*)\)$/);


            let message: ReactNode;

            if (isLink) {

                const myAccount: iUser | undefined = getCurrentLoggedInUser();
                const [useRedirect, setUseRedirect] = useState<string>('');
                const competitions = Bootstrap.bootstrap.state.competitions;

                let hyperLink = isLink[3];
                let chatMessage = isLink[2];
                let compId;


                //redirect to competition link. Admin commands dont prepend /competition/ to the link, so this does.
                if (isLink && hyperLink) {
                    const competitionRegexWithoutPrefix = /^(\d+)$/;
                    const competitionRegexWithPrefix = /^\/competition\/(\d+)$/;

                    if (competitionRegexWithoutPrefix.test(hyperLink)) {
                        const match = hyperLink.match(competitionRegexWithoutPrefix);
                        compId = match ? match[1] : null;
                        hyperLink = '/' + COMPETITION_PATH + '/' + compId + '/';
                    }

                    if (competitionRegexWithPrefix.test(hyperLink)) {
                        const match = hyperLink.match(competitionRegexWithPrefix);
                        compId = match ? match[1] : null;
                    }

                }



                if (window.location.pathname === useRedirect) {
                    setUseRedirect('')
                    return null;
                }

                if ('' !== useRedirect) {
                    return <Navigate key={key} to={useRedirect}/>
                }

                const hasEnoughFunds = undefined !== myAccount && ((myAccount?.funds ?? 0) - parseFloat(competitions?.find(competition => competition.ID == parseFloat(compId))?.buy_in ?? '0')) >= 0
                const thisCompetitionUri = '/' + COMPETITION_PATH + '/' + compId + '/'

                const handleJoinOpen = () => {

                    if (false === isLoggedIn()) {
                        setUseRedirect(thisCompetitionUri + COMPETITION_LOGIN_PATH + '/');
                    } else if (hasEnoughFunds) {
                        setUseRedirect(thisCompetitionUri + COMPETITION_JOIN_PATH + '/');
                    } else if (false === hasEnoughFunds) {
                        setUseRedirect(thisCompetitionUri + WALLET_PATH + '/');
                    }

                }


                message = <>

                    {isLink[1]}

                    <aside className={dig.dFlex}>
                        <Link
                            className={classNames(dig.dBlock, dig.w100)}
                            to={hyperLink}
                            style={{color: "orange"}}>
                            {chatMessage}
                        </Link>
                        {compId && <div style={{cursor: 'pointer', color: 'green'}} onClick={
                            handleJoinOpen
                        }>Join</div>}
                    </aside>
                </>

            } else {

                message = <>{value.global_message?.replace(/&apos;/,'\'').replace(/\\'/, '\'')}</>

            }

            // active
            return <div key={key} className={classNames(
                dig.listGroupItem,
                dig.listGroupItemAction,
                dig.py3,
                dig.chatlistIitem
            )}>
                <div className={classNames(
                    dig.dFlex, dig.w100, dig.alignItemsCenter, dig.justifyContentBetween, dig.fontSize12,
                    dig.mb2
                )}>
                    <strong className={classNames( dig.dFlex, dig.alignItemsCenter)}>

                        {value.global_message_from_user && <ProfilePicture width={18} height={18}
                                                                           alt={value.display_name}
                                                                           user={{
                                                                               ID: value.global_message_from_user,
                                                                               profile_photo: value.profile_photo
                                                                           }}/>}

                        <Link to={"/" + PROFILE_PATH + "/"}

                              className={classNames(
                            dig.digChatItemHeader, dig.ms2, dig.textWhite
                        )}>
                            {value.display_name}
                        </Link>

                        {/*{imAdmin && <span className={classNames(*/}
                        {/*    dig.digChatItemHeader, dig.ms2, dig.textWhite*/}
                        {/*)}>*/}
                        {/*    */}
                        {/*    {*/}
                        {/*        /*not sure what kind of badge is wanted, so i put a term easily searchable in the codebase*!/*/}
                        {/*    Admin, son!</span>*/}
                        {/*}*/}


                    </strong>
                    <div>
                        <small style={{float: 'right', cursor: 'pointer', paddingLeft: '5px'}}

                               onClick={() => {

                                   alert("isLink: " + isLink + "\n"+ "hyperLink: " + isLink?.[3]+ "\n" + "chatMessage: " + isLink?.[2]);


                                   // const alertButtons: iAlertButtonOptions[] = [
                                   //     {
                                   //         text: "Cross-Platform Promotion",
                                   //         value: 'Cross-Platform Promotion',
                                   //         color: "primary",
                                   //     },
                                   //     {
                                   //         text: "Profanity",
                                   //         value: 'Profanity',
                                   //         color: "primary",
                                   //     },
                                   //     {
                                   //         text: "Spam / Fishing",
                                   //         value: 'Spam',
                                   //         color: "primary",
                                   //     },
                                   //     {
                                   //         text: "Other",
                                   //         value: 'Other',
                                   //         color: "primary",
                                   //     },
                                   //
                                   // ];
                                   //
                                   // addAlert({
                                   //     title: "Report Chat",
                                   //     text: "Please select a reason for reporting this chat.",
                                   //     buttons: alertButtons,
                                   //     then: (value) => {
                                   //          alert('You have reported this chat for: ' + value)
                                   //
                                   //         // setReasonForReport(value);
                                   //
                                   //         // todo - add logic for reasonForReport
                                   //
                                   //
                                   //     },
                                   // });

                               }}
                        >X</small>
                        <small
                            className={dig.textWhite}>{value?.global_message_datetime && DropVariables.convertPstToLocal(value?.global_message_datetime)}</small>
                    </div>
                </div>
                <div className={classNames(dig.col12, dig.mb1, dig.fontSize14, dig.textWhite)}>
                    {message}

                </div>

            </div>
        }) || <Loading count={20} message={"Loading Message(s)"}/>}
    </>
}

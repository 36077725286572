import {useState} from "react";
import {toast} from "react-toastify";
// import MediaQuery from "react-responsive";
import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import {iUsers} from "src/variables/C6";
import Input from "src/components/Input/Input";
import styles from "./style.module.scss";
import getUsernames from "../../../api/rest/getUsernames";
import {iUser} from "src/variables/sharedInterfaces";
import Datetime from "react-datetime";
import DropVariables from "../../../variables/DropVariables";
import moment from "moment";
import TermsAndConditions from "../../../components/TermsAndConditions/TermsAndConditions";
import postUser from "../../../api/rest/postUser";
import Bootstrap from "../../../Bootstrap";

export const ADD_GAMER_PATH = 'add-gamer';

interface iAddGamerProps {
    handleClose: () => void,
}

const AddGamer = ({handleClose}: iAddGamerProps) => {
    const dig = getStyles(styles);
    const [gamerInformation, setGamerInformation] = useState<iUsers>({});
    const [searchText, setSearchText] = useState('');
    const [onSearch, setOnSearch] = useState(false);
    const [timer,setTimer] = useState<number>(0);
    const [userList, setUserList] = useState<iUser[]>([]);
    const [inviteeID, setInviteeID] = useState<number>(0);
    const [birthdate, setBirthdate] = useState(() => moment().format('YYYY-MM-DD'));
    const unregisteredInvite = new URLSearchParams(window.location.search).get('referenceId');

    const MINIMUM_AGE = 8;


    const onKeyUpChange = () => {
        const newTimer: undefined | number | ReturnType<typeof setTimeout> = window.setTimeout(() => {
            getUsernames({
                usernameSearch: searchText,
                success: (response) => {
                    setUserList(response?.data?.rest || [])
                }
            })
            setOnSearch(false);
        }, 300);

        setTimer(newTimer);
    }

    const inputChanged = e => {
        setSearchText(e.target.value);
        setOnSearch(true);
        window.clearTimeout(timer);
    }
    const yearRange :number[] = [];
    const thisYear = parseInt(moment().format("YYYY"));

    for (let year = thisYear - 17; year <= thisYear - 13; year++) {
        yearRange.push(year);
    }


    return (
        <div className={classNames(
            dig.cardBody, dig.digBgNeutral2, dig.dFlex, dig.alignItemsCenter
        )}>
            <div className={classNames(dig.containerFluid, dig.p3, dig.dFlex, dig.flexRow, dig.justifyContentStart, dig.addGamerModal)}>
                <div className={classNames(dig.col6, dig.colSm6, dig.dFlex, dig.flexColumn, dig.alignItemsCenter)}>

                    <div className={classNames(dig.ggAddGamerForm)}>
                        <h2 className={classNames(dig.w100, dig.textCenter, dig.ggSettingsLabel, dig.fontSize2Em, dig.fwBold, dig.ggTextPrimary)}>
                            Register & Add New Gamer
                        </h2>
                        <div className={classNames(
                            dig.mt3,
                        )}>
                            <Input
                                label={'Username'}
                                required
                                className={classNames(
                                    dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3
                                )} name="user_login" type="text"
                                value={gamerInformation.user_login}
                                onChange={(e) =>
                                    setGamerInformation({
                                        ...gamerInformation,
                                        user_login: e.target.value
                                    })}
                            />

                            <Input
                                label={'Email'}
                                required
                                className={classNames(
                                    dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3
                                )} name="user_email" type="email"
                                onChange={(e) =>
                                    setGamerInformation({
                                        ...gamerInformation,
                                        user_email: e.target.value
                                    })}
                            />
                            <Input
                                label={'Password'}
                                required
                                className={classNames(
                                    dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3
                                )} name="user_password" type="password"
                                onChange={(e) =>
                                    setGamerInformation({
                                        ...gamerInformation,
                                        user_pass: e.target.value
                                    })}
                            />
                            <Input
                                label={'Confirm Password'}
                                required
                                className={classNames(
                                    dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3
                                )} name="user_password_confirm" type="password"
                            />
                            <label> Birthdate</label>
                            <div className={classNames(dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3,
                                     dig.fontSize1P2Em)}
                                 style={{color: 'black'}}>
                                <Datetime
                                    onChange={(e) => {

                                        if ('string' === typeof e) {

                                            e = moment(e, DropVariables.momentGeneralDateFormat)

                                        }

                                        setBirthdate(e.format('YYYY-MM-DD'))
                                    }}
                                    dateFormat={DropVariables.momentGeneralDateFormat}
                                    initialValue={birthdate}
                                    timeConstraints={{
                                        hours: {min: 0, max: 72, step: 1}
                                    }}
                                />
                            </div>
                            <div
                                className={classNames(dig.dFlex, dig.alignItemsCenter)}>
                                <TermsAndConditions/>
                            </div>


                            <div
                                className={classNames(dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.border0, dig.mt3)}>
                                <button
                                    className={classNames(dig.btn, dig.ggPrimaryBtn, dig.rounded, dig.w100)}
                                    type="submit"
                                    onClick={() => {

                                        const today = moment();
                                        const birthdateMoment = moment(birthdate, "YYYY-MM-DD");
                                        const age = today.diff(birthdateMoment, "years");

                                        if (age < MINIMUM_AGE) {
                                            toast.error(`You must be at least ${MINIMUM_AGE} years old to register.`, DropVariables.toastOptions);
                                            return;
                                        }

                                        postUser({
                                            user_login: gamerInformation.user_login,
                                            user_pass: gamerInformation.user_pass,
                                            user_email: gamerInformation.user_email,
                                            temp_invite_id: unregisteredInvite ? parseInt(unregisteredInvite) : undefined,
                                            user_birthday: moment(birthdate).format(DropVariables.momentGeneralDateFormat),
                                            parent_user_id: Bootstrap.bootstrap.state.id
                                        })?.then((response) => {

                                            if ('number' !== typeof response?.data?.created) {
                                                throw new Error('User not created')
                                            }

                                            toast.success('Successfully created gamer!')

                                            handleClose();

                                            return response
                                        })


                                    }}>
                                    Register Player
                                </button>
                            </div>
                        </div>
                    </div>
                </div>


                <div className={classNames(dig.col6, dig.dFlex, dig.flexColumn, dig.justifyContentStart, dig.px3)}>
                    <div className={classNames(dig.ggAddGamerForm)}>

                        <h2 className={classNames(dig.w100, dig.ggSettingsLabel, dig.fontSize2Em, dig.textStart, dig.fwBold, dig.ggTextPrimary)}>
                            Invite Current Gamer
                        </h2>
                        <input placeholder="DIG Username"
                               maxLength={50}
                               type="text"
                               value={searchText}
                               required
                               className={classNames(dig.formControl, dig.border0, dig.rounded0, dig.borderColorNeutral3)}
                               aria-describedby="inputGroup-sizing-default"
                               onChange={inputChanged}
                               onKeyUp={onKeyUpChange}
                               autoComplete="off"/>
                        {
                            userList.length > 0 && inviteeID === 0 ?
                                <ul id="ui-id-1" className={classNames(dig.uiAutocomplete)}>
                                    {
                                        userList.map(user =>
                                            <li
                                                onClick={() => {
                                                    if (undefined !== user.display_name) {
                                                        setSearchText(user.display_name);
                                                    }
                                                    setInviteeID(user.ID); // system account?
                                                }}
                                                key={user.ID}
                                                className={classNames(dig.uiMenuItem)}>
                                                <div id="ui-id-2" className={classNames(dig.uiMenuItemWrapper)}>{user.display_name}</div>
                                            </li>
                                        )
                                    }

                                </ul>
                                :
                                ''
                        }
                        <div
                            className={classNames(dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.border0, dig.mt3)}>
                            <button
                                className={classNames(dig.btn, dig.ggPrimaryBtn, dig.rounded, dig.w100)}
                                type="submit"
                                onClick={() => {
                                }}>
                                {
                                    onSearch === true ? 'Searching...' : 'INVITE'
                                }

                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default AddGamer;



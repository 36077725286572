import restApi, {iPutC6RestResponse, PUT, removeInvalidKeys} from "src/api/API";
import {C6, dig_competitions, iDig_Comp_Invitation, iDig_Competitions} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iCompetitions} from "src/variables/sharedInterfaces";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";

// Used to handle comp start/cancel/end from the frontend
// NOTE: The request expects either status OR public, not both
export interface iPutCompetition extends iDig_Competitions {
    comp_id: number,
    status?: number,
    public?: number
}

export function putCompetitionCallback(request) {
    if (request.public === undefined || request.public === null) {
        deleteRestfulObjectArrays<iDig_Comp_Invitation>((previousState): iDig_Comp_Invitation[] => {
            // get the objects to delete only
            return previousState.invitations?.filter(invitation => invitation.comp_id === request.comp_id) ?? []
        }, "invitation_id", "invitations")
    }


    updateRestfulObjectArray<iCompetitions>((prev) => {

        const prevComp = prev.competitions?.find(competition => competition.comp_id === request.comp_id)

        if (prevComp === undefined) {
            // possibly from socket where the user does not have this comp in state. Ignore.
            return [];
        }

        return [{
            comp_id: request.comp_id,
            status: request.status ?? prevComp?.status,
            public: request.public ?? prevComp?.public,
            bracket: request.bracket ?? prevComp?.bracket
        }]

    }, "comp_id", "competitions");
}

export default restApi<iPutCompetition, iDig_Competitions, {}, iPutC6RestResponse<iDig_Competitions>>({
        axios: DropVariables.axios,
        tableName: C6.dig_competitions.TABLE_NAME,
        requestMethod: PUT,
        queryCallback: (request) => {
            request.success = 'Successfully updated the competition!'
            request.error = 'An unknown issue occurred while updating the competition. We will be looking into this shortly.'
            return request
        },
        responseCallback: (_response, request) => {

            putCompetitionCallback(request);

            // in socket updates, the determined status (STARTED or CANCELED) is sent back. Here, it is not. We can get it from response.
            let restData = _response?.data?.rest;

            if (restData !== undefined) {
                updateRestfulObjectArray<iCompetitions>([
                    removeInvalidKeys({...restData, ...request} , [dig_competitions])
                ], "comp_id", "competitions");
            }

        }
    });
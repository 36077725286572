import restApi, {DELETE, iDeleteC6RestResponse} from "src/api/API";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iRegistrations} from "src/Bootstrap";
import {dig_comp_registration, iDig_Comp_Registration} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iCompetitions, iInvitation} from "src/variables/sharedInterfaces";
import getUsers from "./getUsers";
import isTest from "../../variables/isTest";


export interface iDeleteRegistration extends iDig_Comp_Registration {
    competitions_id: number,
    team_id: number,
    user_id: number
}

export function deleteRegistrationCallback(request) {
    console.warn('request in deleteRegistrationCallback', request);
    deleteRestfulObjectArrays<iRegistrations>(prev => {

        let matching = prev.registrations?.filter(registration => registration.competitions_id === request.competitions_id
            && registration.team_id === request.team_id) ?? [];

        console.warn('matching registrations: ', matching);
        return matching;

    }, "comp_registration_id", "registrations")


    updateRestfulObjectArray<iCompetitions>((prev) => {

        const prevComp = prev.competitions?.find(competition => competition.comp_id === request.competitions_id)

        const team = prev.teams?.find(team => team.user_team_id === request.team_id)

        if (prevComp === undefined) {
            // possibly from socket where the user does not have this comp in state. Ignore.
            console.warn("Competition for delete registration not found in state!");
            return [];
        }

        return [{
            comp_id: request.competitions_id,
            organizer_id: prevComp?.organizer_id === request.user_id ? 1 : prevComp?.organizer_id,
            complete_teams: prevComp?.complete_teams?.filter(team => team !== request.team_id) ?? [],
            incomplete_teams: prevComp?.incomplete_teams?.filter(team => team !== request.team_id) ?? [],
            registered_users: prevComp?.registered_users?.filter(user => false === team?.rosterAccepted?.includes(user)) ?? [],  // todo - delete others registrations
        }]
    }, "comp_id", "competitions");

    deleteRestfulObjectArrays<iInvitation>((previousState): iInvitation[] => {
        // get the objects to delete only
        return previousState.invitations?.filter(invitation => (invitation.team_id === request.team_id || invitation.sender_id === request.user_id) && invitation.comp_id === request.competitions_id) ?? []
    }, "invitation_id", "invitations")

}

export default restApi<iDeleteRegistration, iDig_Comp_Registration, {}, iDeleteC6RestResponse>({
    axios: DropVariables.axios,
    tableName: dig_comp_registration.TABLE_NAME,
    requestMethod: DELETE,
    skipPrimaryCheck: true,
    queryCallback: (request) => {
        request.success = 'Successfully unregistered!'
        request.error = 'An unknown issue occurred removing the registration information. If issues persist, please contact Drop-In Gaming!'
        return request
    },
    responseCallback: (_response, request, id) => {

        if (!id) {

            return;

        }

        deleteRegistrationCallback(request);

        // re-fetch user for updated wallet
        false === isTest && getUsers({
            userIds: [
                request.user_id
            ],
            cacheResults: false,
        })

    }
});


import classNames from "classnames";
import React from "react";
import {toast} from "react-toastify";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import {getMetaValue} from "src/api/hoc/getMetaValue";
import Input from "src/components/Input/Input";
import Loading from "src/components/Loading/Loading";
import Select from "src/components/Select/Select";
import TermsAndConditions from "src/components/TermsAndConditions/TermsAndConditions";
import {getStyles} from "src/variables/DropVariables";
import CountryInformation, {tCountryInformation} from "src/components/Wallet/CountryInformation";
import styles from "src/components/Wallet/style.module.scss";

export default class Billing extends React.Component<{}, {
    selectedCountry: tCountryInformation|undefined,
    firstName: string,
    lastName: string,
    street: string,
    apt: string,
    town: string,
    state: string,
    zip: string,
    forumValid: boolean,
}> {

    // billing has to handle NMI's BS. This is an easy way to do it.
    static billing: Billing|undefined = undefined;

    constructor(props) {
        super(props)
        Billing.billing = this;

        const user = getCurrentLoggedInUser()

        this.state = {
            selectedCountry: CountryInformation.find((country) => country.name === 'United States (US)'),
            firstName: user ? getMetaValue(user, 'first_name')?.value ?? '' : '',
            lastName: user ? getMetaValue(user, 'last_name')?.value ?? '' : '',
            street: '',
            apt: '',
            town: '',
            state: '',
            zip: '',
            forumValid: false,
        }
    }

    validateForum = () => {
        const setForumValid = (valid: boolean) => {
            this.setState({forumValid: valid})
        }
        if (TermsAndConditions.termsAndConditions.state.checked
            && true === Billing.billing?.billingInformationValid()) {
            setForumValid(true);
        } else {
            setForumValid(false);
        }
    }

    billingInformationValid(): boolean {
        return true;
    }


    render() {

        const {selectedCountry, firstName, lastName} = this.state

        if (undefined === selectedCountry) {

            toast.error('Failed to lookup country information');

            return <Loading message={'Looking up country information!'}/>;

        }

        const dig = getStyles(styles)

        return <>

            <div className={dig.woocommerceBillingFieldsFieldWrapper}>

                <div className={classNames(dig.ps0)}>

                        <div className={dig.row}>
                            <div className={classNames(dig.col12, dig.colMd6)}>
                                <Input type={"text"}
                                       label={'First name'}
                                       placeholder={'First name'}
                                       value={firstName}
                                       onChange={(event) => this.setState({
                                           firstName: event.target.value
                                       })}
                                       required
                                />
                            </div>
                            <div className={classNames(dig.col12, dig.colMd6)}>
                                <Input type={"text"}
                                       label={'Last name'}
                                       placeholder={'Last name'}
                                       value={lastName}
                                       onChange={(event) => this.setState({
                                           lastName: event.target.value
                                       })}
                                       required/>
                            </div>

                        <Select label={"Country / Region"}
                                onChange={(event) => this.setState({
                                    selectedCountry: CountryInformation.find(country => country.name === event.target.value)
                                })}
                                required
                                value={selectedCountry.name}
                                options={CountryInformation.map((country) => {
                            return {
                                value: country.name,
                            }
                        })}
                        />

                        <Input type={"text"} label={'Street address'}
                               placeholder={'House number and street name'}
                               onChange={(event) => this.setState({
                                   street: event.target.value
                               })}
                               required/>

                        <Input type={"text"} placeholder={'Apartment, suite, unit, etc. (optional)'}
                               onChange={(event) => this.setState({
                                   apt: event.target.value
                               })}
                               required/>

                        <Input type={"text"} label={'Town / City'}
                               onChange={(event) => this.setState({
                                   town: event.target.value
                               })}
                               required/>

                        {selectedCountry?.state && (selectedCountry.state === true
                            ? <Input type={"text"} label={'State / Province'}
                                     required
                                     onChange={(event) => this.setState({
                                         state: event.target.value
                                     })} />
                            : <Select label={"State / Province"}
                                      required
                                      onChange={(event) => this.setState({
                                          state: event.target.value
                                      })}
                                      options={selectedCountry.state.map((state) => {
                            return {
                                value: state.abbreviation,
                                label: state.name
                            }
                        })}/>)}

                        {selectedCountry?.postal && <Input type={"text"} required label={selectedCountry?.postal} onChange={(event) => this.setState({
                            zip: event.target.value
                        })}/>}

                    </div>

                </div>

            </div>

            <div className={classNames(dig.my5, dig.small)} id="dig-deposit-details">
                <img className={classNames(dig.equifaxImg)}
                     src="/wp-content/themes/dropingaming/assets/img/equifax-logo-corporate.png"/>
            </div>
        </>
    }


}

import {faEdit} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {useEffect, useState} from "react";
import {apiReturn} from "src/api/API";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import getPosts, {ePost} from "src/api/rest/getPosts";
import Bootstrap from "src/Bootstrap";
import Button from "src/components/Button/Button";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import Pagination from "src/components/Pagination/Pagination";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import ScrollToHere from "src/components/ScrollToHere/ScrollToHere";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {iWpPost} from "src/variables/sharedInterfaces";
import {ARTICLE_PATH} from "src/views/Intel/Article";
import Sidebar from "src/views/Intel/Sidebar";

export const INTEL_PATH = "intel";

export const RULES_PATH = 'rules'

export const CATEGORY_PATH = 'category'

export const POST_SEARCH_PATH = 'search'

export const DATE_SEARCH_PATH = 'date'

export const PRIVACY_PATH = 'privacy'

export const COOKIES_PATH = 'cookies'

export const TERMS_PATH = 'terms'


// core components
export default function Intel(props: WithRouter) {

    const [pageNumber, setPageNumber] = useState<number>(1);
    const [forceUpdate, setForceUpdate] = useState<number>(1);

    const forceRerender = () => setForceUpdate(forceUpdate + 1);

    const {posts} = Bootstrap.bootstrap.state;

    const {search, filter} = props.params

    const [getMorePosts, setGetMorePosts] = useState<undefined | (() => apiReturn<any>)>(undefined);

    let filteredPosts: iWpPost[] = [],
        regex = new RegExp('.*' + search + '.*', 'i');

    useEffect(() => {

        const testSetGetMorePosts = (apiReturn: Awaited<apiReturn<any>>) => {
            if (null === apiReturn || undefined === apiReturn) {
                return;
            }
            if ('function' === typeof apiReturn) {
                setGetMorePosts(apiReturn)
                return;
            }
            forceRerender();
        };

        // Cannot update during an existing state transition (such as within `render`). Render methods should be a pure function of props and state.
        (async () => {
            switch (filter) {
                case DATE_SEARCH_PATH:

                    testSetGetMorePosts(await getPosts({
                        queryPostType: ePost.INTEL
                    }))

                    break;
                case RULES_PATH:

                    testSetGetMorePosts(await getPosts({
                        queryPostType: ePost.GAME_RULES
                    }))

                    break;
                case CATEGORY_PATH:

                    testSetGetMorePosts(await getPosts({
                        queryPostType: ePost.INTEL
                    }))

                    break;

                case POST_SEARCH_PATH:

                    testSetGetMorePosts(await getPosts({
                        queryPostType: ePost.INTEL
                    }))

                    break;

                case PRIVACY_PATH:

                    testSetGetMorePosts(await getPosts({
                        queryPostType: ePost.PRIVACY
                    }))

                    break;

                case COOKIES_PATH:


                    testSetGetMorePosts(await getPosts({
                        queryPostType: ePost.COOKIES
                    }))

                    break;

                case TERMS_PATH:

                    testSetGetMorePosts(await getPosts({
                        queryPostType: ePost.TERMS
                    }))

                    break;

                // eslint-disable-next-line no-fallthrough
                case INTEL_PATH:
                default:


                    testSetGetMorePosts(await getPosts())


            }
        })()
    })


    switch (filter) {
        case DATE_SEARCH_PATH:

            filteredPosts = posts?.filter(post => post.post_date === search) ?? [];

            break;
        case RULES_PATH:
            filteredPosts = posts?.filter(post => post.postType === ePost.GAME_RULES) ?? [];

            break;
        case CATEGORY_PATH:

            filteredPosts = posts?.filter(post => post.categories.find(category => category === search)) ?? []

            break;

        case POST_SEARCH_PATH:
            filteredPosts = posts?.filter(post => post.post_title?.match(regex) || post.post_content?.match(regex)) ?? []

            break;

        case PRIVACY_PATH:
            filteredPosts = posts?.filter(post => post.postType === ePost.PRIVACY) ?? []

            break;

        case COOKIES_PATH:

            filteredPosts = posts?.filter(post => post.postType === ePost.COOKIES) ?? []

            break;

        case TERMS_PATH:

            filteredPosts = posts?.filter(post => post.postType === ePost.TERMS) ?? []

            break;

        // eslint-disable-next-line no-fallthrough
        case INTEL_PATH:
        default:

            filteredPosts = posts?.filter(post => post.postType === ePost.INTEL) ?? []

    }

    if (DropVariables.objectLoading(posts)) {

        return <Loading message={'Loading posts!'}/>

    }

    const isSearchRequest = '' !== search && undefined !== search

    const myAccount = getCurrentLoggedInUser()

    const imAdmin = myAccount?.user_is_admin || false

    const dig = getStyles()

    // this is
    filteredPosts ??= [];

    const postsPerPage = 5

    const startingPagePostNumber = (pageNumber - 1) * postsPerPage;

    const currentPaginatedPosts = filteredPosts.slice(startingPagePostNumber, startingPagePostNumber + postsPerPage)

    console.log(currentPaginatedPosts, filteredPosts, startingPagePostNumber, startingPagePostNumber + postsPerPage)

    const knownPages = Math.ceil((filteredPosts?.length ?? 0) / postsPerPage);

    return <div className={classNames(dig.digMainBlogContent, dig.container)}>
        <div className={dig.row}>

            <ScrollToHere/>

            <Sidebar {...props} />

            <div className={classNames(dig.colSm9, dig.mt5, dig.archive, dig.container)}>
                <h1>Intel{isSearchRequest && <> search: ({search})</>}</h1>

                {currentPaginatedPosts.map((post, index) => {

                    const title = post.post_title

                    const paragraphs = post.post_content?.split('<!-- wp:paragraph -->');

                    const link = '/' + ARTICLE_PATH + '/' + post.ID;

                    return <article key={index}
                                    id={'post-' + post.ID}
                                    className={classNames(dig.post, dig.container)}>
                        <ExpandableBox
                            header={title ? (title.charAt(0).toUpperCase() + title?.slice(1)) : 'Loading'}
                            actions={imAdmin &&
                                <a className={dig.m2}
                                   href={"/wp-admin/post.php?post=" + post.ID + "&action=edit"}>
                                    <FontAwesomeIcon color={'white'} icon={faEdit} size={'xs'}/>
                                </a>}>

                            <small className={"date"}>
                                {post.post_date && DropVariables.convertPstToLocal(post.post_date)}
                            </small>

                            <div dangerouslySetInnerHTML={{__html: paragraphs?.[1] ?? ''}}/>

                            <div className={dig.row}>

                            </div>

                            <Button to={link} title="Read More"
                                    className={classNames(dig.btn, dig.rounded0,"dig-read-more")}>
                                Read More
                                <i className={classNames(dig.fas, dig.faChevronRight, dig.p2)}></i>
                            </Button>

                        </ExpandableBox>
                    </article>
                })}

                {false === DropVariables.objectLoading(posts)
                    && <Pagination currentPage={pageNumber}
                                   knownPages={knownPages}
                                   onClick={(number) => {
                                       setPageNumber(number);
                                       console.log(number, knownPages, typeof getMorePosts)
                                       if (number === knownPages
                                           && 'function' === typeof getMorePosts) {
                                           getMorePosts?.()
                                       }
                                   }}/>}

            </div>

        </div>

    </div>
}

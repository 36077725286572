import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import styles from "src/components/Wallet/style.module.scss";
import postMatchConfirmation from "src/api/rest/postMatchConfirmation";

interface iMatchProperties {
    handleClose: () => void,
    teamId: number,
    compId: number
}

export default function ConfirmMatchResults(props: iMatchProperties) {

    const dig = getStyles(styles)

    const {teamId, compId} = props;

    const handleUpdate = (agreement: number) => {

        postMatchConfirmation({
            comp_id: compId,
            team_id: teamId,
            agreement: agreement
        });

        (() => {
            setTimeout(function() {
                props.handleClose();
            }, 1000);
        })();
    }

    return <div className={classNames("model-content", dig.rounded0, dig.border0)}>
        <div className={classNames(dig.modalHeader, dig.rounded0, dig.border0, dig.digBgCardHeader)}>
            <h3 className={classNames(dig.modalTitle, dig.textDark)} id="staticBackdropLabel">Result Confirmation</h3>
        </div>
        <div className={classNames(dig.modalBody, dig.border0, dig.digBgNeutral8, dig.digTextWhite)}>
            <div className={dig.textCenter}>Do you agree with the results of this match?
                This action cannot be reverted.
            </div>
        </div>
        <div className={classNames(dig.modalFooter, dig.digBgNeutral7, dig.border0, dig.rounded0)}>
            <a className={classNames(dig.btn, dig.btnLg, dig.digBtnGreen, "btn-Yes", dig.rounded0)}
               onClick={() => handleUpdate(1)}>Yes</a>
            <a className={classNames(dig.btn, dig.btnLg, dig.digBtnRed, "btn-no", dig.rounded0)} onClick={() => handleUpdate(0)}>No</a>
        </div>
    </div>

}



import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {
    dig_chat_global_messages,
    dig_comp_invitation,
    dig_comp_registration,
    dig_comp_score, dig_competitions,
    dig_team_roster
} from "src/variables/C6";
import {eCOMPETITION_STATUS, iChatMessages, iCompetitions} from "src/variables/sharedInterfaces";
import Bootstrap from "src/Bootstrap";
import getCompScores from "src/api/rest/getCompScores";
import {postRegistrationCallback} from "src/api/rest/postRegistration";
import getTeams from "src/api/rest/getTeams";
import {deleteRegistrationCallback} from "src/api/rest/deleteRegistration";
import {postTeamRosterCallback} from "src/api/rest/postTeamRoster";
import {putTeamRosterCallback} from "src/api/rest/putTeamRoster";
import {deleteInvitationCallback} from "src/api/rest/deleteInvitation";
import {deleteTeamRosterCallback} from "src/api/rest/deleteTeamRoster";
import getInvitations from "src/api/rest/getInvitations";
import {postInvitationCallback} from "src/api/rest/postInvitation";
import {putCompetitionCallback} from "src/api/rest/putCompetition";
//import moment from "moment";

type iReactToParedWebsocketEvent = (event: any) => any;

export default function (event: any) {
    ([
        // REGISTERED WEBSOCKET EVENT LISTENER
        (event) => 'object' === typeof event
            && dig_chat_global_messages.COLUMNS[dig_chat_global_messages.GLOBAL_MESSAGE] in event
            && updateRestfulObjectArray<iChatMessages>(event, "global_message_id", "globalMessages"),
        (event) => 'object' === typeof event
            && 'wp_zesv6j_' + dig_comp_score.TABLE_NAME in event
            && updateSocketCompScores(event),
        (event) => 'object' === typeof event
            && 'wp_zesv6j_' + dig_comp_registration.TABLE_NAME in event
            && updateSocketCompRegistration(event),
        (event) => 'object' === typeof event
            && 'wp_zesv6j_' + dig_team_roster.TABLE_NAME in event
            && updateSocketTeamRoster(event),
        (event) => 'object' === typeof event
            && 'wp_zesv6j_' + dig_comp_invitation.TABLE_NAME in event
            && updateSocketInvitation(event),
        (event) => 'object' === typeof event
            && 'wp_zesv6j_' + dig_competitions.TABLE_NAME in event
            && updateSocketCompetition(event)



    ] as iReactToParedWebsocketEvent[]).forEach((reactToParedWebsocketEvent) => {
        try {
            console.warn('type of event: ', typeof event, 'event: ', event);
            reactToParedWebsocketEvent(event)
        } catch (e) {
            console.error(e);
        }
    });
}

// scores behave differently than most requests, hence the more custom approach here.
function updateSocketCompScores(event: object) {
    console.warn('updateSocketCompScores', event);

    const eventData = event['wp_zesv6j_' + dig_comp_score.TABLE_NAME];

    if (eventData[0] !== "POST") {
        console.error("Received an invalid websocket event for comp scores: ", event);
        return;
    }

    if (eventData[1] === undefined) {
        console.warn("Received an empty websocket event for comp scores: ", event);
        return;
    }

    let idsForGetCompScores: number[] = [],
        isComplete = eventData[2] !== undefined;

    eventData[1].forEach((compScore: any) => {
        let competition = Bootstrap.bootstrap.state.competitions?.find(comp => comp.comp_id === compScore[dig_comp_score.COMP_ID]);

        // state is updating properly but nothing re-renders. Swapping to a gross working replacement for now because there isn't time.
        if (competition === undefined) {
            //!idsForGetCompScores.includes(compScore[dig_comp_score.COMP_ID]) && idsForGetCompScores.push(compScore[dig_comp_score.COMP_ID]);
            return;
        }

        if (!idsForGetCompScores.includes(compScore[dig_comp_score.COMP_ID])) {

            idsForGetCompScores.push(compScore[dig_comp_score.COMP_ID]);

            isComplete && updateRestfulObjectArray<iCompetitions>([{
                comp_id: compScore[dig_comp_score.COMP_ID],
                status: eventData[2] === true ? eCOMPETITION_STATUS.PAID : eCOMPETITION_STATUS.COMPLETED
            }], 'comp_id', 'competitions');

        }

        /*competition.scores = competition.scores?.filter((score) => score.team_id !== compScore[dig_comp_score.TEAM_ID] ||
            (score.team_id === compScore[dig_comp_score.TEAM_ID] && score.scorebox !== compScore[dig_comp_score.SCOREBOX])) ?? [];

        let newScoreData: iDig_Comp_Score = {
            comp_id: compScore[dig_comp_score.COMP_ID],
            team_id: compScore[dig_comp_score.TEAM_ID],
            user_id: compScore[dig_comp_score.USER_ID],
            scorebox: compScore[dig_comp_score.SCOREBOX],
            score: compScore[dig_comp_score.SCORE]
        };

        //,
        //             created: moment().format("YYYY-MM-DD HH:mm:ss").toString()

        competition.scores.push(newScoreData);

        updateRestfulObjectArray<iCompetitions>([competition], "comp_id", "competitions");*/
    });

    // for comp data not in state, just run getCompetitions
    if (idsForGetCompScores.length > 0) {
        //getCompetitions({competitionIds: idsForGetCompScores, fetchDependencies: true});
        getCompScores({compIds: idsForGetCompScores, cacheResults: false})
    }
}

function updateSocketCompRegistration(event: object) {
    console.warn('updateSocketRegistration', event);

    const eventData = event['wp_zesv6j_' + dig_comp_registration.TABLE_NAME];

    if (eventData[1] === undefined || eventData[1].length === 0) {
        console.warn("Received an empty websocket event for comp registration: ", event);
        return;
    }

    // TODO: dont trigger this if we sent this request (not sure how to tell)
    switch (eventData[0]) {
        case "POST":
            // We currently do not allow for multiple registrations at once (may change with parent/child/IAM registrations) so we can just manually change the ID to an int without a loop
            eventData[1][dig_comp_registration.COMP_REGISTRATION_ID] = parseInt(eventData[1][dig_comp_registration.COMP_REGISTRATION_ID]);
            postRegistrationCallback(prepareResponseKeysForCallback(eventData[1]));
            getTeams({teamIds: [eventData[1][dig_comp_registration.TEAM_ID]], cacheResults: false});
            break;
        case "DELETE":
            deleteRegistrationCallback(prepareResponseKeysForCallback(eventData[1]));
            break;
        default:
    }

}

function updateSocketTeamRoster(event: object) {
    console.warn('updateSocketTeamRoster', event);

    const eventData = event['wp_zesv6j_' + dig_team_roster.TABLE_NAME];

    if (eventData[1] === undefined || eventData[1].length === 0) {
        console.warn("Received an empty websocket event for team roster: ", event);
        return;
    }

    switch (eventData[0]) {
        case "POST":
            eventData[1][dig_team_roster.ID] = parseInt(eventData[1][dig_team_roster.ID]);
            postTeamRosterCallback(prepareResponseKeysForCallback(eventData[1]));
            // refresh invitations
            getInvitations({cacheResults: false});
            break;
        case "PUT":
            eventData[1][dig_team_roster.ID] = parseInt(eventData[1][dig_team_roster.ID]);
            putTeamRosterCallback(prepareResponseKeysForCallback(eventData[1]));
            break;
        case "DELETE":
            deleteTeamRosterCallback(prepareResponseKeysForCallback(eventData[1]));
            break;
        default:
    }
}

function updateSocketInvitation(event: object) {
    console.warn('updateSocketInvitation', event);
    const eventData = event['wp_zesv6j_' + dig_comp_invitation.TABLE_NAME];

    if (eventData[1] === undefined || eventData[1].length === 0) {
        console.warn("Received an empty websocket event for invitation: ", event);
        return;
    }

    switch (eventData[0]) {
        case "POST":
            eventData[1][dig_comp_invitation.INVITATION_ID] = parseInt(eventData[1][dig_comp_invitation.INVITATION_ID]);
            postInvitationCallback(prepareResponseKeysForCallback(eventData[1]));
            break;
        case "DELETE":
            eventData[1][dig_comp_invitation.INVITATION_ID] = parseInt(eventData[1][dig_comp_invitation.INVITATION_ID]);
            deleteInvitationCallback(prepareResponseKeysForCallback(eventData[1]));
            break;
        default:
    }
}

function updateSocketCompetition(event: object) {
    const eventData = event['wp_zesv6j_' + dig_competitions.TABLE_NAME];

    if (eventData[1] === undefined || eventData[1].length === 0) {
        console.warn("Received an empty websocket event for competition: ", event);
        return;
    }

    switch (eventData[0]) {
        case "PUT":
            putCompetitionCallback(prepareResponseKeysForCallback(eventData[1]));
            break;
        default:
    }
}

/**
 * Our response keys are formatted as table.column, but we just want them keyed by column for callback (parity with request)
 * example: {wp_zesv6j_dig_comp_registration.comp_registration_id: "comp_registration_id"} => {comp_registration_id: "comp_registration_id"}
 * @param keys
 */
function prepareResponseKeysForCallback(keys: object) {
    let preparedKeys: any = {};

    // all of the table constants in C6 have these translations saved directly, but there isn't a way that I can find to reference
    // them with the table name as a string. Splitting manually ends up being less complex.
    Object.keys(keys).forEach((key) => {
        preparedKeys[key.split(".")[1]] = keys[key];
    });

    return preparedKeys;
}

import classNames from "classnames";
import moment from "moment";
import {ChangeEvent, useState} from "react";
import {toast} from "react-toastify";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import putUser from "src/api/rest/putUser";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Input from "src/components/Input/Input";
import Loading from "src/components/Loading/Loading";
import DropVariables, {getStyles} from "src/variables/DropVariables";

import {ReactComponent as DigSVG} from "src/assets/img/svg/DIG-logo-lockup-beta-horizontal-white.svg"


enum eVerifyParentTrollBridge {
    SENDING,
    SENT,
    START
}

export default function VerifyParentTrollBridge() {

    const currentUser = getCurrentLoggedInUser()

    const [userEmail, setUserEmail] = useState<string | undefined>('')

    const [verifyState, setVerifyState] = useState<eVerifyParentTrollBridge>(eVerifyParentTrollBridge.START)

    const today = moment();

    const birthdateMoment = moment(currentUser?.user_birthday, "YYYY-MM-DD");

    const age = today.diff(birthdateMoment, "years");

    const dig = getStyles()

    let boxContent: JSX.Element = <></>

    if (verifyState === eVerifyParentTrollBridge.SENT) {
        boxContent = <>The verification email was successfully sent! Please be sure they check there spam folder.</>
    } else if (verifyState === eVerifyParentTrollBridge.SENDING) {
        boxContent = <Loading message={'We are processing your request now!'}/>
    } else {
        boxContent = <>
            <h1 className={dig.textCenter}>Parent approval is needed!</h1>
            <br/>
            <p>
                Users under the age of 16 must have a parent or guardian approve their account. You have registered with
                birthdate ({currentUser?.user_birthday}) which makes you ({age}) years old. Please enter your parent
                or guardians email address below and we will send them a verification email.
            </p>
            <div className={classNames(dig.col6, dig.offset3)}>
                <Input type={"email"}
                       label={'Parental email address:'}
                       onChange={(e: ChangeEvent<HTMLInputElement>) => {
                           setUserEmail(e.target.value)
                       }}/>
                After you have verified your parents email above is correct, you may continue to{' '}
                <a href={'#'}
                   style={{color: "var(--dig_primary_color)"}}
                   onClick={async (event) => {

                       if (undefined === currentUser) {

                           toast.error('You must be logged in to verify your email address. Please contact support if problems persist.')

                           return;

                       }

                       const target = event.target as HTMLElement;

                       target.innerHTML = 'Sending, please be patient!';

                       target.onclick = null;

                       setVerifyState(eVerifyParentTrollBridge.SENDING)

                       const response = await putUser({
                           ID: currentUser?.ID,
                           user_email: userEmail
                       })

                       if (null === response || undefined === response) {

                           toast.error('An error occurred while sending the verification email. Please contact support if problems persist.', DropVariables.toastOptions)

                           return;

                       }

                       if ('object' === typeof response && response.data.updated) {

                           setVerifyState(eVerifyParentTrollBridge.SENT)

                       }

                   }}>
                    send the verification email by clicking here!
                </a>
            </div>
        </>
    }


    return <div className={classNames(dig.container, dig.justifyContentCenter, dig.mt5)}
                style={{color: "white", fontSize: "1.5rem", textAlign: "center"}}>

        <div className={classNames(dig.col6, dig.offset3)}>
            <DigSVG style={{width: "100%", display: "flex"}}/>
        </div>


        <ExpandableBox>

            {boxContent}

        </ExpandableBox>

    </div>
}
import {eCOMPETITION_STATUS} from "src/variables/sharedInterfaces";

export default (statusID?: eCOMPETITION_STATUS) => {
    switch (statusID) {
        case eCOMPETITION_STATUS.WAITING:
            return "Waiting";
        case eCOMPETITION_STATUS.STARTED:
            return "Started";
        case eCOMPETITION_STATUS.COMPLETED:
            return "Completed";
        case eCOMPETITION_STATUS.PAID:
            return "Paid";
        case eCOMPETITION_STATUS.CANCELED:
            return "Canceled";
        default:
            return "Loading...";
    }
}
export default function BracketJs() {

    return `
    
// Display the match icon when hovering it
function match_container() {
    jQuery('.teamContainer').each(function () {
        let team_concat = [],
            roundType = jQuery(this).data('bracket_type'),
            roundNumber = jQuery(this).data('round_number'),
            disqualified_team = false;

        jQuery(this)
            .find('.team')
            .each(function (index) {
                let team_id = jQuery(this).data('user_team_id'),
                    is_disqualified = jQuery(this).data('disqualified');
                if (team_id) {
                    team_concat.push(team_id);
                }
                if(is_disqualified){
                    disqualified_team = true;
                }
            });
        if (team_concat.length >= 2) {
            jQuery(this).addClass('teamFilled');
            jQuery(this).attr('title', 'Click into Match detail');

            jQuery(this).on('click', function (e) {
                e.preventDefault();
                let match_info = {
                        roundType,
                        roundNumber,
                        'teams': team_concat.join('_'),
                    },
                    matchKey = match_info['teams']+'_'+match_info['roundType']+match_info['roundNumber'],
                    self = jQuery(this);

                // disable for multiple clicks
                self.css('pointer-events','none');
                // Toaster notification
                

                jQuery.post(
                    '/bootstrapCustom/bracketUrl/',
                    {
                        action: 'bracket_match_url',
                        match_info,
                        comp_id: jQuery('#bracket').data('comp_id'),
                    },
                    function (res) {
                        if (res.success) {
                            window.location.assign(res.data.url);
                        } else {
                            console.log(res)
                        }
                        self.css('pointer-events','');
                    }
                );
            });
        }
    });
}

(function ($, root, undefined) {
    $(function() {


        if($('#bracket').length > 0) {

        

            match_container();
        }

    });
})(jQuery, this);


    `;
}

import classNames from "classnames";
import React from "react";
import {Link} from 'react-router-dom';
import CoinPackBG from 'src/assets/img/gg-imgs/coin_packs_bg.png';
import SubscriptionBG from 'src/assets/img/gg-imgs/subscription_bg.png';
import Skeleton from "react-loading-skeleton";
import {getStyles} from "src/variables/DropVariables";
import {iUser, iSubscription} from "src/variables/sharedInterfaces";
// core components

export const COIN_PATH = 'coin'

export default class CoinPack extends React.Component<{
    subscriptions?: Array<iSubscription>,
    currentEditUser: iUser
}, {}> {

    render() {
        const {subscriptions} = this.props;

        const dig = getStyles()

        return (
            <div className={classNames(dig.containerFluid, dig.p0, dig.my5, dig.gg__coin_packs)}>
                {
                    subscriptions === null ?
                        <>
                            <div className={dig.row}>
                                <div className={dig.col3}>
                                    <Skeleton count={1} height={250} inline={true}/>
                                </div>
                                <div className={dig.col3}>
                                    <Skeleton count={1} height={250} inline={true}/>
                                </div>
                                <div className={dig.col3}>
                                    <Skeleton count={1} height={250} inline={true}/>
                                </div>
                                <div className={dig.col3}>
                                    <Skeleton count={1} height={250} inline={true}/>
                                </div>
                            </div>
                        </>
                        :
                        <div className={dig.row}>
                            {
                                subscriptions && subscriptions.map((subscription, key) => {

                                    return subscription.charge_interval === 0 && <div key={key} className={classNames(dig.col12, dig.colLg3, dig.colMd4, dig.colSm6, dig.my3, dig.positionRelative)}>
                                        <div
                                            style={{
                                                borderRadius: '10px'
                                            }}
                                            className={classNames(dig.card, dig.border0, dig.mb3, dig.bgDark)}>
                                            <div
                                                style={{
                                                    backgroundImage: `url(${CoinPackBG})`,
                                                    backgroundPosition: 'center',
                                                    backgroundSize: '100%',
                                                    backgroundRepeat: 'no-repeat',
                                                    opacity: '0.2',
                                                    top: '0',
                                                    left: '0',
                                                    width: '100%',
                                                    height: '100%'
                                                }}
                                                className={dig.positionAbsolute}/>
                                            <div

                                                className={classNames(dig.cardBody, dig.ggCoinPacksBody, dig.dFlex, dig.alignItemsCenter, dig.justifyContentCenter, dig.flexColumn, dig.positionRelative)}>

                                                <h1 className={classNames(dig.ggTextGold, dig.mt3)}>{subscription.receive_amount && parseInt(subscription.receive_amount)} Coins</h1>
                                            </div>
                                            <div
                                                className={classNames(dig.cardFooter, dig.dFlex, dig.justifyContentCenter, dig.flexColumn, dig.alignItemsCenter, dig.border0)}>
                                                <h1 className={classNames(dig.textWhite, dig.fontSize3Em, dig.textCenter, dig.my3)}>${subscription.charge_amount && parseFloat(subscription.charge_amount).toFixed(2)}</h1>
                                                <span className={classNames(dig.textWhite, dig.mb5)}>0% Discount</span>
                                                <a href={`/user/?profiletab=dig_deposit_tab&model_id=${subscription.model_id}&user_id=${this.props.currentEditUser?.ID}`}

                                                   className={classNames(dig.btn, dig.textWhite, dig.px3, dig.py2, dig.mb3, dig.fontSize1P5Em, dig.ggAddCoinBtn, dig.border2, dig.borderRounded5)}>
                                                    Buy
                                                </a>

                                            </div>

                                        </div>
                                    </div>

                                })
                            }

                            <div className={classNames(dig.colSm12, dig.my3)}>
                                <div className={classNames(dig.border0, dig.mb3)}>
                                    <div
                                        style={{
                                            backgroundImage: `url(${SubscriptionBG})`
                                        }}

                                        className={classNames(dig.cardBody, dig.bgDark, dig.ggCoinPacksBodyLast, dig.dFlex, dig.alignItemsStart, dig.justifyContentCenter, dig.flexColumn, dig.positionRelative)}>
                                        <div className={dig.ggCoinPacksAds}>
                                            <h1 className={classNames(dig.textWhite, dig.mt3, dig.textStart, dig.fontSize2Em)}>Tired of buying coins over and over again?</h1>
                                            <Link
                                                to={`/parent-portal/profile/${this.props.currentEditUser?.ID}/subscription/`}
                                            >
                                                <h2 className={classNames(dig.textWhite, dig.mt5, dig.textStart, dig.border2, dig.p3, dig.borderRounded5, dig.borderColorGgGold)}>

                                                    learn about monthly autofill coin plans.
                                                    <i className={classNames(dig.fas, dig.faChevronRight, dig.ml3)}></i>

                                                </h2>
                                            </Link>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }
}



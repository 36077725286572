import restApi, {iPostC6RestResponse, POST, removeInvalidKeys} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import Bootstrap from "src/Bootstrap";
//import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, dig_groups, iDig_Groups} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iGroups} from "src/variables/sharedInterfaces";

export default restApi<{
    organization_id: number,
    group_name: string
}, iDig_Groups, {}, iPostC6RestResponse<iDig_Groups>>({
    axios: DropVariables.axios,
    tableName: C6.dig_groups.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'We\'ve successfully created your new group.'
        request.error ??= 'There was an issue creating this group. Please try again.'
        request.created_by = Bootstrap.bootstrap.state.id;
        return request;
    },
    responseCallback: (_response, request, id) => {

        if ('number' !== typeof id) {
            throw new Error('No id returned from group creation.')
        }

        request.entity_id = id;

        updateRestfulObjectArray<iGroups>([
            removeInvalidKeys(request, [dig_groups])
        ], "entity_id", "groups")

    }
});


import restApi, {GET, iGetC6RestResponse} from "src/api/API";
//import getCompetitions from "src/api/rest/getCompetitions";
//import getUsers from "src/api/rest/getUsers";
import {C6, iDig_Comp_Invitation} from "src/variables/C6";
import Bootstrap from "src/Bootstrap";
import DropVariables from "src/variables/DropVariables";
import {iInvitation} from "src/variables/sharedInterfaces";


export default restApi<{}, iDig_Comp_Invitation, {}, iGetC6RestResponse<iDig_Comp_Invitation>>({
    axios: DropVariables.axios,
    tableName: C6.dig_comp_invitation.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (_request) => ({ // because we reference the bootstrap, we need to return an object, not set it statically
        [C6.SELECT]: [
            C6.dig_comp_invitation.INVITATION_ID,
            C6.dig_comp_invitation.USER_ID,
            C6.dig_comp_invitation.COMP_ID,
            C6.dig_comp_invitation.TEAM_ID,
            C6.dig_comp_invitation.SENDER_ID,
            C6.dig_comp_invitation.INVITATION_ID,
            C6.dig_comp_invitation.INVITATION_TYPE,
            C6.dig_comp_invitation.CREATED,
        ],
        [C6.WHERE]: {
            0: {
                [C6.dig_comp_invitation.USER_ID]: Bootstrap.bootstrap.state.id,
                [C6.dig_comp_invitation.SENDER_ID]: Bootstrap.bootstrap.state.id,
            }
        },
        [C6.PAGINATION]: {
            [C6.PAGE]: 1,
            [C6.LIMIT]: 1000
        }
    }),
    responseCallback: response => {

        let restData: iInvitation[] = response?.data?.rest || [];

        let uniqueCompetitionIds: number[] = [];

        let uniqueUserIds: number[] = [];

        Bootstrap.bootstrap.setState({
            invitations: restData
        }, () => {
            restData.map(invite => {

                if (invite.comp_id && false === uniqueCompetitionIds.includes(invite.comp_id)) {
                    uniqueCompetitionIds.push(invite.comp_id)
                }

                if (invite.sender_id && false === uniqueUserIds.includes(invite.sender_id)) {
                    uniqueUserIds.push(invite.sender_id)
                }

                if (invite.user_id && false === uniqueUserIds.includes(invite.user_id)) {
                    uniqueUserIds.push(invite.user_id)
                }

            })

            console.log('Getting competition dependencies from getInvitations; uniqueCompetitionIds:', uniqueCompetitionIds)

            /*if (uniqueCompetitionIds.length > 0) {
                getCompetitions({
                    competitionIds: uniqueCompetitionIds
                })
            }

            if (uniqueUserIds.length > 0) {
                getUsers({
                    userIds: uniqueUserIds
                })
            }*/
        });

    }
})

import classNames from "classnames"
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {iTeam} from "src/variables/sharedInterfaces";
import TeamNameValidation from "src/api/hoc/TeamNameValidation";
import {toast} from "react-toastify";
import Bootstrap from "src/Bootstrap";
import {useState} from "react";

import putTeams from "src/api/rest/putTeams";

interface iTournamentProperties {
    handleClose: () => void,
    currentTeam: iTeam
}

const dig = getStyles()

export default function EditTeamName(props: iTournamentProperties) {

    const { currentTeam } = props;
    const {teams} = Bootstrap.bootstrap.state;

    const [newTeamName, setNewTeamName] = useState(currentTeam.team_name);

    const update = () => {
        // Validation
        const teamNameExists = teams?.find(team => team.team_name === newTeamName),
            isValidTeamName = TeamNameValidation(newTeamName);

        if(undefined !== teamNameExists){
            toast.error('Team name has existed. Please choose a different name!', DropVariables.toastOptions);
            return;
        }

        if(isValidTeamName.length > 0){
            toast.error(isValidTeamName[0], DropVariables.toastOptions);
            return ;
        }

        putTeams({
            user_team_id: currentTeam.user_team_id,
            team_name: newTeamName
        });

        (() => {
            setTimeout(function() {
                props.handleClose();
            }, 1000);
        })();

    }

    return <div className={classNames(dig.modalContent, dig.rounded3, dig.border0, dig.p3, dig.digBgNeutral6)}>
        <div className={classNames(dig.modalHeader, dig.rounded0, dig.digBgNeutral6, dig.border0, dig.py3)}>
            <h2 className={classNames("modal-title font-weight-normal", dig.textWhite)} id="staticBackdropLabel">

                Edit Team
            </h2>
            <div className={"close"} data-dismiss="modal" aria-label="Close">
                <i className={classNames("fas fa-times", dig.m0, dig.textWhite)} onClick={() => props.handleClose()}></i>
            </div>
        </div>


        <div className={classNames(dig.modalBody, dig.digBgNeutral6)}>

            <form className={classNames(dig.bgTransparent, dig.rounded0, dig.border0, "dig-edit team-name-form needs-validation")}
                  role="form">
                <div className={classNames(dig.row, dig.m0, dig.dFlex, dig.alignItemsStart, dig.flexColumn, dig.digBgNeutral9, dig.p3, "rounded-sm")}>
                    <div className={classNames(dig.colSm, dig.p0)}>
                        <h3 className={classNames(dig.textStart, dig.mb3, dig.digOrange)}>Team Name</h3>
                        <div className={classNames(dig.colSm, dig.dFlex, dig.justifyContentCenter, dig.mb3, dig.p0, dig.flexColumn)}>
                            <input className={classNames(dig.formControl, dig.border, dig.rounded0, dig.digBgNeutral6, dig.borderColorNeutral3, dig.textWhite)}
                                   id="dig_team_name"
                                   name="dig_team_name"
                                   type="text"
                                   value={newTeamName}
                                   onChange={(e) => setNewTeamName(e.target.value)}
                                   maxLength={50}
                                   placeholder="Enter Team Name"
                                   required/>
                        </div>
                        <small className={dig.textMuted}>50 characters max., A-Z, 0-9, spaces, - and _ allowed.</small>
                    </div>
                </div>
            </form>
        </div>

        <div className={classNames(dig.modalFooter, dig.rounded0, dig.dFlex, dig.justifyContentCenter, dig.border0)}>
            <button onClick={update}
                className={classNames(dig.btn, dig.btnSuccess, dig.btnLg, "pull-end", "dig-update-team", dig.rounded0, dig.fontSize1Em, dig.w100, dig.fwBold, dig.textUppercase)}
                type="button">
                Update Team
            </button>
        </div>
    </div>


}

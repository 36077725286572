import './lib/lodash.js';

import 'src/assets/css/bracket.roundrobin.css';
import {useEffect} from "react";
import getRoundRobinMatchups from "src/api/rest/getRoundRobinMatchups";
import {iCompetitions} from "src/variables/sharedInterfaces";
import createRoundRobinTableView from "src/api/hoc/RoundRobinTableView";
import Loading from "src/components/Loading/Loading";
import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";


export default function RoundRobin(props: iCompetitions) {

    console.log('RoundRobin')

    const competition = props;


    useEffect(() => {
        getRoundRobinMatchups({comp_id: competition.comp_id});
    });

    const formattedMatchups = createRoundRobinTableView(competition.comp_id);

    useEffect(() => {
        require('./lib/jquery.roundrobin.min.js');

        formattedMatchups && formattedMatchups.map((pool, index) => {

            const nonReact = `

        jQuery(function($) {

             function loadRoundRobin(){
             
                $('#rr`+index+`').text('');
             
                $('#rr`+index+`').group({
                    init: `+JSON.stringify(pool)+`
                })
            }

            loadRoundRobin()

        })`;
            eval(nonReact);
        })

    });



    if (null === formattedMatchups) {
        return <Loading message={'Loading Matchups...'}/>
    }

    const dig = getStyles()


    return <>

        {
            formattedMatchups && formattedMatchups.map((_pool, index) =>  <><h2>Rankings</h2><div className={classNames(dig.mb5)} key={index} id={"rr"+index}></div></>)
        }

    </>
}

import classNames from "classnames";
import Cookies from "js-cookie";
import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import isPRO from "src/variables/isPRO";
import postWalletTransaction from "src/api/rest/postWalletTransaction";
import Bootstrap from "src/Bootstrap";
import Button from "src/components/Button/Button";
import Input from "src/components/Input/Input";
import TermsAndConditions from "src/components/TermsAndConditions/TermsAndConditions";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import isProduction from "src/variables/isProduction";
import PageNotFound from "src/views/Errors/PageNotFound";
import Amount from "src/components/Wallet/Amount/Amount";
import Billing from "src/components/Wallet/Billing/Billing";
import NMI from "src/components/Wallet/NMI/NMI";
import PayDevCrypto from "src/components/Wallet/PayDevCrypto/PayDevCrypto";
import PayPal from "src/components/Wallet/PayPal/PayPal";
import styles from "src/components/Wallet/style.module.scss";
import {toast} from "react-toastify";

// core components

export const WALLET_STYLES = styles

export const WALLET_PATH = 'wallet'
export const DEPOSIT_PATH = 'deposit'
export const WITHDRAW_PATH = 'withdraw'

export interface iWithdrawInformation {
    isPaypal: boolean,
    amount: number,
    paypal_auth_amount?: number
}


export function postWithdraw(info: iWithdrawInformation) {

    // this data is validated by the button click event before firing this function
    const {
        firstName,
        lastName,
        zip,
        selectedCountry,
        state,
        town,
        street,
        apt
    } = Billing.billing?.state ?? {};

    let request = {
        amount: '-' + info.amount.toString(),
        auth_status: 1,
        cc_addr_line_1: street ?? '',
        cc_addr_line_2: apt,
        cc_auth_code: 'N/A',
        cc_city: town,
        cc_country: selectedCountry?.abbreviation ?? '',
        cc_fname: firstName ?? '',
        cc_lname: lastName ?? '',
        cc_mask: 'N/A',
        cc_response_code: 0,
        cc_state: state && selectedCountry?.abbreviation === 'US' ? state: 'N/A',
        cc_zip: zip,
        kt_sid: Cookies.get('KOUNT_SID'),
        user_id: Bootstrap.bootstrap.state.id,
        success: "Successfully requested your withdraw! Please allow 1-2 business days for processing.",
        error: "Failed to submit your transaction! Please try again.",
    }

    if (info.isPaypal) {
        // info.paypal_auth_amount SHOULD be set here, but just in case...
        if (info.paypal_auth_amount === undefined) {
            info.paypal_auth_amount = info.amount + parseFloat(((info.amount - 5) * .05 + .55).toFixed(2))
        }

        // @ts-ignore just let me add this conditionally
        request.pp_authorization_amount = info.paypal_auth_amount;
    }

    return postWalletTransaction(request);

}


export default function Wallet() {

    const myAccount = getCurrentLoggedInUser()

    let myFunds = myAccount?.funds ?? 0

    const prefix = window.location.pathname.match(new RegExp('^(.*' + WALLET_PATH + ')/?'))?.[1].replace(/\/$/, "") + '/'

    const withdrawTabOpen = window.location.pathname.match(new RegExp(WITHDRAW_PATH, "g"))

    const [paymentMethod, setPaymentMethod] = React.useState<'paypal' | 'nmi' | 'paydev' | 'check'>(withdrawTabOpen ? 'check' : 'nmi' as any as 'paypal' | 'nmi' | 'paydev' | 'check');

    const [transactionInProgress, setTransactionInProgress] = React.useState(false)


    const dig = getStyles(styles)

    const withdrawW9 = <div className={classNames(dig.alert, dig.alertFlag)}
                            style={{backgroundColor: "var(--dig_neutral3)"}}>
        <strong><i className="fas fa-radiation-alt"></i></strong>
        <div>
            Once your cumulative earnings withdrawals reach $600
            during any calendar year, the IRS requires
            you to submit a W9 to Drop-In Gaming. Your current earnings withdrawals are $0.00.
        </div>
    </div>

    let paymentOptions;

    switch (paymentMethod) {
        case "paydev":

            if (isProduction) {

                setPaymentMethod('nmi')

            } else {

                paymentOptions = <PayDevCrypto paydev="Paywidget"
                                               amount={Amount?.amount?.state?.monetaryValue || 0}
                                               currency={"USD"}
                                               ref_id={'123456'}
                                               paydev_id="96dfbc6d-a3a2-4023-82eb-02438e7e6192"
                                               className="paydevpaybtn"/>

            }

            break;
        case "paypal":
            paymentOptions = <><TermsAndConditions/><PayPal/></>
            break;
        case "nmi":
            paymentOptions = <NMI><TermsAndConditions/></NMI>
            break;


    }

    const WithdrawComponent = () => {
        return <>
            <div className="um-field um-field-c dig-divider">
                <div className="um-field-area dig-deposit-funds-payment-type">

                    <Input type={'radio'}
                           placeholder={'PayPal (5%, plus $0.30)'}
                           label={"Withdrawal Type"}
                           checked={paymentMethod === 'paypal'}
                           onChange={() => setPaymentMethod('paypal')}/>

                    {paymentMethod === 'paypal'
                        ? <Input type={"text"}
                                 label={'Please enter your PayPal Email address if different from your main DIG account email shown below.'}
                                 placeholder={'PayPal Email Address'}
                                 value={myAccount?.user_email}/>
                        : <br/>}

                    <Input type={'radio'}
                           placeholder={'Check ($10.00)'}
                           checked={paymentMethod === 'check'}
                           onChange={() => setPaymentMethod('check')}/>

                </div>
            </div>
            <TermsAndConditions/>
            <div
                className="row dig-add-funds-btn-groups d-flex justify-content-between align-items-center">

                <Button className={classNames(dig.textWhite,dig.bgSuccess, dig.border0, dig.mt4, dig.px4)} id="dig-withdraw-funds-button"
                        onClick={async () => {

                            // TODO: flesh out a proper value check
                            const {
                                firstName,
                                lastName,
                                zip,
                                selectedCountry,
                                state,
                                town,
                                street
                            } = Billing.billing?.state ?? {};

                            if (
                                firstName === undefined ||
                                firstName === '' ||
                                lastName === undefined ||
                                lastName === '' ||
                                zip === undefined ||
                                zip === '' ||
                                selectedCountry === undefined ||
                                state === undefined ||
                                (state === '' && selectedCountry?.abbreviation === 'US') ||
                                town === undefined ||
                                town === '' ||
                                street === undefined ||
                                street === ''
                            ) {
                                toast.error('Please fill out all billing information!', DropVariables.toastOptions);
                                return;
                            }

                            // ensure terms and conditions have been accepted:
                            const agreed = TermsAndConditions.termsAndConditions?.state?.checked ?? false;

                            if (false === agreed) {

                                alert("You must agree to our terms and conditions before submitting your withdrawal request!");
                                return;

                            }

                            let cashTotal: number = Amount.amount?.state.monetaryValue ?? -1,
                                withdrawInfo: iWithdrawInformation = {
                                    isPaypal: false,
                                    amount: cashTotal
                                };

                            if (cashTotal < 0) {

                                alert("Please enter an amount you'd like to withdraw!");
                                return;

                            }

                            if (paymentMethod === "check") {

                                cashTotal += 10;

                            } else if (paymentMethod === "paypal") {

                                let num = (cashTotal - 5);
                                cashTotal += parseFloat((num * .05 + .55).toFixed(2));
                                withdrawInfo.isPaypal = true;
                                withdrawInfo.paypal_auth_amount = cashTotal;

                            }

                            if (cashTotal > myFunds) {

                                alert("You cannot withdraw more than your total available funds!");
                                return;

                            }

                            setTransactionInProgress(true);
                            await postWithdraw(withdrawInfo);
                            setTransactionInProgress(false);
                        }}
                        >
                    {transactionInProgress ? 'Posting your request, please hang tight!' : 'Request Withdrawal'}
                </Button>
            </div>
        </>
    };

    const DepositComponent = () => {
        return <>
            <div className={classNames(dig.umField, dig.umFieldC, "dig-divider", dig.mt3)}>
                <div
                    className={classNames(dig.umFieldArea, "dig-deposit-funds-payment-type")}>
                    <div>
                        <label htmlFor="dig-wallet-deposit-type"
                               className={classNames(dig.digHighlight2, dig.dInlineBlock)}>Payment
                            <abbr className={classNames(dig.ms2, "required")}
                                  title="required">*</abbr>
                        </label>
                    </div>

                    <Input type={'radio'}
                           className={dig.mt5}
                           checked={paymentMethod === 'nmi'}
                           onChange={() => setPaymentMethod('nmi')}>
                        <div
                            className={classNames(dig.dInline, dig.umFieldCheckboxOption, dig.digWalletPayment, dig.ms2, dig.digHighlight2)}>
                            Credit or Debit Card
                            <img alt={"accept cards"}
                                 src="/wp-content/themes/dropingaming/assets/img/png/accepted-credit-cards.png"/>
                        </div>
                    </Input>


                    <Input type={'radio'}
                           className={dig.mt5}
                           checked={paymentMethod === 'paypal'}
                           onChange={() => setPaymentMethod('paypal')}>
                        <div
                            className={classNames(dig.dInline, dig.umFieldCheckboxOption, dig.digWalletPayment, dig.ms2, dig.digHighlight2)}>
                            Pay with
                            <img
                                src="/wp-content/themes/dropingaming/assets/img/png/accepted-paypal.png"/>
                        </div>
                    </Input>

                    {false && <Input type={'radio'}
                           className={dig.mt5}
                           checked={paymentMethod === 'paydev'}
                           onChange={() => setPaymentMethod('paydev')}>
                        <div
                            className={classNames(dig.dInline, dig.umFieldCheckboxOption, dig.digWalletPayment, dig.ms2, dig.digHighlight2)}>
                            Crypto Wallet
                        </div>
                    </Input>}

                </div>

            </div>

            {paymentOptions}

        </>
    };

    return <div className={classNames(
        dig.container, dig.digDepositTab,
    )}>
        {withdrawTabOpen && withdrawW9}
        {/*<div className={dig.um}>*/}
        <div className={dig.positionRelative} id="dig-add-funds-form">
            <div className={dig.woocommerceBillingFieldsFieldWrapper}>
                <div className={dig.row}>
                    <div className={classNames(dig.colMd6, dig.ps0)}>

                        <div className={classNames(dig.dFlex, dig.justifyContentBetween, dig.container, dig.px3)}>
                            {isPRO() || <Button to={prefix + DEPOSIT_PATH + '/'}
                                                className={classNames(dig.btn, dig.ggPrimaryBtn, dig.textWhite, dig.textCenter, dig.borderRounded5, dig.col6, dig.mx1, dig.dFlex, dig.justifyContentCenter, dig.px2)}>
                                <i className={classNames(dig.fas, "fas fa-plus-circle")}></i> Deposit</Button>}

                            <Button to={prefix + WITHDRAW_PATH + '/'}
                                    className={classNames(dig.btn, dig.ggPrimaryBtn, dig.textWhite, dig.textCenter, dig.borderRounded5, dig.col6, dig.mx1, dig.dFlex, dig.justifyContentCenter, dig.px2)}>
                                <i className={classNames(dig.fas, "fas fa-minus-circle")}></i> Withdraw
                            </Button>
                        </div>


                        <Billing/>
                    </div>
                    <div className={classNames(dig.colMd6, dig.ps0)}>

                        <Amount paymentMethod={paymentMethod}/>

                        <Routes>
                            <Route path={WITHDRAW_PATH + '/'} element={<WithdrawComponent/>}/>

                            {isPRO() ? <Route index element={<Navigate to={prefix + WITHDRAW_PATH + '/'}/>}/>
                                : <Route path={DEPOSIT_PATH + '/'} element={<DepositComponent/>}/>}

                            <Route index element={<Navigate to={prefix + DEPOSIT_PATH + '/'}/>}/>
                            <Route path='*' element={<PageNotFound/>}/>
                        </Routes>

                    </div>
                </div>
            </div>
            {/*</div>*/}
        </div>
        <br/>
    </div>
}



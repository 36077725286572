import restApi, {GET} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iPostmeta} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iWpPost} from "src/variables/sharedInterfaces";

export interface iGetPostmeta {
    post: iWpPost
}

export default restApi<iGetPostmeta, iPostmeta>({
    axios: DropVariables.axios,
    tableName: C6.postmeta.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request) => {

        return {
            [C6.WHERE]: {
                [C6.postmeta.POST_ID]: request.ID,
            },
            [C6.PAGINATION]: {
                [C6.LIMIT]: 1000
            }
        }

    },
    responseCallback: (response, request) => {

        updateRestfulObjectArray<iWpPost>([{
            ID: request.ID,
            categories: [],
            postType: 7,
            postmeta: (response?.data?.rest || [])
        }], "ID", "sliders")

    }

})

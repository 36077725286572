import restApi, {GET} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import Bootstrap from "src/Bootstrap";
import {C6, dig_comp_registration, dig_vendor, iDig_Comp_Registration} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {getCompetitionMetaSubQuery} from "./getCompetitions";
import moment from "moment";


interface iGetNotifications {
    competitionIds?: number[],
    registrationIds?: number[]
}

export default restApi<iGetNotifications, iDig_Comp_Registration>({
    axios: DropVariables.axios,
    tableName: dig_comp_registration.TABLE_NAME,
    requestMethod: GET,
    queryCallback: (request: iGetNotifications) => {

        if (null === Bootstrap.bootstrap.state.registrations) {
            return undefined
        }

        if (Bootstrap.bootstrap.state.registrations === undefined) {
            Bootstrap.bootstrap.setState({
                registrations: null
            })
        }

        if (request.competitionIds !== undefined && request.registrationIds !== undefined) {
            throw new Error("Only one of competitionIds or registrationIds can be defined");
        }

        let where: any = {};

        if (request.competitionIds !== undefined && request.competitionIds.length > 0) {
            where = {
                [dig_comp_registration.COMPETITIONS_ID]: [C6.IN, request.competitionIds]
            }
        } else if (request.registrationIds !== undefined && request.registrationIds.length > 0) {
            where = {
                [dig_comp_registration.COMP_REGISTRATION_ID]: [C6.IN, request.registrationIds]
            }
        }

        let query = {
            [C6.SELECT]: [
                dig_comp_registration.COMP_REGISTRATION_ID,
                dig_comp_registration.USER_ID,
                dig_comp_registration.COMPETITIONS_ID,
                dig_comp_registration.TEAM_ID,
                dig_comp_registration.POSITION,
                dig_comp_registration.CREATED,
                dig_comp_registration.UPDATED,
                dig_comp_registration.VENDOR_ID,
                dig_comp_registration.TEAM_FULL_TIMESTAMP,
                dig_comp_registration.VENDOR_GAMERTAG,
                dig_comp_registration.STREAMING_URL,
                dig_comp_registration.STREAMING_PLATFORM,
                dig_vendor.VENDOR_NAME,
                C6.dig_competitions.COMP_TYPE_ID,
                C6.dig_competitions.MODE_GAME_ID,
                C6.dig_competitions.BEST_OF,
                C6.dig_competitions.NOW_FLAG,
                [C6.dig_competitions.DATETIME, C6.AS, 'comp_datetime'],
                C6.dig_competitions.PUBLIC,
                C6.dig_competitions.PIN,
                C6.dig_competitions.ORGANIZER_ID,
                C6.dig_competitions.STATUS,
                getCompetitionMetaSubQuery('_dig_comp_sub_mode', 'sub_mode'),
            ],
            [C6.JOIN]: {
                [C6.LEFT]: {
                    [dig_vendor.TABLE_NAME]: {
                        [dig_vendor.VENDOR_ID]: dig_comp_registration.VENDOR_ID
                    },
                    [C6.dig_competitions.TABLE_NAME]: {
                        [C6.dig_comp_registration.COMPETITIONS_ID]: C6.dig_competitions.COMP_ID
                    },
                },
            },

            [C6.WHERE]: where,
            [C6.GROUP_BY]: [
                dig_comp_registration.COMP_REGISTRATION_ID,
                dig_comp_registration.USER_ID,
                dig_comp_registration.COMPETITIONS_ID,
                dig_comp_registration.TEAM_ID,
                dig_comp_registration.POSITION,
                dig_comp_registration.CREATED,
                dig_comp_registration.UPDATED,
                dig_comp_registration.VENDOR_ID,
                dig_comp_registration.VENDOR_GAMERTAG,
                dig_comp_registration.STREAMING_URL,
                dig_comp_registration.STREAMING_PLATFORM,
                dig_vendor.VENDOR_NAME,
            ],
            [C6.PAGINATION]: {
                [C6.PAGE]: 1,  // start at one
                [C6.LIMIT]: 1000
            }
        };

        if (undefined === request.competitionIds && undefined === request.registrationIds) {

            if (0 === Bootstrap.bootstrap.state.id) {
                throw Error("Trying to get competition registrations without a user id or specified competition ids")
            }

            query[C6.WHERE] = {
                [dig_comp_registration.USER_ID]: Bootstrap.bootstrap.state.id
            }

        }

        return query
    },
    responseCallback: (response) => {

        let restData = response?.data?.rest || [];

        restData = restData.map((value) => {

            const timelimit = Bootstrap.bootstrap.state.subModes?.find((subMode) => subMode.sub_mode_id == value.sub_mode)?.time_limit;
            value.end_datetime = moment(value.comp_datetime).add(timelimit, 'minutes').format("YYYY-MM-DD HH:mm:ss").toString()

            return value
        })

        updateRestfulObjectArray<iDig_Comp_Registration>(restData, "comp_registration_id", "registrations");

    }
});

import Bootstrap from "src/Bootstrap";
import {eCOMPETITION_STATUS} from "src/variables/sharedInterfaces";

export default function createRoundRobinTableView(compID: number) {
    const parentCompData = Bootstrap.bootstrap.state.competitions?.find(comp => comp.comp_id === compID);
    const matchupData = parentCompData?.round_robin_matchups;

    if (matchupData === undefined || matchupData === null) {
        return undefined;
    }

    const teamData = Bootstrap.bootstrap.state.teams?.filter(team => parentCompData?.complete_teams?.includes(team.user_team_id));

    let formattedMatchups: Object[] = [],
        curPoolId = 1,
        distinctTeams: {id: number, name: string}[] = [],
        curScores: Object[] = [],
        curGroupData: {teams: Object[], matches: Object[]} = {
            teams: [],
            matches: []
        },
        incompleteDataFlag = false;

    // sorted by pool id asc, round asc
    matchupData.map(matchup => {
        // check if we are in a new pool
        if (matchup.pool_id !== curPoolId) {
            // distinctTeams might be unnecessary tbh
            curGroupData.teams = distinctTeams;

            // push the previous pool data
            formattedMatchups.push(curGroupData);

            // reset curGroupData
            curGroupData = {
                teams: [],
                matches: []
            };

            distinctTeams = [];

            // reset curPoolId (pretty sure it isn't possible for an undefined pool_id here but meh)
            curPoolId = matchup.pool_id ?? 0;
        }

        const matchupComp = Bootstrap.bootstrap.state.competitions?.find(comp => comp.comp_id === matchup.comp_id);
        const matchupTeams = teamData?.filter(team => matchupComp?.complete_teams?.includes(team.user_team_id));

        if (matchupComp === undefined || matchupTeams === undefined || Object.keys(matchupComp).length === 1) {
            incompleteDataFlag = true;
            return null;
        }

        let winTracker: any = {};

        const bothTeamsScores = matchupComp?.status === eCOMPETITION_STATUS.STARTED ? [] : matchupComp.scores?.filter(score => matchupTeams.map(team => team.user_team_id).includes(score.team_id ?? 0));

        // it is possible we only have partial data loaded at this point in time, so we must check that comps with status ENDED or PAID have scores
        if ((matchupComp?.status ?? 0) > eCOMPETITION_STATUS.STARTED && (bothTeamsScores === undefined || bothTeamsScores.length === 0)) {
            incompleteDataFlag = true;
            return null;
        }

        for (let i = 1; i <= (matchupComp?.best_of ?? 0); i++) {
            const roundScores = bothTeamsScores?.filter(score => score.scorebox === i);

            if (roundScores === undefined || roundScores.length !== 2 || roundScores[0].score === undefined || roundScores[1].score === undefined) {
                break;
            }

            if (roundScores[0].score === roundScores[1].score) {
                continue;
            }

            let winningTeamId = 0;

            if (roundScores[0].score > roundScores[1].score) {
                winningTeamId = roundScores[0].team_id ?? 0;
            } else {
                winningTeamId = roundScores[1].team_id ?? 0;
            }

            if (winTracker[winningTeamId] === undefined) {
                winTracker[winningTeamId] = 1;
            } else {
                winTracker[winningTeamId]++;
            }
        }

        matchupTeams.map(team => {
            let teamIndex = -1;

            // cant use indexOf in the way
            for (let i = 0; i < distinctTeams.length; i++) {
                if (distinctTeams[i].id === team.user_team_id) {
                    teamIndex = i;
                    break;
                }
            }

            if (teamIndex === -1) {
                let formattedTeam = {
                    id: team.user_team_id,
                    name: team.team_name ?? ''
                };

                distinctTeams.push(formattedTeam);
                teamIndex = distinctTeams.length - 1;
            }

            let formattedScore = {
                "team": teamIndex,
                "score": winTracker[team.user_team_id] ?? 0,
            };

            curScores.push(formattedScore);
        });

        // place score data in formatted object for curGroupData.matches
        if (curScores.length !== 2) {
            return null;
        }

        let formattedMatchup = {
            "id": matchup.comp_id,
            "round": matchup.round_id,
            "link": '/competition/' + matchup.comp_id,
            "a": curScores[0],
            "b": curScores[1]
        };

        curGroupData.matches.push(formattedMatchup);

        // reset curScores for the next matchup
        curScores = [];
    });

    if (incompleteDataFlag) {
        return undefined;
    }

    curGroupData.teams = distinctTeams;

    // push the last pool data
    formattedMatchups.push(curGroupData);

    return formattedMatchups;
}

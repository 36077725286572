import classNames from "classnames";
import {useState} from "react";
import {Routes, Route} from "react-router-dom"
import {Helmet} from "react-helmet";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import Loading from "src/components/Loading/Loading";
import {getStyles} from "src/variables/DropVariables";

import FamilyList from "src/views/ParentalPortal/FamilyList/FamilyList";
import {Link} from "react-router-dom";
import AddGamer from "src/views/ParentalPortal/AddGamer/AddGamer";
import PageNotFound from "src/views/Errors/PageNotFound";
import {ppr} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import Popup from "../../components/Popup/Popup";
import Button from "../../components/Button/Button";
import Bootstrap from "../../Bootstrap";


export const PARENTAL_PORTAL_PATH = "parental-portal";

const ParentalPortal = (props: {}) => {

    console.log('parent portal rendered', props)

    const [acceptPopupOpened, setAcceptPopupOpened] = useState(false);

    const handleAcceptOpen = () => setAcceptPopupOpened(true);

    const handleAcceptClose = () => setAcceptPopupOpened(false);

    const currentUser = getCurrentLoggedInUser();

    if (undefined === currentUser || null === currentUser) {
        return <Loading message={'Loading user information!'}/>;
    }

    const content = <Routes>
        <Route index element={ppr(FamilyList, {
            users: Bootstrap.bootstrap.state.users
        })}/>
        <Route path='*' element={<PageNotFound/>}/>
    </Routes>;

    const dig = getStyles()

    return (
        <div className={classNames(dig.container, dig.py4)}>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Parent Portal - {currentUser.display_name}&apos;s Family</title>
            </Helmet>
            <div>
                <div
                    className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter)}>

                    <div className={classNames(dig.dFlex, dig.justifyContentBetween)}>
                        <h1 className={classNames(dig.fontSize1Em, dig.mb1)}>
                            <Link className={classNames('gg-flex-width', dig.ggTextPrimary)}
                                  to={`/${PARENTAL_PORTAL_PATH}/`}>
                                {currentUser.familyName ? currentUser.familyName : `${currentUser.display_name}'s Family`}
                            </Link>
                        </h1>
                        <Button
                            onClick={handleAcceptOpen}
                            className={classNames(dig.btn, dig.btnSecondary, dig.textWhite, dig.px3, dig.py2, dig.fwBold, dig.ms2)}>
                            Add Gamer
                        </Button>
                    </div>
                    <Popup handleClose={handleAcceptClose} open={acceptPopupOpened}>
                        <div className={classNames(
                            dig.rounded2, dig.border0, dig.digBgNeutral6
                        )} style={{
                            width: "100%",
                            scrollBehavior: "auto"
                        }}>
                            <div className={classNames(
                                dig.modalHeader, dig.rounded0, dig.digBgNeutral6, dig.py3, dig.dFlex, dig.alignItemsCenter
                            )}>
                                <h3>Add Gamer</h3>
                                <div
                                    onClick={() => handleAcceptClose()}
                                    style={{cursor: "pointer", float: "right"}}
                                    className={classNames("close")}
                                    data-dismiss="modal"
                                    aria-label="Close">
                                    <i className={classNames("fas fa-times m-0 text-white")}/>
                                </div>
                            </div>
                            <div className={classNames(
                                dig.modalBody, dig.digBgNeutral6, dig.tabContent
                            )}>
                                <AddGamer handleClose={handleAcceptClose}/>
                            </div>
                        </div>
                    </Popup>


                </div>
                <hr className={classNames(dig.borderTop1, dig.borderColorGg)}/>
            </div>
            {content}
        </div>
    );
};

export default ParentalPortal;


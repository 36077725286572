import restApi, {DELETE, iDeleteC6RestResponse} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Feature_Group_References, iDig_Features} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iGroups} from "src/variables/sharedInterfaces";

export default restApi<{
    feature_entity_id: number;
    group_entity_id: number;
}, iDig_Feature_Group_References, {}, iDeleteC6RestResponse<iDig_Feature_Group_References>>({
    axios: DropVariables.axios,
    tableName: C6.dig_feature_group_references.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success = 'Successfully deleted the feature from the group.'
        request.error = 'Failed to remove the feature from the group. Please try again later.'
        return {
            [C6.WHERE]: {
                [C6.dig_feature_group_references.FEATURE_ENTITY_ID]: request.feature_entity_id,
                [C6.dig_feature_group_references.GROUP_ENTITY_ID]: request.group_entity_id,
            }
        }
    },
    responseCallback: (_response, request, id) => {

        if ('object' !== typeof id) {
            throw new Error('Bad id passed to delete feature group ref.')
        }

        updateRestfulObjectArray<iGroups>((previousState) => {

            const fullGroup: iGroups | undefined = previousState.groups?.find((group: iGroups) => group.entity_id === request.group_entity_id);

            const fullFeature: iDig_Features | undefined = previousState.features?.find((feature: iDig_Features) => feature.feature_entity_id === request.feature_entity_id);

            const index: string | undefined = fullGroup?.feature_code?.find((feature) => feature === fullFeature?.feature_code);

            if (index && index.length > 0) {

                const indexNumber = Number(index);

                if (!isNaN(indexNumber)) { // check if the conversion is successful

                    fullGroup?.feature_code?.splice(indexNumber, 1); // 2nd parameter means remove one item only

                }

            }

            return fullGroup ? [fullGroup] : [];

        }, "entity_id", "groups")

    }
});



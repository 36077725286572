import {faHeadset, faMinusCircle, faPlusCircle, faUsers} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import classNames from "classnames";
import {Link, useNavigate} from "react-router-dom";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import isCOM from "src/variables/isCOM";
import isPRO from "src/variables/isPRO";

import {ReactComponent as BracketSVG} from "src/assets/img/svg-icons/bracket.svg";
import {ReactComponent as InvitationsSVG} from "src/assets/img/svg-icons/invitations.svg";
import {ReactComponent as MatchSVG} from "src/assets/img/svg-icons/match.svg";
import {ReactComponent as ProfileSVG} from "src/assets/img/svg-icons/profile.svg";
import {ReactComponent as TournamentSVG} from "src/assets/img/svg-icons/tournament.svg";
import Bootstrap from "src/Bootstrap";
import Loading from "src/components/Loading/Loading";
import ProfilePicture from "src/components/ProfilePicture/ProfilePicture";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {PARENTAL_PORTAL_PATH} from "src/views/ParentalPortal/ParentalPortal";
import {INFORMATION_PATH} from "src/views/Profile/Information/Information";
import {INVITATIONS_PATH} from "src/views/Profile/Invitations/Invitations";
import {BRACKETS_PATH, MATCHES_PATH, PROFILE_PATH, TOURNAMENTS_PATH} from "src/views/Profile/Profile";
import {DEPOSIT_PATH, WALLET_PATH, WITHDRAW_PATH} from "src/components/Wallet/Wallet";
import {SUPPORT_PATH} from "src/views/Support/Support";
import classes from "./style.module.scss"
import {HISTORY_PATH} from "src/views/Profile/History/History";
import isGG from "src/variables/isGG";

interface iNotification {
    notificationCounts: {
        invitationCount: number,
        tournamentCount: number,
        bracketCount: number,
        matchCount: number
    }
}

export default function UserBar(props: iNotification) {

    const currentUser = getCurrentLoggedInUser();
    const navigate = useNavigate();

    const {id, bootstrap} = Bootstrap.bootstrap.state;

    const isLoggedIn = 0 !== id

    if (false === isLoggedIn) {
        return null;
    }

    const dig = getStyles(classes)

    const DigPipe = <div className={classNames(dig.digPipe, dig.borderBlack, dig.mx3)}/>


    const color = bootstrap.state.darkMode ? 'var(--dig_neutral5)' : DropVariables.getPrimaryColor();

    const {invitationCount, tournamentCount, bracketCount, matchCount} = props.notificationCounts;


    return <div
        className={classNames(dig.container, dig.userBar, dig.w100, dig.mAuto, dig.dFlex, dig.alignItemsCenter, dig.h100, dig.g0)}>

        <div className={classNames(
            dig.borderTop0,
            dig.row,
            dig.dNone,
            dig.dMdFlex,
            {[dig.w100]: isLoggedIn}
        )}>
            {true === isLoggedIn && (undefined === currentUser
                ? <Loading message={"Loading Account Information"} count={1}/>
                : <>
                    <div className={classNames(dig.colSm6, dig.dFlex, dig.justifyContentStart, dig.alignItemsCenter, dig.h100)}>

                        <div className={classNames(dig.dFlex)}>
                            <div className={classNames(dig.me3)}>
                                <ProfilePicture user={currentUser}/>
                            </div>

                            <div className={classNames(dig.dNone, dig.dMdFlex, dig.alignItemsCenter)}>
                                <h3 className={classNames(
                                    dig.dFlex, dig.alignItemsCenter
                                )}
                                    style={{
                                        color: color
                                    }}>
                                    <Link to={'/' + PROFILE_PATH + '/' + currentUser.ID} style={{color: color}}>
                                        {currentUser.display_name}
                                    </Link>
                                </h3>
                            </div>
                        </div>

                        {DigPipe}

                        <div className={classNames(dig.dNone, dig.dMdInlineBlock, dig.me5, dig.ms3)}>
                            <Link to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + INFORMATION_PATH + '/'}>
                                <ProfileSVG
                                    fill={color}
                                    style={{width: "25px"}}/>
                            </Link>
                        </div>

                        <div className={classNames(dig.dNone, dig.dMdFlex, dig.me5)}>
                            <Link
                                className={classNames(dig.positionRelative)}
                                to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + INVITATIONS_PATH + '/'}>
                                {0 !== invitationCount && <span
                                    className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                        dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                        dig.positionAbsolute, dig.top0, dig.start100, dig.translateMiddle)}
                                    style={{display: "block"}}>{invitationCount}</span>}
                                <InvitationsSVG
                                    fill={color}
                                    style={{width: "25px"}}/>
                            </Link>
                        </div>

                        <div onClick={() => (navigate('/' + SUPPORT_PATH + '/'))}
                             className={classNames(dig.dNone, dig.dMdFlex, dig.me5)}>
                            <div className={dig.m2}>
                                <div className={classNames(dig.mt0)}>
                                    <FontAwesomeIcon
                                        icon={faHeadset}
                                        size={'xl'}
                                        className={classNames(dig.fontAwesomeIcon)}
                                    />
                                </div>
                            </div>
                        </div>

                        {false === isCOM() && (false === currentUser.isChild || undefined === currentUser.isChild) && <div className={classNames(dig.dNone, dig.dMdFlex, dig.ms5)}>
                            <Link to={'/' + PARENTAL_PORTAL_PATH + '/'}>
                                <FontAwesomeIcon
                                    style={{color: color}}
                                    icon={faUsers}
                                    size={'xl'}/>
                            </Link>
                        </div>}

                    </div>
                    <div className={classNames(dig.colSm6, dig.dFlex, dig.justifyContentEnd, dig.alignItemsCenter, dig.h100)}>

                        <div className={classNames(dig.dNone, dig.dMdFlex, dig.me5)}>
                            <Link
                                className={classNames(dig.positionRelative)}
                                to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + TOURNAMENTS_PATH + '/'}>
                                {0 !== tournamentCount && <span
                                    className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                        dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                        dig.positionAbsolute, dig.top0, dig.start100, dig.translateMiddle)}
                                    style={{display: "block"}}>{tournamentCount}</span>}
                                <TournamentSVG fill={color} style={{width: "25px"}}/>
                            </Link>
                        </div>

                        <div className={classNames(dig.dNone, dig.dMdFlex, dig.me5)}>
                            <Link
                                className={classNames(dig.positionRelative)}
                                to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + MATCHES_PATH + '/'}>
                                {0 !== matchCount && <span
                                    className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                        dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                        dig.positionAbsolute, dig.top0, dig.start100, dig.translateMiddle)}
                                    style={{display: "block"}}>{matchCount}</span>}
                                <MatchSVG fill={color} style={{width: "25px"}}/>
                            </Link>
                        </div>

                        <div className={classNames(dig.dNone, dig.dMdFlex, dig.me3)}>
                            <Link
                                className={classNames(dig.positionRelative)}
                                to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + BRACKETS_PATH + '/'}>
                                {0 !== bracketCount && <span
                                    className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                        dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                        dig.positionAbsolute, dig.top0, dig.start100, dig.translateMiddle)}
                                    style={{display: "block"}}>{bracketCount}</span>}
                                <BracketSVG fill={color} style={{width: "25px"}}/>
                            </Link>
                        </div>
                        {DigPipe}
                        <div className={classNames(dig.me5, dig.dNone, dig.dMdFlex)}>
                            {isPRO() || <Link to={'/' + PROFILE_PATH + '/' + id + "/" + WALLET_PATH + '/' + DEPOSIT_PATH}>
                                <FontAwesomeIcon
                                    style={{color: color}}
                                    icon={faPlusCircle}
                                    size={'xl'}/>
                            </Link>}
                        </div>
                        <h3 className={classNames(dig.mt0, dig.dNone, dig.dMdFlex)}>
                            <a href={'/' + PROFILE_PATH + '/' + id + '/' + (isPRO() ? WALLET_PATH : HISTORY_PATH) + '/'}
                               title={isPRO() ? "Wallet" : "Account History"}
                               className={classNames(dig.col2, dig.mt1)} style={{color: color, fontSize: '32px'}}>
                                {isGG() ? '' : '$'}{currentUser.funds?.toFixed(2) || 0.00}
                            </a>
                        </h3>

                        <div className={classNames(dig.ms5, dig.dNone, dig.dMdFlex)}>
                            {isPRO() || <Link to={'/' + PROFILE_PATH + '/' + id + "/" + WALLET_PATH + '/' + WITHDRAW_PATH}>
                                <FontAwesomeIcon
                                    style={{color: color}}
                                    icon={faMinusCircle}
                                    size={'xl'}/>
                            </Link>}
                        </div>
                    </div>

                </>)}

        </div>

        {true === isLoggedIn && (undefined === currentUser
            ? <Loading message={"Loading Current User"} count={1}/>
            : <div id={dig.digActionTrayContentsMobile}
                   className={classNames(
                       dig.borderBottom2,
                       dig.borderBlack,
                       dig.digActionTrayContentsMobile,
                       dig.dFlex,
                       dig.justifyContentBetween,
                       dig.alignItemsCenter,
                       dig.w100,
                       dig.dMdNone
                   )}
            >

                <div className={classNames(dig.ms0, dig.profilePic, dig.dFlex, dig.justifyContentCenter)}>
                    <ProfilePicture user={currentUser} className={dig.border1}/>
                </div>
                {DigPipe}
                {isPRO() || <Link to={'/' + PROFILE_PATH + '/' + id + '/' + WALLET_PATH + '/'} title="Deposit Funds">
                    <FontAwesomeIcon
                        color={color}
                        icon={faPlusCircle}
                        size={'lg'}/>
                </Link>}
                <div className={dig.actionTrayBalance}>
                    <Link className={"font-size-2em"} to={'/' + PROFILE_PATH + '/' + id + "/history/"}
                          title="Account History">
                        &nbsp;{isGG() ? '' : '$'}{currentUser.funds?.toFixed(2) || 0.00}</Link>
                </div>
                <Link to={'/' + PROFILE_PATH + '/' + id + '/' + WALLET_PATH + '/'} title="Withdraw Funds">
                    <FontAwesomeIcon
                        color={color}
                        icon={faMinusCircle}
                        size={'lg'}/>
                </Link>
                {DigPipe}
                <div className={classNames(dig.me0, dig.notifications, dig.dFlex, dig.justifyContentCenter)}
                     data-show-always="1">
                    <Link
                        className={classNames(dig.positionRelative)}
                        to={'/' + PROFILE_PATH + '/' + currentUser.ID + '/' + INVITATIONS_PATH + '/'}>
                        {0 !== invitationCount && <span
                            className={classNames(dig.badge, dig.navPills, dig.positionAbsolute, dig.borderPrimary,
                                dig.bgPrimary, dig.textWhite, dig.textCenter, dig.textUppercase, dig.roundedPill,
                                dig.positionAbsolute, dig.top0, dig.start100, dig.translateMiddle)}
                            style={{display: "block"}}>+{invitationCount}</span>}
                        <InvitationsSVG
                            fill={color}
                            style={{width: "25px"}}/>
                    </Link>
                </div>
            </div>)}


    </div>


}

import {ChangeEvent} from "react";

/**
 * @usage - ensure multiple request do not go off to quickly
 *
 * @param shouldContinueAfterTimeout
 * @param cb
 * @param timeoutMs
 */

export function Timeout(
    shouldContinueAfterTimeout: () => boolean,
    cb: () => void,
    timeoutMs: number = 3000): () => void {

    const timer = () => setTimeout(() => {

        if (false === shouldContinueAfterTimeout()) {

            return;

        }

        cb();

    }, timeoutMs);

    const timerId = timer();

    return () => {
        clearTimeout(timerId);
    };


}

Timeout.displayName = 'Timeout';

export function WatchInputElementChangeEvent(event: ChangeEvent<HTMLInputElement>, cb: (event: ChangeEvent<HTMLInputElement>) => (Promise<boolean> | boolean), timeoutMs: number = 3000) {

    const target = event.target;

    const value = target.value;

    event.target.style.color = "orange";

    Timeout(() => value === ((() => target.value)()),
        async () => {

            const callbackResult = await cb(event);

            console.log('User provided input callbackResult passed to WatchInputElementChangeEvent (will cause green or red) value (' + value + ')', callbackResult)

            target.style.color = false === callbackResult ? "red" : "green"

        },
        timeoutMs)

}

WatchInputElementChangeEvent.displayName = 'WatchInputElementChangeEvent';
import {PayPalScriptProvider, PayPalButtons} from "@paypal/react-paypal-js";
import {useState, useMemo} from "react";
import walletInputsValid from "src/api/hoc/walletInputsValid";
import PostWalletTransaction from "src/api/rest/postWalletTransaction";
import Bootstrap from "src/Bootstrap";
import Loading from "src/components/Loading/Loading";
import Amount from "src/components/Wallet/Amount/Amount";
import Cookies from 'js-cookie';
import Billing from "src/components/Wallet/Billing/Billing";
import isProduction from "src/variables/isProduction";


export default function PayPal() {

    const [transactionInProgress, setTransactionInProgress] = useState<boolean>(false);

    const [forumValid] = walletInputsValid()

    console.log('walletInputsValidBool', forumValid)

    if ('' === process.env.REACT_APP_PAYPAL_PUBLIC_KEY) {
        console.log(process.env)
        return <div>PayPal Failed to Collect Public Key</div>
    }

    const iFrame = useMemo(() => <PayPalScriptProvider options={{
        "data-sdk-integration-source": "button-factory",
        "client-id": process.env.REACT_APP_PAYPAL_PUBLIC_KEY || '',
        "disable-funding": "card",
        "enable-funding": "venmo",
        "currency": "USD",
        commit: false,
        intent: "authorize",
        "integration-date": "2021-09-01",
    }}>
        <PayPalButtons
            disabled={!forumValid}
            createOrder={(_data, actions) => {

                setTransactionInProgress(true);

                // I feel it would be a dark practice to change this 'on error' value to anything other than $0.00
                let rawAmount = Amount.amount?.state.monetaryValue ?? 0.00;
                const amount = (rawAmount + (Amount.amount?.processingFee(rawAmount) ?? 0.00)).toFixed(2).toString();

                return actions.order.create({
                    purchase_units: [{
                        "amount": {
                            "currency_code": "USD",
                            "value": amount,
                            "breakdown": {
                                "item_total": {
                                    "currency_code": "USD",
                                    "value": amount
                                }
                            }
                        },
                        items: [
                            {
                                "name": "Drop-In Gaming Funds Deposit",
                                "description": "This deposit adds to your available funds in your wallet at Drop-In Gaming.",
                                "unit_amount": {
                                    "currency_code": "USD",
                                    "value": amount
                                },
                                "quantity": "1"
                            }
                        ]
                    }],
                    application_context: {
                        shipping_preference: "NO_SHIPPING"
                    }
                });
            }}
            onApprove={async (_data, actions) => {
                actions.order?.authorize().then((details) => {

                    console.log(details)

                    const name = details.payer.name?.given_name;

                    const {firstName, lastName, zip, selectedCountry, state, town, street, apt} = Billing.billing?.state ?? {};

                    console.log(`Transaction completed by ${name}`, details, actions, _data);

                    PostWalletTransaction({
                        action: 'deposit', // todo - race condition is here, validation on B.E. needed
                        amount: Amount.amount?.state.monetaryValue.toString() ?? '',
                        cc_addr_line_1: street ?? '',
                        cc_addr_line_2: apt,
                        cc_city: town,
                        cc_country: selectedCountry?.abbreviation ?? '',
                        cc_fname: firstName ?? '',
                        cc_lname: lastName ?? '',
                        cc_state: state,
                        cc_zip: zip,
                        error: "Failed to submit your paypal transaction!",
                        kt_sid: Cookies.get('KOUNT_SID'),
                        payment_token: details.purchase_units[0]?.payments?.authorizations?.[0].id as string,
                        pp_authorization_amount: details.purchase_units[0].amount.value,
                        pp_authorization_currency: details.purchase_units[0].amount.currency_code,
                        pp_authorization_id: details.id,
                        pp_authorization_payer_email: details.payer.email_address,
                        pp_authorization_status: details.status,
                        success: "Successfully added your paypal transaction!",
                        transaction_id: details.id,
                        user_id: Bootstrap.bootstrap.state.id,
                    })?.finally(() => {
                        setTransactionInProgress(false);
                    })

                });

            }}
            style={{layout: "vertical"}}/>
    </PayPalScriptProvider>, [forumValid]);

    return <>
        {transactionInProgress && <Loading message={"Processing your transaction, do not leave this page!"}/>}
        <br/>
        {iFrame}
        {isProduction || <div className="um-field um-field-c mb-3" id="dig-paypal-test-info"
             style={{paddingTop: "10px !important", backgroundColor: "grey"}}>
            <div className="um-field-area dig-cc-test-info">
                <p>Test PayPal Personal Information:</p>
                <p>Email: sb-4gvcr6053878@personal.example.com</p>
                <p>Password: digtest1234</p>
                <p><a href="https://www.sandbox.paypal.com/" target="_nweWindow"
                      style={{textDecoration: "underline"}}>PayPal Test Account Login</a>
                </p><p></p>
                <p>Test PayPal Business Information:</p>
                <p>Email: sb-ngq0h1416805@business.example.com</p>
                <p>Password: digtest1234</p>
                <p><a href="https://www.sandbox.paypal.com/" target="_nweWindow"
                      style={{textDecoration: "underline"}}>PayPal Test Account Login</a>
                </p></div>
        </div>}
    </>
}
import {toast} from "react-toastify";
import restApi, {DELETE, iDeleteC6RestResponse} from "src/api/API";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";
import Bootstrap from "src/Bootstrap";
import {C6, iUsers} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iUser} from "src/variables/sharedInterfaces";
import logout from "../hoc/logout";

export default restApi<{
    ID: number
}, iUsers, {}, iDeleteC6RestResponse<iUsers>>({
    axios: DropVariables.axios,
    tableName: C6.users.TABLE_NAME,
    requestMethod: DELETE,
    queryCallback: (request) => {
        request.success ??= 'We\'ve successfully removed the account from our records!'
        request.error ??= 'An unknown issue occurred removing the account.'
        return request
    },
    responseCallback: (_response, request, successful) => {

        const bootstrap: Bootstrap = Bootstrap.bootstrap;

        console.log('deleteUser successful', successful)

        if (successful) {

            if (request.ID === bootstrap.state.id) {

                logout(true).then(() => {
                    // todo - verify this is working
                    toast.success('All session cookies has been removed from your browser.')
                })

            }

            deleteRestfulObjectArrays<iUser>([{ID: request.ID}], 'ID', 'users')

        }

    }
});



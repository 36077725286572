import restApi, {POST} from "src/api/API";
import {C6, iDig_Competitions, iDig_Team_Roster} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {iTeam} from "src/variables/sharedInterfaces";
import getInvitations from "./getInvitations";

// Used when sending an invite for your team, or for a comp in cases where the user is not yet on the roster
// uid = receiving user id
export interface iPostRoster extends iDig_Team_Roster {
    comp_id?: number,
    uid: number,
    user_team_id: number
}

export function postTeamRosterCallback(request) {
    updateRestfulObjectArray<iTeam>((prev) => {
        let prevTeam = prev.teams?.find(team => team.user_team_id === request.user_team_id),
            prevRosterDetails = prevTeam?.rosterDetails?.filter(roster => roster.uid !== request.uid) ?? [];

        if (prevTeam === undefined) {
            // likely a socket event for a team we don't have or need in state. Ignore.
            return [];
        }

        prevRosterDetails.push({
            id: request.id,
            uid: request.uid,
            user_team_id: request.user_team_id,
            position: 0,
            acceptance: 0
        });

        let rosterPending = prevTeam.rosterPending?.filter(uid => uid !== request.uid) ?? [];

        return [{
            user_team_id: request.user_team_id,
            rosterDetails: prevRosterDetails,
            rosterPending: [
                ...(rosterPending),
                request.uid
            ]
        }]
    }, 'user_team_id', 'teams');

    getInvitations();
}

export default restApi<iPostRoster, iDig_Team_Roster & iDig_Competitions>({
        axios: DropVariables.axios,
        tableName: [C6.dig_team_roster.TABLE_NAME, C6.dig_competitions.TABLE_NAME],
        requestMethod: POST,
        queryCallback: (request) => {
            request.success ??= 'Successfully add to the team!'
            request.error ??= 'An unknown issue occurred during your team roster addition. Please try again!'
            return request
        },
        responseCallback: (_response, request, id) => {

            if (!id || 'number' !== typeof id) {
                return;
            }

            request.id = id;

            postTeamRosterCallback(request);

        }
    });

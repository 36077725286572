import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import updateRestfulObjectArray, {eUpdateInsertMethod} from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Chat_Global_Messages} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iChatMessages} from "src/variables/sharedInterfaces";

export default restApi<{}, iDig_Chat_Global_Messages, {}, iPostC6RestResponse<iDig_Chat_Global_Messages>>({
    axios: DropVariables.axios,
    tableName: C6.dig_chat_global_messages.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success = 'Global message sent!'
        request.error = 'An unknown issue occurred while sending your message. We will be looking into this shortly.'
        return request
    },
    responseCallback: (response, _request, id) => {

        if ('string' !== typeof id) {
            throw new Error("ID is not a string in postChatGlobalMessages.tsx");
        }

        updateRestfulObjectArray<iChatMessages>([response.data.rest], "global_message_id", "globalMessages", eUpdateInsertMethod.LAST);

    }
});


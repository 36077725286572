import classNames from "classnames";
import {ReactElement, useState} from "react";
import {Link, Navigate} from "react-router-dom";
import compTypeToSingular from "src/api/hoc/compTypeToSingular";
import Bootstrap from "src/Bootstrap";
import Loading from "src/components/Loading/Loading";
import DropVariables, {eMONEY_OUTPUT_CONTEXT, getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, iCompetitions, iUser} from "src/variables/sharedInterfaces";

import FreeCompOverlay from "src/assets/img/free-comp-overlay.png"
import getCurrentLoggedInUser from "../../api/hoc/getCurrentLoggedInUser";
import isLoggedIn from "src/variables/isLoggedIn";
import {COMPETITION_JOIN_PATH, COMPETITION_PATH} from "src/views/Competition/Competition";
import isGG from "src/variables/isGG";
import SvgInline from "src/components/SvgInline/SvgInline";
import {ReactComponent as TournamentSVG} from "src/assets/img/svg-icons/tournament.svg";
import {ReactComponent as MatchSVG} from "src/assets/img/svg-icons/match.svg";
import {ReactComponent as BracketSVG} from "src/assets/img/svg-icons/bracket.svg";

interface iCompetitionCardProperties {
    id: number;
    competition: iCompetitions;
}

export default function CompetitionCard({competition}: iCompetitionCardProperties) {

    const {id, regions} = Bootstrap.bootstrap.state;

    const [useRedirect, setUseRedirect] = useState<string>('');

    const myAccount: iUser | undefined = getCurrentLoggedInUser();

    const dig = getStyles();

    if (DropVariables.objectLoading(competition)) {

        return <div className={classNames(dig.col12, dig.colLg3, dig.colMd4, dig.colSm6)}>
            <Loading count={3} message={'Loading competition information'}/>
        </div>

    }

    const {buy_in, custom_buy_in, registered_users} = competition

    const fullBuyIn = custom_buy_in || buy_in || '0.0000';

    let totalPrize: number = DropVariables.totalPrize(competition)

    const customTotalPrize = competition.custom_total_prize || ''

    const freeBuyIn = fullBuyIn === '0.0000';

    const competitionTypeInt = competition.comp_type_id;

    const isMatch = eCOMPETITION.MATCHES === competitionTypeInt;

    const competitionCapacity = competition.max_players;

    const maxTeamSize = competition.team_size;

    if (undefined === maxTeamSize) {

        return <Loading message={'Loading competition team size information'}/>

    }

    // TODO: properly implement queue for matches
    let completedTeamsJoined = competition.complete_teams?.length ?? 0;

    let totalTeamsJoined = (competition.incomplete_teams?.length ?? 0) + completedTeamsJoined;

    // tournaments/brackets are never truly "full", as you can still join the queue
    let isFull = isMatch && totalTeamsJoined === competitionCapacity;

    const isRegistered = true === registered_users?.includes(id);

    let prizeDisplay: string;

    if (totalPrize <= 0) {

        prizeDisplay = 'Bragging Rights'

    } else {

        prizeDisplay = (isGG() ? totalPrize.toFixed(0) + ' Coin' : '$' + totalPrize.toFixed(2)) + ' Prize'

    }

    const hasEnoughFunds = undefined !== myAccount && ((myAccount?.funds ?? 0) - parseFloat(competition.buy_in ?? '0')) >= 0

    const competitionStatusNumber: number | undefined = competition.status;

    if (undefined === competitionStatusNumber) {

        return <Loading message={'Gathering competition status'}/>

    }

    let banner: ReactElement | undefined = undefined;

    if (isMatch) {

        banner = <div className={classNames("card-text text-dark mt-3 match-status match-not-registered")}>

            ({totalTeamsJoined}/{competition.max_players} {(maxTeamSize === 1) ? 'players' : 'teams'} joined)

        </div>

    } else if (!isMatch && completedTeamsJoined > 0) {
        banner = <div className={classNames("card-text text-dark mt-3 match-status match-not-registered")}>
            ({completedTeamsJoined}/{competition.max_players} {(maxTeamSize === 1) ? 'players' : 'teams'} joined)
        </div>
    }

    /** eCOMPETITION_STATUS.WAITING === competitionStatusNumber &&
     <div className={classNames("card-text text-dark mt-3 match-status match-waiting")}>
     STATUS: WAITING
     </div> **/

    switch (competitionStatusNumber) {
        case eCOMPETITION_STATUS.STARTED:
        case eCOMPETITION_STATUS.CANCELED:
        case eCOMPETITION_STATUS.COMPLETED:
        case eCOMPETITION_STATUS.PAID:
            banner = <div className={classNames({
                    [dig.matchStarted]: competitionStatusNumber === eCOMPETITION_STATUS.STARTED,
                    [dig.matchCanceled]: competitionStatusNumber === eCOMPETITION_STATUS.CANCELED,
                    [dig.matchCompleted]: competitionStatusNumber === eCOMPETITION_STATUS.COMPLETED,
                    [dig.matchPaid]: competitionStatusNumber === eCOMPETITION_STATUS.PAID,
                }, dig.cardText, dig.textDark, dig.mt3, dig.matchStatus
            )}>
                STATUS: {eCOMPETITION_STATUS[competitionStatusNumber]}
            </div>
    }

    const thisCompetitionUri = '/' + COMPETITION_PATH + '/' + competition.comp_id + '/'

    let joinElement: ReactElement | null = null;

    if (!isFull) {
        if (false === isLoggedIn()) {
            joinElement = <Link
                to={"/competition/" + competition.comp_id + "/login/"}
                className={classNames(dig.digEnterTournament)}
                onChange={() => setUseRedirect(thisCompetitionUri + COMPETITION_JOIN_PATH + '/')}
            >
                Join
            </Link>
        } else if (hasEnoughFunds) {
            joinElement = <Link to={"/competition/" + competition.comp_id + "/join/"}
                                className={classNames(dig.digEnterTournament)}>
                Join
            </Link>
        } else {
            joinElement = <Link to={"/competition/" + competition.comp_id + "/wallet/"}
                                className={classNames(dig.digEnterTournament)}>
                Join
            </Link>
        }
    }

    if ('' !== useRedirect) {
        return <Navigate to={useRedirect}/>
    }

    let compTypeIcon: ReactElement | null = null;

    switch (competition.comp_type_id) {
        case 1:
            compTypeIcon = <MatchSVG/>
            break;
        case 2:
            compTypeIcon = <TournamentSVG/>
            break;
        case 3:
            compTypeIcon = <BracketSVG/>
            break;
    }

    const arrayOfRegionIds = competition?.regions?.map(id => parseInt(id, 10)) || [];

    // @ts-ignore
    const filteredRegions = regions?.filter(item => arrayOfRegionIds?.includes(item.region_id));

    const teamsize: ReactElement = <i title="Team size" className={("fas fa-user")}/>

    const getObjects = () => {
        const objs: any = []
        for (var i = 0; i < maxTeamSize; i++) {
            objs.push(teamsize)
        }

        return objs;
    }


    return <div className={classNames(dig.dBlock, dig.h100)}>

        <div style={{
            background: 'url("' + competition?.sponsor_photo + '") 100% 0% / cover',
        }}
             className={classNames(dig.digPresentationCard, dig.digBgNeutral2, dig.rounded0, dig.border0, dig.card, dig.freeComp, dig.h100)}>
            <div className={classNames(dig.cardHeader, dig.textWhite, dig.mt1)}>
                <div className={classNames(dig.matchTitle, dig.textWhite)}>
                    <i className={classNames(dig.textWhite)}>
                        {compTypeIcon}
                    </i>
                    <h4 className={classNames(dig.textWhite, dig.textUppercase, dig.fwBold, dig.fontSize15)}>
                        {compTypeToSingular(competition.comp_type_id)}
                    </h4>
                </div>
                <div className={classNames(dig.dFlex, dig.alignItemsCenter)}>
                    {freeBuyIn &&
                    <div className={dig.digFreeCompOverlay}>
                        <img src={FreeCompOverlay}
                             alt="free comp"/>
                    </div>}
                    <span className={classNames(dig.ms2, dig.digCardCompId, dig.digHighlight2)}>ID: {competition.comp_id}</span>
                </div>
            </div>
            <div className={classNames(dig.cardBody, dig.p0, dig.textWhite)}>
                <div className={classNames(dig.cardImage, dig.minHeight100Px)}>
                    {
                        competition.game_logo_image ?
                            <>
                                <SvgInline
                                    className={classNames(dig.styleSvg, dig.digSecondaryFillColor, dig.w100, dig.h100)}
                                    url={competition.game_logo_image}
                                />
                            </>
                            :
                            <h1 className={classNames(dig.fontSize3Em, dig.textCenter, dig.textUppercase, dig.digOrange)}>{competition.code}</h1>
                    }

                </div>
                <h2
                    className={classNames(
                        dig.textWhite, dig.textCenter, dig.digCompTitle, dig.dFlex, dig.alignItemsCenter,
                        dig.mb2, dig.mtLg0, dig.px2
                    )}>
                    {competition.competition_title ?? competition.name} </h2>
                <h4 className={dig.mb1}>
                    <span>Platforms: </span>
                    <i title="PC" className={("fas fa-mouse")}/>
                    <i title="Playstation" className={("fab fa-playstation")}/>
                    <i title="Xbox" className={("fab fa-xbox")}/>
                </h4>
                <h4 className={dig.mb3}>
                    <span>Region: </span>
                    {
                        filteredRegions?.map(region => region.region_name).join(' ')
                    }

                </h4>
                <h4 className={dig.mb3}>
                    {getObjects()
                    }


                </h4>

                <h2 className={classNames(dig.digOrange, dig.mb2, dig.mt4, dig.cardPrize)}>
                    {customTotalPrize !== '' ? customTotalPrize : prizeDisplay}
                </h2>
                <div className={classNames(dig.cardText, dig.mb2, dig.matchTeam, dig.digHighlight2)}>
                    {DropVariables.formatMoney(fullBuyIn, eMONEY_OUTPUT_CONTEXT.BUY_IN)} {isMatch && "+ " + DropVariables.formatMoney(parseFloat(fullBuyIn) * parseFloat(competition.comp_percentage || '0'), eMONEY_OUTPUT_CONTEXT.ENTRY_FEE)}
                </div>
                <div className={classNames(dig.cardText, dig.tournamentCardTime)}>
                    <h4 className={classNames(dig.digHighlight2, dig.fwBold)}>
                        {isMatch && competition.now_flag === 1 ? "Available Now" : competition.datetime ? DropVariables.convertPstToLocal(competition.datetime) : 'Loading date!'}
                    </h4>
                </div>

                {banner ?? <><br/><br/></>}

            </div>
            <div className={classNames(dig.cardFooter, dig.rounded0)}>
                {(() => {

                    if (competitionStatusNumber === eCOMPETITION_STATUS.PAID) {
                        return <div className={classNames(
                            dig.digCompStackableContainer, dig.view, dig.secondary
                        )}>
                            <Link to={"/competition/" + competition.comp_id}
                                  className={dig.digCompViewMatch}>View Results</Link>
                        </div>
                    }

                    if (isRegistered) {

                        if (competitionStatusNumber === eCOMPETITION_STATUS.WAITING) {

                            return <>
                                <div className={classNames(
                                    dig.digCompStackableContainer, dig.available
                                )}>
                                    <Link to={"/competition/" + competition.comp_id}
                                          className={dig.digCompViewMatch}>View</Link>
                                </div>
                                <div className={classNames(
                                    dig.digCompStackableContainer, dig.view, dig.compCanceled
                                )}>
                                    <Link to={"/competition/" + competition.comp_id}
                                          className={dig.digCompViewMatch}>Withdraw</Link>
                                </div>
                            </>

                        } else {

                            return <div className={classNames(
                                dig.digCompStackableContainer, dig.view, dig.secondary
                            )}>
                                <Link to={"/competition/" + competition.comp_id}
                                      className={dig.digCompViewMatch}>View</Link>
                            </div>

                        }

                    }


                    return <>
                        {!isFull && <div className={classNames(
                            dig.digCompStackableContainer, dig.available
                        )}>
                            {joinElement}
                        </div>}
                        <div className={classNames(dig.digCompStackableContainer, dig.view, dig.secondary)}>
                            <Link to={"/competition/" + competition.comp_id}
                                  className={dig.digCompViewMatch}>
                                View
                            </Link>
                        </div>
                    </>
                })()}
            </div>
        </div>
    </div>

}

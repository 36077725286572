import classNames from "classnames";
import React from "react";
import {Link} from "react-router-dom";
import {toast} from "react-toastify";
import CompetitionSort from "src/api/hoc/CompetitionSort";
import {globalNavigate} from "src/api/hoc/GlobalHistory";
import isGameBlocked from "src/api/hoc/isGameBlocked";
import getCompetitions from "src/api/rest/getCompetitions";
import getGameList from "src/api/rest/getGameList";
import Bootstrap from "src/Bootstrap";
import CompetitionGrid from "src/components/CompetitionGrid/CompetitionGrid";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import Tabs from "src/components/Tabs/Tabs";
import {getStyles} from "src/variables/DropVariables";
import DropVariables from "src/variables/DropVariables";
import {
    eCOMPETITION,
    eCOMPETITION_STATUS,
    eCOMPETITION_TIMING,
    iCompetitions,
    iGame
} from "src/variables/sharedInterfaces";
import moment from "moment";
import {GAMES_PATH} from "src/views/Games/Games";


enum eGAME_PAGE_TABS {
    ALL,
    BRACKETS,
    FREE
}

export const GAME_PATH = 'game';

// core components
export default class Game extends React.Component<WithRouter, {
    category: eCOMPETITION,
    subCategory: eGAME_PAGE_TABS
}> {
    constructor(props) {
        super(props);

        this.state = {
            category: eCOMPETITION.TOURNAMENTS,
            subCategory: eGAME_PAGE_TABS.ALL
        }

    }

    componentDidMount() {

        const currentGameID = parseInt(this.props.params.id);

        const {games, competitions} = Bootstrap.bootstrap.state;

        if (null === games || null === competitions) {
            return;
        }

        if (undefined === games) {

            getGameList();

        }

        const gameCompetitions = competitions?.filter(comp =>
            comp.game_id === currentGameID &&
            comp.status !== eCOMPETITION_STATUS.CANCELED &&
            comp.status !== eCOMPETITION_STATUS.COMPLETED &&
            comp.status !== eCOMPETITION_STATUS.PAID &&
            comp.parent_comp === 0 &&
            comp.public === 1 &&
            (comp.now_flag === 1 || moment(comp.datetime).isSameOrAfter(moment()))
        )

        if (undefined === gameCompetitions || gameCompetitions.length < 100) {

            getCompetitions({
                gameID: currentGameID,
                timing: eCOMPETITION_TIMING.FUTURE
            })

        }

    }

    switchSubCategory = (c: eGAME_PAGE_TABS) => this.setState({subCategory: c})

    switchCategory = (c: eCOMPETITION) => this.setState({category: c})

    render() {

        const {games, competitions} = Bootstrap.bootstrap.state;

        const currentGameID: number = parseInt(this.props.params.id);

        if (isGameBlocked(currentGameID)) {

            toast.error("This game is not available, your parental units will need to manually allowed this game to play!", DropVariables.toastOptions);

            globalNavigate('/' + GAMES_PATH + '/');

            return null;

        }

        const currentGame: iGame | undefined = games?.find(x => currentGameID === x.game_id)

        if (undefined === currentGame ||
            DropVariables.undefinedOrNull(competitions)) {

            return <Loading message={"Loading current game!"}/>

        }

        let currentCompetitions: iCompetitions[] = competitions?.filter(comp =>
            comp.game_id === currentGameID &&
            comp.status !== eCOMPETITION_STATUS.CANCELED &&
            comp.status !== eCOMPETITION_STATUS.COMPLETED &&
            comp.status !== eCOMPETITION_STATUS.PAID &&
            comp.parent_comp === 0 &&
            (comp.now_flag === 1 || moment(comp.datetime).isSameOrAfter(moment()))
        ) ?? [];

        if (eCOMPETITION.MATCHES === this.state.category) {

            currentCompetitions = currentCompetitions.filter(value => value.comp_type_id === eCOMPETITION.MATCHES)

        } else {

            switch (this.state.subCategory) {

                case eGAME_PAGE_TABS.ALL:

                    currentCompetitions = currentCompetitions.filter(value => value.comp_type_id !== eCOMPETITION.MATCHES)


                    break;

                case eGAME_PAGE_TABS.BRACKETS:

                    currentCompetitions = currentCompetitions.filter(value => value.comp_type_id === eCOMPETITION.BRACKETS)

                    break;

                case eGAME_PAGE_TABS.FREE:

                    currentCompetitions = currentCompetitions
                        .filter((value) => '0.0000' === value.buy_in)


                    break;

            }

        }

        currentCompetitions
            .sort(CompetitionSort.sortCompetitionsMoreRegistrationsFirst)
            .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.PAID))

        const dig = getStyles()

        return <div className={classNames(dig.digUserActionGroup, dig.upcomingEvents)}>
            {/*<!-- Card deck -->*/}
            <div className={classNames(
                dig.row, dig.g0, dig.positionRelative, dig.gameHeroHeaderContainer,
            )}
                 style={{backgroundImage: "url(" + currentGame.header_background_image + ")"}}>
                <div className={classNames(dig.gameHeroHeader, dig.col12)} style={{flex: "0 0 400px"}}>
                    <div className={classNames(dig.row, dig.dFlex, dig.justifyContentCenter)}>
                        <div className={classNames(dig.col8, dig.colLg2)}>
                            <img width="100%" alt="Featured image"
                                 src={currentGame.featured_image}
                                 data-ll-status="loading"/>
                            <Link
                                className={classNames(dig.dFlex, dig.justifyContentCenter, dig.digBtnBlue,
                                    dig.fwBold, dig.textUppercase, dig.alignItemsCenter, dig.backToGames
                                )} to={"/" + GAMES_PATH + '/'}>
                                <i className={classNames("fas fa-chevron-left mr-3")}></i>
                                Back to All Games
                            </Link>
                        </div>
                        <div className={classNames(
                            dig.col12, dig.colLg10, dig.dFlex, dig.flexColumn, dig.justifyContentEnd
                        )}>
                            <div className={classNames(dig.gameTitle, dig.mb4)}>
                                <h1 className={classNames(
                                    dig.fwBolder, dig.textWhite, dig.mb0, dig.mt0, dig.fontSize3Em
                                )}>
                                    {currentGame.name}
                                </h1>
                                <h4 className={classNames(
                                    dig.fwBold, dig.textWhite, dig.alignContentStart,
                                    dig.dFlex, dig.alignItemsCenter
                                )}>
                                    <i title="PC"
                                       className={classNames("fas fa-desktop font-size-1em mr-2 game_platforms_icon")}></i>
                                    <i title="Playstation"
                                       className={classNames("fab fa-playstation mr-2 font-size-1em game_platforms_icon")}></i>
                                    <i title="Xbox"
                                       className={classNames("fab fa-xbox font-size-1em mr-2 game_platforms_icon")}></i>
                                    <i className={classNames("fas fa-random font-size-1em game_platforms_icon")}
                                       title="Crossplay"></i>
                                </h4>
                            </div>
                            <div className={dig.gamesDescription}>
                                <p>{currentGame.description}</p>
                            </div>
                            <Tabs tabs={[
                                eCOMPETITION[eCOMPETITION.TOURNAMENTS],
                                eCOMPETITION[eCOMPETITION.MATCHES],
                            ]}
                                  activeTab={eCOMPETITION[this.state.category]}
                                  onTabClick={(tab) => this.switchCategory(eCOMPETITION[tab])}/>

                        </div>
                    </div>
                </div>
            </div>

            {this.state.category === eCOMPETITION.TOURNAMENTS
                && <div className={classNames(dig.tabContent, dig.mtSm1, dig.mtMd4)}>
                    <div role="tabpanel" aria-labelledby="tournament-tab"
                         className={classNames(dig.tabPane, dig.fade, dig.gamesDashboard, dig.active, dig.show)}>
                        {/*<!-- Sub tabs -->*/}
                        <Tabs tabs={[
                            eGAME_PAGE_TABS[eGAME_PAGE_TABS.ALL],
                            eGAME_PAGE_TABS[eGAME_PAGE_TABS.BRACKETS],
                            eGAME_PAGE_TABS[eGAME_PAGE_TABS.FREE]
                        ]}
                              activeTab={eGAME_PAGE_TABS[this.state.subCategory]}
                              onTabClick={(tab) => this.switchSubCategory(eGAME_PAGE_TABS[tab])}/>
                    </div>
                </div>}

            <CompetitionGrid competitionsToDisplay={currentCompetitions}
                             displaySort={true}
            />

            {/*<!--\ Card deck -->*/}
        </div>

    }
}



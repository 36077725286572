import React, {ChangeEvent, ReactNode, useEffect, useState} from "react";
import {toast} from "react-toastify";
import postUpdateMetaValue from "src/api/hoc/postUpdateMetaValue";
import getUserMeta from "src/api/rest/getUserMeta";
import putUser from "src/api/rest/putUser";
import deleteUser from "src/api/rest/deleteUser";
import Button from "src/components/Button/Button";
import Input from "src/components/Input/Input";
import {ppr} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {iUsermeta, RegExpMap, users} from "src/variables/C6";
import isGG from "src/variables/isGG";
import Settings from "src/views/Profile/Information/Settings/Settings";
import {iAmProfileDescendant} from "src/views/Profile/Profile";
import {WatchInputElementChangeEvent} from "src/components/Timeout/Timeout";
import Loading from "src/components/Loading/Loading";
import classNames from "classnames";
import swal from "sweetalert";
import {redirect} from "react-router-dom";
import styles from "./style.module.scss";

export const INFORMATION_PATH = 'account'

export default function Information(props: iAmProfileDescendant) {


    const {currentEditUser} = props;

    const [currentPassword, setCurrentPassword] = useState<string>('')
    const [newPassword, setNewPassword] = useState<string>('')
    const [confirmNewPassword, setConfirmNewPassword] = useState<string>('')

    const dig = getStyles(styles)

    useEffect(() => {

        if (false === DropVariables.undefinedOrNull(currentEditUser) &&
            currentEditUser.userMeta === undefined) {   // null would indicate that our ajax call has not yet returned

            getUserMeta({
                user: currentEditUser
            });

        }
    })

    if (null === currentEditUser?.userMeta || undefined === currentEditUser?.userMeta) {

        return <Loading message={"Loading User Metadata :)"}/>;

    }

    const userMeta: Array<iUsermeta> = currentEditUser.userMeta;

    interface iAccessMeta {
        umeta_id: number;
        key: string;
        value?: string;
    }

    const getMetaValue = (key): iAccessMeta => {

        const valueObject: iUsermeta | undefined = userMeta.find(x => x.meta_key === key);

        return {
            umeta_id: valueObject?.umeta_id,
            key: key,
            value: valueObject?.meta_value  // this could be undefined
        } as iAccessMeta;

    };

    const handlePasswordUpdate = async (inputName: string, inputValue: string): Promise<boolean> => {

        if ('CURRENT PASSWORD' === inputName) {
            setCurrentPassword(inputValue)
        } else if ('NEW PASSWORD' === inputName) {
            setNewPassword(inputValue)
        } else if ('CONFIRM PASSWORD' === inputName) {
            setConfirmNewPassword(inputValue)
        } else {
            console.error('Invalid input name: (', inputName, ')', inputValue)
            return false
        }
        return true
    }

    const informationInput = (inputName: string,
                              inputKey: number,
                              defaultValue: string,
                              cb: (event: ChangeEvent<HTMLInputElement>) => boolean | Promise<boolean>,
                              regexValidations: RegExpMap[keyof RegExpMap] = {},
                              password: boolean = false) =>

            <div className={classNames(dig.w100, dig.my4)} key={inputKey}>
                <h2 className={classNames(dig.ggSettingsLabel, dig.textStart, dig.fwNormal)}>{inputName}</h2>
                <Input type={password ? "password" : "text"}
                       regexValidations={regexValidations}
                       value={defaultValue}
                       className={password ? classNames(dig.formControl, dig.passwordInput, dig.mt3, "gg-settings=input") :classNames(dig.formControl, dig.mt3, "gg-settings=input")}
                       onChange={(event: ChangeEvent<HTMLInputElement>) => WatchInputElementChangeEvent(event, cb)}
                />
            </div>;

    function displayMetaChange(metaData: iAccessMeta, nicename: string) {
        return informationInput(nicename, metaData.umeta_id, metaData.value ??= '',
            (event: ChangeEvent<HTMLInputElement>) => {
                return postUpdateMetaValue({
                    currentEditUser: props.currentEditUser,
                    umeta_id: metaData.umeta_id,
                    metaKey: metaData.key,
                    metaValue: event.target.value
                })
            })
    }

    function handlePasswordInputs(inputName: string) {
        let inputValue;
        switch (inputName) {
            case 'NEW PASSWORD':
                inputValue = newPassword
                break;
            case 'CONFIRM PASSWORD':
                inputValue = confirmNewPassword
                break;
            case 'CURRENT PASSWORD':
                inputValue = currentPassword
                break;
            default:
                return ''
        }

        return informationInput(inputName, 0, inputValue,
            (event) => handlePasswordUpdate(inputName, event.target.value),
            users.REGEX_VALIDATION[users.USER_PASS], true)
    }

    const passwordSubmitDisabled = newPassword !== confirmNewPassword
        || "" === currentPassword
        || "" === newPassword
        || "" === confirmNewPassword

    // const regexStreamingURL = new RegExp('^(https://www\.(twitch\.tv|youtube\.com|facebook\.com))/([a-zA-Z0-9!@#$%^&*()_\/-]+)$');


    const forumDefaults = {
        'PERSONAL INFORMATION': {
            'FIRST NAME': displayMetaChange(getMetaValue('first_name'), 'FIRST NAME'),
            'LAST NAME': displayMetaChange(getMetaValue('last_name'), 'LAST NAME'),
            'PHONE NUMBER': displayMetaChange(getMetaValue('billing_phone'), 'PHONE NUMBER'),
            'WEBSITE': displayMetaChange(getMetaValue('gamertag-website'), 'WEBSITE'),
        },
        'BILLING INFORMATION': {
            'FIRST NAME': displayMetaChange(getMetaValue('first_name'), 'FIRST NAME'),
            'LAST NAME': displayMetaChange(getMetaValue('last_name'), 'LAST NAME'),
            'COMPANY': displayMetaChange(getMetaValue(''), 'COMPANY'),
            'COUNTRY': displayMetaChange(getMetaValue(''), 'COUNTRY'),
            'STREET ADDRESS': displayMetaChange(getMetaValue('street'), 'STREET ADDRESS'),
            'TOWN / CITY': displayMetaChange(getMetaValue('city'), 'TOWN / CITY'),
            'STATE': displayMetaChange(getMetaValue('state'), 'STATE'),
            'ZIP CODE': displayMetaChange(getMetaValue('zip'), 'ZIP CODE'),
            'PHONE NUMBER': displayMetaChange(getMetaValue('billing_phone'), 'PHONE NUMBER'),
            'EMAIL': displayMetaChange(getMetaValue('user_email'), 'EMAIL'), // User already has an email field??
        },
        'SHIPPING INFORMATION': {
            'FIRST NAME': displayMetaChange(getMetaValue('first_name'), 'FIRST NAME'),
            'LAST NAME': displayMetaChange(getMetaValue('last_name'), 'LAST NAME'),
            'COMPANY': displayMetaChange(getMetaValue(''), 'COMPANY'),
            'COUNTRY': displayMetaChange(getMetaValue(''), 'COUNTRY'),
            'STREET ADDRESS': displayMetaChange(getMetaValue('street'), 'STREET ADDRESS'),
            'TOWN / CITY': displayMetaChange(getMetaValue('city'), 'TOWN / CITY'),
            'STATE': displayMetaChange(getMetaValue('state'), 'STATE'),
            'ZIP CODE': displayMetaChange(getMetaValue('zip'), 'ZIP CODE'),
            'PHONE NUMBER': displayMetaChange(getMetaValue('billing_phone'), 'PHONE NUMBER'),
            'EMAIL': displayMetaChange(getMetaValue('user_email'), 'EMAIL'), // User already has an email field??
        },
        'PASSWORD': {
            'NEW PASSWORD': handlePasswordInputs('NEW PASSWORD'),
            'CONFIRM PASSWORD': handlePasswordInputs('CONFIRM PASSWORD'),
            'CURRENT PASSWORD': handlePasswordInputs('CURRENT PASSWORD'),
            'SUBMIT PASSWORD': <>
                <Button
                    disabled={passwordSubmitDisabled}
                    className={passwordSubmitDisabled ? classNames(dig.passwordDisabled, dig.textWhite) : classNames(dig. resetPassword, dig.textWhite)}
                    style={{minHeight: '2.3em', marginTop: '1.9rem'}}
                    label="Submit"
                    onClick={() => {

                    if (newPassword !== confirmNewPassword) {
                        toast.error('New passwords do not match!', DropVariables.toastOptions)
                        console.log('New passwords do not match!', '(' + newPassword + ')', '(' + confirmNewPassword + ')')
                        return;
                    }

                    putUser({
                        ID: currentEditUser.ID,
                        user_pass: confirmNewPassword,
                        user_activation_key: currentPassword
                    })
                }}>Reset Password</Button>
            </>,
        },
        'GAMERTAGS': {
            'XBOX LIVE': displayMetaChange(getMetaValue('gamertag-xbox'), 'XBOX LIVE'),
            'PLAYSTATION NETWORK': displayMetaChange(getMetaValue('gamertag-playstation'), 'PLAYSTATION NETWORK'),
            'STEAM': displayMetaChange(getMetaValue('gamertag-steam'), 'STEAM'),
            'EPIC GAMES': displayMetaChange(getMetaValue('gamertag-epicgames'), 'EPIC GAMES'),
            'SPLITGATE ID': displayMetaChange(getMetaValue('gamertag-splitgate-id'), 'SPLITGATE ID'),
            'DUEL LINKS ID': displayMetaChange(getMetaValue('gamertag-duel-links-id'), 'DUEL LINKS ID'),
            'ACTIVISION': displayMetaChange(getMetaValue('gamertag-activision'), 'ACTIVISION'),
            'ELECTRONIC ARTS': displayMetaChange(getMetaValue('gamertag-ea'), 'ELECTRONIC ARTS'),
            '2k ACCOUNT': displayMetaChange(getMetaValue('gamertag-2k'), '2k ACCOUNT'),
            'FALL GUYS ACCOUNT': displayMetaChange(getMetaValue('gamertag-fallguys'), 'FALL GUYS ACCOUNT'),
            'NINTENDO': displayMetaChange(getMetaValue('gamertag-nintendo'), 'NINTENDO'),
            'DBZ ID': displayMetaChange(getMetaValue('gamertag-dbz-id'), 'DBZ ID'),
        },
        'STREAMING': {
            'TWITCH CHANNEL NAME': displayMetaChange(getMetaValue('gamertag-twitch'), 'TWITCH CHANNEL NAME'),
            'FACEBOOK CHANNEL NAME': displayMetaChange(getMetaValue('gamertag-facebook'), 'FACEBOOK CHANNEL NAME'),
            'YOUTUBE CHANNEL NAME': displayMetaChange(getMetaValue('gamertag-youtube'), 'YOUTUBE CHANNEL NAME'),
            'KICK CHANNEL NAME': displayMetaChange(getMetaValue('gamertag-kick'), 'KICK CHANNEL NAME'),
        },
    };




    // if(displayMetaChange(getMetaValue('new_password'))){
    //     // send email to user saying their password was updated
    // }

    const displayForum = (obj: any) =>
        Object.keys(obj).map((heading, index) =>
            <div key={index}>
                <div
                    className={classNames(dig.row, dig.g0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsStart, dig.w100)}>

                    <h1 className={classNames(dig.ggTextPrimary, dig.fwNormal, dig.w100, dig.mb4)}>{heading}</h1>

                    {Object.keys(obj[heading]).map((inputName, inputKey) => {

                            const metaData: ReactNode = obj[heading][inputName];

                            if (null === metaData || undefined === metaData) {

                                return null;

                            }

                            if (React.isValidElement(metaData)) {

                                return <div className={classNames(dig.col12, dig.colSm6, dig.pe3)} key={inputKey}>
                                    {metaData}
                                </div>;

                            }

                            return null;

                        }
                    )}
                </div>
                <hr className={classNames(dig.borderTop1, "border-color-GG", dig.w100)}/>
            </div>
        );


    const imCHILD = props.currentEditUser?.isChild || false;
    const displayWarning = () => {
        swal({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this account!",
            icon: "warning",
            buttons: ['No thanks!', 'Yes, Delete it'],
            dangerMode: true,
        })
            .then((willDelete) => {
                if (willDelete) {
                    deleteUser({
                        ID: props.currentEditUser.ID,
                        success: () => {
                            swal("Poof! Your account has been deleted!", {
                                icon: "success",
                            });
                            redirect('/')
                            return undefined
                        }
                    });
                } else {
                    swal("Your account is safe!")
                }
            })
    }

    return (
        <div
            className={classNames(dig.containerFluid, dig.p0, dig.my5, dig.ggProfile, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter, dig.flexColumn)}>

            {true === isGG() && false === imCHILD ? ppr(Settings, {}) : ''}

            {displayForum(forumDefaults)}
            <button className={classNames(dig.btn, dig.btnDanger, dig.btnLg, dig.textUppercase, dig.fs2)}
                    onClick={displayWarning}>Delete Account
            </button>
        </div>
    );
}


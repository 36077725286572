import classNames from "classnames";
import {ReactNode} from "react";
import Skeleton, {SkeletonTheme} from "react-loading-skeleton";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {ReactComponent as MobileLogo} from "src/assets/img/new-mobile-logo.svg"

interface iLoading {
    lineHeight?: number,
    count?: number,
    children?: ReactNode,
    message: string | undefined,
    icon?: undefined | boolean
}

export default function Loading(props: iLoading) {

    const dig = getStyles();

    const primaryColor = DropVariables.getPrimaryColor()

    return props.icon === false || props.icon === undefined ? <div className={classNames(dig.my5, dig.px3, dig.pb5, dig.container)} style={{lineHeight: props.lineHeight || 4}}>
            <SkeletonTheme baseColor={primaryColor} highlightColor="#444">
                <Skeleton
                    className={classNames(dig.row, dig.my3, dig.justifyContentCenter, dig.mAuto)}
                    count={props.count || 1}
                    height={60}
                    inline={true}/>
                {props.children}
                {undefined !== props?.message && <h2 style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                }}>{props?.message}</h2>}
            </SkeletonTheme>
        </div>
        :
        <div className={classNames(dig.container, dig.h100, dig.w100, dig.dFlex, dig.justifyContentCenter, dig.alignItemsCenter, dig.flexColumn)}>
            <MobileLogo style={{width: "150px", fill: "#2f80f2"}}/>
            <SkeletonTheme baseColor={primaryColor} highlightColor="#444">
                {props.children}
                {undefined !== props?.message && <h3 className={classNames(dig.mt4)}>{props?.message}</h3>}
            </SkeletonTheme>
        </div>;

}


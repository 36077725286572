import classNames from "classnames";
import {useState} from "react";
import {toast} from "react-toastify";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import Bootstrap from "src/Bootstrap";
import Button from "src/components/Button/Button";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Input from "src/components/Input/Input";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import Select from "src/components/Select/Select";
import postContactSupport from "src/api/rest/postContactSupport";

export const SUPPORT_PATH = "Support";

export default function Support() {

    const myAccount = getCurrentLoggedInUser()

    const [name, setName] = useState<string>(myAccount?.display_name ?? '')

    const [email, setEmail] = useState<string>(myAccount?.user_email ?? '')

    const [phone, setPhone] = useState<string>('')

    const [requestCallback, setRequestCallback] = useState<number>(0)

    const [category, setCategory] = useState<string>('General Support')

    const [message, setMessage] = useState<string>('')

    const dig = getStyles();

    const currentUser = getCurrentLoggedInUser()

    return <section className={classNames(dig.offset2, dig.col8)}>

        <ExpandableBox header={'Contact Support'}>

            {currentUser === undefined
                ? <>Oh no! Lets get logged in before using the direct contact options. You may also <a
                    href="mailto:support@dropingaming.com">email us</a> directly or call <a
                    href="tel:1-866-DROPIN4">1-866-DROPIN4</a>
                    <Button onClick={() => {
                        Bootstrap.bootstrap.setState({
                            loginPopup: true
                        })
                    }}>Login & Contact Us</Button>
                </>
                : <article id="post-11963" className="page type-page status-publish hentry">
                    <p>Please use the form below to contact our Support team. You may also <a
                        href="mailto:support@dropingaming.com">email us</a> directly or call <a
                        href="tel:1-866-DROPIN4">1-866-DROPIN4</a>.
                    </p>
                    <div className={dig.row}>
                        <div className={dig.colMd12}>

                            <Input label={'Name'}
                                   type="text"
                                   value={name}
                                   onChange={(event) => setName(event.target.value)}/>


                            <Input label={'Email'}
                                   type="text"
                                   value={email}
                                   onChange={(event) => setEmail(event.target.value)}/>

                            <Input label={'Phone #'}
                                   type="text"
                                   value={phone}
                                   onChange={(event) => setPhone(event.target.value)}/>


                            <Input
                                type="checkbox"
                                checked={!!requestCallback}
                                onChange={(event) => setRequestCallback(event.target.value === 'false' ? 1 : 0)}>Request
                                Callback</Input>

                            <Select label={'Support Category'} name="support-category"
                                    id="support-category"
                                    options={[
                                        {value: 'General Support'},
                                        {value: 'Api Support'},
                                        {value: 'Trouble Registering for a Competition'},
                                        {value: 'Trouble Adding Team Members'},
                                        {value: 'Trouble Depositing Funds'},
                                        {value: 'Trouble Withdrawing Funds'},
                                        {value: 'Other'},
                                    ]}
                                    value={category}
                                    onChange={(event) => setCategory(event.target.value)}
                                    className={classNames("custom-select")}
                                    aria-hidden="true">
                            </Select>

                            Message
                            <p className={classNames(dig.formRow, dig.validateRequired, dig.woocommerceInputWrapper, "form-row-wide")}>
                                        <textarea className={classNames(dig.inputText, dig.textBlack)}
                                                  id={dig.digCustomerMessageBody}
                                                  value={message}
                                                  onChange={(event) => setMessage(event.target.value)}
                                                  name="message"/>
                            </p>


                            <div onClick={() => {
                                    if ('' === name) {
                                        toast.error('Please enter your name', DropVariables.toastOptions)
                                        return
                                    }

                                    if ('' === email) {
                                        toast.error('Please enter your email', DropVariables.toastOptions)
                                        return;
                                    }

                                    if ('' === phone) {
                                        toast.error('Please enter your phone number', DropVariables.toastOptions)
                                        return;
                                    }

                                    if ('' === message) {
                                        toast.error('Please enter a message', DropVariables.toastOptions)
                                        return;
                                    }

                                    postContactSupport({
                                        support_name: name,
                                        support_email: email,
                                        support_callback_requested: requestCallback,
                                        support_phone_number: phone,
                                        support_category: category,
                                        support_message: message,
                                    })
                                }}
                                className={classNames(dig.row, dig.digContactSupportButton, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter)}>
                                <button
                                    id={dig.digContactSupportButton}
                                    type="button"
                                    className={dig.digPrimaryButtonAction}>
                                    Contact Support
                                </button>
                            </div>
                        </div>
                    </div>
                </article>}
        </ExpandableBox>
    </section>
}

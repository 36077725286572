import classNames from "classnames";
import {useEffect, useState} from "react";
import compTypeToSingular from "src/api/hoc/compTypeToSingular";
import putCompetition from "src/api/rest/putCompetition";
import Bootstrap from "src/Bootstrap";
import {CountDown} from "src/components/CountDown/CountDown";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import Loading from "src/components/Loading/Loading";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, iCompetitions} from "src/variables/sharedInterfaces";
import moment from "moment";


interface iJoinStatus {
    openJoinPopup: () => void,
    competition: iCompetitions,
    sendTimeleftToParent: (data: any) => void
}

export default function JoinStatus(props: iJoinStatus) {

    const [timeLeft, setTimeLeft] = useState<number>(0)

    const {registrations, id} = Bootstrap.bootstrap.state
    const {competition} = props;

    let stopTimer: undefined | (() => void) = undefined;

    useEffect(() => {

        if (competition.now_flag === 1
            || competition.status === undefined
            || competition.now_flag === undefined
            || competition.status > eCOMPETITION_STATUS.STARTED
            || (competition.status === eCOMPETITION_STATUS.STARTED && (competition.sub_mode === undefined || competition.sub_mode === null))
            || competition.datetime === undefined
            || competition.datetime === null
            || competition.datetime === '') {
            console.groupCollapsed("something is terribly wrong");
            console.warn("competition.now_flag === 1", competition.now_flag === 1);
            console.warn("competition.status === undefined", competition.status === undefined);
            console.warn("competition.status > eCOMPETITION_STATUS.STARTED", (competition?.status ?? 0) > eCOMPETITION_STATUS.STARTED);
            console.warn("(competition.status === eCOMPETITION_STATUS.STARTED && (competition.sub_mode === undefined || competition.sub_mode === null))", (competition.status === eCOMPETITION_STATUS.STARTED && (competition.sub_mode === undefined || competition.sub_mode === null)));
            console.warn("competition.datetime === undefined", competition.datetime === undefined);
            console.warn("competition.datetime === null", competition.datetime === null);
            console.warn("competition.datetime === ''", competition.datetime === '');
            console.warn(competition);
            console.groupEnd()
            return;
        }

        let countdownTime = competition.datetime,
            putData = {
                comp_id: competition.comp_id,
                cacheResults: true,
                blocking: true
            };
        console.warn('original comp time: ', competition.datetime);

        // at this point, we know we are either a match in WAITING status, or a tournament/bracket in WAITING or STARTED status
        if (competition.status === eCOMPETITION_STATUS.STARTED) {

            const timelimit = Bootstrap.bootstrap.state.subModes?.find((subMode) => subMode.sub_mode_id == competition.sub_mode)?.time_limit;

            if (undefined === timelimit) {
                return;
            }

            // literally impossible for submode to be undefined here. Not sure what crack the editor is smoking
            countdownTime = moment(countdownTime).add(timelimit, 'minutes').format("YYYY-MM-DD HH:mm:ss").toString();

            console.warn('countdown time for started: ', countdownTime);

            putData['status'] = eCOMPETITION_STATUS.COMPLETED;

        }

        if (0 >= DropVariables.unixTimeUntil(countdownTime)) {

            putCompetition(putData);

            return;

        }



        stopTimer = CountDown(countdownTime, (timeLeft: number) => {
            if (timeLeft <= 0) {
                putCompetition(putData);
            }

            setTimeLeft(timeLeft);

            // update once when we enter the last 5 minutes (for displaying clantag)
            if (timeLeft === 300) {
                props.sendTimeleftToParent(timeLeft)
            }
        })

        return () => {

            stopTimer?.();

        }

    }, [Bootstrap.bootstrap.state.competitions])

    if (undefined === competition || (competition.comp_type_id !== eCOMPETITION.MATCHES
        && competition.comp_type_id !== eCOMPETITION.ROUND_ROBIN &&
        (competition.sub_mode === undefined))) {

        return <Loading message={"Loading competitions!"}/>

    }

    const secondsToDHMS = (seconds: number) => {
        seconds = Number(seconds);
        const d = Math.floor(seconds / (3600 * 24));
        const h = Math.floor(seconds % (3600 * 24) / 3600);
        const m = Math.floor(seconds % 3600 / 60);
        const s = Math.floor(seconds % 60);
        return [d, h, m, s];
    }

    const [days, hours, minutes, seconds] = secondsToDHMS(timeLeft);

    const competitionStatusInt = competition.status

    const compAllowLateStart = competition.competitionsMeta?.find(meta => meta.meta_key === '_late_registration')

    console.log('late ',compAllowLateStart?.meta_value)

    // we use || here to help with timing, we don't want join and delete to show at the same time
    const joinedCompetition = undefined !== registrations?.find(registration => registration.user_id === id
        && registration.competitions_id === competition.comp_id) || true === competition.registered_users?.includes(id)

    // queue is not fully implemented with matches, so we need to stop joins if it is one and two teams have already joined
    const isMatchAndIsFull = competition.comp_type_id === eCOMPETITION.MATCHES && (competition.incomplete_teams?.length ?? 0) + (competition.complete_teams?.length ?? 0) >= (competition.max_players ?? 2)

    const eligibleToJoin = (false === joinedCompetition && (compAllowLateStart || competitionStatusInt === eCOMPETITION_STATUS.WAITING) && false === isMatchAndIsFull);

    if (timeLeft <= 0
        && competition.now_flag === 0
        && false === eligibleToJoin) {

        return null;

    }

    const dig = getStyles()


    if (0 < timeLeft || eligibleToJoin) {

        return <ExpandableBox>
         {competition.now_flag === 0 && <>
             <div className={classNames(dig.colSm, dig.p0)}>
                <div className={classNames(
                    dig.listGroup, dig.listGroupFlush, dig.my1, dig.dBlock
                )}>
                        <><h5 className={classNames(
                            dig.ms3, dig.cardTitle, dig.textUppercase, dig.alignContentStart, dig.digColorNeutral3
                        )}>
                            {competition.status === eCOMPETITION_STATUS.WAITING ? 'Starts' : 'Ends'} in : {timeLeft}
                        </h5>
                            <div className={dig.m3}
                                 id={dig.digCountdownCounter}>
                                <div
                                    className={classNames(
                                        dig.bgTransparent, dig.border2, dig.px0, dig.py3, dig.borderColorNeutral3, dig.digCountdownCounter, dig.m0
                                    )}>
                                    <div id="clock-c"
                                         className={classNames(
                                             dig.row, dig.mb0, dig.g0
                                         )}>
                                        <div className={dig.col3}>
                                            <div className={dig.col}>
                                                <h2 className={classNames(
                                                    dig.fwNormal, dig.textCenter
                                                )}>{Math.abs(days)}</h2>
                                            </div>
                                            <h5 className={classNames(
                                                dig.col, dig.digColorNeutral3, dig.textCenter
                                            )}>DAYS</h5>
                                        </div>
                                        <div className={dig.col3}>
                                            <div className={dig.col}>
                                                <h2 className={classNames(dig.fwNormal, dig.textCenter)}>{Math.abs(hours)}</h2>
                                            </div>
                                            <h5 className={classNames(dig.col, dig.digColorNeutral3, dig.textCenter)}>HRS</h5>
                                        </div>
                                        <div className={dig.col3}>
                                            <div className={dig.col}>
                                                <h2 className={classNames(dig.fwNormal, dig.textCenter)}>{Math.abs(minutes)}</h2>
                                            </div>
                                            <h5 className={classNames(dig.col, dig.digColorNeutral3, dig.textCenter)}>MINS</h5>
                                        </div>
                                        <div className={dig.col3}>
                                            <div className={dig.col}>
                                                <h2 className={classNames(dig.fwNormal, dig.textCenter)}>{Math.abs(seconds)}</h2>
                                            </div>
                                            <h5 className={classNames(dig.col, dig.digColorNeutral3, dig.textCenter)}>SECS</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                </div>
            </div>
         </>}


        {/* Join Competition */}
        {eligibleToJoin && <div className={classNames(dig.cardBody, dig.pt0)}>
            <div className={classNames(dig.row, dig.m0)}>
                <div onClick={(e) => {
                        e.preventDefault();
                        props.openJoinPopup();
                     }}
                     style={{cursor: 'pointer'}}
                     className={classNames(
                         dig.fwBold, dig.textUppercase, dig.digBtnGreen, dig.textCenter, dig.py2, dig.fontSize1P2Rem, dig.w100
                     )}>
                    Join {compTypeToSingular(competition.comp_type_id)}
                </div>
            </div>
        </div>}

        {((competitionStatusInt === eCOMPETITION_STATUS.PAID || competitionStatusInt === eCOMPETITION_STATUS.COMPLETED) &&
                <div className={classNames(dig.cardBody, dig.pt0)}>
                    <div className={classNames(dig.row, dig.m0)}>
                        <div
                            className={classNames(
                                dig.fwBold, dig.textUppercase, dig.digBtnGreen, dig.textCenter, dig.py3, dig.fontSize1P5Em, dig.w100)}>
                            This {compTypeToSingular(competition.comp_type_id)} has ended.
                        </div>
                    </div>
                </div>) ||
            (competitionStatusInt === eCOMPETITION_STATUS.CANCELED &&
                <div className={classNames(dig.cardBody, dig.pt0)}>
                    <div className={classNames(dig.row, dig.m0)}>
                        <div
                            className={classNames(
                                dig.fwBold, dig.textUppercase, dig.digBtnRed, dig.textCenter, dig.py3, dig.fontSize1P5Em, dig.w100)}>
                            This {compTypeToSingular(competition.comp_type_id)} has been cancelled.
                        </div>
                    </div>
                </div>)}


        </ExpandableBox>
    }

    return null;

}


import classNames from "classnames";
import React from "react";
import {getStyles} from "src/variables/DropVariables";

interface iSelectOptions extends React.SelectHTMLAttributes<HTMLSelectElement> {
    label: undefined | string,
    options: React.OptionHTMLAttributes<HTMLOptionElement>[]
}

export default function Select(props: iSelectOptions) {

    const options = props.options

    const dig = getStyles()

    return <div className={classNames(dig.formRow, dig.validateRequired)}
                style={{margin: "1em 0"}}>
        {props.label && <div className={dig.row}>
            <div className={dig.col12}>
                <label className={classNames(dig.digHighlight2)}>
                    <p>{props.label}&nbsp;
                        <abbr className={"required"} title="required">*</abbr>
                    </p>
                </label>
            </div>
        </div>}
        <div className={dig.row}>
            <div className={dig.col12}>
                <select {...props}
                        style={{color: "black", height: '2em', width: "100%", border:"2px solid var(--dig-light-blue)"}}>
                    {options.map((optionProps, index) => {
                        return <option
                            key={index} {...optionProps}>{optionProps.label || optionProps.value}</option>
                    })}

                </select>
            </div>
        </div>
    </div>
}

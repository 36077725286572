import classNames from "classnames";
import React, {createRef} from "react";
import {faComment} from "@fortawesome/free-solid-svg-icons";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import TranslateText from "src/api/hoc/TranslateText";
import DropVariables, {getStyles} from "src/variables/DropVariables";
import isPRO from "src/variables/isPRO";
//import {COMMUNITY_PATH} from "src/views/Community/Community";
import {GAMES_PATH} from "src/views/Games/Games";
import {INTEL_PATH} from "src/views/Intel/Intel";
import {MATCHES_PATH} from "src/views/Matches/Matches";
import {PARENTAL_PORTAL_PATH} from "src/views/ParentalPortal/ParentalPortal";
import {STORE_PATH} from "src/views/Store/Store";
import {TOURNAMENTS_PATH} from "src/views/Tournaments/Tournaments";
import {Link} from "react-router-dom";

// @link https://dev.to/abachi/how-to-change-svg-s-color-in-react-42g2
import {ReactComponent as ComSVG} from "src/assets/img/svg/DIG-logo-lockup-beta-horizontal-white.svg"
import {ReactComponent as MobileLogo} from "src/assets/img/new-mobile-logo.svg"
import {ReactComponent as GgSVG} from "src/assets/img/svg/DIG-logo-lockup-horizontal-white-GG.svg"
import logout from "src/api/hoc/logout"
import HeaderStyles from "./style.module.scss"
import {eGLOBAL_CHAT_TABS} from "../Chat/Chat";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Bootstrap from "../../Bootstrap";
import UserBarMobile from "./UserBar/UserBarMobile";
import isGG from "src/variables/isGG";
import UserBar from "./UserBar/UserBar";
import isLoggedIn from "src/variables/isLoggedIn";
import {eCOMPETITION, eCOMPETITION_STATUS} from "../../variables/sharedInterfaces";
import CompetitionSort from "../../api/hoc/CompetitionSort";


// CSS
interface iHeaderState {
    alert: any,
    loginPopup: boolean,
    loggingIn: boolean,
    toggleMenu: boolean,
    toggleSubMenu: boolean
}

export default class Header extends React.Component<{}, iHeaderState> {

    wrapperRef = createRef<HTMLDivElement>();

    constructor(props) {
        super(props);

        this.state = {
            alert: null,
            loginPopup: false,
            loggingIn: false,
            toggleMenu: false,
            toggleSubMenu: false,
        }

    }

    componentDidMount() {
        document.body.addEventListener('mousedown', this.handleMenuClose)
    }

    componentWillUnmount() {
        document.body.removeEventListener("mousedown", this.handleMenuClose)
    }

    /*
    * Handle mobile close when clicking outside
    * */
    handleMenuClose = (e) => {
        if (
            this.wrapperRef.current &&
            !this.wrapperRef.current.contains(e.target)
        ) {
            this.setState({
                toggleMenu: false
            })

        }
    }

    dig = getStyles(HeaderStyles)

    styleNavItem = classNames(this.dig.navItem, this.dig.py1, this.dig.pyLg4)

    styleNavLink = classNames(this.dig.navLink, this.dig.h100, this.dig.w100)

    headerButton = ({text, url, onClick, blank = false}: {
        text: string,
        url: string,
        blank?: boolean,
        onClick?: () => void
    }) =>
        <div
            className={classNames(this.styleNavItem)}>
            {true === blank
                ? <a href={url} target={'blank'} className={this.styleNavLink}>
                    {text}
                </a>
                : <Link className={this.styleNavLink}
                        onClick={() => {
                            onClick?.()
                        }}
                        to={url}>
                    {text}
                </Link>}
        </div>
    dropdownItem = ({text, url}: { text: string, url: string }) => <div
        className={classNames(this.dig.dropdownItem, this.dig.dropdownItem)}>
        <Link to={url}>
            <TranslateText>
                {text}
            </TranslateText>
        </Link>
    </div>

    render() {

        const dig = this.dig;
        const {id, bootstrap, competitions} = Bootstrap.bootstrap.state;
        // const bootstrap = Bootstrap.bootstrap
        const currentUser = getCurrentLoggedInUser();
        const chatClosed = bootstrap.state.rightSideBarTabOpened === eGLOBAL_CHAT_TABS.NONE

        const showParentTab = isGG()
            && Array.isArray(currentUser?.children)
            && currentUser?.children?.length !== 0;

        const showDashboardTab = currentUser?.user_is_admin === true;

        const notificationCounts = {
            invitationCount: Bootstrap.bootstrap.state.invitations?.filter(invitation => invitation.user_id === currentUser?.ID)?.length ?? 0,
            tournamentCount: competitions?.filter(competition =>
                competition.comp_type_id === eCOMPETITION.TOURNAMENTS &&
                competition.registered_users?.includes(id))
                .sort(CompetitionSort.sortCompetitionsDateTime)
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.WAITING))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.CANCELED))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.COMPLETED))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.PAID))
                .length ?? 0,
            bracketCount: competitions?.filter(competition =>
                competition.comp_type_id === eCOMPETITION.BRACKETS &&
                competition.registered_users?.includes(id))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.WAITING))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.CANCELED))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.COMPLETED))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.PAID))
                .length ?? 0,
            matchCount: competitions?.filter(competition =>
                competition.comp_type_id === eCOMPETITION.MATCHES &&
                competition.registered_users?.includes(id) &&
                competition.parent_comp === 0)
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.CANCELED))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.COMPLETED))
                .sort(CompetitionSort.sortCompetitionStatusLast(eCOMPETITION_STATUS.PAID))
                .length ?? 0

        }

        console.log(notificationCounts)

        return <>
            <nav
                className={classNames(dig.navbar, dig.w100, dig.pyLg0, (this.state.toggleMenu ? dig.subOpened : dig.subClosed))}>
                <div ref={this.wrapperRef} className={classNames(dig.container, dig.p0, dig.h100)}>
                    {/* Logo */}
                    <Link to="/"
                          className={classNames(
                              dig.navbarBrand,
                              dig.dLgBlock,
                              dig.dNone,
                              dig.pLg0,
                              dig.floatStart
                          )}>
                        {isGG()
                            ? <GgSVG style={{width: "155px", display: "flex", fill: "white !important"}}/>
                            : <ComSVG style={{width: "204px", display: "flex", fill: "white !important"}}/>}
                    </Link>
                    {/* mobile menu */}
                    <div className={classNames(
                        dig.dFlex,
                        dig.justifyContentBetween,
                        dig.dLgNone,
                        dig.w100,
                        dig.px2,
                        dig.alignItemsCenter
                    )}>
                        <button
                            onClick={() => this.setState({
                                toggleMenu: !this.state.toggleMenu,
                                toggleSubMenu: false
                            })}
                            className={classNames(dig.collapsed, dig.border0, dig.bgTransparent)}
                            type="button"
                            data-dig-toggle="collapse"
                            data-dig-target="#navbarCollapse" aria-expanded="false">
                            <i className={classNames((this.state.toggleMenu ? "fas fa-xmark" : "fas fa-bars"), dig.textWhite, dig.fontSize2Em)}/>
                        </button>
                        <Link className={classNames(
                            dig.navbarBrand, dig.dLgNone, dig.m0, dig.p0, dig.orderLg1, dig.dBlock
                        )} to='/'>

                            <MobileLogo style={{width: "40px", fill: "white !important"}}/>
                        </Link>


                        <div className={classNames(this.styleNavItem)}
                             onClick={() => {
                                 bootstrap.setState({
                                     rightSideBarTabOpened: chatClosed ? eGLOBAL_CHAT_TABS.CHAT : eGLOBAL_CHAT_TABS.NONE
                                 })
                             }}>
                            <FontAwesomeIcon icon={faComment} size={'2x'}
                            />
                        </div>
                    </div>

                    {/*\ mobile menu */}

                    <div className={classNames(
                        ...(this.state.toggleMenu ? [dig.dBlock] : [dig.dNone, dig.dLgBlock]), dig.navbarNavWrapper, dig.g0
                    )}>
                        <div className={classNames(
                            dig.navbarNav,
                            dig.dFlex, dig.flexLgRow, dig.justifyContentEnd,
                            dig.pLg0, dig.alignItemsCenter, dig.g0
                        )}>

                            {this.headerButton({
                                text: 'About',
                                url: "https://www.dropingaming.co/",
                                blank: true,
                            })}

                            {this.headerButton({
                                text: 'Games',
                                url: "/" + GAMES_PATH + "/",
                                onClick: () => this.setState({toggleMenu: false})
                            })}

                            <div className={classNames(
                                dig.navItem,
                                dig.dropdown,
                            )}>
                                <div
                                    className={classNames(dig.dFlex, dig.w100, dig.justifyContentBetween, dig.alignItemsCenter)}>
                                    {this.headerButton({
                                        text: 'Tournaments',
                                        url: "/" + TOURNAMENTS_PATH + "/",
                                        onClick: () => this.setState({toggleMenu: false})
                                    })}

                                    <i
                                        onClick={() => this.setState({
                                            toggleSubMenu: !this.state.toggleSubMenu
                                        })}
                                        className={classNames('fas fa-caret-down', dig.textWhite, dig.me2)}/>
                                </div>


                                <div
                                    className={classNames(...(this.state.toggleSubMenu ? [dig.dBlock] : [dig.dNone, dig.dLgBlock]), dig.subMenu)}>

                                    {this.dropdownItem({
                                        text: 'All Tournaments',
                                        url: "/" + TOURNAMENTS_PATH + "/"
                                    })}

                                    {this.dropdownItem({
                                        text: 'Free',
                                        url: "/tournaments/free/"
                                    })}

                                    {this.dropdownItem({
                                        text: 'Special',
                                        url: "/tournaments/special/"
                                    })}

                                    {this.dropdownItem({
                                        text: 'Partnerships',
                                        url: "/categories/partnerships/"
                                    })}

                                </div>
                            </div>

                            {this.headerButton({
                                text: 'Matches',
                                url: "/" + MATCHES_PATH + "/",
                                onClick: () => this.setState({toggleMenu: false})
                            })}

                            {/* TODO: restore this when it is time
                                this.headerButton({
                                text: 'Community',
                                url: "/" + COMMUNITY_PATH + "/",
                                onClick: () => this.setState({toggleMenu: false})
                            })*/}

                            {this.headerButton({
                                text: 'Intel',
                                url: "/" + INTEL_PATH + "/",
                                onClick: () => this.setState({toggleMenu: false})
                            })}

                            {DropVariables.appLocal && this.headerButton({
                                text: 'Merch',
                                url: "/" + STORE_PATH + "/",
                                onClick: () => this.setState({toggleMenu: false})
                            })}

                            {undefined !== currentUser
                                ? <>
                                    {showParentTab && this.headerButton({
                                        text: 'Parent',
                                        url: "/" + PARENTAL_PORTAL_PATH + "/"
                                    })}

                                    {showDashboardTab && this.headerButton({
                                        text: 'Admin',
                                        url: (window.location.port === '3000') ? "//local.dropingaming.com:8080/wp-admin/" : "/wp-admin/",
                                        blank: true
                                    })}
                                    <div
                                        className={classNames(this.styleNavItem, dig.logout, dig.dFlex, dig.justifyContentStart, dig.alignItemsCenter)}>

                                        {this.headerButton({
                                            text: 'Log Out',
                                            url: "/",
                                            onClick: () => logout()
                                        })}

                                    </div>

                                    <div className={classNames(dig.dLgNone, dig.vh100, dig.w100, dig.bgNeutral2)}>
                                        <UserBarMobile notificationCounts={notificationCounts}/>
                                    </div>
                                </>
                                : <>
                                    <div className={classNames(this.styleNavItem, dig.dNone, dig.dLgBlock, dig.login)}>
                                        <a onClick={() => {
                                            bootstrap.setState({loginPopup: true})
                                        }} className={classNames(this.styleNavLink)}>
                                            Log In
                                        </a>
                                    </div>
                                    <div className={classNames(this.styleNavItem, dig.dNone, dig.dLgBlock, dig.join)}>
                                        <a className={classNames(this.styleNavLink, dig.register)}
                                           onClick={() => {
                                               bootstrap.setState({joinPopup: true})
                                           }}>
                                            Join
                                        </a>
                                    </div>
                                </>}
                            {(false === isPRO() || currentUser?.isParent) &&
                                <div className={classNames(this.styleNavItem, dig.pt4, dig.dNone, dig.dLgFlex)}
                                     onClick={() => {

                                         bootstrap.setState({
                                             rightSideBarTabOpened: chatClosed ? eGLOBAL_CHAT_TABS.CHAT : eGLOBAL_CHAT_TABS.NONE
                                         })

                                     }}>
                                    <FontAwesomeIcon className={classNames(dig.fontAwesomeIcon)}
                                                     icon={faComment}
                                                     size={'xl'}
                                    />
                                </div>}
                        </div>
                        {
                            undefined !== currentUser ?
                                null :
                                <div className={dig.dLgNone}>
                                    <ul className={classNames(dig.navbarNav, dig.dFlex,
                                        dig.flexRow, dig.alignItemsCenter,
                                        dig.justifyContentStart, dig.px3
                                    )}>
                                        <li className={classNames(dig.navItem, dig.p0, dig.textCenter)}>
                                            <div className={classNames(dig.navLink, dig.login)}
                                                 onClick={() => {
                                                     bootstrap.setState({loginPopup: true})
                                                 }}
                                            >
                                                Log In
                                            </div>
                                        </li>
                                        <li className={classNames(dig.navItem, dig.p0, dig.textCenter)}>
                                            <div className={classNames(dig.navLink, dig.join)}
                                                 onClick={() => {
                                                     Bootstrap.bootstrap.setState({joinPopup: true})
                                                 }}>
                                                Join
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                        }
                    </div>


                </div>
            </nav>


            <div className={classNames({
                [dig.colMd3]: false === isLoggedIn(),
                [dig.userActionTray]: true === isLoggedIn()
            })}>
                <UserBar notificationCounts={notificationCounts}/>
            </div>
        </>;

    }

}


import classNames from "classnames";
import {Link} from "react-router-dom";
import Bootstrap from "src/Bootstrap";
import Loading from "src/components/Loading/Loading";
import ProfilePicture from "src/components/ProfilePicture/ProfilePicture";
import {getStyles} from "src/variables/DropVariables";
import DropVariables from "src/variables/DropVariables";
import {eCOMPETITION_STATUS, iCompetitions, iUser} from "src/variables/sharedInterfaces";
import {TEAM_PATH} from "src/views/Team/Team";
import deleteTeamRoster from "src/api/rest/deleteTeamRoster";
import postTeammateStake from "src/api/rest/postTeammateStake";
import compTypeToSingular from "src/api/hoc/compTypeToSingular";
import style from './style.module.scss';
import SearchUser from "src/components/SearchUser/SearchUser";
import {availableStreamingPlatforms} from "../Modals/JoinWithTeam";
import DropDown from "src/components/DropDown/DropDown";
import Input from "../../../components/Input/Input";
import {useState} from "react";
import putRegistration from "../../../api/rest/putRegistration";
import {WatchInputElementChangeEvent} from "../../../components/Timeout/Timeout";
import {addAlert, iAlertButtonOptions} from "../../../components/Alert/Alert";
import {getMetaValue} from "../../../api/hoc/getMetaValue";
import getCurrentLoggedInUser from "../../../api/hoc/getCurrentLoggedInUser";

interface iShowRoster {
    competition: iCompetitions,
    teamId: number,
    canEdit?: boolean
}


// For clarification: this is roster at the TOP of tournaments/matches. Not the queue/registered view at the bottom (although it is being used for both at the moment)
export default function Roster(props: iShowRoster) {



    console.log('Roster.tsx RENDER', props)

    const {competition, teamId} = props;


    const canEdit = props.canEdit ?? false;

    const {teams, registrations, invitations, id, users} = Bootstrap.bootstrap.state;


    const myRegistration = registrations?.find(registration =>
        registration.competitions_id === competition.comp_id &&
        registration.user_id === id)?.streaming_platform;

    let myTwitchStreamingPlatform, myYouTubeStreamingPlatform, myFacebookStreamingPlatform, myKickStreamingPlatform;

    const currentUser = getCurrentLoggedInUser();

    if (currentUser !== undefined) {
        myTwitchStreamingPlatform = getMetaValue(currentUser, 'gamertag-twitch').value;
        myYouTubeStreamingPlatform = getMetaValue(currentUser, 'gamertag-youtube').value;
        myFacebookStreamingPlatform = getMetaValue(currentUser, 'gamertag-facebook').value;
        myKickStreamingPlatform = getMetaValue(currentUser, 'gamertag-kick').value;
    }


    const [updatedStreamingPlatform, setUpdatedStreamingPlatform] = useState<string | undefined>(myRegistration);


    const team = teams?.find(team =>
        team.user_team_id === teamId
    )


    if (undefined === team ||
        undefined === registrations ||
        undefined === invitations) {

        return <Loading message={"Loading Roster"} count={4}/>

    }

    const teamManager = team.uid;

    const imTeamManager = teamManager === id;

    const acceptedList: number[] = [];

    const awaitingAcceptanceList: number[] = [];

    registrations
        ?.filter(registration => registration.team_id === team.user_team_id && registration.competitions_id === competition.comp_id)
        .forEach(registration => registration.user_id && acceptedList.push(registration.user_id))

    team.rosterDetails?.forEach(roster => roster.uid && (acceptedList.includes(roster.uid) || awaitingAcceptanceList.push(roster.uid)));

    // technically only includes registered, but this will make more sense for plugging into the view
    let rosterMembers = users?.filter(user => acceptedList.includes(user.ID)) || []

    const rosterReady = competition.team_size === acceptedList.length;

    const currentInvitations = invitations?.filter(invite => invite.team_id === teamId && invite.comp_id === competition.comp_id) ?? []

    const rosterWaitingAcceptance = false === rosterReady && competition.team_size === acceptedList.length + awaitingAcceptanceList.length;

    const dig = getStyles(style)

    // const customStreamingMeta = competition.competitionsMeta?.find(meta => meta.meta_key === '_streamer_url')

    // const streamingPlatforms = competition.competitionsMeta?.streaming_platforms?.split(',') || []

    // meta key - gamertag-twitch

    // const customUserStreamingMeta = users?.find(user => user.ID === id)?.userMeta?.find(meta => meta.meta_key === 'gamer_tag_twitch')

    let borderColor, btnColor;

    if (rosterReady) {

        borderColor = dig.borderSuccess

        // borderColor = 'dig-border-yellow' dig-bg-orange
        btnColor = dig.digBtnGreen

    } else {

        if (rosterWaitingAcceptance) {

            borderColor = dig.digBorderYellow

            btnColor = dig.digBgLightYellow

        } else {

            borderColor = dig.digBorderLightRed

            btnColor = dig.digBtnLightRed

        }

    }

    const isWaiting = eCOMPETITION_STATUS.WAITING === competition.status;


    const updatedStreamingURL = (streaming_platform, registration) => {

        console.log('streaming_platform',streaming_platform)

        putRegistration({
            comp_registration_id: registration.comp_registration_id ? registration.comp_registration_id : 0,
            vendor_gamertag: registration.vendor_gamertag,
            streaming_platform: streaming_platform,
            streaming_url:
                streaming_platform === 'Twitch'
                    ? myTwitchStreamingPlatform
                    : streaming_platform === 'YouTube'
                        ? myYouTubeStreamingPlatform
                        : streaming_platform === 'Facebook'
                            ? myFacebookStreamingPlatform
                            : streaming_platform === 'Kick'
                                ? myKickStreamingPlatform
                                : null
        });
    }


    return <>
        <div className={classNames(dig.mt2)}>
            <div className={classNames(dig.card, dig.rounded0, dig.mb3, borderColor)}>
                <div
                    className={classNames(
                        dig.cardHeader, dig.border0, dig.rounded0, dig.dFlex, dig.justifyContentBetween,
                        dig.alignItemsCenter, btnColor
                    )}>
                    <h4 className={classNames(dig.mb0, dig.cardTitle, dig.textWhite, dig.alignItemsStart, dig.textStart)}>
                        <i className={rosterReady ? 'far fa-check-circle mr-2' : 'fas fa-exclamation-triangle mr-2"'}></i>
                        {rosterReady ? ' Roster Ready - ' : ' Roster Pending -'} {team.team_name}
                    </h4>
                    <h5 className={classNames(dig.dLgBlock, dig.dNone, "float-right m-0")}>
                        <Link to={"/" + TEAM_PATH + "/" + team.user_team_id + "/"}>
                            Team Profile
                            <i className={classNames("fas fa-angle-right", dig.ms1)}></i>
                        </Link>
                    </h5>
                </div>
                <div className={classNames(
                    dig.cardBody, dig.tableResponsive, dig.digTableResponsive, dig.digBgNeutral2
                )}>
                    <table
                        className={classNames(
                            dig.table, dig.digBgNeutral2, dig.m0, dig.tableBorderless, dig.textWhite
                        )}>
                        <thead>
                        <tr>
                            <th scope={dig.col}>ROSTER</th>
                            <th scope={dig.col}>GAMERTAG</th>
                            <th scope={dig.col}>STREAMING</th>
                            <th scope={dig.col}>STATUS</th>
                            <th scope={dig.col}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {rosterMembers.map((user, index) => {

                            // now with gamertag built in like the Ice Road Truckers intended
                            const registration = registrations?.find(registration => registration.user_id === user.ID && registration.competitions_id === competition.comp_id);

                            // if acceptance is 0, there is no registration
                            if (undefined === registration) {
                                return <tr key={index}>Loading...</tr>
                            }

                            return <tr key={index}>
                                <td className={dig.alignMiddle}>
                                    <div className={classNames(
                                        dig.dFlex, dig.alignItemsCenter, dig.flexWrap
                                    )}>
                                        <div className={dig.me3}>
                                            <ProfilePicture user={user} width={25} height={25}/>
                                        </div>
                                        <div className={classNames(dig.dFlex, dig.justifyContentBetween, dig.flexWrap)}>
                                            <span className={dig.me2}>{user.display_name}</span>
                                            {user.ID === teamManager &&
                                                <span className={classNames(dig.badge, dig.bgNeutral6, dig.p2)}>
                                                    Manager
                                                </span>}
                                        </div>
                                    </div>
                                </td>
                                <td className={dig.alignMiddle}>
                                    {registration?.vendor_gamertag ?? ''}
                                </td>
                                <td className={dig.alignMiddle}>
                                    {/* if im logged in show dropdown or just show link (red watch live button), check if teammates have selected a streaming platform*/}

                                    {(canEdit && id === user.ID)
                                        // this line removes the ability to change streaming platforms after the competition has started
                                        // && ((competition.datetime ? Date.parse(competition.datetime) : 0) > Date.now()))
                                        &&


                                        <aside>
                                            <DropDown
                                                defaultValue={registration?.streaming_platform ? registration?.streaming_platform : 'none'}
                                                options={availableStreamingPlatforms}
                                                onChange={(value) => {
                                                    setUpdatedStreamingPlatform(value);

                                                    /*if (value) {
                                                        switch (value) {
                                                            case 'Twitch':
                                                                if (myTwitchStreamingPlatform !== '') {
                                                                    updatedStreamingURL(value, registration);
                                                                }
                                                                break;
                                                            case 'YouTube':
                                                                if (myYouTubeStreamingPlatform !== '') {
                                                                    updatedStreamingURL(value, registration);
                                                                }
                                                                break;
                                                            case 'Facebook':
                                                                if (myFacebookStreamingPlatform !== '') {
                                                                    updatedStreamingURL(value, registration);
                                                                }
                                                                break;
                                                            default:
                                                                updatedStreamingURL('', registration);
                                                                break;
                                                        }
                                                    }*/
                                                }}
                                            />


                                            {(updatedStreamingPlatform) && <div>

                                                {/*input field for adding a streaming url if option other than none is selected*/}
                                                {(() => {
                                                        const regexStreamingURL = new RegExp('^(https://www\.(twitch\.tv|youtube\.com|facebook\.com|kick\.com))/([a-zA-Z0-9!@#$%^&*()_\/-]+)$');
                                                        console.log('updatedStreamingPlatform', updatedStreamingPlatform)

                                                        switch (updatedStreamingPlatform) {
                                                            case 'Twitch':
                                                                return <Input
                                                                    value={myTwitchStreamingPlatform !== '' ? myTwitchStreamingPlatform : 'https://www.twitch.tv/'}
                                                                    onChange={(event) => {
                                                                        console.log(event.target.value)

                                                                        WatchInputElementChangeEvent(event, () => {
                                                                            console.log(event.target.value)

                                                                            if (false === event.target.value.startsWith('https://www.twitch.tv/')) {
                                                                                const alertButton: iAlertButtonOptions[] = [
                                                                                    {
                                                                                        text: "I understand",
                                                                                        value: 'cancel',
                                                                                        className: "gg__swal_cancel_btn",
                                                                                        color: "danger",
                                                                                    }];
                                                                                addAlert({
                                                                                    title: "Bad URL, Bad!",
                                                                                    text: "Please make sure your URL starts with 'https://www.twitch.tv/'",
                                                                                    icon: "warning",
                                                                                    dangerMode: true,
                                                                                    buttons: alertButton,
                                                                                })
                                                                                event.target.value = 'https://www.twitch.tv/'
                                                                                return false

                                                                            }

                                                                            if (regexStreamingURL.test(event.target.value)) {
                                                                                //setUpdatedStreamingPlatform('Twitch')
                                                                                myTwitchStreamingPlatform = event.target.value;
                                                                                updatedStreamingURL('Twitch', registration)

                                                                            }
                                                                            return true
                                                                        })
                                                                    }}/>
                                                            case 'YouTube':
                                                                return <Input
                                                                    value={myYouTubeStreamingPlatform !== '' ? myYouTubeStreamingPlatform : 'https://www.youtube.com/'}
                                                                    onChange={(event) => {

                                                                        WatchInputElementChangeEvent(event, () => {
                                                                            console.log(event.target.value)

                                                                            if (false === event.target.value.startsWith('https://www.youtube.com/')) {
                                                                                const alertButton: iAlertButtonOptions[] = [
                                                                                    {
                                                                                        text: "I understand",
                                                                                        value: 'cancel',
                                                                                        className: "gg__swal_cancel_btn",
                                                                                        color: "danger",
                                                                                    }];
                                                                                addAlert({
                                                                                    title: "Bad URL, Bad!",
                                                                                    text: "Please make sure your URL starts with 'https://www.youtube.com/'",
                                                                                    icon: "warning",
                                                                                    dangerMode: true,
                                                                                    buttons: alertButton,
                                                                                })
                                                                                event.target.value = 'https://www.youtube.com/'
                                                                                return false

                                                                            }
                                                                            if (regexStreamingURL.test(event.target.value)) {
                                                                                //setUpdatedStreamingPlatform('YouTube')
                                                                                myYouTubeStreamingPlatform = event.target.value;
                                                                                updatedStreamingURL('YouTube', registration)

                                                                            }
                                                                            return true
                                                                        })
                                                                    }}/>
                                                            case 'Facebook':
                                                                return <Input
                                                                    value={myFacebookStreamingPlatform !== '' ? myFacebookStreamingPlatform : 'https://www.facebook.com/'}
                                                                    onChange={(event) => {

                                                                        WatchInputElementChangeEvent(event, () => {
                                                                            console.log(event.target.value)

                                                                            if (false === event.target.value.startsWith('https://www.facebook.com/')) {
                                                                                const alertButton: iAlertButtonOptions[] = [
                                                                                    {
                                                                                        text: "I understand",
                                                                                        value: 'cancel',
                                                                                        className: "gg__swal_cancel_btn",
                                                                                        color: "danger",
                                                                                    }];
                                                                                addAlert({
                                                                                    title: "Bad URL, Bad!",
                                                                                    text: "Please make sure your URL starts with 'https://www.facebook.com/'",
                                                                                    icon: "warning",
                                                                                    dangerMode: true,
                                                                                    buttons: alertButton,
                                                                                })
                                                                                event.target.value = 'https://www.facebook.com/'
                                                                                return false

                                                                            }
                                                                            if (regexStreamingURL.test(event.target.value)) {
                                                                                //setUpdatedStreamingPlatform('Facebook')
                                                                                myFacebookStreamingPlatform = event.target.value;
                                                                                updatedStreamingURL('Facebook', registration)

                                                                            }
                                                                            return true
                                                                        })
                                                                    }}/>
                                                            case 'Kick':
                                                                return <Input
                                                                value={myKickStreamingPlatform !== '' ? myKickStreamingPlatform : ''}
                                                                onChange={(event) => {
                                                                    WatchInputElementChangeEvent(event, () => {
                                                                        console.log(event.target.value)

                                                                        if (false === event.target.value.startsWith('https://www.kick.com/')) {
                                                                            const alertButton: iAlertButtonOptions[] = [
                                                                                {
                                                                                    text: "I understand",
                                                                                    value: 'cancel',
                                                                                    className: "gg__swal_cancel_btn",
                                                                                    color: "danger",
                                                                                }];
                                                                            addAlert({
                                                                                title: "Bad URL, Bad!",
                                                                                text: "Please make sure your URL starts with 'https://www.kick.com/'",
                                                                                icon: "warning",
                                                                                dangerMode: true,
                                                                                buttons: alertButton,
                                                                            })
                                                                            event.target.value = 'https://www.kick.com/'
                                                                            return false

                                                                        }

                                                                        if (regexStreamingURL.test(event.target.value)) {
                                                                            //setUpdatedStreamingPlatform('Facebook')
                                                                            myKickStreamingPlatform = event.target.value;
                                                                            updatedStreamingURL('Kick', registration)

                                                                        }
                                                                        return true
                                                                    })

                                                                }}
                                                                />
                                                            case 'none':
                                                            default:
                                                                // WatchInputElementChangeEvent( event,() => {
                                                                //     console.log(event.target.value)
                                                                //     updatedStreamingURL('none', registration)
                                                                // });
                                                                return ''
                                                        }
                                                    }
                                                )()}
                                            </div>}
                                        </aside> || (
                                            (registration.streaming_platform === 'none' || null === registration.streaming_url || undefined === registration.streaming_url || '' === registration.streaming_url) ?
                                                <div
                                                    className={classNames(dig.badge, dig.digBtnRed, dig.justifyContentCenter)}>
                                                    NOT STREAMING
                                                </div>
                                                :
                                                <div
                                                    className={classNames(dig.badge, dig.digBtnGreen, dig.justifyContentCenter)}>
                                                    <a target="_blank" rel="noreferrer" href={
                                                        registration.streaming_url
                                                    }> WATCH LIVE </a>
                                                </div>
                                        )
                                    }

                                </td>
                                <td className={dig.alignMiddle}>
                                    <div className={classNames(
                                        dig.dFlex, dig.alignItemsCenter
                                    )}>
                                        <div
                                            className={classNames(
                                                dig.badge, dig.rounded0, dig.cursorDefault, dig.digBtnGreen, dig.fwBold
                                            )}>
                                            <i className={classNames("fas fa-check", dig.digColorNeutral2)}></i>
                                            <div
                                                className={classNames(dig.registeredStatus, dig.w100, dig.pe1, dig.digColorNeutral2)}>
                                                Registered
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className={dig.alignMiddle}>
                                    {imTeamManager && isWaiting && user.ID !== teamManager &&
                                        <button title="Remove from roster" data-header="Remove"
                                                className={classNames(dig.btn, dig.digBtnRed)}
                                                onClick={() => {
                                                    deleteTeamRoster({
                                                        uid: user.ID,
                                                        user_team_id: team.user_team_id,
                                                        comp_id: competition.comp_id
                                                    })
                                                }}>
                                            <i className={classNames("fas fa-trash-alt text-white")}></i>
                                        </button>}
                                </td>
                            </tr>
                        })}

                        {awaitingAcceptanceList.map((userID, key) => {

                            const user: (iUser | undefined) = users?.find(user => user.ID === userID);
                            const invite = currentInvitations.find(invitation => invitation.user_id === userID);

                            if (undefined === user ||
                                DropVariables.objectLoading(user)) {
                                return;
                            }

                            return <tr key={key}>
                                <td className={dig.alignMiddle}>
                                    <div className={classNames(dig.dFlex, dig.alignItemsCenter, dig.flexWrap)}>
                                        <div className={dig.me3}>
                                            <ProfilePicture user={user}/>
                                        </div>
                                        <div className={classNames(dig.dFlex, dig.justifyContentBetween, dig.flexWrap)}>
                                            <div className={dig.me2}>
                                                {user.display_name}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td className={dig.alignMiddle}>
                                </td>
                                <td className={dig.alignMiddle}>
                                </td>
                                <td className={dig.alignMiddle}>
                                    <div className={classNames(dig.dFlex, dig.alignItemsCenter)}>
                                        <div
                                            className={classNames(dig.badge, dig.rounded0, dig.cursorDefault, dig.digBgLightYellow, dig.fwBold, dig.textDark)}>
                                            <i className={classNames("fas fa-flag dig-color-neutral2 pending-icon")}></i>
                                            <div className={dig.pendingStatus}>Pending</div>
                                        </div>
                                        {canEdit && invite?.paid_by === undefined && invite?.sender_id === id && parseFloat(competition?.buy_in || '0') > 0 &&
                                            <div
                                                className={classNames(dig.btn, dig.rounded0, dig.cursorPointer, dig.digBtnGreen, dig.fwBold)}
                                                style={{marginLeft: "1rem"}}>
                                                <div onClick={(e) => {
                                                    e.preventDefault();
                                                    postTeammateStake({
                                                        user_id: user.ID,
                                                        buyer_id: id,
                                                        amount: '-' + competition.buy_in,
                                                        action: 'comp_id: ' + competition.comp_id,
                                                        comment: compTypeToSingular(competition.comp_type_id).toUpperCase() + ' BUY-IN (TEAMMATE)'
                                                    });
                                                }}>Pay for Player
                                                </div>
                                            </div>}
                                    </div>
                                </td>
                                {canEdit && (imTeamManager || user.ID === id) &&
                                    <td className={dig.alignMiddle}>
                                        {(undefined !== invite?.invitation_id)
                                            ? <button title="Remove from roster"
                                                      className={classNames(dig.btn, dig.digBtnRed)}
                                                      onClick={() => {
                                                          let data = {
                                                              uid: user.ID,
                                                              user_team_id: team.user_team_id
                                                          };

                                                          if (!imTeamManager) {
                                                              data['invitation_id'] = invite?.invitation_id;
                                                          }

                                                          deleteTeamRoster(data);
                                                      }}>
                                                <i className={classNames("fas fa-trash-alt text-white")}></i>
                                            </button>
                                            : <button title="Remove from roster"
                                                      className={classNames(dig.btn, dig.digBtnRed)}
                                                      onClick={() => {
                                                          deleteTeamRoster({
                                                              uid: user.ID,
                                                              user_team_id: team.user_team_id,
                                                              comp_id: competition.comp_id
                                                          })

                                                      }}>
                                                <i className={classNames("fas fa-sync-alt text-white")}></i>
                                            </button>}
                                    </td>}
                            </tr>
                        })}
                        <tr className={"invite-pending"}></tr>
                        </tbody>

                    </table>


                </div>

                {canEdit && imTeamManager &&
                    <div
                        className={classNames(btnColor, dig.rounded0,
                            dig.cardFooter, dig.border0, dig.dFlex, dig.justifyContentBetween, dig.alignItemsCenter
                        )}>
                        <h5 className={classNames(dig.floatEnd, dig.m0)}>
                            * Changes made here will affect your permanent roster created in the
                            <Link to={"/" + TEAM_PATH + "/" + team.user_team_id + "/"}> <b>team profile.</b></Link>
                        </h5>
                    </div>}

            </div>
        </div>

        {canEdit && imTeamManager && false === rosterReady &&
            competition.team_size !== acceptedList.length + awaitingAcceptanceList.length &&
            <SearchUser
                className={classNames(dig.positionRelative)}
                btnClassName={classNames(dig.digBgOrange)}
                teamId={teamId}
                comp_id={competition.comp_id}/>
        }

    </>


}


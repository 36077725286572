import {useEffect} from 'react';
import {useLocation} from "react-router-dom";
import ScrollIntoViewDirective from "src/api/hoc/scrollIntoView";
import {getStyles} from "src/variables/DropVariables";

function ScrollToHere() {

    const location = useLocation();

    let mainStart: Element | null = null;

    useEffect(() => {
        mainStart && ScrollIntoViewDirective(mainStart)
    }, [location]);

    const dig = getStyles()

    return <div className={dig.hiddenAnchor}
                ref={(el) => {
                    mainStart = el;
                }}>
    </div>;
}

export default ScrollToHere;
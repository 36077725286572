import classNames from "classnames";
import React from "react";
import objectIsLoading from "src/api/hoc/objectIsLoading";
import getCompetitions from "src/api/rest/getCompetitions";
import Bootstrap from "src/Bootstrap";
import {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, eCOMPETITION_TIMING, iCompetitions} from "src/variables/sharedInterfaces";
import {Link} from "react-router-dom";
import CompetitionGrid from "src/components/CompetitionGrid/CompetitionGrid";
import Loading from "src/components/Loading/Loading";
import {WithRouter} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";

//CSS
import styles from "./style.module.scss";
import moment from "moment";

export const TOURNAMENTS_PATH = 'tournaments';

export default class Tournaments extends React.Component<WithRouter, {
    joinPopup: boolean
}> {
    constructor(props) {
        super(props);
        this.state = {
            joinPopup: true
        }
    }

    componentDidMount() {

        const {competitions} = Bootstrap.bootstrap.state;

        if (competitions === null) {

            return;

        }

        getCompetitions({
            competitionTypeIds: [2,3,4],
            timing: eCOMPETITION_TIMING.NOW
        });



    }

    render() {


        const {competitions} = Bootstrap.bootstrap.state

        const dig = getStyles(styles)

        const wait = (waitFor: string) => <>
            <div className={dig.container}><Loading message={waitFor}/></div>
        </>


        if (objectIsLoading(competitions)) {

            return wait('Fetching Competitions');

        }

        const category = window.location.pathname || '';

        let competitionsToDisplay: Array<iCompetitions>,
            baseFiltered = competitions?.filter(comp =>
                comp.comp_type_id !== eCOMPETITION.MATCHES &&
                comp.status !== eCOMPETITION_STATUS.CANCELED &&
                comp.status !== eCOMPETITION_STATUS.COMPLETED &&
                comp.status !== eCOMPETITION_STATUS.PAID &&
                moment(comp.datetime).isSameOrAfter(moment())
            ) || [];


        switch (category.toLowerCase()) {
            case '/tournaments/brackets/':
                competitionsToDisplay = baseFiltered.filter(value => value.comp_type_id === eCOMPETITION.BRACKETS) || [];
                break;
            case '/tournaments/free/':
                competitionsToDisplay = baseFiltered.filter(value => value.categories?.toLowerCase()
                    .includes('free')) || [];
                break;
            case '/tournaments/partnerships/':
                competitionsToDisplay = baseFiltered.filter(value => value.categories?.toLowerCase()
                    .includes('partnerships')) || [];

                break;
            case '/tournaments/special/':
                competitionsToDisplay = baseFiltered.filter(value => value.categories?.toLowerCase()
                    .includes('special')) || [];
                break;
            default:
                competitionsToDisplay = baseFiltered;

        }

        const TOURNAMENT_TYPES: string[] = [
            'All Tournaments',
            'Brackets',
            'Free',
            'Special',
            'Partnerships'
        ];

        return <div className={classNames(dig.px4, dig.container, dig.pt5)}>

            <h1>Tournaments</h1>

            <div className={classNames(dig.digUserActionGroup, dig.upcomingEvents, dig.tournamentDashboard)}
                 id="dig-upcoming-matches-selector-group">
                <ul id="myTab" role="tablist"
                    className={classNames(dig.sortMenu, dig.nav, dig.navTabs, dig.navPills, dig.alignItemsCenter, dig.digBgNeutral1, dig.flexSmRow, dig.textCenter, dig.bgLight, dig.border0, dig.roundedNav, dig.mt5)}>

                    {TOURNAMENT_TYPES.map((value, key) => {
                        const URI = value === 'All Tournaments' ? '' : (value + '/');
                        return <li key={key} style={{color: "green"}}
                                   className={classNames(dig.navItem, dig.flexSmFill, dig.m0)}>
                            <Link to={"/tournaments/" + URI} id="tournament-tab" role="tab"
                                  aria-controls={value}
                                  className={classNames(dig.navLink, dig.border0, dig.rounded0, dig.textUppercase, dig.fwBold, (category === ('/tournaments/' + URI) ? dig.active : ""))}>
                                {value}
                            </Link>
                        </li>
                    })}

                </ul>
                <div id="myTabContent" className={"tab-content"}>
                    <div id="tournament" role="tabpanel" aria-labelledby="tournament-tab"
                         className={classNames("filter-container infinite-scroll")}>
                        <div className={classNames(dig.row, dig.justifyContentCenter)}>
                            {/* @link https://stackoverflow.com/questions/2450954/how-to-randomize-shuffle-a-javascript-array */}
                            <CompetitionGrid
                                competitionsToDisplay={competitionsToDisplay}
                                displaySort={false}
                            />
                        </div>
                    </div>
                    <div
                        className={classNames(dig.dNone, dig.justifyContentCenter, dig.mb5, dig.alignItemsCenter, dig.fwNormal)}
                        id="infinite-loading">
                        <span className={dig.spinnerBorder} role="status" aria-hidden="false"/>
                        <span className={"ml-2"}>Loading</span>
                    </div>
                </div>
            </div>
        </div>

    }

}


import classNames from "classnames";
import Bootstrap from "src/Bootstrap";
import ExpandableBox from "src/components/ExpandableBox/ExpandableBox";
import {getStyles} from "src/variables/DropVariables";
import {eCOMPETITION, eCOMPETITION_STATUS, iCompetitions} from "src/variables/sharedInterfaces";
import Roster from "src/views/Competition/Roster/Roster";

import style from 'src/views/Competition/RegisteredTeams/style.module.scss';
import SearchUser from "src/components/SearchUser/SearchUser";

interface iShowRegisteredTeams {
    competition: iCompetitions,
    maxTeams: number,
}

export default function RegisteredTeams(props: iShowRegisteredTeams) {

    console.log('RegisteredTeams', props)

    const {teams, registrations, id} = Bootstrap.bootstrap.state
    const {competition} = props

    const dig = getStyles(style)

    // if matches loads the individual roster team views there. If not, load the registered/queue lists
    if (competition.comp_type_id === eCOMPETITION.MATCHES) {

        competition.incomplete_teams ??= []

        // first, determine which team is the "manager" team (team manager id matches the comp organizer id)
        // matches don't care about queue order, so combine complete and incomplete teams into 1 array
        let bothTeams = competition.incomplete_teams.concat(competition?.complete_teams ?? []),
            isMatchOwner = id === competition.organizer_id,
            ownerRegistration = registrations?.find(registration => registration.competitions_id === competition.comp_id && registration.user_id === competition.organizer_id),
            homeTeamId = ownerRegistration?.team_id,
            awayTeamId: number | undefined = undefined;

        bothTeams.map(teamId => {
            if (homeTeamId === undefined) {
                homeTeamId = teamId;
            } else if (homeTeamId !== teamId) {
                awayTeamId = teamId;
            }
        });

        let homeTeam = teams?.find(team => team.user_team_id === homeTeamId),
            awayTeam = teams?.find(team => team.user_team_id === awayTeamId);

        return <>

            <ExpandableBox header={homeTeam?.team_name  || "Team A"}>
                <div className={dig.colSm12}>
                    {(homeTeam === undefined &&
                        <span>{competition.status === eCOMPETITION_STATUS.CANCELED ? "Nobody joined this team" : "This slot is available! Join before it is filled."}</span>
                    ) || homeTeamId && <Roster competition={competition} teamId={homeTeamId} canEdit={competition.status === eCOMPETITION_STATUS.WAITING}/>}
                </div>
            </ExpandableBox>

            <ExpandableBox header={awayTeam?.team_name || "Team B" }>
                <div className={classNames(dig.colSm12)}>
                    {(awayTeam === undefined && (
                        (isMatchOwner && competition.status === eCOMPETITION_STATUS.WAITING &&
                                <SearchUser
                                    className={classNames(dig.positionRelative)}
                                    btnClassName={classNames(dig.digBgOrange)}
                                    comp_id={competition?.comp_id}
                                    inviteType={"postInvitation"}
                                    placeholder={"Enter a user's DiG gamertag to invite them."} />

                        ) ||
                        <span>{competition.status === eCOMPETITION_STATUS.CANCELED ? "Nobody joined this team" : "This slot is available! Join before it is filled."}</span>
                    )) || awayTeamId && <Roster competition={competition} teamId={awayTeamId} canEdit={competition.status === eCOMPETITION_STATUS.WAITING}/>}
                </div>
            </ExpandableBox>

        </>

    }

    // Tournament or Bracket

    return <><ExpandableBox
        header={'Fully Registered Teams (' + (competition.complete_teams?.length ?? 0) + ')'}
        startCollapsed={true}
    >
        <div id="show_teams" className={classNames(dig.row, dig.m0)}>
            <div className={classNames("bootstrap-table bootstrap4")}>
                <div className={"fixed-table-toolbar"}></div>

                <div className={"fixed-table-container"}
                     style={{paddingBottom: "0px"}}>
                    <div className={"fixed-table-header"}
                         style={{display: "none"}}>
                    </div>
                    <div className={"fixed-table-body"}>

                        {competition.complete_teams?.map((teamId, index) => {

                            return <Roster key={index} competition={competition}
                                           teamId={teamId}/>;

                        })}

                    </div>
                    <div className={"fixed-table-footer"}>
                        <table>
                            <thead>
                            <tr></tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
            <div className={"clearfix"}></div>
        </div>
    </ExpandableBox><ExpandableBox
        header={'Queue (' + (competition.incomplete_teams?.length ?? 0) + ')'}
        startCollapsed={true}
    >
        <div id="show_teams" className={classNames("row m-0", dig.row,)}>
            <div className={classNames("bootstrap-table bootstrap4")}>
                <div className={"fixed-table-toolbar"}></div>

                <div className={"fixed-table-container"}
                     style={{paddingBottom: "0px"}}>
                    <div className={"fixed-table-header"}
                         style={{display: "none"}}>
                    </div>
                    <div className={"fixed-table-body"}>

                        {competition.incomplete_teams?.map((teamId, index) => {

                            return <Roster key={index} competition={competition}
                                           teamId={teamId}/>;

                        })}

                    </div>
                    <div className={"fixed-table-footer"}>
                        <table>
                            <thead>
                            <tr></tr>
                            </thead>
                        </table>
                    </div>
                </div>
            </div>
            <div className={"clearfix"}></div>
        </div>
    </ExpandableBox></>


}


import classNames from "classnames";
import React, {Suspense} from 'react';
import {BrowserRouter, MemoryRouter} from "react-router-dom";
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {clearCache} from "src/api/API";
import changed from "src/api/hoc/changed";
import getCurrentLoggedInUser from "src/api/hoc/getCurrentLoggedInUser";
import {GlobalHistory} from "src/api/hoc/GlobalHistory";
import logout from "src/api/hoc/logout";
import getOrganizations from "src/api/rest/getOrganizations";
import {ACTIVATION_PATH} from "src/components/Activation/Activation";
import {CHILD_ACTIVATION_PATH} from "src/components/ChildActivation/ChildActivation";
import VerifyParentTrollBridge from "src/components/VerifyParentTrollBridge/VerifyParentTrollBridge";
import isCOM from "src/variables/isCOM";
//import isEdgeBrowser from "src/variables/isEdgeBrowser";
//import isGG from "src/variables/isGG";
import isPRO from "src/variables/isPRO";
import isTest from "src/variables/isTest";
import {clearCompetitionCache} from "src/api/rest/getCompetitions";
import {iPayoutTable, iTournamentPayoutTable} from "src/api/rest/getTournamentPayoutTable";
import Alert, {iAlert} from "src/components/Alert/Alert";
import Chat, {eGLOBAL_CHAT_TABS} from "src/components/Chat/Chat";
import Footer from "src/components/Footer/Footer";
import ForceLegacyPasswordReset from "src/components/ForceLegayPasswordReset/ForceLegacyPasswordReset";
import Header from "src/components/Header/Header";
import SignUpAndIn, {eSignUpAndIn, iSignUpAndIn} from "src/components/Header/Modal/SignUpAndIn";

import ScrollToHere from "src/components/ScrollToHere/ScrollToHere";
import VerifyAccountTrollBridge from "src/components/VerifyAccountTrollBridge/VerifyAccountTrollBridge";
import context from 'src/variables/DropVariables';
import DropVariables, {getStyles} from 'src/variables/DropVariables';
import 'jquery';

/** STYLES
 * Moving forward all styles must be css or scss modules
 * @link https://github.com/css-modules/css-modules
 * @link https://create-react-app.dev/docs/adding-a-css-modules-stylesheet/
 * @note check out CreateCompetition.tsx for an example
 * The module file naming convention is  [name].module.css
 **/
// import "pace-js/pace.min.js";
// import "src/assets/css/pace.scss";
import "@fortawesome/fontawesome-free/css/all.min.css"
import "react-datetime/css/react-datetime.css";
import "dropzone/dist/dropzone.css";
import "dropzone/dist/dropzone.js";

import {iCategories, iChatMessages, iCompetitions, iDigRegistrations, iGame, iGroups, iInvitation, iOrganizations, iResult, iTeam, iUser, iWpPost} from "src/variables/sharedInterfaces";

import {iDig_Features, iDig_Region, iDig_Sub_Mode, iDig_Subscription_Model, iDig_Vendor, iOptions} from "src/variables/C6";

import DropInGaming from "src/DropInGaming";
//import WpAdmin from "src/components/WpAdmin/WpAdmin";
import authenticated from "src/api/hoc/authenticated";
import {ppr} from "src/components/PassPropertiesAndRender/PassPropertiesAndRender";
import {getBrowserLanguage, iLanguage} from "src/variables/supportedLanguages";
import BackendThrowable from "src/views/Errors/BackendThrowable";
import Localhost from "src/views/Errors/Localhost";
import MaintenanceMode from "src/views/Errors/MaintenanceMode";
import Bricked504 from "src/views/Troubleshoot/Bricked504";
import Loading from './components/Loading/Loading';

//import {ReactComponent as ComSVG} from "src/assets/img/svg/DIG-logo-lockup-beta-horizontal-white.svg"
//import {ReactComponent as GgSVG} from "src/assets/img/svg/DIG-logo-lockup-horizontal-white-GG.svg"
import {CookieConsent} from "react-cookie-consent";
import DigWebSocket from "./components/WebSocket/DigWebSocket";
import getRegions from "./api/rest/getRegions";


export interface iBootstrapProperties {
    children?: React.ReactNode | React.ReactNode[] | undefined;
}

export type iRegistrations = iDigRegistrations;

export type tStatefulApiData<T> = T[] | undefined | null;

// this refers to the value types of the keys above, aka values in the state
export interface iRestfulObjectArrayTypes {
    users: tStatefulApiData<iUser>,
    teams: tStatefulApiData<iTeam>,
    games: tStatefulApiData<iGame>,
    payoutTable: tStatefulApiData<iPayoutTable>,
    tournamentPayoutTable: tStatefulApiData<iTournamentPayoutTable>,
    competitions: tStatefulApiData<iCompetitions>,
    subscriptions: tStatefulApiData<iDig_Subscription_Model>,
    globalMessages: tStatefulApiData<iChatMessages>,
    invitations: tStatefulApiData<iInvitation>,
    registrations: tStatefulApiData<iRegistrations>,
    vendors: tStatefulApiData<iDig_Vendor>,
    posts: tStatefulApiData<iWpPost>,
    subModes: tStatefulApiData<iDig_Sub_Mode>,
    results?: tStatefulApiData<iResult>,
    options?: tStatefulApiData<iOptions>,
    features?: tStatefulApiData<iDig_Features>,
    groups?: tStatefulApiData<iGroups>,
    organizations: tStatefulApiData<iOrganizations>,
    categories: tStatefulApiData<iCategories>,
    sliders: tStatefulApiData<iWpPost>,
    regions: tStatefulApiData<iDig_Region>,
}

export type tRestfulObjectArrayKeys = keyof iRestfulObjectArrayTypes

export type tRestfulObjectArrayValues = iRestfulObjectArrayTypes[tRestfulObjectArrayKeys];

// @ts-ignore
export type tRestfulObjectValues = tRestfulObjectArrayValues[number];

// our central container, single page application is best with the bootstrap
export interface iBootstrapState extends iRestfulObjectArrayTypes {
    alertsWaiting: iAlert[],
    authenticating?: boolean,
    axios: import("axios").AxiosInstance,
    backendThrowable: { [key: string]: any }[],
    bootstrap: Bootstrap,
    bricked504: boolean,
    chatInput: string,
    colorLocked: boolean,
    darkMode: boolean,
    id: number,
    invalidPasswordResetKey: string | undefined,
    joinPopup: boolean,
    loginPopup: boolean,
    maintenanceMode: boolean,
    operationActive: boolean,
    rightSideBarTabOpened: eGLOBAL_CHAT_TABS,
    translateToLanguage: iLanguage | undefined,
    websocket?: WebSocket,
    websocketData: any[],
    websocketEvents: MessageEvent[],
    websocketMounted: boolean,
}

export default class Bootstrap extends React.Component<{}, iBootstrapState> {
    // @link https://github.com/welldone-software/why-did-you-render
    static bootstrap: Bootstrap;
    static lastLocation = window.location.pathname;
    static whyDidYouRender = true;

    constructor(props) {

        super(props);

        Bootstrap.bootstrap = this;

        // This should only ever be done here, when the full state is being trashed.
        clearCache({
            ignoreWarning: true
        });

        clearCompetitionCache()

        /** We can think of our app as having one state; this state.
         * Long-term, I'd like us to store this state to local storage and only load updates on reload...
         * Class based components are far easier to manage state in local storage and pass state down to children.
         * Children, if not faced with a local storage or other complexity should be a functional component. Functional
         * components' tend to be shorter syntactically and bonus points if it's stateless.
         **/
        this.state = {
            id: 0,
            maintenanceMode: false,
            alertsWaiting: [],
            chatInput: "",
            bootstrap: this,
            invalidPasswordResetKey: undefined,
            rightSideBarTabOpened: window.innerWidth <= 600 || true === isPRO() ? eGLOBAL_CHAT_TABS.NONE : eGLOBAL_CHAT_TABS.CHAT,
            backendThrowable: [],
            websocketEvents: [],
            websocketData: [],
            axios: context.axios,
            features: undefined,
            groups: undefined,
            payoutTable: undefined,
            subModes: undefined,
            tournamentPayoutTable: undefined,
            authenticating: undefined,
            users: undefined,
            teams: undefined,
            websocketMounted: false,
            competitions: undefined,
            websocket: undefined,
            globalMessages: undefined,
            subscriptions: undefined,
            games: undefined,
            invitations: undefined,
            posts: undefined,
            registrations: undefined,
            options: undefined,
            vendors: undefined,
            translateToLanguage: getBrowserLanguage(),
            bricked504: false,
            colorLocked: true,
            darkMode: true,
            loginPopup: false,
            joinPopup: false,
            operationActive: false,
            organizations: [],
            categories: undefined,
            sliders: undefined,
            regions: undefined,
        };

    }

    websocketTimeout = 1000

    switchDarkAndLightTheme = () => this.setState({darkMode: !this.state.darkMode}, () => {
        document.body.dataset.theme = this.state.darkMode ? "dark" : "light";
    });

    getLoggedInUser = () => this.state.users?.find(user => user.ID === this.state.id)

    shouldComponentUpdate(
        nextProps: Readonly<iBootstrapProperties>,
        nextState: Readonly<iBootstrapState>,
        _nextContext: any): boolean {

        changed(this.constructor.name, "props", this.props, nextProps)
        changed(this.constructor.name, "state", this.state, nextState)

        // this is a conditional to ensure we don't render when the websocket sends an update.
        // we only want to render when a functional callable decides the data is worth rendering in our runtime.
        // A good example is getting your own global message updated through the websocket when we already had the data
        // via ajax. It's easier to do the negative check here than to do the positive check; what shouldn't cause and update,
        // todo - though if updates happen nearly concurrently it will (as it currently seem) be concatenated into one update.
        // todo - this is a good thing, but it means the negative test is not perfect.
        return true;
    }

    componentDidMount() {

        if (window.location.hostname === 'localhost') {

            console.log('window.location.hostname === \'localhost\'');

            return;

        }

        getOrganizations({
            organization_ids: [1]
        })

        getRegions()

        if (undefined === this.state.authenticating) {

            authenticated()

        }


    }

    componentDidUpdate(_prevProps: Readonly<iBootstrapProperties>, _prevState: Readonly<iBootstrapState>, _snapshot?: any) {
        if (Bootstrap.lastLocation !== location.pathname) {
            Bootstrap.lastLocation = location.pathname;
            const websocket = this.state.websocket;
            if (websocket?.readyState === WebSocket.OPEN) {
                websocket.send('location: ' + location.pathname)
                console.log(location.pathname)
            }
        }
    }

    render() {

        const {bootstrap, id, rightSideBarTabOpened, maintenanceMode} = this.state


        if (maintenanceMode && undefined === localStorage.getItem('bypassMaintenanceMode')) {
            return <MaintenanceMode/>
        }


        console.log("BOOTSTRAP TSX RENDER");

        const colorHex = '#' + Math.random().toString(16).slice(-6);

        if (false === this.state.colorLocked) {

            // what a simple way to track re-renders, when the canvas nest changes colors the app was rerender from the root
            document.documentElement.style
                .setProperty('--dig_primary_color', colorHex);

        }


        if (DropVariables.isLocal && false === isTest) {

            // console.log("color", '(' + color + ')')

        }

        if (undefined === this.state.authenticating) {

            switch (window.location.hostname) {
                case 'localhost': {

                    return <Localhost/>

                }

                default: {

                    if (this.state.bricked504) {

                        return <Bricked504/>

                    }

                }

            }

        }

        if (this.state.backendThrowable.length > 0 && true === this.state.backendThrowable[0]?.expanded) {

            return <>
                <BackendThrowable/>
            </>

        }

        const websocket = this.state.websocket

        if (DropVariables.isLocal && websocket !== undefined) {

            if (websocket?.readyState !== WebSocket.OPEN) {

                return <>
                    <Loading icon={true} count={3} message={"Connecting to the websocket for live updates!"}/>
                </>

            }

        }

        const currentUser = getCurrentLoggedInUser()

        if (0 !== this.state.id &&
            (undefined === currentUser ||
                1 === Object.keys(currentUser).length)) {

            return <>
                <Loading icon={true} count={1} message={"Gathering your information..."}/>
            </>

        }

        const isLoggedIn = 0 !== id
        const dig = getStyles()

        const chatClosed = rightSideBarTabOpened === eGLOBAL_CHAT_TABS.NONE

        if(chatClosed){
            document.body.classList.remove(dig.chatOpened);
            document.body.classList.add(dig.chatClosed);
        }else{
            document.body.classList.remove(dig.chatClosed);
            document.body.classList.add(dig.chatOpened);
        }


        // if (isEdgeBrowser) {
        //
        //     return <>
        //         {isGG()
        //             ? <GgSVG style={{width: "155px", display: "flex", fill: color + " !important"}}/>
        //             : <ComSVG style={{width: "204px", display: "flex", fill: color + " !important"}}/>}
        //         <div style={{color: "white", fontSize: "1.5rem", textAlign: "center"}}>
        //             <p>
        //                 Oh no! Edge does not support the amazing features we have ready for you.
        //                 Please choose another browser ie. Chrome, Firefox, Safari, Opera, Brave, etc.
        //             </p>
        //         </div>
        //     </>
        //
        // }

        if (0 !== id) {

            if (bootstrap.state.invalidPasswordResetKey !== undefined) {
                return <>
                    <ForceLegacyPasswordReset/>
                </>
            }

            if (currentUser?.user_status === 0) {

                // todo - make this email or phone number verification

                // todo - be more explicit about the url
                if (!window.location.href.split('/').includes(ACTIVATION_PATH)) {
                    return <>
                        <VerifyAccountTrollBridge/>
                    </>
                }

            } else if (!isCOM() && currentUser?.isChild && currentUser?.parents?.length === 0) {

                // child parent troll bridge

                if (window.location.href.split('/').includes(CHILD_ACTIVATION_PATH)) {
                    console.log("CHILD_ACTIVATION_PATH", window.location.href)
                    logout();
                    return <></>
                }

                return <>
                    <VerifyParentTrollBridge/>
                </>

            }

        }

        const reactRouterContext = (children: any) => {

            if (isTest) {
                return <MemoryRouter initialEntries={['/']}>{children}</MemoryRouter>
            }

            return <BrowserRouter>{children}</BrowserRouter>

        }

        return <Suspense fallback={<Loading message={'Loading Dynamic Features...'}/>}>
            <Alert/>
            {true === this.state.authenticating
                ? <Loading icon={true} count={1} message={"Dropping In..."}/>
                : reactRouterContext(<>
                    <GlobalHistory/>
                    <DigWebSocket/>
                    <div data-theme={'dark'} className={classNames({
                        [dig.justifyContentEnd]: false === isLoggedIn
                    })}>

                        <Header/>

                        {false === isLoggedIn
                        && (bootstrap.state.loginPopup || bootstrap.state.joinPopup)
                        && ppr<iSignUpAndIn>(SignUpAndIn, {
                            display: (() => {
                                if (bootstrap.state.loginPopup) {
                                    return eSignUpAndIn.LOGIN_POPUP
                                } else if (bootstrap.state.joinPopup) {
                                    return eSignUpAndIn.JOIN_POPUP
                                }
                                return eSignUpAndIn.NONE
                            })(),
                            handleClose: () => {
                                bootstrap.setState({loginPopup: false, joinPopup: false})
                            },
                        })}

                        <main role="main" className={classNames(dig.main,
                            {[dig.loggedIn]: isLoggedIn})}>

                            <div className={classNames(dig.row, dig.g0)}>
                                <div
                                    className={classNames(dig.col12, dig.mAuto)}>
                                    <div className={dig.row}>
                                        <ScrollToHere/>
                                        <DropInGaming/>
                                    </div>
                                </div>
                                {(false === isPRO() || currentUser?.isParent) && <Chat/>}
                            </div>
                            <Footer/>
                        </main>
                    </div>
                    {localStorage.getItem('cookieConsent') === 'true' ? null :
                        <CookieConsent
                            debug
                            buttonStyle={{
                                background: 'var(--dig_primary_color)',
                            }}
                            onAccept={() => {
                                localStorage.setItem('cookieConsent', 'true')
                            }}
                        >
                            This website uses cookies to enhance the user experience.
                        </CookieConsent>
                    }

                    <ToastContainer/>
                </>)}
        </Suspense>

    }

}


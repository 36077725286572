export default function zoom(): string{
    return `
        if(jQuery('#bracket').length > 0){
                
                jQuery(document).ready(function(){
                    const element       = document.getElementById('bracket'),
                     zoomInButton       = document.getElementById('zoom-in'),
                     zoomOutButton      = document.getElementById('zoom-out'),
                     resetButton        = document.getElementById('reset');
                     let panzoom        = {};
                     
                     
                     if((jQuery('.jQBracket').width() + 400) < jQuery('.bracket_container').width()){
                         panzoom = Panzoom(element, {
                            disablePan: true
                        });
                         jQuery('#findMe').hide();
                     }else{
                         let bracketCon = jQuery('.bracket_container').offset(),
                            bracketClass = jQuery('.bracket').offset();
                            console.log('bracketCon',bracketCon);
                        panzoom = Panzoom(element, {
                            minScale: 0.5,
                            maxScale: 3,
                            startX: bracketCon.left - bracketClass.left 
                        });
                        
                     }
                    zoomInButton.addEventListener('click', panzoom.zoomIn);
                    zoomOutButton.addEventListener('click', panzoom.zoomOut);
                    resetButton.addEventListener('click', panzoom.reset);
                })
            }
    `;
}

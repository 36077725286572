import restApi, {iPostC6RestResponse, POST, removeInvalidKeys} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {
    C6, dig_comp_registration,
    iDig_Comp_Registration,
    iDig_Competitions_Meta,
    iDig_Vendor,
} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {eCOMPETITION, iCompetitions, iInvitation} from "src/variables/sharedInterfaces";
import deleteRestfulObjectArrays from "src/api/hoc/deleteRestfulObjectArrays";
import getInvitations from "./getInvitations";
import getUsers from "./getUsers";
import isTest from "../../variables/isTest";

export interface iPostRegistration extends iDig_Comp_Registration {
    user_id: number,
    competitions_id: number,
    team_id: number,
    vendor_id: number,
    vendor_gamertag: string,
    meta_value?: string,
    vendor_name: string
}

export function postRegistrationCallback(request) {

    console.warn("request data: ", request);
    // request has all the infos
    updateRestfulObjectArray<iDig_Comp_Registration>((_prev) => {
        const requestFiltered: any = removeInvalidKeys(request, [dig_comp_registration]);
        let preComp = _prev.competitions?.find(competition => competition.comp_id === request.competitions_id)

        requestFiltered.comp_datetime = preComp?.datetime
        requestFiltered.end_datetime = preComp?.end_datetime
        requestFiltered.status = preComp?.status
        requestFiltered.sub_mode = preComp?.sub_mode

        return [requestFiltered]
    }, "comp_registration_id", "registrations")


    updateRestfulObjectArray<iCompetitions>((prev): iCompetitions[] => {
        console.warn('prev state misbehaving: ', prev);
        let prevComp = prev.competitions?.find(competition => competition.comp_id === request.competitions_id),
            data: iCompetitions[] = [{
                comp_id: request.competitions_id,
                registered_users: [
                    ...prevComp?.registered_users || [],
                    request.user_id
                ]
            }];

        if (undefined === prevComp || prevComp?.complete_teams?.includes(request.team_id) || prevComp?.incomplete_teams?.includes(request.team_id)) {
            // currently there is no way to prevent a socket update event from firing if we are the user who sent the initial request,
            // so returning here instead of throwing an error because this otherwise becomes a race condition with the socket callback.
            console.warn("socket update sent to the shadow realm");
            return [];
        }

        if (prevComp.comp_type_id === eCOMPETITION.MATCHES && prevComp.organizer_id === 1) {

            data[0].organizer_id = request.user_id

        }

        if (prevComp?.team_size === 1) {
            data[0].complete_teams = [
                ...prevComp?.complete_teams || [],
                request.team_id
            ]
        } else {
            data[0].incomplete_teams = [
                ...prevComp?.incomplete_teams || [],
                request.team_id
            ]
        }

        return data;
    }, "comp_id", "competitions")

    deleteRestfulObjectArrays<iInvitation>((previousState): iInvitation[] => {
        // get the objects to delete only
        return previousState.invitations?.filter(invitation => invitation.user_id === request.user_id && invitation.comp_id === request.competitions_id) ?? []
    }, "invitation_id", "invitations")

    getInvitations({cacheResults: false});

}

export default restApi<iPostRegistration, iDig_Comp_Registration & iDig_Competitions_Meta & iDig_Vendor, {}, iPostC6RestResponse<iDig_Comp_Registration & iDig_Competitions_Meta & iDig_Vendor>>({
    axios: DropVariables.axios,
    tableName: [C6.dig_comp_registration.TABLE_NAME, C6.dig_competitions_meta.TABLE_NAME, C6.dig_vendor.TABLE_NAME],
    requestMethod: POST,
    queryCallback: (request) => {
        request.position = 1
        request.success ??= 'Successfully registered to the competition!'
        request.error ??= 'An unknown issue occurred during your competition registration.'
        return request
    },
    responseCallback: (_response, request, id) => {

        if ('number' !== typeof id) {
            return;
        }


        request.comp_registration_id = id;

        postRegistrationCallback(request);

        // re-fetch user for updated wallet
        false === isTest && getUsers({
            userIds: [
                request.user_id
            ],
            cacheResults: false,
        })

    }
});

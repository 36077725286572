import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
//import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Features} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";

export default restApi<{}, iDig_Features, {}, iPostC6RestResponse<iDig_Features>>({
    axios: DropVariables.axios,
    tableName: C6.dig_features.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Feature created!'
        request.error ??= 'There was an issue creating this feature. Please try again.'
        return request;
    },
    responseCallback: (_response, request, id) => {

        if ('number' !== typeof id) {
            return;
        }

        updateRestfulObjectArray<iDig_Features>([
            {
                ...request,
                feature_entity_id: id,
            }
        ], "feature_entity_id", "features")

    }
});


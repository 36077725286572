import {useState} from "react";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {toast} from 'react-toastify';
import {iUser} from "src/variables/sharedInterfaces";
import {addAlert, iAlertButtonOptions} from '../Alert/Alert';
import classNames from "classnames";
import Button from "./Button";
import {getStyles} from "src/variables/DropVariables";
import putUserInfo from "../../api/rest/putUserInfo";

export default function ActivationButton(props: {
    enabledText: string,
    disabledText: string,
    user: iUser,
}) {
    const [isOnClick, setIsOnClick] = useState(false);

    const updateCurrentEditUser = (currentEditUser: iUser) => {
        updateRestfulObjectArray<iUser>([currentEditUser], "ID", "users")
    }

    const dig = getStyles()

    return (
        <Button
            onClick={(_e) => {

                setIsOnClick(true);

                const activeStatus = props.user.user_active == 1 ? 0 : 1;

                const alertButtons : iAlertButtonOptions[] = [
                    {
                        text: "No, Cancel",
                        value: 'cancel',
                        className: "gg__swal_cancel_btn",
                        color: "default",
                    },
                    {
                        text: activeStatus == 1 ? "Yes, Activate" : "Yes, Hold",
                        value: 'yes',
                        className: activeStatus == 1 ? "gg__swal_confirm_btn" : "gg__swal_hold_btn btn-danger",
                        color: activeStatus == 1 ? "success" : "danger",
                    }];


                addAlert({
                    title: activeStatus == 1 ? "Activate Account" : "Place Account on Hold",
                    text: activeStatus == 1 ? "Reactivating this account will allow the player to play and spend coins." : "A hold on this account will stop the player from entering games and spending coins.",
                    icon: "warning",
                    dangerMode: true,
                    buttons: alertButtons,
                    then: (value ) => {

                        alert(value)

                        putUserInfo({
                            user_id   : props.user.ID,
                            user_active: activeStatus,
                        })

                        setIsOnClick(false);
                        updateCurrentEditUser({
                            ...props.user,
                            user_active: activeStatus,
                        });
                        toast.success(
                            activeStatus == 1
                                ? "Account Activated Successfully"
                                : "Account Placed on Hold Successfully"
                        );
                    },
                });
            }}
            type="button"
            className={classNames(dig.btn, dig.btnSecondary, dig.textWhite, dig.px2, dig.py2, dig.fwBold, dig.ms2)}
        >
            {
                isOnClick ?
                    "Loading..." :
                    (props.user.user_active) === 0 ? props.enabledText : props.disabledText
            }
        </Button>
    )

}

import restApi, {GET, iGetC6RestResponse} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Organizations} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iOrganizations} from "src/variables/sharedInterfaces";


export default restApi<{
    organization_ids?: number[],
}, iDig_Organizations, {}, iGetC6RestResponse<iDig_Organizations & {
    features: string,
    groups: string,
    users: string,
}>>({
        axios: DropVariables.axios,
        tableName: C6.dig_organizations.TABLE_NAME,
        requestMethod: GET,
        queryCallback: (request) => {
            let query = {
                [C6.SELECT]: [
                    C6.dig_organizations.ORGANIZATION_ID,
                    C6.dig_organizations.ORGANIZATION_NAME,
                    [C6.GROUP_CONCAT, C6.dig_features.FEATURE_ENTITY_ID, 'features'],
                    [C6.GROUP_CONCAT, C6.dig_groups.ENTITY_ID, 'groups'],
                    [C6.GROUP_CONCAT, C6.dig_user_organizations.USER_ID, 'users'],
                ],
                [C6.JOIN]: {
                    [C6.LEFT]: {
                        [C6.dig_user_organizations.TABLE_NAME]: {
                            [C6.dig_user_organizations.ORGANIZATION_ID]: C6.dig_organizations.ORGANIZATION_ID,
                        },
                        [C6.dig_groups.TABLE_NAME]: [
                            C6.dig_organizations.ORGANIZATION_ID,
                            C6.dig_groups.ORGANIZATION_ID
                        ],
                        [C6.dig_group_references.TABLE_NAME]: [
                            C6.dig_groups.ENTITY_ID,
                            C6.dig_group_references.GROUP_ID
                        ],
                        [C6.dig_feature_group_references.TABLE_NAME]: [
                            C6.dig_feature_group_references.GROUP_ENTITY_ID,
                            C6.dig_groups.ENTITY_ID
                        ],
                        [C6.dig_features.TABLE_NAME]: [
                            C6.dig_feature_group_references.FEATURE_ENTITY_ID,
                            C6.dig_features.FEATURE_ENTITY_ID
                        ],
                    }
                },
                [C6.WHERE]: {},
                [C6.GROUP_BY]: [
                    C6.dig_organizations.ORGANIZATION_ID
                ],
                [C6.PAGINATION]: {
                    [C6.LIMIT]: 100
                }
            }

            if (request.organization_ids?.length) {
                query[C6.WHERE][C6.dig_organizations.ORGANIZATION_ID] = [C6.IN, request.organization_ids]
            }

            return query

        },
        responseCallback: (response, _request) => {

            let updatedOrganizations: iOrganizations[] = []

            response?.data?.rest?.forEach((organization) => {

                updatedOrganizations.push({
                    ...organization,
                    features: organization.features?.split(',').map(group => parseInt(group)) ?? [],
                    groups: organization.groups?.split(',').map(group => parseInt(group)) ?? [],
                    users: organization.users?.split(',').map(user => parseInt(user)) ?? [],
                })

            })

            updateRestfulObjectArray<iDig_Organizations>(updatedOrganizations, "organization_id", "organizations")

        }

    }
)

import restApi, {iPostC6RestResponse, POST} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Organizations} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";

export default restApi<{
    organization_name: string,
    organization_owner: number,
}, iDig_Organizations, {}, iPostC6RestResponse<iDig_Organizations>>({
    axios: DropVariables.axios,
    tableName: C6.dig_organizations.TABLE_NAME,
    requestMethod: POST,
    queryCallback: (request) => {
        request.success ??= 'Organization created!'
        request.error ??= 'There was an issue creating this organization. Please try again.'
        return request;
    },
    responseCallback: (_response, request, id) => {

        if ('number' !== typeof id) {
            return;
        }

        updateRestfulObjectArray<iDig_Organizations>([
            {
                ...request,
                organization_id: id,
            }
        ], "organization_id", "organizations")

    }
});


import {AxiosResponse} from "axios";
import {toast} from "react-toastify";
import {setCookies} from "src/api/hoc/axiosInterceptors";
import Bootstrap from "src/Bootstrap";
import DropVariables from "src/variables/DropVariables";

export default async function logout(userDeleted = false): Promise<null|AxiosResponse<any>> {

    console.groupCollapsed('%c logout', 'color: #cc0')
    console.trace();
    console.groupEnd()

    const bootstrap = Bootstrap.bootstrap;

    if (0 === bootstrap.state.id) {

        toast.error('Failed to change the logged in status! Please contact Drop-In Gaming if problems persist.', DropVariables.toastOptions)

        return null;

    }

    const clearCookies = () => setCookies(document.cookie.split(";").map(function(c) {

        // we need to keep some cookies, even removing all and setting back immediately will run into race conditions
        if (c.includes('dropDeveloper') || c.includes('XDEBUG_SESSION')) {

            return c;

        }

        return c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");

    }));

    const clearState = () => bootstrap.setState({
        id: 0,
        websocketEvents: [],
        websocketData: [],
        alertsWaiting: [],
    });

    if (false === userDeleted) {

        clearState()

        let promise = await bootstrap.state.axios.get('/logout/');

        clearCookies();

        // redirect hard to clear local state
        window.location.href = window.location.href + '#logout';

        return promise;

    }

    localStorage.clear();

    clearState()

    clearCookies();

    return null;

}
import restApi, {GET} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iDig_Vendor} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";


export default restApi({
            axios: DropVariables.axios,
            tableName: C6.dig_vendor.TABLE_NAME,
            requestMethod: GET,
            queryCallback: (request) => {
                return request
            },
            responseCallback: (response, _request) => {
                updateRestfulObjectArray<iDig_Vendor>((response?.data?.rest || []), "vendor_id", "vendors")
            }
        }
    )

import restApi, {iPutC6RestResponse, PUT} from "src/api/API";
import updateRestfulObjectArray from "src/api/hoc/updateRestfulObjectArrays";
import {C6, iUsermeta} from "src/variables/C6";
import DropVariables from "src/variables/DropVariables";
import {iUser} from "src/variables/sharedInterfaces";

export interface iPutUserMeta extends iUsermeta {
    user_id: number,
    responseCallback?: (id: boolean | string | number) => void
}

export default restApi<iPutUserMeta, iUsermeta, {}, iPutC6RestResponse<iPutUserMeta>>({
        axios: DropVariables.axios,
        tableName: C6.usermeta.TABLE_NAME,
        requestMethod: PUT,
        queryCallback: (request) => {
            request.success = 'Successfully updated user metadata!'
            request.error = 'An unknown issue occurred while updating the user metadata. We will be looking into this shortly.'
            return request
        },
        responseCallback: (response, request, id) => {


            if ('number' !== typeof id) {
                throw new Error("id is not a number")
            }

            updateRestfulObjectArray<iUser>(previousState => {

                const currentEditUser = previousState.users?.find(user => user.ID === request.user_id)

                const currentEditUserMeta = currentEditUser?.userMeta || [];

                const updatedMeta: iUsermeta = response?.data?.rest;

                const updatedUserMeta: iUsermeta[] = [
                    ...currentEditUserMeta.filter(metaValue => metaValue.meta_key !== request.meta_key),
                    updatedMeta
                ];

                return [{
                    ID: request.user_id,
                    userMeta: updatedUserMeta
                }]
            }, "ID", "users")

            request.responseCallback?.(id)

        }
    });

import React, {MouseEvent, useState} from "react";
import {iUser} from "src/variables/sharedInterfaces";
import postTeamRoster from "src/api/rest/postTeamRoster";
import getUsernames from "src/api/rest/getUsernames";
import classNames from "classnames";
import {getStyles} from "src/variables/DropVariables";
import Bootstrap from "src/Bootstrap";
import postInvitation from "src/api/rest/postInvitation";
import OutsideClickHandler from "react-outside-click-handler";

interface iSearchUser extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    id?: string,
    onClick?: (e: MouseEvent<HTMLButtonElement>) => void,
    children?: React.ReactNode | React.ReactNode[] | undefined;
    isSelectable?: boolean,
    isSelected?: boolean,
    to?: string,
    href?: string,
    ref?: any,
    label?: string | undefined | React.ReactNode,
    teamId?: number,
    comp_id?: number,
    inviteType?: string,
    btnClassName?: string,
    placeholder?: string
}

export default function SearchUser(props: iSearchUser) {
    const {
        teamId,
        comp_id,
        inviteType = 'postTeamRoster',
        className,
        btnClassName,
        placeholder = 'DIG Username'
    } = props;

    const {id} = Bootstrap.bootstrap.state

    const [outsideClick, setOutsideClick] = useState(false)
    const [searchText, setSearchText] = useState('');
    const [onSearch, setOnSearch] = useState(false);
    const [userList, setUserList] = useState<iUser[]>([]);
    const [inviteeID, setInviteeID] = useState<number>(0);
    const [timer, setTimer] = useState<number>(0);

    const onClickInvite = (e) => {
        e.preventDefault();

        // have to set this here because can only set consts in switch statements
        let rosterSubmitData = {
            uid: inviteeID,
            user_team_id: teamId,
            success: (_response) => {
                setInviteeID(0);
                return undefined;
            }
        };

        switch (inviteType) {
            case 'postTeamRoster':
                if (comp_id !== undefined) {
                    rosterSubmitData['comp_id'] = comp_id;
                }

                postTeamRoster(rosterSubmitData);
                break;
            case 'postInvitation':
                postInvitation({
                    user_id: inviteeID,
                    sender_id: id,
                    team_id: undefined,
                    comp_id: comp_id,
                    invitation_type: 'match'
                });
                break;
        }

    }

    const inputChanged = e => {
        setSearchText(e.target.value);
        setOnSearch(true);
        setInviteeID(0);
        window.clearTimeout(timer);
    }

    const onKeyUpChange = () => {
        const newTimer: undefined | number | ReturnType<typeof setTimeout> = window.setTimeout(() => {
            getUsernames({
                usernameSearch: searchText,
                cacheResults: false,
                success: (response) => {
                    setUserList(response?.data?.rest || [])
                }
            })
            setOnSearch(false);
        }, 300);

        setTimer(newTimer);
    }

    const dig = getStyles()

    return <div className={classNames(className)}>
        <OutsideClickHandler onOutsideClick={() => {
            setOutsideClick(true)
            setSearchText('');
        }}>
            <div className={classNames(dig.inputGroup, "pickup-roster", dig.border2, dig.borderColorNeutral2, dig.p0)}>

                <input placeholder={placeholder}
                       maxLength={50}
                       type="text"
                       value={searchText}
                       required
                       className={classNames(dig.formControl, dig.border0, dig.digBgNeutral6, dig.rounded0, dig.textWhite, dig.p3, "dig-invite-roster", dig.h100, "ui-autocomplete-input")}
                       aria-describedby="inputGroup-sizing-default"
                       onChange={inputChanged}
                       onKeyUp={onKeyUpChange}
                       onFocus={() => {
                           setOutsideClick(false)

                       }}
                       autoComplete="off"/>
                <div className={"input-group-append"}>

                    <button
                        onClick={onClickInvite}
                        className={classNames(btnClassName, dig.fwBold, dig.btn, dig.btnOutlineSecondary, dig.py3, dig.px5, dig.rounded0, dig.border0, dig.digBgNeutral2, "dig-invite-roster", dig.fontSize1Em, dig.textWhite)}
                        type="button">{
                        onSearch === true ? 'Searching...' : 'INVITE'
                    }
                    </button>


                </div>
            </div>

            {
                outsideClick === false && userList.length > 0 && inviteeID === 0 ?
                    <ul className={classNames(dig.uiAutocomplete)}>
                        {
                            userList.map(user =>
                                <li
                                    onClick={() => {
                                        if (undefined !== user.display_name) {
                                            setSearchText(user.display_name);
                                        }
                                        setInviteeID(user.ID); // system account?
                                    }}
                                    key={user.ID}
                                    className={classNames(dig.uiMenuItem)}>
                                    <div className={classNames(dig.uiMenuItemWrapper)}>{user.display_name}</div>
                                </li>
                            )
                        }

                    </ul>
                    :
                    ''
            }
        </OutsideClickHandler>
    </div>
}
